/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC} from 'react';

/* prettier-ignore */
export const TermsOfServiceFI: FC = () => {
  return (
    <div
      css={(theme) => css`
        white-space: pre-line;
        overflow-y: auto;
        padding: ${theme.spacing(2)}px;
        margin: ${theme.spacing(1)}px;
      `}
    >
      {`KASVULUOTAIN on Alltech Fennoaqua Oy:n kehittämä ja rakentama, älypuhelimella ja tietokoneella toimiva sovellus (”sovellus”),
      joka on tarkoitettu kalanruokinnan suunnittelun, ohjauksen, toteutuksen ja sen seurannan apuvälineeksi,
      sekä kalojen biomassan kasvun seurantavälineeksi.

      Alltech Fennoaqua ylläpitää tätä sovellusta palveluna kalanviljelyalalla toimiville yrityksille.
      Järjestelmään kuuluu ns. työpöytäosa, jota käytetään pc:llä sekä päätelaitteeseen ladattava
      mobiilisovellus, jota käytetään joko puhelimella tai tablet-tietokoneella. Käyttäjän on itse
      hankittava sovelluksen edellyttämä laite ja tietoliikenneyhteydet ja asennettava sovellus.
      Alltech Fennoaqua ei takaa eikä vastaa, että sovellusta voidaan käyttää Käyttäjän laitteella.

      Alltech Fennoaqua vastaa omien tietojärjestelmiensä osalta niiden tietoturvan
      asianmukaisesta ylläpidosta.

      KÄYTTÖEHDOT
      Asentamalla ja käyttämällä Alltech Fennoaquan sovellusta käyttäjä hyväksyy sovelluksen
      käyttöehdot sekä Kasvuluotaimen oheislaitteiden takuuehdot. Lista oheislaitteista löytyy
      kohdasta takuuehdot.

      SISÄLLÖN PAIKKANSAPITÄVYYS
      Tässä sovelluksessa annetut tiedot annetaan käyttäjien käyttöön "sellaisenaan" ilman
      minkäänlaista nimenomaisesti ilmaistua tai oletettua takuuta tai mitään kaupankäynnin
      kohteeksi soveltuvuutta, tiettyyn tarkoitukseen soveltuvuutta tai oikeuksien
      loukkaamattomuutta koskevaa takuuta. Alltech Fennoaqua tekee kaiken kohtuullisesti
      oletettavan voidakseen tarjota tarkkoja ja ajantasaisia tietoja, mutta se ei kuitenkaan takaa
      millään tavalla tietojen todenperäisyyttä tai täydellisyyttä. Alltech Fennoaqua voi ajoittain
      lisätä, muuttaa, parantaa tai päivittää sovelluksessa olevia tietoja ilman erillistä ilmoitusta.
      Alltech Fennoaqualla on oikeus etäpäivittää kasvuluotaimeen liitettyjen laitteiden
      ohjelmistoa. Alltech Fennoaqua ei ota mitään vastuuta mistään sovelluksen sisällön virheistä
      tai mistään pois jätetyistä tiedoista. Alltech Fennoaqua ei takaa, että sovellus tai sitä
      ylläpitävät palvelimet eivät sisällä mitään haittaohjelmia tai muita haitallisia elementtejä.

      KÄYTTÖOIKEUDEN RAJOITUKSET JA TEKIJÄNOIKEUDET
      Sovellus on tekijänoikeudellisesti suojattua aineistoa Copyright © Alltech Fennoaqua Oy
      2023. Kaikki oikeudet pidätetään.

      Sisällön kopioiminen kokonaan tai osittain (linkittämällä suoraan tai muulla tavalla),
      kappaleiden valmistaminen, sisällön siirtäminen, jakelu, edelleen luovutus ja esittäminen on
      kiellettyä ilman Alltech Fennoaquan etukäteen antamaa kirjallista lupaa.
      Sovellusta saa käyttää ja tallentaa vain käyttäjän omaa, henkilökohtaista käyttöä varten.
      Sovelluksessa olevia Alltech Fennoaquan omistusoikeutta ilmaisevia merkintöjä ei saa
      muuttaa eikä poistaa.

      Alltech Fennoaqualla ei ole velvoitetta luovuttaa sovelluksen lähdekoodia. Avoimen
      lähdekoodin (open source) osalta lähdekoodin luovutukseen sovelletaan kyseisen avoimen
      lähdekoodin käyttöoikeusehtoja. Luovutettavissa olevan lähdekoodin Alltech Fennoaqua voi
      antaa käyttöön kustannusten korvausta vastaan.

      LINKIT KOLMANSIEN OSAPUOLIEN SIVUSTOILLE
      Alltech Fennoaqualla ei ole vastuuta sovellukseen mahdollisesti linkitettyjen sivustojen
      sisällöstä tai mistään tällaisilla linkitetyillä sivustoilla olevista linkeistä, joita Alltech
      Fennoaqua ei hallinnoi. Tällaiset linkit on tarkoitettu vain käyttäjien avuksi, eivätkä ne
      tarkoita sitä, että Alltech Fennoaqua tukisi millään tavalla linkitetyn sivuston sisältöä tai
      palveluntarjoajaa. Alltech Fennoaqua ei ole missään tilanteessa vastuussa tähän
      sovellukseen linkitettyjen kolmansien osapuolten sivustojen sisällöstä tai kyseisten
      kolmansien osapuolten toimista.

      MATERIAALIEN TOIMITTAMINEN
      Alltech Fennoaqua käsittelee käyttäjän sovellukseen syöttämiä tietoja luottamuksellisesti
      eikä luovuta niitä kolmansille osapuolille ilman käyttäjän nimenomaista suostumusta.
      Käyttäjän henkilötiedot tallennetaan Kasvuluotain-sovelluksen käyttäjärekisteriin ja niitä
      käsitellään henkilötietolain edellyttämällä tavalla (linkki: Kasvuluotain-sovelluksen
      käyttäjärekisterin rekisteriseloste).
      Alltech Fennoaqua on kuitenkin oikeutettu käyttämään Kasvuluotain-sovelluksen kautta
      kerättyjä tietoja liiketoiminnan, ruokintaosaamisen ja rehukehityksen kehittämisessä.
      Käyttäjä antaa Alltech Fennoaqualle maksuttoman, rajoittamattoman ja peruuttamattoman
      oikeuden käyttää tietoja Alltech Fennoaquan markkinoinnissa osana laajempia useamman
      yrityksen yhteenvetoja tai yksittäisinä tuloksina. Tällaisissa yhteenvedoissa yksittäisen
      käyttäjän tiedot eivät kuitenkaan ole mitenkään tunnistettavia.
      Näitä käyttöoikeusehtoja lukuun ottamatta mitään sovelluksessa tehtyjä toimia ei voida
      pitää minkäänlaisena sopimuksena Alltech Fennoaquan ja sovelluksen käyttäjän välillä.

      SALASANA
      Käyttäjä ei saa paljastaa rekisteröitymisen yhteydessä antamaansa salasanaa muille.
      Käyttäjän tulee viipymättä vaihtaa salasanansa ja ilmoittaa Alltech Fennoaqualle, jos hän
      epäilee salasanansa joutuneen luvattoman kolmannen henkilön tietoon tai käyttäjän
      tietoturvaa on rikottu. Alltech Fennoaqua ei ole vastuussa eikä korvausvelvollinen, jos
      käyttäjän salasanan tietoonsa saanut kolmas taho käyttää sovellusta.

      VASTUUVAPAUSLAUSEKE
      Käyttäjä käyttää tätä sovellusta omalla kustannuksellaan, riskillään ja vastuullaan. Ellei
      pakottavasta lainsäädännöstä muuta johdu, Alltech Fennoaqua, sen tavaran- tai
      palveluntoimittajat tai mikään muu kolmas osapuoli, joka on osallistunut tämän sovelluksen
      sisällön luomiseen, tuottamiseen tai toimittamiseen, ei ole missään olosuhteissa minkään
      lainsäädännön nojalla vastuussa käyttäjälle tai kenellekään muulle osapuolelle koituneista
      välittömistä vahingoista, välillisistä vahingoista, erikoisvahingoista, seuraamuksen
      luonteisista korvauksista, oheisvahinkojenkorvauksista, liitännäisvahingoista tai välillisistävahingoista,
      jotka johtuvat tämän sovelluksen käytöstä. Sovelluksessa oleva tieto saattaa
      sisältää esimerkiksi haittaohjelmia tai ohjelmavirheitä Alltech Fennoaqua ei ole vastuussa
      mistään lähettämiensä tai vastaanottamiensa tietojen luvattomasta muuttamisesta,
      haittaohjelmista, ohjelmavirheistä eikä mistään niiden seurauksista.

      Kasvuluotaimen laskentatoiminnallisuudet perustuvat Alltech Fennoaquan
      ruokintataulukoihin sekä kalankasvatusyrityksen arviorehukertoimiin ja mahdollisesti
      omavalinteisiin ruokintamuutoksiin. Alltech Fennoaquan ruokintataulukoiden arvot ovat
      suuntaa-antavia arvoja. Niiden tarkkuus riippuu ympäristöolosuhteista, joista tärkeimmät
      ovat veden lämpötila ja veden happipitoisuus. Ruokintataulukoiden arvot perustuvat
      olosuhteisiin, joissa kasvatuslaitokselta poistuvan veden minimihappipitoisuus on 7 mg litraa
      kohden. Lisäksi käyttäjän tulee huomioida, että sovellus on apuväline, jonka antamat tiedot
      perustuvat käyttäjän sovellukseen syöttämille tiedoille. Näin ollen sovelluksen kautta saadut
      tulokset eivät välttämättä vastaa todellisuutta ja käyttäjä vastaa yksin ruokintapäätöksistä
      ja kasvatuksen lopputuloksesta.

      Alltech Fennoaqua ei ole vastuussa ruokinta-automaatin ohjauksen tai ruokinta-automaatin
      aiheuttamista välittömistä vahingoista, välillisistä vahingoista, erikoisvahingoista,
      seuraamuksen luonteisista korvauksista, oheisvahinkojen korvauksista, liitännäisvahingoista
      tai välillisistä vahingoista.

      TAKUUEHDOT
      Alltech Fennoaqua myöntää myymilleen ja toimittamilleen oheislaitteille (AquaControlkontrolliyksikkö,
      kamera, happi, lämpötila ja rehutason anturit) takuun, joka kattaa
      materiaali ja valmistusvirheiden korjauksen Suomessa. Käyttäjän on ilmoitettava
      takuupyyntö aina Alltech Fennoaqualle.
      Takuun pituus laitteittain ostopäivästä lukien:
      AquaControl-kontrolliyksikkö
      • 12 kuukautta
      Kamera
      • 12 kuukautta
      Happi, lämpötila ja rehutason anturit
      • 12 kuukautta
      Takuu kattaa seuraavat palvelut:
      • Tuoteperäisten virheiden korjaukset kun:
      o Asennusympäristö täyttää asetetut vaatimukset
      o Korjauksen suorittaa Alltech Fennoaqua Oy:n edustaja tai valtuuttama kolmas
      osapuoli
      o Palveluaika arkipäivisin klo 08.00–16.00
      o Alltech Fennoaqua varaa oikeuden myös vaihtaa tuote uuteen tai vastaavaan
      käytettyyn tuotteeseen
      o Palvelukieli on suomi ja englanti.

      o Mahdollinen korvaava tuote toimitetaan ilman lisävarusteita tai tarvikkeita
      o Korvaava tuote tai takuuhuollossa käytetyt varaosat voivat olla myös
      käytettyjä tuotteita, joiden toiminta on uudenveroista

      Takuu ei kata:
      • Normaalisti kuluneiden osien vaihtoa
      • Ohjelmistoja tai niiden osia
      • Muiden valmistajien tuotteita

      Takuu ei kata vikoja, joiden aiheuttaja on:
      • Käyttöohjeiden tai mahdollisten asennusohjeiden vastainen käyttö
      • Asennusympäristöstä aiheutuneet vauriot
      • Käyttäjän tekemät muutokset laitteeseen tai asennuksiin
      • Vääränlainen tai puutteellinen huolto, etenkin jos huollon on suorittanut muu kuin

      Alltech Fennoaquan valtuuttama taho
      • Tahallisesti tai törkeällä huolimattomuudella aiheutettu vika
      • Virtapiikki, mukaan lukien salamanisku
      • Laite, joka ei ole Alltech Fennoaquan myymä
      • Kolmannen osapuolen työstä tai komponentista syntyneitä vaurioita

      Jos vika johtuu yllämainituista seikoista, Alltech Fennoaqua pidättää oikeuden laskuttaa
      käyttäjää korjauspalveluista. Takuussa vaihdetuista tuotteista tulee Alltech Fennoaquan
      omaisuutta. Tuotteen vaihto ei johda uuteen takuuseen, vaan vaihdetun/korjatun tuotteen
      takuu kestää alkuperäisen tuotteen takuuajan loppuun. Alltech Fennoaqua varaa oikeuden
      edellyttää käyttäjän tekevän käyttäjän vaihdettavaksi tarkoitettujen yksiköiden vaihdon
      annettujen ohjeiden mukaisesti itse sekä tarvittaessa edellyttää käyttäjän lataamaan ja
      käyttämään viimeisimpiä ja soveltuvimpia ohjelmistokorjauksia/-päivityksiä ennen laitteen
      takuuhuoltoa. Alltech Fennoaqua ei ole vastuussa välillisistä vahingoista, liiketoiminnallisista
      tappioista tai voittojen menetyksestä, mukaan lukien välillisistä vahingoista, jotka johtuvat
      esimerkiksi sovelluksen tai laitteen toimintahäiriöstä, toimimattomuudesta tai
      kyvyttömyydestä tarjota palvelua.

      MUUTOKSET
      Alltech Fennoaqualla on oikeus muuttaa näitä käyttöehtoja, milloin tahansa ilman
      ennakkoilmoitusta.

      TILIN SULKEMINEN
      Alltech Fennoaqua varaa oikeuden käyttäjän sovelluksessa olevan tilin sulkemiseen, jos
      käyttäjä rikkoo näitä käyttöehtoja tai tili on ollut käyttämättä yli 6 kuukautta. Mikäli tili
      suljetaan edellä mainitulla tavalla, Alltech Fennoaqualla on oikeus ilman
      korvausvelvollisuutta hävittää käyttäjän tilillä olleet tiedot. Alltech Fennoaqua varaa lisäksi
      oikeuden olla myöntämättä käyttäjälle uutta tiliä sovelluksen toistuvan väärinkäytön tai
      näiden käyttöehtojen olennaisen rikkomisen perusteella. Käyttäjällä on oikeus, milloin
      tahansa lopettaa sovelluksen käyttö ilmoittamalla siitä Alltech Fennoaqualle.

      SOVELLETTAVA LAKI JA ERIMIELISYYDET
      Näihin käyttöehtoihin sovelletaan Suomen lakia, lukuun ottamatta sen
      lainvalintasäännöksiä. Näistä käyttöehdoista aiheutuvat riidat ratkaistaan lopullisesti
      välimiesmenettelyssä Keskuskauppakamarin välimiesmenettelysääntöjen mukaisesti.
      Välimiesoikeus on yksijäseninen. Välimiesmenettelyn paikka on Helsinki, Suomi.
      Välimiesmenettelyn kieli on suomi.

      Copyright © Alltech Fennoaqua Oy 2023. Kaikki oikeudet pidätetään.
    `}
    </div>
  );
};
