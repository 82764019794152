
export const voltageToPercents = (voltage: number, type: '12v' | '24v') => {
  const min = type === '12v' ? 11.00 : 23.02;
  const max = type === '12v' ? 12.80 : 25.46;
  let percents = ((voltage - min) / (max - min)) * 100;
  if (Math.round(percents) >= 100) percents = 100;
  else if(Math.round(percents) <= 0) percents = 0;

  return percents;
}
