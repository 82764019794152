/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@material-ui/core';
import { Column, useTable } from 'react-table';

export const KLTable = <T extends object>({
  data,
  columns,
  summaryRowCount = 0,
  narrow = false,
}: {
  data: T[];
  columns: Column<T>[];
  summaryRowCount?: number;
  narrow?: boolean;
}) => {
  const theme = useTheme();
  const tableInstance = useTable({
    columns,
    data,
    initialState: {},
  });

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    getTableBodyProps,
  } = tableInstance;

  const getRowStyle = (i: number) =>
    summaryRowCount === (i + 1)
      ? { borderBottom: `2px solid ${theme.palette.primary.main}` }
      : {};

  return (
    <TableContainer>
      <Table aria-label="user table" {...getTableProps()} size="small">
        <TableHead>
          {headerGroups.map((headerGroup, index) => (
            <TableRow
              {...headerGroup.getHeaderGroupProps()}
              style={getRowStyle(-1)}
            >
              {headerGroup.headers.map((column) => (
                <TableCell
                  key={column.id}
                  component="th"
                  css={narrow ? css`
                    &.MuiTableCell-root {
                      padding-left: 0;
                      padding-right: 0;
                    }
                    &.MuiTableCell-root + &.MuiTableCell-root {
                      padding-left: 6px;
                      padding-right: 0;
                    }
                  ` : null}
                >
                    {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()} style={getRowStyle(i)} hover>
                {row.cells.map((cell) => (
                  <TableCell
                    component="td"
                    css={narrow ? css`
                      &.MuiTableCell-root {
                        padding: 0;
                      }
                      &.MuiTableCell-root + &.MuiTableCell-root {
                        padding: 0 0 0 6px;
                      }
                    ` : css`
                      &.MuiTableCell-root {
                        padding-top: 0;
                        padding-bottom: 0;
                      }
                    `}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
