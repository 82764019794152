/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { FC } from 'react';
import { Icon } from '@iconify/react';
import cogLine from '@iconify/icons-clarity/cog-line';
import { useIntl } from 'react-intl';
import { Tooltip } from '@material-ui/core';

const Button = styled.div<{ disabled: boolean }>`
  height: 40px;
  width: 40px;
  margin: 10px;
  background-color: ${({ theme, disabled }) =>
    disabled ? '#F3F3F3' : theme.palette.buttonbg.main};
  color: ${({ theme, disabled }) =>
    disabled ? '#C2C2C2' : theme.palette.text.primary};
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const SettingsButton: FC<{
  disabled: boolean;
  disabledTooltipTextId: string;
  onClick?: any;
}> = ({ disabled, disabledTooltipTextId, onClick }) => {
  const intl = useIntl();
  return (
    <Tooltip
      title={disabled ? intl.formatMessage({ id: disabledTooltipTextId }) : ''}
      aria-label="modifying settings not allowed"
      arrow
      css={css`
        font-size: 28px;
      `}
    >
      <Button
        role="button"
        disabled={disabled}
        onClick={!disabled ? onClick : null}
      >
        <Icon height={20} icon={cogLine} />
      </Button>
    </Tooltip>
  );
};
