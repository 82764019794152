/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Box, Button, Theme, Typography } from '@material-ui/core';
import { FormattedMessage as M } from 'react-intl';
import { CalibrationPopOver } from './calibration-popover';
import { FeedingScheduleModal } from './feeding-schedule/feeding-schedule-modal';
import { useState } from 'react';

export const FeederSettings = () => {
  const buttonCss = (theme: Theme) => css`
    &.MuiButton-root {
      margin-top: ${theme.spacing(2)}px;
      box-shadow: 0px 3px 3px rgba(0, 88, 100, 0.15);
      :last-child {
        margin-bottom: ${theme.spacing(1)}px;
      }
      border: 1px solid ${theme.palette.primary.main};
      background-color: ${theme.palette.background2.main};
      color: ${theme.palette.primary.main};
      :hover {
        background-color: ${theme.palette.hover.main};
      }
    }
  `;
  const [feedingSchedule, setFeedingSchedule] = useState(false);

  return (
    <Box
      css={(theme) => css`
        flex: 0 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: ${theme.spacing(2)}px;
        margin-right: ${theme.spacing(0)}px;
        margin-bottom: ${theme.spacing(1)}px;
      `}
    >
      <Button
        fullWidth
        color="primary"
        variant="contained"
        type="submit"
        css={buttonCss}
        onClick={() => setFeedingSchedule(true)}
      >
        <Typography component="span" variant="h5">
          <M id="FeedingSchedule" />
        </Typography>
      </Button>
      <CalibrationPopOver />
      {feedingSchedule && (
        <FeedingScheduleModal handleClose={() => setFeedingSchedule(false)} />
      )}
    </Box>
  );
};
