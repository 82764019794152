/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Box } from '@material-ui/core';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { useStore } from 'stores/store-hooks';
import { FeederAutomation } from './tabs/feeder-automation/feeder-automation-tab';
import { HistoryListView } from './tabs/event-and-command-history/history-list-view';
import { GraphTabs } from './tabs/graphs/graph-tabs';
import { SiloTab } from './tabs/silo/silo-tab';
import { CommentsTab } from './tabs/comments/comments-tab';
import { GrowthFactorsTab } from './tabs/growth-factors/growth-factors-tab';
import { HarvestingTab } from './tabs/harvesting/harvesting-tab';
import {
  MEDIA_BREAKPOINT_LG,
  MEDIA_BREAKPOINT_MD,
  MEDIA_BREAKPOINT_SD,
} from '../../constants';

export const TabContentContainer = () => {
  const { id } = useParams<{ id: string }>();
  const basinStore = useStore('basinStore');

  return (
    <Box
      css={(theme) => css`
        flex: 1 1 100%;
        display: flex;
        border-radius: 5px;
        background-color: ${theme.palette.common.white};
        min-height: 520px;
        height: max-content;
        width: 100%;
        @media (max-width: ${MEDIA_BREAKPOINT_LG}px) {
          width: 950px;
        }
        @media (max-width: ${MEDIA_BREAKPOINT_MD}px) {
          width: 750px;
        }
        @media (max-width: ${MEDIA_BREAKPOINT_SD}px) {
          width: 600px;
        }
      `}
    >
      <Switch>
        {basinStore.hasIotAutomation && (
          <Route exact path="/basin/:id/feeder-automation">
            <FeederAutomation />
          </Route>
        )}
        <Route path="/basin/:id/graphs">
          <GraphTabs />
        </Route>
        <Route exact path="/basin/:id/silo">
          <SiloTab />
        </Route>
        <Route exact path="/basin/:id/growth-factors">
          <GrowthFactorsTab />
        </Route>
        <Route exact path="/basin/:id/harvesting">
          <HarvestingTab />
        </Route>
        <Route exact path="/basin/:id/event-history">
          <HistoryListView type="EventHistory" />
        </Route>
        <Route exact path="/basin/:id/comments">
          <CommentsTab />
        </Route>
      {/*  {basinStore.hasIotAutomation && (
          <Route exact path="/basin/:id/command-history">
            <HistoryListView type="CommandHistory" hideFilters />
          </Route>
        )}*/}
        <Redirect
          exact
          path="*"
          to={
            basinStore.hasIotAutomation
              ? `/basin/${id}/feeder-automation`
              : `/basin/${id}/graphs`
          }
        />
      </Switch>
    </Box>
  );
};
