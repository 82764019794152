import { ISimpleSite } from './site';
import { ICompany } from './company';

export enum SEASON_MODES {
  Automatic = 'A',
  Static = 'S',
}

export enum SEASONS {
  Spring = 'spring',
  Summer = 'summer',
  Autumn = 'autumn',
}

export interface IFacility {
  url?: string;
  id: number;
  name?: string;
  company?: ICompany;
  deleted?: boolean;
  seasonmode?: string;
  staticseason?: any;
  seasonstartsummer?: string;
  seasonstartautumn?: string;
  season?: string;
  translated_season?: string;
  sites?: ISimpleSite[];
  address?: string;
  street_number?: string;
  route?: string;
  neighborhood?: string;
  locality?: string;
  postal_code?: string;
  country?: string;
  place_id?: string;
}
