/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { LinearProgress } from '@material-ui/core';
import { FC } from 'react';

export interface ICustomProgressBar {
  value: number;
  progressColor: string;
}

export const CustomProgressBar: FC<ICustomProgressBar> = ({
  value,
  progressColor,
}) => {
  return (
    <LinearProgress
      variant="determinate"
      value={value}
      css={(theme) => css`
        &.MuiLinearProgress-root {
          border: 1px solid ${theme.palette.line2.main};
          height: 8px;
          border-radius: 4px;
          &.MuiLinearProgress-colorPrimary {
            background-color: ${theme.palette.background2.main};
          }
          .MuiLinearProgress-bar1Determinate {
            background-color: ${progressColor};
            border-radius: 4px;
          }
        }
      `}
    />
  );
};
