import {makeAutoObservable, runInAction} from 'mobx';
import {addPackages, addProducts, deletePackages, modifyProducts, updateFeedTypes,} from 'services/api';
import {IPackage, IProduct} from 'models/orders';
import {IFeedType} from 'models/silo';
import orderStore from './orders-store';
import toastStore from './toast-store';

const createFeedType = (
  product: IProduct,
  pkg: IPackage,
  deleted: boolean = false
) => ({
  deleted: deleted,
  url: "",
  productid: Number(pkg.productCode),
  name: (product.description + " " + pkg.description),
  kind: (product.materialGroups[2] === "Starter feeds"
          || product.materialGroups[2] === "65F") ? "starter" : (
    (product.materialGroups[1] === "Rainbow trout feeds"
        || product.materialGroups[1] === "610") ? "rainbowtrout" : (
      (product.materialGroups[1] === "White fish and sturgeon feeds"
          || product.materialGroups[1] === "612") ? "whitefish" : "other"
  )),
  phosphoruspercentage: String(pkg.phosphorPercentage),
  nitrogenpercentage: String(pkg.nitrogenPercentage),
});

class ProductListStore {
  constructor() {
    makeAutoObservable(this);
  }

  packageIdsToDelete: number[] = [];
  packagesToAdd: Record<number, IPackage[]> = {}; // Maps product ids -> pkgs
  productsToAdd: IProduct[] = [];
  productsToModify: IProduct[] = [];

  get noUpdates() {
    if (this.productsToModify.length > 0) return false;
    return this.productsToAdd.filter((p) => p.packages.length).length === 0
      && this.packageIdsToDelete.length === 0
      && Object.keys(this.packagesToAdd).length === 0;
  }

  resetUpdates = () => {
    runInAction(() => {
      this.packageIdsToDelete = [];
      this.packagesToAdd = {};
      this.productsToAdd = [];
      this.productsToModify = [];
    });
  };

  saveUpdates = async () => {
    try {
      const addToRestApi: IFeedType[] = Object.keys(
        this.packagesToAdd
      ).map(Number).flatMap(k => this.packagesToAdd[k].map(
        pkg => createFeedType(
          orderStore.products.find(p => p.id === k) as IProduct,
          pkg
        )
      )).concat(this.productsToAdd.flatMap(
        product => product.packages.map(pkg => createFeedType(product, pkg))
      ));

      const markAsDeleted = this.packageIdsToDelete.flatMap(id => {
        const pkg = orderStore.products.flatMap(p => p.packages)
                      .find(pkg => pkg.id === id) as IPackage;

        if (addToRestApi.find(ft => String(ft.productid) === pkg.productCode)) {
          return [];
        }

        return [createFeedType(
          orderStore.products.find(
            p => !!p.packages.find(x => x.id === id)
          ) as IProduct,
          pkg,
          true
        )];
      });

      await Promise.all([
        addPackages(Object.keys(this.packagesToAdd).map(Number).map((k) => ({
          product: k,
          packages: this.packagesToAdd[k],
        }))),
        addProducts(this.productsToAdd.filter((p) => p.packages.length)),
        updateFeedTypes(addToRestApi.concat(markAsDeleted)),
        modifyProducts(this.productsToModify)
      ]);
      // Do this last, so that we don't lose products when all of their packages
      // are replaced
      await deletePackages(this.packageIdsToDelete);
    } catch (error) {
      toastStore.setToast("UpdateProductsFailed");
    }
  };

  addPackageToDeleteList = (packageId: number) => {
    runInAction(() => {
      if (this.packageIdsToDelete.indexOf(packageId) < 0) {
        this.packageIdsToDelete.push(packageId);
      }
    });
  };

  removePackageFromDeleteList = (packageId: number) => {
    runInAction(() => {
      const index = this.packageIdsToDelete.indexOf(packageId);
      if (index >= 0) {
        this.packageIdsToDelete.splice(index, 1);
      }
    });
  };

  addPackageToProductList = (productId: number, pkg: IPackage) => {
    runInAction(() => {
      if (!this.packagesToAdd[productId]) {
        this.packagesToAdd[productId] = [];
      }

      this.packagesToAdd[productId].push(pkg);
    });
  };

  removePackageFromProductList = (productId: number, pkg: IPackage) => {
    runInAction(() => {
      if (!this.packagesToAdd[productId]) {
        return;
      }

      const index = this.packagesToAdd[productId].indexOf(pkg);
      if (index >= 0) {
        this.packagesToAdd[productId].splice(index, 1);
      }
    });
  };

  addProductToAddList = (product: IProduct) => {
    runInAction(() => {
      this.productsToAdd.push(product);
    });
  };

  removeProductFromAddList = (product: IProduct) => {
    runInAction(() => {
      const index = this.productsToAdd.indexOf(product);
      if (index >= 0) {
        this.productsToAdd.splice(index, 1);
      }
    });
  };

  addPackageToProductInAddList = (product: IProduct, pkg: IPackage) => {
    runInAction(() => {
      const index = this.productsToAdd.indexOf(product);
      if (index >= 0) {
        this.productsToAdd[index].packages.push(pkg);
      }
    });
  };

  removePackageFromProductInAddList = (product: IProduct, pkg: IPackage) => {
    runInAction(() => {
      const index = this.productsToAdd.indexOf(product);
      if (index >= 0) {
        const pkgIndex = this.productsToAdd[index].packages.indexOf(pkg);
        if (pkgIndex >= 0) {
          this.productsToAdd[index].packages.splice(pkgIndex, 1);
        }
      }
    });
  };

  renameProductDescription = (product: IProduct, description: string) => {
    runInAction(() => {
      product.description = description;
      const modifiedProduct = this.productsToModify.find((e) => e.id === product.id);
      if (modifiedProduct) {
        modifiedProduct.description = description;
      }
      else {
        this.productsToModify.push({...product, description: description});
      }
    });
  }
}

export default new ProductListStore();
