/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { UserModel } from 'models/user';
import styled from '@emotion/styled';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { Column, useGlobalFilter, useSortBy, useTable } from 'react-table';
import { UserListToolBar } from './user-list-toolbar';

interface IUserTable {
  users: UserModel[];
  columns: Column<UserModel>[];
  companyId: number | null;
}

const Root = styled(Box)`
  width: 100%;
  // border-top: ${({ theme }) => `1px solid ${theme.palette.grey[500]}`};
`;

export const UserTable: FC<IUserTable> = observer(
  ({ companyId, users, columns }) => {
    const tableInstance = useTable(
      {
        columns,
        data: users,
        initialState: {
          // @ts-ignore
          sortBy: [
            {
              id: 'username',
              desc: false,
            },
          ],
        },
      },
      useGlobalFilter,
      useSortBy
    );

    const {
      getTableProps,
      headerGroups,
      prepareRow,
      rows,
      getTableBodyProps,
    } = tableInstance;

    const {
      setGlobalFilter,
      state: { globalFilter },
    } = tableInstance as any;

    const getColumnDirection = (column: any) => {
      if (column.isSorted) {
        return column.isSortedDesc ? 'desc' : 'asc';
      }
    };

    return (
      <Root>
        <UserListToolBar
          companyId={companyId}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
        />
        <TableContainer>
          <Table aria-label="user table" {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      component="th"
                      // @ts-ignore
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {
                        <div
                          css={css`
                            display: flex;
                          `}
                        >
                          {column.render('Header')}
                          {
                            // @ts-ignore
                            column.isSorted && (
                              <TableSortLabel
                                active
                                direction={getColumnDirection(column)}
                              />
                            )
                          }
                        </div>
                      }
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()} hover>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell component="td" {...cell.getCellProps()}>
                          <Typography component="div" variant="h6">
                            {cell.render('Cell')}
                          </Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Root>
    );
  }
);
