import {
  AverageWeightGraph,
  BiomassGraph,
  CumFeedAmount,
  FeedAmountGraph,
  FeedPercentageGraph,
} from "./graphs";


export const tabs = [
  {
    value: 0,
    path: `/biomass`,
    labelId: 'Biomass',
    component: BiomassGraph,
  },
  {
    value: 1,
    path: `/average-weight`,
    labelId: 'AverageWeight',
    component: AverageWeightGraph,
  },
  {
    value: 2,
    path: `/feed-amount`,
    labelId: 'FeedAmount',
    component: FeedAmountGraph,
  },
  {
    value: 3,
    path: `/feed-percentage`,
    labelId: 'FeedingPercentage',
    component: FeedPercentageGraph,
  },
  {
    value: 4,
    path: `/cun-feed-amount`,
    labelId: 'CumulativeFeedAmount',
    component: CumFeedAmount,
  }
];
