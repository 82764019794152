import { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import { FormattedMessage as M } from 'react-intl';
import { Grid, Link, Typography } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {ICompany} from 'models/company';
import { Modal, ModalActions, ModalContent } from 'components/modal-dialog';
import { companyDialogModel } from './company-dialog-model';
import { useForm } from 'react-hook-form';
import { useStore } from 'stores/store-hooks';

interface ICompanyDialog {
  handleClose: () => void;
  company?: ICompany | null;
  updateCompany: (
    companyId: number | undefined,
    data: ICompany
  ) => Promise<any>;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required('error.validation.name')
    .max(256, 'error.validation.name'),
  businesscode: yup
    .string()
    .required('error.validation.businesscode')
    .max(32, 'error.validation.businesscode'),
  customerid: yup
    .string()
    .required('error.validation.customerid')
    .max(32, 'error.validation.customerid'),
  // TODO: make additional_customerids use YUP validation
  phonenumber: yup.string().max(32, 'error.validation.phonenumber'),
  website: yup.string().max(200, 'error.validation.website'),
  address: yup.string().max(128, 'error.validation.address'),
});

export const CompanyDialog = ({
  company,
  handleClose,
  updateCompany,
}: ICompanyDialog) => {

  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    control
  } = useForm<ICompany>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: company || {},
  });
  const toastStore = useStore('toastStore');

  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

  const submitData = async (data: ICompany) => {

    const isNullOrEmpty = (v: string | undefined | null) => v === null || v === undefined || v.trim() === '';

    const removeEmptyAdditionalCustomerIds = () => {
      data.additional_customer_accounts = data.additional_customer_accounts?.filter(
        account => Object.values(account).find(v => !isNullOrEmpty(v)));
    }

    const additionalCustomerIdsHasRequired = () => {
      const acids = data.additional_customer_accounts;
      if(acids === null || acids === undefined || acids.length === 0) return true;
      const nonValid = acids.find(account =>
        !(isNullOrEmpty(account.customerid) || isNullOrEmpty(account.address) ||
          isNullOrEmpty(account.locality) || isNullOrEmpty(account.postal_code))
      );
      return !!nonValid;
    }

    const additionalCustomerIdsHasDuplicates = () => {
      if(data.additional_customer_accounts === null || data.additional_customer_accounts === undefined) return false;
      const existingCustomerIds = company?.customerid ? [company?.customerid] : [];

      const duplicate = data.additional_customer_accounts.find(account => {
        const isDuplicate = existingCustomerIds.find(cid => cid === account.customerid)
        existingCustomerIds.push(account.customerid);
        return isDuplicate;
      });
      return !!duplicate;
    }

    removeEmptyAdditionalCustomerIds();
    if(!additionalCustomerIdsHasRequired()) {
      toastStore.setToast('validation.error.additional_customer_ids_empty');
      return;
    }

    if(additionalCustomerIdsHasDuplicates()) {
      toastStore.setToast('validation.error.additional_customer_ids_unique');
      return;
    }

    const { errors } = await updateCompany(company?.id, data);
    if (errors?.customerid) {
      setError('customerid', {
        type: 'server',
        message: 'error.validation.customerid.notAccepted',
      });
      return;
    }
    if (errors) {
      // some other error -> show generic error note
      toastStore.setToast('validation.error.unexpected');
      return;
    }
    // success
    toastStore.setToast('CompanyCreated', 'success');
    handleClose();
  };

  return (
    <Modal handleClose={handleClose}>
      <form noValidate onSubmit={handleSubmit(submitData)}>
        <ModalContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography component="span" variant="subtitle2">
                {company ? <M id="EditCompany" /> : <M id="AddCompany" />}
              </Typography>
            </Grid>
            {companyDialogModel.mainContent.map((item) => {
              const Component = item.component;
              // @ts-ignore
              const itemError = errors?.[item.name];
              return (
                <Grid item xs={item.size} key={item.name}>
                  <Component
                    name={item.name}
                    id={`company-dialog-${item.name}`}
                    label={<M id={item.labelId} />}
                    inputRef={register}
                    error={!!itemError}
                    helperText={itemError && <M id={`${itemError?.message}`} />}
                    {...item.componentProps}
                  />
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Link
                role="button"
                href="#"
                underline="always"
                onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
              >
                {showAdditionalInfo ? (
                  <M id="HideAdditionalInformation" />
                ) : (
                  <M id="ShowAdditionalInformation" />
                )}
              </Link>
            </Grid>
            {showAdditionalInfo && (
              <Fragment>
                {companyDialogModel.additionalContent.map((item) => {
                  const Component = item.component;
                  // @ts-ignore
                  const itemError = errors?.[item.name];
                  return (
                    <Grid item xs={item.size} key={item.name}>
                      <Component
                        name={item.name}
                        id={`company-dialog-${item.name}`}
                        label={<M id={item.labelId} />}
                        inputRef={register}
                        error={!!itemError}
                        control={control}
                        helperText={
                          itemError && <M id={`${itemError?.message}`} />
                        }
                        {...item.componentProps}
                      />
                    </Grid>
                  );
                })}
              </Fragment>
            )}
          </Grid>
        </ModalContent>
        <ModalActions>
          <Button
            color="primary"
            type="submit"
            disabled={!!Object.keys(errors).length}
          >
            <M id="Save" />
          </Button>
          <Button
            onClick={() => {
              clearErrors();
              handleClose();
            }}
            color="primary"
          >
            <M id="Cancel" />
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};
