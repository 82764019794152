/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import styled from '@emotion/styled';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import MuiTableRow from '@material-ui/core/TableRow';
import { Column, useSortBy, useTable } from 'react-table';
import { IFishBasin } from 'models/fishbasin';

interface IBasinTable {
  basins: IFishBasin[];
  columns: Column<IFishBasin>[];
  siteId: number | null;
  archiveMode?: boolean; // Is archive mode active
}

export const BasinTable: FC<IBasinTable> = observer(
  ({ siteId, basins, columns, archiveMode }) => {
    const automationAvailable = (basins: IFishBasin[]) => {
      return basins.some((b) => b.supportunit_id);
    };
    const hiddenColumns = automationAvailable(basins) ? [] : ['supportunit_id'];

    const tableInstance = useTable(
      {
        columns,
        data: basins,
        initialState: {
          // @ts-ignore
          sortBy: [
            {
              id: 'name',
              desc: true,
            },
          ],
          hiddenColumns,
        },
      },
      useSortBy
    );

    const {
      getTableProps,
      headerGroups,
      prepareRow,
      rows,
      getTableBodyProps,
    } = tableInstance;

    const getColumnDirection = (column: any) => {
      if (column.isSorted) {
        return column.isSortedDesc ? 'desc' : 'asc';
      }
    };
    return (
      <Root>
        <TableContainer>
          <Table
            aria-label="user table"
            {...getTableProps()}
            css={(theme) => css`
              &.MuiTable-root {
                border-collapse: inherit;
                border-spacing: 0 ${theme.spacing(2)}px;

                .MuiTableHead-root .MuiTableCell-root {
                  padding-bottom: 0;
                  padding-top: 0;
                  border-bottom: none;
                  line-height: 14px;
                }
              }
            `}
          >
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      component="th"
                      align="center"
                      // @ts-ignore
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {
                        <div
                          css={css`
                            display: flex;
                            justify-content: center;
                          `}
                        >
                          {column.render('Header')}
                          {
                            // @ts-ignore
                            column.isSorted && (
                              <TableSortLabel
                                active
                                direction={getColumnDirection(column)}
                              />
                            )
                          }
                        </div>
                      }
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <StyledTableRow
                    archiveMode={archiveMode}
                    {...row.getRowProps()}
                    hover
                  >
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          component="td"
                          {...cell.getCellProps()}
                          align="center"
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Root>
    );
  }
);

const Root = styled(Box)`
  width: 100%;
  border-top: ${({ theme }) => `1px solid ${theme.palette.grey[500]}`};
`;

const RowBorderStyle = (
  archiveMode?: boolean,
  highlightBorder?: string,
  defaultBorder?: string
): string => {
  if (archiveMode && highlightBorder) return highlightBorder;
  return defaultBorder || 'transparent';
};

const StyledTableRow = styled(
  ({
    //@ts-ignore - FIXME: styled component specific typing issues
    archiveMode,
    //@ts-ignore
    ...props
  }) => <MuiTableRow {...props} />
)<{ archiveMode?: boolean }>`
  &.MuiTableRow-root {
    .MuiTableCell-body:first-of-type {
      border-radius: 15px 0 0 15px;
      border-left: ${({ theme, archiveMode }) =>
        `1px solid ${RowBorderStyle(
          archiveMode,
          theme.palette.warning.main,
          theme.palette.line2.main
        )}`};
    }
    .MuiTableCell-body:last-child {
      border-right: ${({ theme, archiveMode }) =>
        `1px solid ${RowBorderStyle(
          archiveMode,
          theme.palette.warning.main,
          theme.palette.line2.main
        )}`};
      border-radius: 0 15px 15px 0;
    }
    .MuiTableCell-body {
      background-color: ${({ theme }) => theme.palette.common.white};
      border-top: ${({ theme, archiveMode }) =>
        `1px solid ${RowBorderStyle(
          archiveMode,
          theme.palette.warning.main,
          theme.palette.line2.main
        )}`};
      border-bottom: ${({ theme, archiveMode }) =>
        `1px solid ${RowBorderStyle(
          archiveMode,
          theme.palette.warning.main,
          theme.palette.line2.main
        )}`};
      box-shadow: 0px 4px 5px -3px rgba(29, 54, 61, 0.05);
      margin: 0;
      padding: 0;
    }
  }
`;
