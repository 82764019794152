/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FormattedMessage as M } from 'react-intl';
import { InlineIcon } from '@iconify/react';
import closeOutline from '@iconify-icons/carbon/close-outline';
import { FC } from 'react';
import {
  Box,
  Button,
  Typography,
  Theme,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';

export const StopFeedingButton: FC<{
  handleClick: () => void;
}> = observer(({ handleClick }) => {

  const buttonTypographyStyle = (theme: Theme) => css`
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: ${theme.spacing(1)}px;
    > svg {
      margin-right: ${theme.spacing(1)}px;
    }
  `;

  return (
    <Box display="flex" alignItems="center">
      <Button
        fullWidth
        color="primary"
        variant="contained"
        type="submit"
        onClick={() => handleClick()}
      >
        <Typography component="span" variant="h5" css={buttonTypographyStyle}>
          <InlineIcon icon={closeOutline} height={24} />
          <M id="StopFeeding" />
        </Typography>
      </Button>
    </Box>
  );
});
