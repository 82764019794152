import translationsEn from './en.json';
import translationsFi from './fi.json';
import translationsRu from './ru.json';
import translationsSv from './sv.json';
import logo from 'img/raisioaqua_kasvuluotain_logo_nega.png';
import logoEn from 'img/raisioaqua_kasvuluotain_en_logo_nega.png';
import logoRu from 'img/raisioaqua_kasvuluotain_ru_logo_nega.png';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';
import fiLocale from 'date-fns/locale/fi';
import svLocale from 'date-fns/locale/sv';
import { Locale, format, formatDistanceStrict } from 'date-fns';
import { IntlShape } from 'react-intl';

export const localeMap: Record<string, Locale> = {
  en: enLocale,
  fi: fiLocale,
  ru: ruLocale,
  sv: svLocale,
};

const translations: Record<string, Record<string, string>> = {
  en: translationsEn,
  fi: translationsFi,
  ru: translationsRu,
  sv: translationsSv,
};

/*
 * Default to English when a string isn't available for a particular language.
 * Theoretically we should be passing a default value to intl.formatMessage()
 * every time we call it, but it's safer to do this than to change every call
 * throughout the codebase.
 */
export const getTranslations = (language: string) => ({
  ...translations['en'],
  ...translations[language],
});

export const localizedDuration = (intl: IntlShape, start: Date, end: Date) =>
  formatDistanceStrict(start, end, { locale: localeMap[intl.locale] });

export const shortDuration = (s: number) => format(new Date(s * 1000), 'mm:ss');

export const formatDate = (intl: IntlShape, date: Date) =>
  intl.locale === "sv" ? format(date, 'dd.MM.yyyy') : intl.formatDate(
    date,
    { day: 'numeric', month: 'numeric', year: 'numeric' }
  );

export const dateAndTime = (intl: IntlShape, date: Date) =>
  formatDate(intl, date) + ' ' + intl.formatMessage({ id: 'AtTime' }) + ' '
    + format(date, 'HH:MM');

export const dateFormatString = (intl: IntlShape) =>
  intl.formatDate(new Date("2000-10-23"), {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
  }).replace("23", "dd").replace("10", "MM").replace("2000", "yyyy");

export const localizedLogo = (intl: IntlShape) =>
  intl.locale === 'en' || intl.locale === 'sv'
    ? logoEn
    : intl.locale === 'ru'
    ? logoRu
    : logo;
