/** @jsxRuntime classic */
/** @jsx jsx */
import {jsx} from '@emotion/react';
import {FC} from 'react';

/* todo some reason @emotion/react wont work here like it does on terms of service!? */

/* prettier-ignore */
export const PrivacyPolicyFI: FC = () => {
  return (
    <div
      style={{
        whiteSpace: 'pre-line',
        overflowY: 'auto',
        padding: '16px',
        margin: '8px',
      }}
    >
      {`Alltech Fennoaqua Oy:n tietosuojakäytäntö
      Päivitetty 26.6.2023
      Alltech Fennoaqua Oy suojaa asiakkaidensa, potentiaalisten asiakkaidensa ja
      muiden verkkosivustoillaan vierailevien yksityisyyttä tämän
      tietosuojakäytännön mukaisesti. Tietosuojakäytäntö koskee kaikkia Alltech
      Fennoaquan verkkosivustoja, tuotteita ja palveluja, joiden yhteydessä Alltech
      Fennoaqua kerää henkilötietoja
      Rekisterinpitäjällä tarkoitetaan näissä ehdoissa Alltech Fennoaqua Oy:tä.
      Yhteystietomme löydät tämän sivun lopusta.
      Alla kerromme, miten ja mitä tarkoitusta varten käsittelemme henkilötietojasi.
      Henkilötietojen käsittelyn perusteet
      Henkilötietojen käsittely tarkoittaa muun muassa henkilötietojen keräämistä,
      tallentamista, säilyttämistä, muokkaamista tai muuttamista sekä käyttämistä ja
      luovuttamista tai siirtämistä.
      Henkilötietojesi käsittelylle on aina lain sallima peruste, kuten
      • sopimus
      • antamasi nimenomainen suostumus henkilötietojesi käsittelyyn,
      • meillä on henkilötietojesi käsittelyyn muu oikeutettu, liiketoimintamme
      hoitamiseen perustuva syy tai
      • meidän täytyy käsitellä henkilötietojasi lakisääteisen velvoitteen takia.
      Kerättävät henkilötiedot
      Jotta voit käyttää tai voimme tarjota sinulle palveluitamme, meidän täytyy
      yleensä pyytää sinulta tiettyjä henkilötietoja.
      Tallennamme sinulta antamiasi henkilötietoja:
      nimi, sähköpostiosoite, puhelinnumero, postiosoite, sukupuoli,
      syntymäaika, kieli
      • annetut suostumukset ja kiellot
      • yhteydenotot asiakaspalveluumme ja lähettämäsi viestit sekä antamasi
      palaute
      • osallistumisesi kampanjoihimme ja kilpailuihimme
      • henkilökohtaiset kiinnostuksesi kohteet
      • valokuva
      • yrityksen/työnantajan nimi ja yhteystiedot, ammattinimike
      • ruokavaliotiedot tai terveystiedot järjestämiämme tilaisuuksia tai
      kampanjoita varten
      Emme kerää tai käsittele arkaluonteisia henkilökohtaisia tietoja. Sellaisia tietoja
      voit kuitenkin joutua antamaan meille työnhaun tai työsuhteen yhteydessä tai
      järjestämiämme tilaisuuksia tai kampanjoita varten ruokavaliota tai
      terveystietoja kysyttäessä. Emme tällöin käytä tietoja muuhun kuin siihen
      tarkoitukseen, johon olet ne meille suostumuksellasi luovuttanut.
      Verkkosivustojemme tai palvelujemme käytön yhteydessä voimme lisäksi
      kerätä tietoja palveluidemme kehittämiseksi, kuten:
      • ostoksiin ja muihin kanssamme tekemiisi liiketoimiin liittyvät tiedot,
      esimerkiksi tilaukset, toimitukset, maksumenetelmät ja toimitusosoitteet
      • käyttämäsi selain, käyttöjärjestelmä, laitemalli, IP-osoitteesi, yhteyden
      muodostamisaika ja yhteyden kesto sekä sijaintitietosi
      • verkkosivut, joiden kautta olet siirtynyt sivustollemme, tarkastelemasi
      sivut ja muu toimintasi sivustollamme
      • evästeet ja muut tunnisteet
      • sosiaalisen media alustoilla kerätyt tiedot
      Saamme sinusta henkilötietoja myös muilta tahoilta tiettyjen palvelujen
      yhteydessä tai niiden toimittamiseksi:
      • viranomaisten ylläpitämistä julkisista rekistereistä kerätyt tiedot
      • kuljetusyrityksiltä saatavat tiedot
      • kolmansilta osapuolilta ostetut tiedot
      Alltech Fennoaqua ei lähtökohtaisesti kerää lasten henkilötietoja. Mikäli
      yksittäiset kuluttajakampanjat tai kilpailut ovat avoimia myös alle 13-vuotiaille,
      edellytämme osallistujalta hänen huoltajansa nimenomaista suostumusta
      alaikäisen henkilötietojen käsittelyyn. Henkilötietojen käsittelystä informoidaan
      tällöin lisäksi kampanjan ja/tai kilpailun säännöissä.
      Henkilötietojen käyttö
      Käytämme sinusta keräämiämme tietoja seuraaviin tarkoituksiin:
      • tuotteidemme ja palvelujemme tarjoaminen ja markkinointi, kuten
      uutiskirjeen lähetys
      • asiakaspalvelu ja asiakassuhteen hoito, kuten asiakaspalautteiden käsittely
      • tuotteidemme ja palvelujemme kehittäminen, kuten kuluttajatutkimusten
      kautta tapahtuva analysointi
      Henkilötietojen säilytysaika
      Säilytämme henkilötietojasi niin kauan kuin on tarpeen tässä
      tietosuojakäytännössä todettuja tarkoituksia varten. Henkilötietojen
      säilytysaikaan vaikuttaa tietojen käyttötarkoitus, esimerkiksi asiakkuutesi
      hoitaminen tai sopimuksemme täytäntöönpaneminen.
      Säilytämme henkilötietojasi pitempään, jos sovellettava laki niin määrää tai
      sallii laillisten oikeuksiemme todentamiseksi, käyttämiseksi tai
      puolustamiseksi.
      Sähköpostimarkkinointilistaltamme pääset poistumaan itse viestissä olevan
      poistumislinkin kautta.
      Henkilötietojen siirtäminen tai luovuttaminen muille
      Jotta voimme toteuttaa henkilötietojen käsittelyyn liittyvät toimenpiteet,
      siirrämme tarvittaessa henkilötietojasi sellaisille valtuutetuille ulkopuolisille
      tahoille, jotka käsittelevät tietoja lukuumme näiden tietosuojaehtojen sekä
      lainmukaisten sopimusten perusteella. Tällaisia tahoja ovat:
      • markkinointitutkimuksia ja -kampanjoita toteuttavat tahot
      • luottotietoja tarkistavat tahot sekä maksupalvelun tarjoajat
      • kuljetusliikkeet tai postituspalveluja tarjoavat tahot
      • tiedotteiden tai uutiskirjeiden lähetyspalvelut
      • palvelujen tekniset tukitoimittajat
      Henkilötietojasi ei lähtökohtaisesti luovuteta kolmansille osapuolille. Voimme
      kuitenkin luovuttaa henkilötietojasi kolmansille osapuolille sovellettavan lain
      mukaisesti seuraavista syistä:
      • valtuutetuille ulkopuolisille tahoille, jotka käsittelevät tietoja lukuumme
      näiden tietosuojaehtojen sekä lainmukaisten sopimusten perusteella
      • viranomaisille lakiin perustuvien pyyntöjen perusteella
      • liiketoimintojen myynnin tai muiden yritysjärjestelyjen yhteydessä uusille
      omistajille
      Jos siirrämme henkilötietojasi EU:n tai ETA-alueen ulkopuolelle, siirto tehdään
      käyttäen asianmukaisia meidän ja käsittelijän/siirronsaajan välisiä sopimuksia,
      kuten Euroopan unionin komission vahvistamia vakiosopimuslausekkeita.
      Henkilötietojen suojaaminen
      Alltech Fennoaqua suojaa henkilötietoja tuhoamiselta, häviämiseltä tai
      muuttamiselta, luvattomalta luovuttamiselta, luvattomalta pääsyltä sekä muulta
      lainvastaiselta tai luvattomalta käsittelyltä muun muassa estämällä luvattoman
      pääsyn järjestelmiimme palomuurien ja muiden suojakeinojen avulla. Vain
      erikseen nimetyillä työntekijöillä sekä puolestamme tai toimeksiannostamme
      työskentelevillä ulkopuolisilla tahoilla on pääsy henkilötietoihin tai oikeus
      käsitellä henkilötietoja. Kaikkia näitä tahoja sitoo salassapitovelvollisuus.
      Oikeutesi henkilötietojen käsittelyyn liittyen
      Sinulla on oikeuksia henkilötietojesi käsittelyyn liittyen, ellei sovellettavasta
      laista muuta johdu.
      Oikeuksiasi ovat:
      • saada nähtäväksesi sinusta rekisterissämme olevat henkilötiedot sekä
      oikeus saada kopio niistä
      • pyytää virheellisten tietojesi oikaisemista, muokkaamista ja täydentämistä
      • pyytää tietojesi poistamista, käsittelyn rajoittamista tai vastustaa
      henkilötietojesi käsittelyä
      • peruuttaa antamasi suostumus siltä osin kuin henkilötietojasi käsitellään
      suostumuksen perusteella. Suoramarkkinointiviestit voit peruuttaa
      viestissä olevan peruutuslinkin kautta. Meillä voi kuitenkin olla oikeus
      käsitellä tietojasi muulla lainmukaisella perusteella. Suostumuksen
      peruuttaminen ei myöskään vaikuta ennen peruutusilmoituksen
      vastaanottamista tapahtuneen käsittelyn lainmukaisuuteen.
      • vaatia antamiesi tietojen siirtämistä toiselle rekisterinpitäjälle
      jäsennellyssä, yleisesti käytetyssä ja koneellisesti luettavassa muodossa.
      • tehdä valitus valvontaviranomaiselle, jos henkilötietojasi ei mielestäsi ole
      käsitelty tämän tietosuojakäytännön tai lain mukaisesti.
      Valvontaviranomaisen yhteystiedot ovat: Tietosuojavaltuutetun toimisto,
      PL 800, 00521 Helsinki; puhelin: 029 56 66700; tietosuoja(at)om.fi.
      Ennen kuin voit käyttää edellä kerrottuja oikeuksiasi voimme joutua pyytämään
      sinulta lisätietoja varmistaaksemme henkilöllisyytesi. Emme tallenna näitä
      lisätietoja ja ne poistetaan, kun henkilöllisyytesi on varmistettu.
      Jos haluat käyttää henkilötietoihisi liittyviä oikeuksia, tee meille kirjallinen
      pyyntö alla oleviin yhteystietoihin. Tutkimme pyyntösi niin pian kuin on
      mahdollista ja saat vastauksen lain mukaisessa määräajassa eli 30 päivässä.
      Tietosuojakäytännön muutokset
      Alltech Fennoaqua pidättää itselleen oikeuden muuttaa tätä tietosuojakäytäntöä
      lainsäädännön muuttuessa tai liiketoimintansa kehittämiseksi. Muutoksista
      tiedotetaan tällä sivustolla. Mikäli muutokset vaikuttavat Alltech Fennoaquan
      verkkosivuston ja palvelujen käyttäjien oikeuksiin olennaisesti, ilmoitamme
      muutoksista verkkosivustojemme etusivulla sekä mahdollisuuksien mukaan
      erikseen sähköpostitse.
      Rekisterinpitäjän yhteystiedot:
      Alltech Fennoaqua Oy
      Osoite: Raisionkaari 55, 21200 Raisio
      Tietosuoja-asioissa pyydämme lähettämään sähköpostia osoitteeseen
      raisioaqua(at)fennoaqua.fi.
    `}
    </div>
  );
};
