import {Button, Snackbar} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useStore } from '../../stores/store-hooks';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

export const ToastComponent = observer(() => {
  const toastStore = useStore('toastStore');
  const { isOpen, messageId, type } = toastStore;
  const intl = useIntl();

  if (toastStore.showRefreshButton) {
    return(
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      open={isOpen}
      autoHideDuration={8000}
      onClose={toastStore.clearToast}
      style={{margin: 10}}
    >
      <Alert onClose={toastStore.clearToast} severity={type}>
        <div style={{textAlign: "center"}}>
          <p>{intl.formatMessage({ id: messageId })}</p>
          <Button variant={"outlined"} onClick={toastStore.refreshPage}>{intl.formatMessage({id: 'Refresh'})} </Button>
        </div>
      </Alert>
    </Snackbar>
    );
  } else {
    return (
      <Snackbar
        open={isOpen}
        autoHideDuration={6000}
        onClose={toastStore.clearToast}
      >
        <Alert onClose={toastStore.clearToast} severity={type}>
          {messageId &&
            intl.formatMessage({ id: messageId }, toastStore.values as any)}
        </Alert>
      </Snackbar>
    );
  }
});
