import {FC} from "react";
import {TableRow} from "@material-ui/core";

interface IExpandableTableRow {
  expanded: boolean
  expandComponent: any;
}

export const ExpandableTableRow: FC<IExpandableTableRow> = ({ children, expanded, expandComponent }) => {
  return (
    <>
      <TableRow>
        {children}
      </TableRow>
      {expanded && (
        <>
          {expandComponent}
        </>
      )}
    </>
  );
};
