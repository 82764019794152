import { makeAutoObservable, runInAction } from 'mobx';
import { ICompany } from '../models/company';
import {
  createCompany,
  getCompanies,
  deleteCompany,
  updateCompany,
  createFacilityForCompany,
} from '../services/api';
import { SEASONS } from '../models/facility';

class CompanyStore {
  constructor() {
    makeAutoObservable(this);
  }

  companies: ICompany[] = [];
  companiesById: Record<number, ICompany> = {};
  deleteList: number[] = [];

  loadCompanies = async () => {
    const response = await getCompanies();
    runInAction(() => {
      const companiesById: Record<number, ICompany> = {};
      response.data.forEach((c) => {
        companiesById[c.id] = c;
      });
      this.companies = response.data;
      this.companiesById = companiesById;
    });
  }

  createCompany = async (
    companyId: number | undefined,
    company: ICompany
  ): Promise<any> => {
    try {
      const response = await (companyId
        ? updateCompany(companyId, company)
        : createCompany(company));
      if (!companyId) {
        await createFacilityForCompany(response.data.id, {
          id: -1,
          address: response.data.address,
          street_number: '',
          route: '',
          neighborhood: '',
          locality: '',
          postal_code: '',
          country: '',
          place_id: '',
          name: response.data.name,
          seasonstartsummer: new Date().getFullYear() + '-06-01',
          seasonstartautumn: new Date().getFullYear() + '-09-01',
          deleted: false,
          seasonmode: 'A',
          staticseason: SEASONS.Summer
        });
      }
      runInAction(() => {
        if (companyId) {
          const index = this.companies.findIndex((c) => c.id === companyId);
          this.companies[index] = response.data;
        } else {
          this.companies = [...this.companies, response.data];
        }
      });
      return response?.data;
    } catch (error) {
      return { errors: error?.response?.data, status: error?.response?.status };
    }
  };

  get pendingDeleteCount() {
    return this.deleteList.length;
  }

  companysBasinsWithIot(companyId: number) {
    const company = this.companiesById[companyId];
    return company.facilities?.flatMap(facility => (
      facility.sites?.map(site => site.fishbasins?.filter(basin => !!basin.supportunit_id))
    ));
  }

  updateDeleteList(companyId: number, action: 'add' | 'remove') {
    if (action === 'remove') {
      runInAction(() => {
        this.deleteList = this.deleteList.filter((id) => id !== companyId);
      });
    }
    if (action === 'add') {
      runInAction(() => {
        this.companies = this.companies.filter((c) => c.id !== companyId);
        this.deleteList.push(companyId);
      });
    }
  }

  clearDeleteList() {
    runInAction(() => {
      this.deleteList = [];
    });
  }

  filterCompanies = (filter: string) => {
    return this.companies.filter(
      (c) =>
        c.name?.toLowerCase().includes(filter.toLowerCase()) ||
        c.address?.toLowerCase().includes(filter.toLowerCase()) ||
        c.locality?.toLowerCase().includes(filter.toLowerCase())
    );
  };

  cancelDelete() {
    runInAction(() => {
      this.deleteList = [];
      this.loadCompanies();
    });
  }

  /**
   * Deletes companies one by one. Django API dos not currently support ´batch delete, but this is OK for now.
   */
  async deleteCompanies(): Promise<number[]> {
    const failedIds: number[] = [];
    const promises = this.deleteList.map(async (id) => {
      try {
        await deleteCompany(id);
        runInAction(() => {
          // clear id from delete list after successfully removal
          this.deleteList = this.deleteList.filter((dId) => dId !== id);
        });
      } catch (error) {
        failedIds.push(id);
      }
    });
    await Promise.all(promises);
    return failedIds;
  }
}

export default new CompanyStore();
