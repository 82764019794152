import { IFeedingScheduleEntry } from 'models/feeding-schedule';
import { useCallback } from 'react';
import { FeedTimePicker } from './time-picker';
import { PercentagePicker } from './percentage-picker';
import { useIntl } from 'react-intl';
import { Box, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { useStore } from 'stores/store-hooks';
import {DurationPicker} from "./duration-picker";

const COLUMN_WIDTHS = ['30%', '20%', '30%', '10%', '10%'];

export const useEntryItems = (
  entry: IFeedingScheduleEntry,
  calibration: number,
  isHw2: boolean
) => {
  const intl = useIntl();
  const scheduleStore = useStore('feedingScheduleStore');
  const getEntryItems = useCallback(
    (entry: IFeedingScheduleEntry) => {
      let endTimeInfoText = '';
      if (entry.endTimeDisplayValue) {
        endTimeInfoText = ` - (${entry.endTimeDisplayValue})`;
      }
      let amountOfFeedInfoText = '';
      if (entry.amountOfDailyFeeding !== undefined) {
        const parsedAmount = intl.formatNumber(entry.amountOfDailyFeeding, {
          style: 'decimal',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
        amountOfFeedInfoText = ` % (${parsedAmount} ${intl.formatMessage({
          id: "UnitKg"
        })})`;
      }
      let batchSizeInfoText = '';
      if (entry.feedingBatchSize !== undefined) {
        const parsedAmount = intl.formatNumber(entry.feedingBatchSize, {
          style: 'decimal',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
        const perBatchText = intl.formatMessage({ id: 'PerBatch' });
        batchSizeInfoText = `(${parsedAmount} ${intl.formatMessage({
          id: "UnitKg"
        })} ${perBatchText})`;
      }
      return [
        {
          id: 0,
          flexBasis: COLUMN_WIDTHS[0],
          value: (
            <FeedTimePicker
              startTime={entry.startTimeStamp}
              views={['hours', 'minutes']}
              infoText={endTimeInfoText}
              onAccept={scheduleStore.modifyEntryTimeCallback(entry.rowId, 0)}
            />
          ),
        },
        {
          id: 1,
          flexBasis: COLUMN_WIDTHS[1],
          value: (
            <PercentagePicker
              value={entry.percentageOfDailyFeeding}
              infoText={amountOfFeedInfoText}
              onModified={scheduleStore.modifyEntryPercentCallback(entry.rowId)}
            />
          ),
        },
        {
          id: 2,
          flexBasis: COLUMN_WIDTHS[2],
          value: (
            <Box display="flex">
              {isHw2
                ? <DurationPicker
                  seconds={entry.timeFeeding}
                  onAccept={scheduleStore.modifyEntryTimeCallback(entry.rowId, 2)}
                  infoText={batchSizeInfoText}
                  max={Math.ceil(
                    (entry.amountOfDailyFeeding || 0) * 1000 / calibration
                  )}
                />
                : <FeedTimePicker
                  seconds={entry.timeFeeding}
                  views={['minutes', 'seconds']}
                  infoText={batchSizeInfoText}
                  onAccept={scheduleStore.modifyEntryTimeCallback(entry.rowId, 2)}
                  max={Math.ceil(
                    (entry.amountOfDailyFeeding || 0) * 1000 / calibration
                  )}
                />
              }
            </Box>
          ),
        },
        {
          id: 3,
          flexBasis: COLUMN_WIDTHS[3],
          value: (scheduleStore.schedule
              && scheduleStore.schedule.calculatePeriodsForEntry(entry) > 1 ? (
            <FeedTimePicker
              seconds={entry.timeIdle}
              onAccept={scheduleStore.modifyEntryTimeCallback(entry.rowId, 3)}
              views={['minutes', 'seconds']}
            />
          ) : <Typography>-</Typography>),
        },
        {
          id: 4,
          flexBasis: COLUMN_WIDTHS[4],
          value: (
            <IconButton
              aria-label="delete"
              size="small"
              color="primary"
              onClick={scheduleStore.deleteEntry(entry.rowId)}
            >
              <DeleteIcon />
            </IconButton>
          ),
        },
      ];
    },
    [calibration, intl, isHw2, scheduleStore]
  );
  return getEntryItems(entry);
};

export const useHeaderItems = (isHw2: boolean) => {
  const getHeaderItems = useCallback(
    () => [
      {
        id: 0,
        flexBasis: COLUMN_WIDTHS[0],
        headerId: 'TimeOfFeeding',
      },
      {
        id: 1,
        flexBasis: COLUMN_WIDTHS[1],
        headerId: 'PercentOfDailyFeeding',
      },
      {
        id: 2,
        flexBasis: COLUMN_WIDTHS[2],
        headerId: isHw2 ? 'TimeFeedingHours' : 'TimeFeeding',
      },
      {
        id: 3,
        flexBasis: COLUMN_WIDTHS[3],
        headerId: 'TimeIdle',
      },
      {
        id: 4,
        flexBasis: COLUMN_WIDTHS[4],
      },
    ],
    [isHw2]
  );
  return getHeaderItems();
};

export const useSummaryItems = (
  totalPeriods: number,
  totalPercentage: number,
  totalFed: number
) => {
  const intl = useIntl();
  const getHeaderItems = useCallback(() => {
    const totalFedFormatted = intl.formatNumber(totalFed, {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    const totalPercentageFormatted = intl.formatNumber(totalPercentage, {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    return [
      {
        id: 0,
        flexBasis: COLUMN_WIDTHS[0],
      },
      {
        id: 1,
        flexBasis: COLUMN_WIDTHS[1],
        value: (
          <Typography
            component="span"
            variant="h3"
            color={totalPercentage !== 100 ? 'error' : 'primary'}
          >
            {`${totalPercentageFormatted}% (${totalFedFormatted} `
              + intl.formatMessage({ id: "UnitKg" }) + ')'}
          </Typography>
        ),
      },
      {
        id: 2,
        flexBasis: COLUMN_WIDTHS[2],
        value: (
          <Typography
            component="span"
            variant="h3"
            color="primary"
          >
            {intl.formatMessage(
              { id: "NumberOfPeriods" },
              { periods: totalPeriods }
            )}
          </Typography>
        ),
      },
      {
        id: 3,
        flexBasis: COLUMN_WIDTHS[3],
      },
      {
        id: 4,
        flexBasis: COLUMN_WIDTHS[4],
      },
    ];
  }, [intl, totalFed, totalPercentage, totalPeriods]);
  return getHeaderItems();
};
