// generated with: https://bareynol.github.io/mui-theme-creator

// extending theme: https://medium.com/javascript-in-plain-english/extend-material-ui-theme-in-typescript-a462e207131f
// https://material-ui.com/guides/typescript/#customization-of-theme

import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import { MEDIA_BREAKPOINT_LG } from '../constants';

const mediaLg = `@media (max-width:${MEDIA_BREAKPOINT_LG}px)`;

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#005864',
    },
    secondary: {
      main: '#A5CD39',
    },
    text: {
      primary: '#555555',
    },
    type: 'light',
    background: {
      default: '#EEF5F9',
    },
    background2: {
      main: '#F8FAFB',
    },
    error: {
      main: '#E31C3D',
    },
    warning: {
      main: '#F9C642',
    },
    success: {
      main: '#4AA564',
    },
    buttonbg: {
      main: '#e8e8e8',
    },
    line1: {
      main: '#B5B5B5',
    },
    line2: {
      main: '#8CA7AA',
    },
    hover: {
      main: '#EEF5F9',
    },
    divider: 'rgba(255,255,255,0.5)',
  },
  typography: {
    fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',
    body1: {
      fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',
    },
    body2: {
      fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',
    },
    h1: {
      fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: 28,
      lineHeight: '38px',
      '': {
        fontSize: 24,
        lineHeight: '34px',
      },
    },
    h2: {
      fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',
      fontWeight: 600,
      fontSize: 24,
      lineHeight: '32px',
      [mediaLg]: {
        fontSize: 20,
        lineHeight: '28px',
      },
    },
    h3: {
      fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',
      fontWeight: 600,
      fontSize: 22,
      [mediaLg]: {
        fontSize: 18,
      },
    },
    h4: {
      fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '25px',
      [mediaLg]: {
        fontSize: 14,
        lineHeight: '22px',
      },
      fontStyle: 'normal',
    },
    h5: {
      fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '22px',
      [mediaLg]: {
        fontSize: 12,
        lineHeight: '18px',
      },
    },
    h6: {
      fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '19px',
      [mediaLg]: {
        fontSize: 10,
        lineHeight: '15px',
      },
    },
    subtitle1: {
      fontSize: 24,
      lineHeight: '32px',
      [mediaLg]: {
        lineHeight: '28px',
        fontSize: 20,
      },
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: 20,
      [mediaLg]: {
        fontSize: 16,
      },
      fontWeight: 600,
    },
    button: {
      fontWeight: 600,
    },
    caption: {
      fontSize: 16,
      [mediaLg]: {
        fontSize: 12,
      },
      fontWeight: 600,
    },
    overline: {
      fontWeight: 500,
      lineHeight: 1.25,
    },
  },
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: 5,
      },
    },
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
    },
    MuiTableRow: {
      hover: {
        '&:hover': {
          backgroundColor: '#EEF5F9 !important',
        },
      },
    },
    MuiListItem: {
      divider: {
        margin: '10px',
      },
      button: {
        '&:hover': {
          background:
            'linear-gradient(270deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.1) 100%)',
        },
        '&$selected': {
          borderRight: '4px solid #A5CD39',
          background:
            'linear-gradient(270deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.1) 100%)',
        },
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: '1.1rem',
      },
    },
  },
};

export const theme = createMuiTheme(themeOptions);
