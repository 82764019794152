import {CSSProperties, FC} from 'react';
import { useIntl } from 'react-intl';
import { TextField } from '@material-ui/core';

// Pure HTML numeric inputs can be less user-friendly than is ideal
export const NumericInput: FC<{
  value?: number | null;
  onChange?: (n: number) => any;
  onChangeNullable?: (n: number | null) => any //if this is defined it disables errors and other non null functionality
  fractionDigits?: number;
  disabled?: boolean;
  label?: string;
  min?: number;
  max?: number;
  onBlur?: () => any;
  autoFocus?: boolean;
  ariaLabel?: string;
  onFocus?: () => any;
  disableArrows?: boolean;
  style?: CSSProperties;
}> = ({
  value = 0,
  onChange,
  fractionDigits = 0,
  disabled = false,
  label = "",
  min = 0,
  max,
  onBlur,
  onFocus,
  autoFocus = false,
  ariaLabel,
  disableArrows = false,
  style,
  onChangeNullable = null
}) => {
  const intl = useIntl();
  const expandedPower = Math.pow(10, fractionDigits);

  return (
    <TextField
      InputProps={{
        inputProps: {
          style: style,
        }
      }}
      aria-label={ariaLabel || label}
      autoFocus={autoFocus}
      disabled={disabled}
      error={!onChangeNullable && value !== null && isNaN(value)}
      fullWidth
      helperText={!onChangeNullable && (value !== null && isNaN(value)) ? intl.formatMessage({
        id: fractionDigits > 0 ? "PleaseEnterANumber" : "PleaseEnterAnInteger"
      }) : null}
      inputProps={{
        step: (1 / expandedPower),
        min: min,
        max: max,
      }}
      label={label}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={(event) => {
        const rawValue = event.target.value;
        const parts = rawValue.split('.');
        let integerPart = parts[0];
        let fractionalPart = parts[1] ? parts[1].substring(0, fractionDigits) : '';
        const truncatedValue = fractionalPart ? `${integerPart}.${fractionalPart}` : integerPart;
        const parsedValue = parseFloat(truncatedValue);
        const newValue = Math.round(parsedValue * expandedPower) / expandedPower;
        if (max !== undefined && newValue > max) {
          if (onChange) onChange(max);
          if (onChangeNullable) onChangeNullable(max);
        } else if (newValue < min) {
          if (onChange) onChange(min);
          if (onChangeNullable) onChangeNullable(min);
        } else {
          if (onChange) onChange(newValue);
          if (onChangeNullable) onChangeNullable(newValue);
        }
      }}
      type={disableArrows ? "text" : "number" }
      inputMode={disableArrows ? "numeric" : "text"}
      value={(value === null || isNaN(value)) ? "" : value}
    />
  );
};
