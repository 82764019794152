/** @jsxRuntime classic */
/** @jsx jsx */
import React, { FC, useEffect } from 'react';
import { css, jsx } from '@emotion/react';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { upperTabMenuItems, lowerTabMenuItems } from './tab-menu-items';
import { Fragment, useState } from 'react';
import { useStore } from 'stores/store-hooks';
import { Icon } from '@iconify/react';
import WarningIcon from "@material-ui/icons/Warning";
import {observer} from "mobx-react-lite";

const TabListMenuPane: FC<{
  menuItems: {
    id: number;
    itemTextId: string;
    path: string;
    icon?: any;
  }[];
  selectedItem: number;
  setSelectedItem: (item: number) => any;
}> = observer(({ menuItems, selectedItem, setSelectedItem }) => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const theme = useTheme();
  const eventStore = useStore('eventStore');

  return (
    <Box
      css={(theme) => css`
        flex: 0 0 250px;
        display: flex;
        border-radius: 5px;
        margin-right: ${theme.spacing(2)}px;
        margin-bottom: ${theme.spacing(2)}px;
        background-color: ${theme.palette.common.white};
      `}
    >
      <List
        css={css`
          &.MuiList-root {
            width: 100%;
          }
        `}
      >
        {menuItems.map((menuItem, indexInMenuItemList) => {
          const isSelected = menuItem.id === selectedItem;
          return (
            <Fragment key={menuItem.id}>
              <ListItem
                button
                component={Link}
                to={`/basin/${id}${menuItem.path}`}
                selected={isSelected}
                css={(theme) => css`
                  &.MuiListItem-root {
                    color: ${theme.palette.primary.main};
                    box-sizing: border-box; // So displaying border doesn't cause size to fluctuate
                    border-left: ${theme.spacing(1)}px solid transparent;
                    border-right: ${theme.spacing(1)}px solid transparent;
                    :hover,
                    &&.Mui-selected {
                      background-color: ${theme.palette.hover.main};
                    }

                    &&.MuiListItem-gutters {
                      padding-left: ${theme.spacing(3)}px;
                      padding-right: ${theme.spacing(3)}px;
                      padding-top: ${theme.spacing(1)}px;
                      padding-bottom: ${theme.spacing(1)}px;
                    }
                  }
                `}
                onClick={() => setSelectedItem(menuItem.id)}
              >
                {menuItem.icon && (
                  <ListItemIcon
                    css={css`
                      &.MuiListItemIcon-root {
                        min-width: 24px;
                        margin-right: ${theme.spacing(1)}px;
                      }
                    `}
                  >
                    <Icon
                      icon={menuItem.icon}
                      height={24}
                      color={theme.palette.primary.main}
                    />
                  </ListItemIcon>
                )}
                <ListItemText disableTypography>
                  <Box display='flex' alignItems='center'>
                    <Typography
                      variant="h5"
                      component="span"
                      css={css`
                        && {
                          font-weight: ${isSelected ? 900 : 600};
                        }
                      `}
                    >
                      {intl.formatMessage({ id: menuItem.itemTextId })}
                    </Typography>
                    { menuItem.path === '/event-history' && eventStore.hasAlarms &&
                      <WarningIcon
                        style={{color: theme.palette.error.main}}
                      /> }
                  </Box>
                </ListItemText>
              </ListItem>
              {indexInMenuItemList >= menuItems.length - 1 ? null : (
                <Divider
                  variant="middle"
                  css={(theme) => css`
                    &.MuiDivider-root {
                      background-color: ${theme.palette.primary.main};
                    }
                  `}
                />
              )}
            </Fragment>
          );
        })}
      </List>
    </Box>
  );
});

export const TabListMenu = () => {
  const basinStore = useStore('basinStore');
  const graphStore = useStore('graphStore');
  const location = useLocation();
  const { hasIotAutomation } = basinStore;

  // filter out automation and command history items if automation is disabled
  const filteredUpperTabMenuItems = hasIotAutomation
    ? upperTabMenuItems
    : upperTabMenuItems.filter((item) => item.id !== 0 && item.id !== 6);

  const selected = filteredUpperTabMenuItems
    .concat(lowerTabMenuItems)
    .find((item) => location.pathname.includes(item.path));

  const [selectedItem, setSelectedItem] = useState(
    selected ? selected.id : hasIotAutomation ? 0 : 1
  );

  useEffect(() => {
    // ensure that selected item is updated on route navigation or by browser back-button press
    setSelectedItem(selected ? selected.id : hasIotAutomation ? 0 : 1);
    // clear graph store loadTimer to ensure graphs are reload on tab change
    graphStore.resetLoadTimer();
  }, [graphStore, hasIotAutomation, selected]);

  return (
    <div>
      <TabListMenuPane
        menuItems={filteredUpperTabMenuItems}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
      <TabListMenuPane
        menuItems={lowerTabMenuItems}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
    </div>
  );
};
