/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/react';
import { IWidgetComponent } from 'models/widget';
import { FC } from 'react';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import { useStore } from 'stores/store-hooks';
import { Icon, InlineIcon } from '@iconify/react';
import avatarLine from '@iconify/icons-clarity/avatar-line';
import logoutLine from '@iconify-icons/clarity/logout-line';
import { OwnUserEditDialog } from 'components/user/own-user-edit-dialog';
import { LanguageSelect } from 'components/appbar/language-select';
import { useIntl } from 'react-intl';

const Root = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 0 ${({ theme }) => theme.spacing(3)}px;
`;

const StyledDivider = styled(Divider)`
  && {
    background-color: ${({ theme }) => theme.palette.grey[300]};
    margin: ${({ theme }) => theme.spacing(2)}px 0px;
  }
`;

export const MenuWidget: FC<IWidgetComponent> = ({ widget }) => {
  const userStore = useStore('userStore');
  const theme = useTheme();
  const intl = useIntl();
  const [userEditDialogOpen, setOwnUserEditDialogOpen] = useState(false);

  const showOwnUserEditDialog = () => {
    setOwnUserEditDialogOpen(!userEditDialogOpen);
  };

  const hideOwnUserEditDialog = () => {
    setOwnUserEditDialogOpen(false);
  };

  return (
    <Root>
      <LanguageSelect />
      <StyledDivider flexItem orientation="vertical" />
      <Box>
        <IconButton
          color="inherit"
          aria-label="user profile"
          onClick={showOwnUserEditDialog}
        >
          <Icon
            icon={avatarLine}
            color={theme.palette.primary.main}
            height={24}
          />
        </IconButton>
        <Button
          onClick={showOwnUserEditDialog}
          css={(theme) => css`
            && {
              text-transform: none;
              color: ${theme.palette.primary.main};
            }
          `}
        >
          {userStore.currentUser?.full_name || userStore.currentUser?.username}
        </Button>
      </Box>
      <StyledDivider flexItem orientation="vertical" />
      <Tooltip title={intl.formatMessage({ id: 'Logout' })} arrow>
        <IconButton
          color="inherit"
          aria-label="logout"
          onClick={userStore.logout}
        >
          <InlineIcon
            icon={logoutLine}
            color={theme.palette.primary.main}
            height={24}
          />
        </IconButton>
      </Tooltip>
      {userEditDialogOpen && (
        <OwnUserEditDialog
          handleClose={hideOwnUserEditDialog}
          user={userStore?.currentUser}
          updateUserData={userStore.updateUserData}
        />
      )}
    </Root>
  );
};
