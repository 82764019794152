import {Modal, ModalActions, ModalContent} from "../modal-dialog";
import {Box, Grid, Typography, useTheme} from "@material-ui/core";
import {FormattedMessage as M} from "react-intl";
import Button from "@material-ui/core/Button";
import {FC, ReactNode} from "react";
import {ISelectionItem} from "./multiple-camera-view";


interface IAreYouSureDialog {
  titleId: string;
  selectedItems: Array<ISelectionItem>;
  handleClose: () => void;
  handleSubmit: () => void;
  children: ReactNode | undefined;
}

export const AreYouSureDialog: FC<IAreYouSureDialog> = ({titleId, handleClose, selectedItems, handleSubmit, children}) => {
  const theme = useTheme();

  return (
    <Modal handleClose={() => handleClose()}>
      <form>
        <ModalContent>
          <Box>
            <Box display="flex" p={1} pl={2} bgcolor={theme.palette.grey[200]}>
              <Typography component="span" variant="h4">
                <M id={titleId}/>
              </Typography>
            </Box>
            <Box p={2} maxWidth={450}>
              {selectedItems.length ? (
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography variant="h6">
                      <M id="AreYouSure"/>
                    </Typography>
                    <div>
                      {selectedItems.map(item => (
                        <ul>
                          <li>
                            <Typography variant="h6" component="span" color="primary">
                              {item.basin.name}
                            </Typography>
                          </li>
                        </ul>
                      ))}
                    </div>
                  </Grid>
                  <Grid item>
                    {children}
                  </Grid>
                </Grid>
              ) : (
                <Typography variant="h5" component="span" color="primary">
                  <M id="NoBasinsSelected"/>
                </Typography>
              )}
            </Box>
          </Box>
        </ModalContent>
        <ModalActions>
          <Button
            color="primary"
            type="submit"
            disabled={!selectedItems.length}
            onClick={(event: any) => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <M id="Yes"/>
          </Button>
          <Button
            onClick={() => {
              handleClose();
            }}
            color="primary"
          >
            <M id="No"/>
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
}
