/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Container, CssBaseline } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FC, useLayoutEffect } from 'react';
import { AppBar } from 'components/appbar';
import { Toast } from 'components/toast';
import { SideMenu } from 'components/sidemenu';
import styled from '@emotion/styled';
import { useStore } from 'stores/store-hooks';
import { observer } from 'mobx-react-lite';
import { Redirect, useLocation } from 'react-router';
import { DRAWER_WIDTH_MAX, DRAWER_WIDTH_MIN } from '../../constants';

const Content = styled.main<{
  isAuthenticated?: boolean;
  isDashboardRoute?: boolean;
  drawerWidth: number;
  // appBarOffset?: number; // TODO: Use
  bottomPadding?: number;
}>`
  flex-grow: 1;
  margin-left: ${(props) =>
    props.isAuthenticated ? `${props.drawerWidth}px` : 0};
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: ${(props) =>
    props.isDashboardRoute
      ? '25px'
      : props.bottomPadding !== undefined
      ? `${props.bottomPadding}px`
      : '8px'};
`;

export const LayoutComponent: FC = observer(({ children }) => {
  const userStore = useStore('userStore');
  const { drawerWidth, setDrawerOpenStatus } = useStore('settingsStore');
  const { isAuthenticated, currentUser, hasAcceptedTermsOfService } = userStore;
  const location = useLocation();
  const isDashboardRoute = location.pathname.includes('dashboard');
  const bottomPadding = location.pathname.includes('facilities')
    ? 0
    : undefined;

  // side-menu (drawer-component) is open by default if viewport width is >1350px
  const drawerDefaultOpenStatus = useMediaQuery('(min-width:1450px)', {
    noSsr: true,
  });

  useLayoutEffect(() => {
    if (isAuthenticated && !currentUser) {
      userStore.getCurrentUser();
      setDrawerOpenStatus(
        drawerDefaultOpenStatus,
        drawerDefaultOpenStatus ? DRAWER_WIDTH_MAX : DRAWER_WIDTH_MIN
      );
    }
  });

  // force re-direct to accept-terms if ToS is not accepted
  if (
    currentUser &&
    isAuthenticated &&
    !hasAcceptedTermsOfService &&
    !location.pathname.includes('accept-terms')
  ) {
    return <Redirect from="*" to="/accept-terms" />;
  }

  return (
    <Container
      maxWidth={false}
      css={(theme) => css`
        height: 100vh;
        padding: ${!isAuthenticated ? '0 !important' : 'inherit'};
        display: flex;
        overflow: hidden;
        background-color: ${!isAuthenticated && theme.palette.primary.main};

        @media (min-width: 600px) {
          &.MuiContainer-root {
            padding-left: ${theme.spacing(2)}px;
            padding-right: ${theme.spacing(1)}px;
          }
        }
      `}
    >
      <CssBaseline />
      {!isDashboardRoute && (
        <AppBar leftOffset={isAuthenticated ? drawerWidth : 0} />
      )}
      {isAuthenticated && <SideMenu />}
      <Content
        isAuthenticated={isAuthenticated}
        isDashboardRoute={isDashboardRoute}
        drawerWidth={drawerWidth}
        bottomPadding={bottomPadding}
      >
        {children}
        <Toast />
      </Content>
    </Container>
  );
});
