import { FC, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { deviationConfig, deviationOptions } from './chart-config';
import { useStore } from 'stores/store-hooks';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import {
  temperatureConfig,
  temperatureOption,
  mainUnitOxygenConfig,
  mainUnitOxygenOption,
} from './chart-config';

export const DeviationGraph: FC = observer(() => {
  const graphStore = useStore('graphStore');
  const {
    deviationFromRecommendationChartData,
    basinChartLoading,
    siteTemperatureChartData,
    siteTemperatureLoading,
    oxygenChartData,
    oxygenChartLoading,
  } = graphStore;

  const intl = useIntl();
  useEffect(() => {
    graphStore.loadBasinChartData();
    graphStore.loadSiteWaterTemperature();
    graphStore.loadSiteOxygenValues(intl);
  }, [graphStore, intl]);
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1 1 100%"
      height="100%"
      width="100%"
    >
      <Box ml={2} display="flex" flexDirection="column">
        <Typography align="center" component="span" variant="h3">
          {intl.formatMessage({ id: 'DeviationFromRecommendation' })}
        </Typography>
        <ReactECharts
          style={{ height: '420px', width: '100%' }}
          showLoading={basinChartLoading}
          option={{
            ...deviationOptions(intl),
            ...deviationFromRecommendationChartData,
          }}
          {...deviationConfig}
        />
      </Box>

      <Box ml={2} mt={3} display="flex" flexDirection="column">
        <Typography align="center" component="span" variant="h3">
          {intl.formatMessage({ id: 'SiteTemperature' })}
        </Typography>
        <ReactECharts
          style={{ minHeight: '420px', width: '100%' }}
          showLoading={siteTemperatureLoading}
          option={{
            ...temperatureOption(intl),
            ...siteTemperatureChartData,
          }}
          {...temperatureConfig}
        />
      </Box>
      <Box ml={2} mt={3} display="flex" flexDirection="column">
        <Typography align="center" component="span" variant="h3">
          {intl.formatMessage({ id: 'Oxygen' })}
        </Typography>
        <ReactECharts
          style={{ height: '420px', width: '100%' }}
          showLoading={oxygenChartLoading}
          option={{ ...mainUnitOxygenOption(intl), ...oxygenChartData }}
          {...mainUnitOxygenConfig}
        />
      </Box>
    </Box>
  );
});
