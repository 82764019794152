import { FC, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { averageWeightConfig, averageWeightOptions } from './chart-config';
import { useStore } from 'stores/store-hooks';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

export const AverageWeightGraph: FC = observer(() => {
  const graphStore = useStore('graphStore');
  const { averageWeightData, basinChartLoading } = graphStore;
  const intl = useIntl();
  useEffect(() => {
    graphStore.loadBasinChartData();
  });
  return (
    <ReactECharts
      showLoading={basinChartLoading}
      style={{ height: '420px', width: '100%' }}
      option={{ ...averageWeightOptions(intl), ...averageWeightData }}
      {...averageWeightConfig}
    />
  );
});
