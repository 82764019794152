import { IconButton, Typography } from '@material-ui/core';
import {FormattedMessage as M, IntlShape} from 'react-intl';
import DeleteIcon from '@material-ui/icons/Delete';
import { Column } from 'react-table';
import Button from '@material-ui/core/Button';

export const getFeedingScheduleProfileColumns = (
  intl: IntlShape,
  onProfileClick: (index: number) => () => void,
  onDeleteProfile: (index: number) => () => void,
) => {
  const baseColumns = [
    {
      Header: (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'FeedingProfileName' })}
        </Typography>
      ),
      accessor: 'name',
    },
  ] as Column[];

  const editControlColumns = [
    {
      Header: (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'FeedingScheduleProfileSelection' })}
        </Typography>
      ),
      id: 'edit',
      Cell: ({ cell, row }) => {
        return (
          <Button
            onClick={onProfileClick(row.index)}
          >
            <M id="SelectAsProfile"/>
          </Button>
        );
      },
    },
    {
      Header: (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Delete' })}
        </Typography>
      ),
      id: 'delete',
      disableSortBy: true,
      Cell: ({ cell, row }) => {
        return (
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={onDeleteProfile(row.index)}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ] as Column[];

  return [...baseColumns, ...editControlColumns];
};
