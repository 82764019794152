/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import Button from '@material-ui/core/Button';
import { BASIN_TYPE, FEEDING_METHOD, IFishBasin } from 'models/fishbasin';
import { FormattedMessage as M } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Modal, ModalActions, ModalContent } from 'components/modal-dialog';
import { useForm } from 'react-hook-form';
import { useStore } from 'stores/store-hooks';
import {
  BasinNameRow,
  BasinTypeSelection,
  FishStartAmount,
  FishInitialPrice,
  FishInitialWeight,
  BasinDimensionsRound,
  BasinDimensionsSquare,
  FeedingMethodSelection,
  FishDetails, GrowingSeason, BasinCreated,
} from './basin-form-components';
import {format, setDate, setMonth} from "date-fns";

interface IBasinAddDialog {
  siteId: number;
  handleClose: () => void;
  editedBasin?: IFishBasin | null;
  updateBasin: (siteId: number, data: any) => Promise<any>;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required('error.validation.name')
    .max(256, 'error.validation.name'),
  basintype: yup.string().required('error.validation.basintype'),
  dimensions: yup.object().when('basintype', {
    is: BASIN_TYPE.Round,
    then: yup.object({
      diameter: yup
        .number()
        .typeError('error.validation.diameter')
        .required('error.validation.diameter')
        .min(0, 'error.validation.diameter')
        .max(9999999999, 'error.validation.diameter'),
      depth: yup
        .number()
        .typeError('error.validation.depth')
        .required('error.validation.depth')
        .min(0, 'error.validation.depth')
        .max(9999999999, 'error.validation.depth'),
    }),
    otherwise: yup.object({
      width: yup
        .number()
        .typeError('error.validation.width')
        .required('error.validation.width')
        .min(0, 'error.validation.width')
        .max(9999999999, 'error.validation.width'),
      length: yup
        .number()
        .typeError('error.validation.length')
        .required('error.validation.length')
        .min(0, 'error.validation.length')
        .max(9999999999, 'error.validation.length'),
      depth: yup
        .number()
        .typeError('error.validation.depth')
        .required('error.validation.depth')
        .min(0, 'error.validation.depth')
        .max(9999999999, 'error.validation.depth'),
    }),
  }),
  brand: yup.string().max(256, 'error.validation.brand'),
  startweight: yup
    .number()
    .typeError('error.validation.startweight')
    .required('error.validation.startweight')
    .max(9999999999, 'error.validation.startweight')
    .min(0, 'error.validation.startweight'),
  startamount: yup
    .number()
    .typeError('error.validation.startAmount')
    .required('error.validation.startAmount')
    .max(9999999999, 'error.validation.startAmount')
    .min(0, 'error.validation.startAmount'),
  purchaseprice: yup
    .number()
    .typeError('error.validation.purchaseprice')
    .required('error.validation.purchaseprice')
    .max(999999, 'error.validation.purchaseprice')
    .min(0, 'error.validation.purchaseprice')
});

export const BasinDialog = ({
  editedBasin,
  handleClose,
  updateBasin,
  siteId,
}: IBasinAddDialog) => {
  const basinStore = useStore('basinStore');
  const { fishTypes } = basinStore;
  if (editedBasin) {
    editedBasin.fishtype_id = editedBasin.fishtype?.id;
  }
  const {
    register,
    handleSubmit,
    errors,
    clearErrors,
    control,
    watch,
  } = useForm<IFishBasin>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: editedBasin || {
      fishtype_id: fishTypes?.length ? fishTypes[0].id : -1,
      basintype: BASIN_TYPE.Round,
      feedingmethod: FEEDING_METHOD.Automatic,
      dimensions: {
        diameter: 1,
        depth: 1,
        length: 1,
        width: 1,
      },
      growingseason_start: format(
        setDate(setMonth(new Date(), 4), 1),
        'yyyy-MM-dd'
      ),
      growingseason_end: format(
        setDate(setMonth(new Date(), 8), 1),
        'yyyy-MM-dd'
      ),
    },
  });
  const toastStore = useStore('toastStore');
  const basintype = watch('basintype');
  const submitData = async (data: any) => {
    const { errors } = await updateBasin(
      siteId,
      // editedBasin ? editedBasin.id : null,
      data
    );
    if (errors) {
      // some other error -> show generic error note
      toastStore.setToast('validation.error.unexpected');
      return;
    }
    // success
    handleClose();
  };

  return (
    <Modal handleClose={handleClose}>
      <form noValidate onSubmit={handleSubmit(submitData)}>
        <ModalContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography component="span" variant="subtitle2">
                <M id={editedBasin ? 'EditBasin' : 'AddNewBasin'} />
              </Typography>
            </Grid>
            <BasinNameRow register={register} errors={errors} />
            <BasinTypeSelection control={control} />
            {basintype === BASIN_TYPE.Round ? (
              <BasinDimensionsRound register={register} errors={errors} />
            ) : (
              <BasinDimensionsSquare register={register} errors={errors} />
            )}
            <FeedingMethodSelection control={control} errors={errors} />
            <FishDetails
              register={register}
              errors={errors}
              control={control}
            />
            <FishInitialWeight
              register={register}
              control={control}
              errors={errors}
            />
            <FishStartAmount
              register={register}
              control={control}
              errors={errors}
            />
            <FishInitialPrice
              register={register}
              control={control}
              errors={errors}
            />
            <BasinCreated
              register={register}
              control={control}
              errors={errors}
            />
            <GrowingSeason
              register={register}
              control={control}
              errors={errors}
            />
          </Grid>
        </ModalContent>
        <ModalActions>
          <Button
            color="primary"
            type="submit"
            disabled={!!Object.keys(errors).length}
          >
            <M id="Save" />
          </Button>
          <Button
            onClick={() => {
              clearErrors();
              handleClose();
            }}
            color="primary"
          >
            <M id="Cancel" />
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};
