import chatBubbleLine from '@iconify-icons/clarity/chat-bubble-line';

export const upperTabMenuItems = [
  {
    id: 0,
    itemTextId: 'Automatic',
    path: '/feeder-automation',
  },
  {
    id: 1,
    itemTextId: 'Graphs',
    path: '/graphs',
  },
  {
    id: 2,
    itemTextId: 'FeedUsage',
    path: '/silo',
  },
  {
    id: 3,
    itemTextId: 'GrowthFactors',
    path: '/growth-factors',
  },
  {
    id: 4,
    itemTextId: 'Harvesting',
    path: '/harvesting',
  },
  {
    id: 5,
    itemTextId: 'Events',
    path: '/event-history',
  },
/*  {
    id: 6,
    itemTextId: 'CommandHistory',
    path: '/command-history',
  },*/
];

export const lowerTabMenuItems = [
  {
    id: 7,
    itemTextId: 'Comments',
    path: '/comments',
    icon: chatBubbleLine,
  },
];
