import { FC, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { siloLevelConfig, siloLevelOption } from './chart-config';
import { useStore } from 'stores/store-hooks';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

export const SiloLevelGraph: FC = observer(() => {
  const graphStore = useStore('graphStore');
  const { siloLevelChartData, siteTemperatureLoading } = graphStore;
  const intl = useIntl();
  useEffect(() => {
    graphStore.loadSiloLevelChart();
  }, [graphStore]);
  return (
    <ReactECharts
      style={{ height: '420px', width: '100%' }}
      showLoading={siteTemperatureLoading}
      option={{
        ...siloLevelOption(intl),
        ...siloLevelChartData,
      }}
      {...siloLevelConfig}
    />
  );
});
