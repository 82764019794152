/** @jsxRuntime classic */
/** @jsx jsx */
import { FC } from 'react';
import { css, jsx } from '@emotion/react';
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';

const Root = styled.div`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-color: ${({ theme }) => fade(theme.palette.common.white, 0.15)};
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  hover: {
    background-color: ${({ theme }) => fade(theme.palette.common.white, 0.25)};
    background-color: red;
  }
`;

const IconWrapper = styled.div`
  width: theme.spacing(7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export interface IGlobalFilter {
  globalFilter: any;
  setGlobalFilter: any;
}

export const GlobalFilter: FC<IGlobalFilter> = ({
  globalFilter,
  setGlobalFilter,
}) => {
  const intl = useIntl();
  return (
    <Root>
      <IconWrapper>
        <SearchIcon />
      </IconWrapper>
      <InputBase
        css={(theme) => css`
          border: 1px solid ${theme.palette.grey[300]};
          border-radius: 5px;
          padding-left: ${theme.spacing(1)}px;
          margin-left: ${theme.spacing(1)}px;
        `}
        value={globalFilter || ''}
        placeholder={intl.formatMessage({ id: 'Search' })}
        onChange={(e) => {
          setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        inputProps={{ 'aria-label': 'search' }}
      />
    </Root>
  );
};
