/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { debounce } from 'debounce';
import { Box, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import styled from '@emotion/styled';

const IconWrapper = styled.div`
  width: theme.spacing(7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ISearchField {
  setSearchTerm: (searchTerm: string) => void;
}

export const SearchField: FC<ISearchField> = ({ setSearchTerm }) => {
  const intl = useIntl();

  const handleChange = debounce((e: React.ChangeEvent<{ value: string }>) => {
    setSearchTerm(e.target.value);
  }, 500);

  return (
    <Box
      className="search-container"
      css={(theme) => css`
        background-color: ${theme.palette.common.white};
        flex-direction: row;
        display: flex;
        padding: ${theme.spacing(1)}px;
        margin-right: ${theme.spacing(1)}px;
        border-radius: 5px;
      `}
    >
      <IconWrapper>
        <SearchIcon />
      </IconWrapper>
      <InputBase
        css={(theme) => css`
          border: 1px solid ${theme.palette.grey[300]};
          border-radius: 5px;
          padding-left: ${theme.spacing(1)}px;
          margin-left: ${theme.spacing(1)}px;
        `}
        onChange={handleChange}
        placeholder={intl.formatMessage({ id: 'Search' })}
        inputProps={{ 'aria-label': 'search' }}
      />
    </Box>
  );
};
