import {
  Box,
  Button, Table, TableBody, TableCell, TableHead, TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import React, {FC} from "react";
import {FormattedMessage as M, useIntl} from "react-intl";
import {useFieldArray, Controller} from "react-hook-form";
import {IAdditionalCustomerAccount} from "../../models/company";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

interface IListTextField {
  values: string[];
  name: string;
  id: string;
  label: React.ReactNode;
  control: any;
  inputRef: (name: string, RegisterOptions?: any) => ({ onChange: any, onBlur: any, name: any, ref: any });
}

export const AdditionalCustomerAccount: FC<IListTextField> = (
  {name, id, label, control}) => {

  const intl = useIntl();
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
  });

  return (
    <Box>
      <Typography>{label}</Typography>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell width="20%"><M id="CustomerId"/>*</TableCell>
            <TableCell width="20%"><M id="Name"/></TableCell>
            <TableCell width="40%"><M id="Address"/>*</TableCell>
            <TableCell width="5%"><M id="ZipCode"/>*</TableCell>
            <TableCell width="14%"><M id="PostalCity"/>*</TableCell>
            <TableCell> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((item, index) =>
            <SelectedItem
              key={item.id}
              name={name}
              item={item}
              index={index}
              control={control}
              onRemove={() => remove(index)}
            />
          )}
          <TableRow>
            <TableCell onClick={() => {
              const defaultDescription = intl.formatMessage({id: "AdditionalCustomerId"})
              append({description: defaultDescription} as IAdditionalCustomerAccount)
            }}>
              <Button style={{paddingLeft: 0, justifyContent: "start"}}>{intl.formatMessage({id: "Add"})}</Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

interface ISelectedItem {
  item: any;
  name: string;
  index: number;
  control: any;
  onRemove: () => void;
}

const SelectedItem: FC<ISelectedItem> = ({item, name, index, control, onRemove}) => {
  return (
    <TableRow>
      <TableCell>
        <Controller
          as={<TextField fullWidth/>}
          name={`${name}[${index}].customerid`}
          control={control}
          defaultValue={item.additional_customerid}
        />
      </TableCell>
      <TableCell>
        <Controller
          as={<TextField fullWidth/>}
          name={`${name}[${index}].description`}
          control={control}
          defaultValue={item.description}
        />
      </TableCell>
      <TableCell>
        <Controller
          as={<TextField fullWidth/>}
          name={`${name}[${index}].address`}
          control={control}
          defaultValue={item.address}
        />
      </TableCell>
      <TableCell>
        <Controller
          as={<TextField fullWidth/>}
          name={`${name}[${index}].postal_code`}
          control={control}
          defaultValue={item.postal_code}
        />
      </TableCell>
      <TableCell>
        <Controller
          as={<TextField fullWidth/>}
          name={`${name}[${index}].locality`}
          control={control}
          defaultValue={item.locality}
        />
      </TableCell>
      <TableCell>
        <Button onClick={onRemove} style={{padding:2, minHeight: 0, minWidth: 0,}}>
          <DeleteForeverIcon/>
        </Button>
      </TableCell>
    </TableRow>
  );
};
