/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { useTheme } from '@material-ui/core';
import { Accordion } from 'components/accordion';
import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useStore } from 'stores/store-hooks';
import { FacilityRowHeader } from './facility-row/facility-row-header';
import { FacilitySiteRow } from './site-row/facility-site-row';
import { FacilityDialog } from './facility-row/facility-add-dialog';
import { IFacility } from 'models/facility';
import { ICompany } from '../../models/company';

const Footer = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px;
  padding-left: 98px;
  margin-left: -30px;
  background-color: ${({ theme }) => theme.palette.background.default};
  width: 100%;
`;

export const FacilitiesComponent = observer(() => {
  const theme = useTheme();
  const scrollToRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const [facilityDialog, setFacilityDialog] = useState<{
    visible: boolean;
    editedFacility: IFacility | null;
  }>({
    visible: false,
    editedFacility: null,
  });

  // const toastStore = useStore('toastStore');
  const userStore = useStore('userStore');
  const siteStore = useStore('siteStore');
  const { isRaisioAdminOrSuperuser, isConcernCompanyAdmin, isCompanyAdmin } = userStore;
  const companyStore = useStore('companyStore');
  const facilityStore = useStore('facilityStore');
  const filterStore = useStore('filterStore');

  useEffect(() => {
    if (scrollToRef.current && scrollContainerRef.current) {
      const ref = scrollToRef.current;
      const containerRef = scrollContainerRef.current;
      if (siteStore.pendingSiteLoads.length === 0) {
        containerRef.scrollTo({
          top: ref.offsetTop - ref.clientHeight,
          // behavior: 'smooth',
        });
        facilityStore.setFacilityScrollToTarget(undefined);
      }
    }
  }, [facilityStore, scrollToRef, siteStore.pendingSiteLoads.length]);

  useEffect(() => {
    if (facilityStore.facilities.length === 0) {
      facilityStore.loadFacilities();
    }
    siteStore.loadSites();
  }, [facilityStore, siteStore]);

  useEffect(() => {
    companyStore.loadCompanies();
  }, [companyStore, isRaisioAdminOrSuperuser, isConcernCompanyAdmin, isCompanyAdmin]);
  const { facilities } = facilityStore;
  const { dataFilter } = filterStore;
  const getFilteredFacilities = useCallback(() => {
    if (
      (isRaisioAdminOrSuperuser || isConcernCompanyAdmin) &&
      dataFilter.type === 'company' &&
      dataFilter.id !== -1
    ) {
      return facilities.filter(
        (facility) =>
          facility.company?.id === dataFilter.id && !facility.deleted
      );
    }
    return facilities.filter((f) => !f.deleted);
  }, [dataFilter.id, dataFilter.type, facilities, isConcernCompanyAdmin, isRaisioAdminOrSuperuser]);

  const [deleteModeActive] = useState(false);

  const getCompany = (facility: IFacility) => {
    return companyStore.companies.filter((company: ICompany) => {
      return company.id === facility.company?.id
    })[0];
  }

  const updateFacility = async (
    companyId: number | null,
    modifiedData: IFacility
  ) => {
    const { editedFacility } = facilityDialog;
    if (editedFacility) {
      let company = getCompany(editedFacility);
      company.name = modifiedData.name;
      company.address = modifiedData.address;
      await companyStore.createCompany(editedFacility.company?.id, company);
      await companyStore.loadCompanies();
      return await facilityStore.updateFacility(
        editedFacility.id,
        modifiedData
      );
    }
    return await facilityStore.createFacility(companyId, modifiedData);
  };

  const facilityHasIot = (facility: IFacility) => {
    const facilitySites = siteStore.simplifiedSites
      .filter(site => site.facility.id === facility.id);
    if(!facilitySites) {
      return false;
    }
    return facilitySites.flatMap(site => site.fishbasins).some(basin => basin?.supportunit_id);
  }

  return (
    <Fragment>
      <div
        ref={scrollContainerRef}
        css={css`
          max-height: 100%;
          overflow-y: auto;
        `}
      >
        {getFilteredFacilities().map((facility) => {
          try {
            const company = getCompany(facility);
            facility['name'] = company.name || '';
            facility['address'] = company.address || '';
          } catch (e) {
            console.error(e);
          }

          return (
            <Accordion
              key={facility.id}
              id={facility.id}
              expandIcon={false}
              ref={
                facility.id === facilityStore.facilityScrollToTarget
                  ? scrollToRef
                  : null
              }
              isExpandedInitially={
                facilityStore.facilityScrollToTarget === facility.id
              }
              accordionHeader={
                <FacilityRowHeader
                  hasIot={facilityHasIot(facility)}
                  setFacilityDialog={setFacilityDialog}
                  facility={facility}
                />
              }
              accordionContent={<FacilitySiteRow facility={facility} />}
              isDisabled={!!facility.sites?.length}
              disabledTooltipTextId="FacilityRemovalNotAllowed"
              controlButtonColor={
                deleteModeActive ? theme.palette.error.main : undefined
              }
              onControlButtonClick={(id) =>
                facilityStore.updateDeleteList(id, 'add')
              }
            />
          );
        })}
        {(isCompanyAdmin || isRaisioAdminOrSuperuser) && (
          <Footer>
            {
            }
          </Footer>
        )}
        {facilityDialog.visible && (
          <FacilityDialog
            editedFacility={facilityDialog.editedFacility}
            handleClose={() =>
              setFacilityDialog({ visible: false, editedFacility: null })
            }
            updateFacility={updateFacility}
          />
        )}
      </div>
    </Fragment>
  );
});
