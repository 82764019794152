import {makeAutoObservable, runInAction} from 'mobx';
import {
  updateFeedTypesCompanySpecific,
} from 'services/api';
import {IFeedType} from 'models/silo';
import toastStore from './toast-store';

class FeedTypeListStore {
  constructor() {
    makeAutoObservable(this);
  }

  feedTypesToAdd: IFeedType[] = [];
  feedTypesToDelete: IFeedType[] = [];

  get noUpdates() {
    return this.feedTypesToDelete.length === 0
      && this.feedTypesToAdd.length === 0;
  }

  resetUpdates = () => {
    runInAction(() => {
      this.feedTypesToAdd = [];
      this.feedTypesToDelete = [];
    });
  };

  saveUpdates = async () => {
    try {
      await Promise.all([
        updateFeedTypesCompanySpecific(this.feedTypesToAdd.concat(this.feedTypesToDelete
          .filter(e => e.id)
          .map(e => {
            return {
              ...e,
              deleted: true
            }
        }))),
      ]);
    } catch (error) {
      toastStore.setToast("UpdateProductsFailed");
    }
  };

  addFeedTypeToDeleteList = (feedType: IFeedType) => {
    runInAction(() => {
      if (this.feedTypesToDelete.indexOf(feedType) < 0) {
        this.feedTypesToDelete.push(feedType);
      }
    });
  };

  removeFeedTypeFromDeleteList = (feedType: IFeedType) => {
    runInAction(() => {
      const index = this.feedTypesToDelete.indexOf(feedType);
      if (index >= 0) {
        this.feedTypesToDelete.splice(index, 1);
      }
    });
  };

  addToFeedTypeList = (feedtype: IFeedType) => {
    runInAction(() => {
      this.feedTypesToAdd.push(feedtype);
    });
  };

  removeFromFeedTypeList = (feedtype: IFeedType) => {
    runInAction(() => {
      const index = this.feedTypesToAdd.indexOf(feedtype);
      if (index >= 0) {
        this.feedTypesToAdd.splice(index, 1);
      }
    });
  };
}

export default new FeedTypeListStore();
