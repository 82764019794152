import React from 'react';
import userStore from './user-store';
import toastStore from './toast-store';
import companyStore from './company-store';
import siteStore from './site-store';
import facilityStore from './facility-store';
import dashboardStore from './dashboard-store';
import basinStore from './basin-store';
import iotStore from './iot-device-store';
import feedingScheduleStore from './feeding-schedule-store';
import alarmStore from './alarm-store';
import eventStore from './events-store';
import graphStore from './graph-store';
import siloStore from './silo-store';
import commentStore from './comments-store';
import growthFactorTableStore from './growth-factor-table-store';
import harvestingStore from './harvesting-store';
import linkAndCopyStore from './link-and-copy-store';
import orderStore from './orders-store';
import archiveStore from './archive-store';
import settingsStore from './settings-store';
import filterStore from './filter-store';
import passwordResetStore from './password-reset-store';
import productListStore from './product-list-store';
import reportStore from './report-store';
import feedTypeListStore from './feedtype-list-store';

export const stores = Object.freeze({
  userStore,
  toastStore,
  companyStore,
  siteStore,
  facilityStore,
  dashboardStore,
  basinStore,
  iotStore,
  feedingScheduleStore,
  alarmStore,
  eventStore,
  graphStore,
  siloStore,
  commentStore,
  growthFactorTableStore,
  harvestingStore,
  linkAndCopyStore,
  orderStore,
  archiveStore,
  settingsStore,
  filterStore,
  passwordResetStore,
  productListStore,
  reportStore,
  feedTypeListStore
});

export const storesContext = React.createContext(stores);
export const StoresProvider = storesContext.Provider;
