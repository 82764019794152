import React, {useEffect} from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Grid, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useStore } from 'stores/store-hooks';

export const ContactInfoInput = observer(() => {
  const intl = useIntl();
  const { companies, loadCompanies } = useStore('companyStore');
  const {
    company,
    email,
    emailOkay,
    firstName,
    firstNameOkay,
    lastName,
    lastNameOkay,
    phone,
    phoneOkay,
    businessId,
    setCompany,
    setEmail,
    setFirstName,
    setLastName,
    setPhone,
    setBusinessId,
    setAddress1,
    setCity,
    setZipCode
  } = useStore('orderStore');

  const applyCustomerId = (customerIdWithDescription: string) => {
    if(!company) return;
    const customerId = customerIdWithDescription.split(' (')[0];
    setBusinessId(customerId);
    if(customerId === company?.customerid) {
      setAddress1(company.address || '');
      setCity(company.locality || '');
      setZipCode(company.postal_code || '');
    }
    else {
      const account = company?.additional_customer_accounts?.find(account => account.customerid = customerId);
      if(!account) return;
      setAddress1(account.address || '');
      setCity(account.locality || '');
      setZipCode(account.postal_code || '');
    }
  }

  useEffect(() => {
    const loadData = async () => {
      if (!companies.length) {
        await loadCompanies();
      }
    };
    loadData();
  }, [companies, loadCompanies]);

  return (
    <Grid container direction="column" spacing={2} item>
      <Grid item>
        <Typography color="primary" variant="h2">
          {intl.formatMessage({ id: 'ContactInfo' })}
        </Typography>
      </Grid>
      {(companies && companies.length > 1) && (
        <Grid item>
          <Autocomplete
            getOptionLabel={(option) => option.name || String(option.id)}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(event, value) => { setCompany(value); }}
            options={companies}
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({ id: "CompanyName" })}
              />
            )}
            value={company}
          />
        </Grid>
      )}
      <Grid container spacing={2} item>
        <Grid item xs={6}>
          <TextField
            aria-label={intl.formatMessage({ id: 'FirstName' })}
            error={!firstNameOkay}
            fullWidth
            helperText={
              firstNameOkay
                ? null
                : intl.formatMessage({
                    id: 'error.validation.firstName',
                  })
            }
            label={intl.formatMessage({ id: 'FirstName' })}
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            aria-label={intl.formatMessage({ id: 'LastName' })}
            error={!lastNameOkay}
            fullWidth
            helperText={
              lastNameOkay
                ? null
                : intl.formatMessage({
                    id: 'error.validation.lastName',
                  })
            }
            label={intl.formatMessage({ id: 'LastName' })}
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
        </Grid>
      </Grid>
      <Grid item>
        <TextField
          aria-label={intl.formatMessage({ id: 'Email' })}
          error={!emailOkay}
          fullWidth
          helperText={
            emailOkay
              ? null
              : intl.formatMessage({
                  id: 'error.validation.email',
                })
          }
          label={intl.formatMessage({ id: 'Email' })}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
      </Grid>
      <Grid item>
        <TextField
          aria-label={intl.formatMessage({ id: 'PhoneNumber' })}
          error={!phoneOkay}
          fullWidth
          helperText={
            phoneOkay
              ? null
              : intl.formatMessage({
                  id: 'PhoneNumberNotValid',
                })
          }
          label={intl.formatMessage({ id: 'PhoneNumber' })}
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          getOptionLabel={(option) => option || ''}
          getOptionSelected={(option, value) => option === value}
          disableClearable={true}
          disabled={!company}
          onChange={(event, value) => applyCustomerId(value)}
          options={[...(company?.additional_customer_accounts?.map(account => {
            return `${account.customerid} (${account.description})`;
          }) || []), `${company && company.customerid} (${(intl.formatMessage({id: "DeliveryCustomerId"}))})`]}
          renderInput={(params) => (
            <TextField
              {...params}
              label={intl.formatMessage({ id: "CustomerId" })}
            />
          )}
          value={businessId}
        />
      </Grid>
    </Grid>
  );
});
