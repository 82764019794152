/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FormattedMessage as M } from 'react-intl';
import * as yup from 'yup';
import { ChangeEvent, FC, useState } from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Fade,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import pencilLine from '@iconify/icons-clarity/pencil-line';
import { InlineIcon } from '@iconify/react';
import Popper from '@material-ui/core/Popper';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  usePopupState,
  bindToggle,
  bindPopper,
  PopupState,
} from 'material-ui-popup-state/hooks';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useStore } from 'stores/store-hooks';
import { postFeedStockEvent } from 'services/api';

interface IEditableTextField {
  facilityId: number;
  productCode: string;
}

interface IUpdateData {
  amountRemoval?: number;
  amountAddition?: number;
}

export const FeedStockWidgetPopover: FC<IEditableTextField> = ({
  facilityId,
  productCode,
}) => {
  const theme = useTheme();
  const userStore = useStore('userStore');
  const toastStore = useStore('toastStore');
  const dashboardStore = useStore('dashboardStore');
  const { canEdit } = userStore;
  const intl = useIntl();
  const [tabValue, setTabValue] = useState(0);
  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'feedStockWidgetPopper',
  });

  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const updateData = async (data: IUpdateData) => {
    let amount: number | null = null;
    if (data.amountRemoval) {
      amount = -data.amountRemoval;
    } else if (data.amountAddition) {
      amount = data.amountAddition;
    }

    if (amount !== null) {
      const response = await postFeedStockEvent({
        facility_id: facilityId,
        feedtype_id: productCode,
        correction: true,
        change: amount,
      });
      if (response.status === 201) {
        popupState.close();
        dashboardStore.loadFeedStockData();
      } else {
        toastStore.setToast('FeedStockBalanceModificationFailed');
      }
    }
  };

  return (
    <Box display="flex" alignItems="center">
      {canEdit && (
        <Box
          color="primary"
          {...bindToggle(popupState)}
          css={css`
            display: flex;
            margin-right: ${theme.spacing(2)}px;
            cursor: pointer;
          `}
        >
          <InlineIcon
            height={24}
            icon={pencilLine}
            color={theme.palette.line2.main}
            hFlip
          />
        </Box>
      )}
      <Popper
        {...bindPopper(popupState)}
        transition
        css={css`
          z-index: 1; // ensure that popper is on top of video component in Firefox
        `}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={popupState.close}
            mouseEvent="onMouseDown"
          >
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <Box
                  display="flex"
                  p={1}
                  pl={2}
                  bgcolor={theme.palette.grey[200]}
                >
                  <Typography component="span" variant="h4">
                    <M id="FeedStockModification" />
                  </Typography>
                </Box>
                <Tabs value={tabValue} onChange={handleTabChange}>
                  <Tab label={intl.formatMessage({ id: 'Addition' })} />
                  <Tab label={intl.formatMessage({ id: 'Removal' })} />
                </Tabs>
                <PanelOneContent
                  value={tabValue}
                  popupState={popupState}
                  updateData={updateData}
                />
                <PanelTwoContent
                  value={tabValue}
                  popupState={popupState}
                  updateData={updateData}
                />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      css={css`
        max-width: 300px;
      `}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

interface IPanelContent {
  value: any;
  popupState: PopupState;
  updateData: (data: IUpdateData) => void;
}

const PanelOneContent = ({ value, popupState, updateData }: IPanelContent) => {
  const schema = yup.object().shape({
    amountAddition: yup
      .number()
      .transform((_, value) => {
        return +value.replace(/,/, '.');
      })
      .min(0, 'error.validation.amount')
      .max(2147483647, 'error.validation.amount')
      .typeError('error.validation.amount')
      .required('error.validation.amount'),
  });
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  return (
    <form noValidate onSubmit={handleSubmit(updateData)}>
      <TabPanel value={value} index={0}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              name="amountAddition"
              margin="dense"
              id="edit-add-stock-amount"
              label={<M id="AmountToAdd" />}
              inputRef={register}
              error={!!errors.amount}
              helperText={
                errors.amount && <M id={`${errors?.amount?.message}`} />
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              type="submit"
              disabled={!!Object.keys(errors).length}
            >
              <M id="Save" />
            </Button>
            <Button onClick={popupState.close} color="primary">
              <M id="Cancel" />
            </Button>
          </Grid>
        </Grid>
      </TabPanel>
    </form>
  );
};

const PanelTwoContent = ({ value, popupState, updateData }: IPanelContent) => {
  const schema = yup.object().shape({
    amountRemoval: yup
      .number()
      .transform((_, value) => {
        return +value.replace(/,/, '.');
      })
      .min(0, 'error.validation.amount')
      .max(2147483647, 'error.validation.amount')
      .typeError('error.validation.amount')
      .required('error.validation.amount'),
  });
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: '',
    },
  });

  return (
    <form noValidate onSubmit={handleSubmit(updateData)}>
      <TabPanel value={value} index={1}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              name="amountRemoval"
              margin="dense"
              id="edit-feed-stock-amount"
              label={<M id="AmountToRemove" />}
              inputRef={register}
              error={!!errors.amount}
              helperText={
                errors.amount && <M id={`${errors?.amount?.message}`} />
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              type="submit"
              disabled={!!Object.keys(errors).length}
            >
              <M id="Save" />
            </Button>
            <Button onClick={popupState.close} color="primary">
              <M id="Cancel" />
            </Button>
          </Grid>
        </Grid>
      </TabPanel>
    </form>
  );
};
