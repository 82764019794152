import { makeAutoObservable, runInAction } from 'mobx';

export type FilterType = 'company' | 'facility' | 'all' | 'site';
export interface IDataDisplayFilter {
  type: FilterType;
  id: number;
}

class FilterStore {
  constructor() {
    makeAutoObservable(this);
  }

  dataFilter: IDataDisplayFilter = {
    type: 'all',
    id: -1,
  };

  reset = () => {
    this.dataFilter = {
      type: 'all',
      id: -1,
    };
  };

  setDataFilter(id: number, type: FilterType) {
    runInAction(() => {
      this.dataFilter = {
        id,
        type,
      };
    });
  }
}

export default new FilterStore();
