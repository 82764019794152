import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import React from 'react';
import { Layout } from 'components/layout';
import { ForgotPassword, Login, ResetPassword } from 'components/login';
import { Dashboard, RaisioDashboard } from 'components/dashboard';
import { OrdersView } from 'components/orders/orders-view';
import { Facilities } from 'components/facility-view';
import { CompanyList } from 'components/company/company-list';
import { BasinView } from 'components/basin/basin-view';
import { Archive } from 'components/archive-view';
import { Basins } from 'components/basinlist-view';
import { UserListPage } from 'components/user/user-list';
import { theme } from 'theme/theme';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import { IntlProvider } from 'react-intl';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { StoresProvider, stores } from './stores/stores';
import { useStore } from 'stores/store-hooks';
import { observer } from 'mobx-react-lite';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { UserModel } from 'models/user';
import { localeMap, getTranslations } from 'translations/locales';
import { AcceptTerms } from './components/terms-of-service/accept-terms';
import {ReportCompanyList} from "./components/reports/report-company-list";
import {SiteView} from "./components/site/site-view";
import {TermsOfService} from "./components/terms-of-service/terms-of-service";
import {BasinCompareView} from "./components/basin-compare/basin-compare-view";
import {PrivacyPolicy} from "./components/privacy-policy/privacy-policy";
import {FacilityVideoView} from "./components/facility-view/facility-video-view/facility-video-view";

const App = observer(() => {
  let userStore = useStore('userStore');

  const {
    selectedLanguage,
    isAuthenticated,
    hasAcceptedTermsOfService,
    isRaisioAdminOrSuperuser,
    canEdit,
    currentUser,
    isConcernCompanyAdmin,
  } = userStore;

  return (
    <IntlProvider
      locale={selectedLanguage}
      messages={getTranslations(selectedLanguage)}
    >
      <MuiThemeProvider theme={theme}>
        <EmotionThemeProvider theme={theme}>
          <Router>
            <StoresProvider value={stores}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={localeMap[selectedLanguage]}
              >
                <Switch>
                  {/* Mobile app links to terms.html page.*/}
                  <Route exact path={['/terms', '/terms.html']}>
                    <TermsOfService/>
                  </Route>
                  <Route exact path={['/privacy', '/privacy.html']}>
                    <PrivacyPolicy/>
                  </Route>
                  <Layout>
                    <Routes
                      isAuthenticated={isAuthenticated}
                      isAdmin={isRaisioAdminOrSuperuser || isConcernCompanyAdmin}
                      currentUser={currentUser}
                      canEdit={canEdit}
                      hasAcceptedTermsOfService={hasAcceptedTermsOfService}
                    />
                  </Layout>
                </Switch>
              </MuiPickersUtilsProvider>
            </StoresProvider>
          </Router>
        </EmotionThemeProvider>
      </MuiThemeProvider>
    </IntlProvider>
  );
});

const Routes = ({
  isAuthenticated,
  isAdmin,
  canEdit,
  currentUser,
  hasAcceptedTermsOfService,
}: {
  isAuthenticated: boolean;
  isAdmin: boolean;
  canEdit: boolean;
  hasAcceptedTermsOfService: boolean;
  currentUser: UserModel | null;
}) => {
  return !isAuthenticated ? (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route exact path="/reset-password">
        <ResetPassword />
      </Route>
      <Redirect exact path="*" to="/login" />
    </Switch>
  ) : (
    <Switch>
      {!hasAcceptedTermsOfService && (
        <Route exact path="/accept-terms">
          <AcceptTerms />
        </Route>
      )}
      <Route exact path="/dashboard">
        {currentUser && (isAdmin ? <RaisioDashboard /> : <Dashboard />)}
      </Route>
      <Route exact path="/facilities">
        <Facilities />
      </Route>
      <Route exact path="/basins">
        <Basins />
      </Route>
      <Route exact path="/orders">
        {currentUser && (
          canEdit ? <OrdersView /> : <Redirect to="/dashboard" />
        )}
      </Route>
      <Route exact path="/archive">
        <Archive />
      </Route>
      <Route path="/basin/:id">
        <BasinView />
      </Route>
      <Route exact path="/companies">
        {currentUser && (
          isAdmin ? <CompanyList /> : <Redirect to="/dashboard" />
        )}
      </Route>
      <Route exact path="/users">
        {currentUser && (
          canEdit ? <UserListPage /> : <Redirect to="/dashboard" />
        )}
      </Route>
      <Route exact path="/reports">
        {currentUser && (
          canEdit ? <ReportCompanyList /> : <Redirect to="/dashboard" />
        )}
      </Route>
      <Route path="/site/:id">
        {currentUser && (
          <SiteView />
        )}
      </Route>
      <Route path="/facility/:id">
        {currentUser && (
          <FacilityVideoView />
        )}
      </Route>
      <Route path="/basin-compare">
        {currentUser && (
          canEdit
            ? <BasinCompareView />
            : <Redirect to="/dashboard" />
        )}
      </Route>
      <Redirect exact path="*" to="/dashboard" />
    </Switch>
  );
};

export default App;
