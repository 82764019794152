//import cogLine from '@iconify/icons-clarity/cog-line';
import barChartLine from '@iconify/icons-clarity/bar-chart-line';
import factoryLine from '@iconify/icons-clarity/factory-line';
import fishLine from '@iconify/icons-clarity/fish-line';
//import clipboardLine from '@iconify/icons-clarity/clipboard-line';
import truckLine from '@iconify/icons-clarity/truck-line';
import lineChartLine from '@iconify/icons-clarity/line-chart-line';
import groupLine from '@iconify/icons-clarity/group-line';
import archiveLine from '@iconify-icons/clarity/archive-line';

export enum AccessLevel {
  RAISIO_ADMIN,
  PROTECTED,
  ALL,
};

export const menuItems = [
  {
    id: 0,
    itemTextId: 'Dashboard',
    icon: barChartLine,
    type: 'item',
    path: '/dashboard',
    divider: true,
    accessLevel: AccessLevel.ALL,
  },
  {
    id: 1,
    itemTextId: 'FacilityView',
    icon: factoryLine,
    type: 'item',
    path: '/facilities',
    divider: false,
    accessLevel: AccessLevel.ALL,
  },
  {
    id: 3,
    itemTextId: 'BasinView',
    icon: fishLine,
    type: 'item',
    path: '/basins',
    divider: true,
    accessLevel: AccessLevel.ALL,
  },
  /*  {
    id: 4,
    itemTextId: 'FeedStock',
    itemTextIdForRaisio: 'FeedStock',
    icon: clipboardLine,
    type: 'clipboardLine',
    path: '/feedstock',
    divider: false,
  }, */
  {
    id: 5,
    itemTextId: 'Orders',
    icon: truckLine,
    type: 'item',
    path: '/orders',
    divider: true,
    accessLevel: AccessLevel.PROTECTED,
  },
  {
    id: 6,
    itemTextId: 'Reports',
    icon: lineChartLine,
    type: 'item',
    path: '/reports',
    divider: true,
    accessLevel: AccessLevel.PROTECTED,
  },
  {
    id: 7,
    itemTextId: 'Companies',
    icon: groupLine,
    type: 'item',
    path: '/companies',
    divider: false,
    accessLevel: AccessLevel.RAISIO_ADMIN,
  },
  {
    id: 8,
    itemTextId: 'Users',
    icon: groupLine,
    type: 'item',
    path: '/users',
    divider: false,
    accessLevel: AccessLevel.PROTECTED,
  },
  /*  {
    id: 9,
    itemTextId: 'Settings',
    itemTextIdForRaisio: 'Settings',
    icon: cogLine,
    type: 'item',
    path: '/settings',
    divider: false,
  }, */
  {
    id: 10,
    itemTextId: 'ArchiveView',
    icon: archiveLine,
    type: 'item',
    path: '/archive',
    divider: false,
    accessLevel: AccessLevel.ALL,
  }
];
