import { IntlShape } from 'react-intl';
import { Column, Row } from 'react-table';
import { Button, Typography } from '@material-ui/core';
import { IOngoingFast } from 'models/harvesting';
import { dateAndTime, localizedDuration } from 'translations/locales';

export const getFastingColumns = (
  intl: IntlShape,
  startCleaning: (fast: IOngoingFast) => any,
  removeRest: (fast: IOngoingFast) => any
) => [
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "Timestamp" })}
      </Typography>
    ),
    id: "timestamp",
    accessor: "timestamp",
    Cell: ({ value }: { value: string; }) => (
      <Typography>
        {dateAndTime(intl, new Date(value))}
      </Typography>
    ),
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "DurationOfFast" })}
      </Typography>
    ),
    id: "duration",
    accessor: "timestamp",
    Cell: ({ value }: { value: string; }) => (
      <Typography>
        {localizedDuration(intl, new Date(), new Date(value))}
      </Typography>
    ),
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "BiomassKg" })}
      </Typography>
    ),
    accessor: "biomass",
    Cell: ({ value }: { value: number; }) => (
      <Typography>
        {intl.formatNumber(value, {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "AmountPcs" })}
      </Typography>
    ),
    accessor: "amount",
    Cell: ({ value }: { value: number; }) => (
      <Typography>{intl.formatNumber(value)}</Typography>
    ),
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "AdditionalInformation" })}
      </Typography>
    ),
    accessor: "details",
  },
  {
    id: "clean",
    Cell: ({ row, value }: { row: Row, value: number }) => (
      <div>
        <Button
          onClick={() => startCleaning(row.original as IOngoingFast)}
          variant="outlined"
        >
          {intl.formatMessage({ id: "Harvest" })}
        </Button>
        { (
          <Button
            onClick={() => removeRest(row.original as IOngoingFast)}
            variant="outlined"
          >
            {intl.formatMessage({ id: "Remove" })}
          </Button>
        )}

      </div>
    ),
  },
] as Column<IOngoingFast>[];
