/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import PersonIcon from '@material-ui/icons/Person';
import { format } from 'date-fns';
import { formatDate } from 'translations/locales';

export const UserEvent: FC<{
  text: string | undefined;
  cssText: string | undefined;
  username: string | undefined;
  timestamp: string;
}> = ({ text, cssText, username, timestamp }) => {
  const theme = useTheme();
  const intl = useIntl();
  const date = new Date(timestamp);
  const time = format(date, 'HH:mm');
  const formattedDate = formatDate(intl, date);
  return (
    <Box
      css={css`
        display: flex;
        background-color: ${theme.palette.background.default};
        margin-bottom: ${theme.spacing(3)}px;
      `}
    >
      <Box
        css={css`
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.12);
          min-width: 90px;
          min-height: 120px;
        `}
      >
        <Typography component="span" variant="overline">
          {formattedDate}
        </Typography>
        <Typography component="span" variant="overline">
          {time}
        </Typography>
        <PersonIcon
          fontSize={'large'}
          css={css`
            min-width: 50px;
            min-height: 50px;
            color: ${theme.palette.primary.light};
          `}
        />
      </Box>
      <Box display="flex" flexDirection="column" flex="1 1 auto">
        <Box
          css={(theme) => css`
            border-radius: 4px;
            padding: ${theme.spacing(2)}px;
          `}
        >
          <Typography component="div" variant="body1" color="primary">{`${
            username || ''
          } ${intl.formatMessage({ id: 'ChangedCageVariables' })}`}</Typography>
          <Box
            css={css`
              max-width: 70%;
              color: ${theme.palette.primary.main};
              font-size: ${theme.typography.body1.fontSize};
              font-family: ${theme.typography.body1.fontFamily};
              line-height: ${theme.typography.body1.lineHeight};
              font-weight: ${theme.typography.body1.fontWeight};
            `}
            dangerouslySetInnerHTML={{ __html: text || '' }}
          />
        </Box>
      </Box>
    </Box>
  );
};
