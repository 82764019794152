/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { Paper, useTheme } from '@material-ui/core';
import { Responsive } from 'react-grid-layout';
import {
  breakpoints,
  cols,
  layouts,
  LAYOUT_RESIZE_ENABLED_,
  LAYOUT_DRAG_ENABLED,
} from './layout';
import { raisioWidgets as widgets } from 'components/widgets/widgets';
import { useStore } from 'stores/store-hooks';
import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useSize } from './use-size-hook';

const Handle = styled.span`
  // handle for testing, needs proper styling if used in production
  position: relative;
  left: calc(100% - 15px);
  bottom: 22px;
  cursor: nwse-resize;
`;

const WidgetWrapper = styled(Paper)`
  &.MuiPaper-root {
    color: ${({ theme }) => theme.palette.text.secondary};
    background: ${({ theme }) => theme.palette.common.white};
    height: 100%;
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: none;
  }
`;

export const RaisioDashboard = observer(() => {
  const theme = useTheme();
  const dashboardStore = useStore('dashboardStore');
  const target = useRef(null);
  useEffect(() => {
    if (!dashboardStore.basinList.length) {
      dashboardStore.loadSimplifiedBasinList();
    }
  }, [dashboardStore]);
  const size = useSize(target);

  return (
    <div
      ref={target}
      css={css`
        max-height: 100%;
        overflow-y: auto;
        min-width: 700px;
      `}
    >
      <Responsive
        layouts={layouts}
        cols={cols}
        breakpoints={breakpoints}
        isDraggable={LAYOUT_DRAG_ENABLED}
        width={size?.width === undefined ? 0 : (size.width || 0) - 8}
        isBounded
        containerPadding={[theme.spacing(0), theme.spacing(2)]}
        margin={[theme.spacing(2), theme.spacing(2)]}
        rowHeight={72}
        isResizable={LAYOUT_RESIZE_ENABLED_}
        resizeHandle={
          LAYOUT_RESIZE_ENABLED_ && (
            <Handle className="react-resizable-handle">&#8515;</Handle>
          )
        }
      >
        {widgets.map((widget) => {
          const Component = widget.component;
          return (
            <div key={widget.id}>
              <WidgetWrapper key={widget.id} id={widget.id}>
                <Component widget={widget} />
              </WidgetWrapper>
            </div>
          );
        })}
      </Responsive>
    </div>
  );
});
