/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Box, Grid } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useStore } from 'stores/store-hooks';
import * as yup from 'yup';

export const PhoneNumberChipInput = observer(() => {
  const intl = useIntl();
  const alarmStore = useStore('alarmStore');
  const [isPhoneNumberValid, setPhoneNumberValid] = useState(true);

  const validatePhoneNumber = (chip: any) => {
    const phoneRegExp = /^$|^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;
    const phoneValidator = yup.string().max(32).matches(phoneRegExp);
    const isValid = phoneValidator.isValidSync(chip);
    setPhoneNumberValid(isValid);
    return isValid;
  };

  return (
    <Grid item xs={12}>
      <Box m={4}>
        <ChipInput
          fullWidth
          css={(theme) => css`
            .MuiChip-root {
              background-color: ${theme.palette.secondary.main} !important;
              :hover {
                background-color: ${theme.palette.secondary.dark};
              }
              :focus {
                background-color: ${theme.palette.secondary.light};
              }
            }
          `}
          defaultValue={alarmStore.settings.receivers.phoneNumbers}
          onChange={alarmStore.updatePhoneNumbers}
          onUpdateInput={(event) => {
            if (!isPhoneNumberValid) validatePhoneNumber(event.target.value);
          }}
          onBeforeAdd={validatePhoneNumber}
          label={intl.formatMessage({ id: 'PhoneNumber' })}
          blurBehavior="clear"
          FormHelperTextProps={{
            style: {
              marginBottom: '-25px',
            },
          }}
          error={!isPhoneNumberValid || alarmStore.tooManyPhoneNumbers}
          newChipKeyCodes={[13, 32]}
          helperText={
            alarmStore.tooManyPhoneNumbers
              ? intl.formatMessage({ id: 'TooManyPhoneNumbers' }, { max: 3 })
              : isPhoneNumberValid
                  ? intl.formatMessage({ id: 'SeparateNumbersWithSpace' })
                  : intl.formatMessage({ id: 'PhoneNumberNotValid' })
          }
        />
      </Box>
    </Grid>
  );
});
