import React, {FC, Fragment, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../stores/store-hooks';
import {ICompany} from '../../models/company';
import {Accordion} from '../accordion';
import {ReportsList} from './reports-list';
import styled from '@emotion/styled';
import {ReportCompanyHeader} from './report-company-header';

export const ReportCompanyList: FC = observer(() => {
  const companyStore = useStore('companyStore');
  const reportStore = useStore('reportStore');

  useEffect(() => {
    companyStore.loadCompanies();
  }, [companyStore]);

  const onCompanyExpand = async (expanded: boolean, company: ICompany) => {
    if (!expanded) return;
    reportStore.loadReportForCompany(company.id);
  };

  return (
    <Fragment>
      <ScrollableDiv>
        {companyStore.companies.map((company) => {
          return (
            <div key={company.id}>
              <Accordion
                onExpandCallback={(expanded) =>
                  onCompanyExpand(expanded, company)
                }
                key={company.id}
                id={company.id}
                expandIcon={false}
                isExpandedInitially={companyStore.companies.length === 1}
                accordionHeader={<ReportCompanyHeader company={company} />}
                accordionContent={<ReportsList {...company} />}
              />
            </div>
          );
        })}
      </ScrollableDiv>
    </Fragment>
  );
});

const ScrollableDiv = styled.div`
  max-height: 100%;
  overflow-y: auto;
`;
