import { IntlShape } from 'react-intl';
import { Column, Row } from 'react-table';
import { IconButton, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { ICleaningResult } from 'models/harvesting';
import { formatDate } from 'translations/locales';

export const getCleaningColumns = (
  intl: IntlShape,
  summaryRowCount: number,
  editCleaningResult: (cleaningResult: ICleaningResult) => any,
) => [
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "Date" })}
      </Typography>
    ),
    accessor: "cleaningdate",
    Cell: ({ value }: { value: string }) => (
      <Typography>
        {value ? formatDate(intl, new Date(value)) : ""}
      </Typography>
    ),
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "AmountPcs" })}
      </Typography>
    ),
    accessor: "amount",
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "HarvestingStartBiomass" })}
      </Typography>
    ),
    accessor: "originalweight",
    Cell: ({ value }: { value: number }) => (
      <Typography>
        {intl.formatNumber(value, {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "HarvestingLiveWeight" })}
      </Typography>
    ),
    accessor: "liveweight",
    Cell: ({ value }: { value: number }) => (
      <Typography>
        {intl.formatNumber(value, {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "HarvestingCleanedWeight" })}
      </Typography>
    ),
    accessor: "cleanedweight",
    Cell: ({ value }: { value: number }) => (
      <Typography>
        {intl.formatNumber(value, {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "HarvestingRoeKg" })}
      </Typography>
    ),
    accessor: "roe_weight",
    Cell: ({ value }: { value: number }) => (
      <Typography>
        {intl.formatNumber(value, {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "HarvestingGutKg" })}
      </Typography>
    ),
    accessor: "gut_weight",
    Cell: ({ value }: { value: number }) => (
      <Typography>
        {intl.formatNumber(value, {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "AdditionalInformation" })}
      </Typography>
    ),
    accessor: "details",
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "Edit" })}
      </Typography>
    ),
    id: "edit",
    Cell: ({ row }: { row: Row }) => row.index < summaryRowCount ? null : (
      <IconButton
        aria-label="edit"
        color="primary"
        onClick={() => editCleaningResult(row.original as ICleaningResult)}
        size="small"
      >
        <EditIcon />
      </IconButton>
    ),
  },
] as Column<ICleaningResult>[];
