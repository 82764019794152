import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Checkbox, Grid, Typography } from '@material-ui/core';
import { AlarmTypes } from 'models/alarm';
import { NumericInput } from 'components/input-fields';

const GenericRow: FC<{ header: any; items: any[] }> = ({ header, items }) => (
  <Grid item xs={12}>
    <Box display="flex" flexDirection="row" ml={4}>
      {header && (
        <Box display="flex" flex="1 1 100%" alignItems="center">{header}</Box>
      )}
      <>
        {items.map((item, i) => (
          <Box key={i} display="flex" flex="1 1 100%" justifyContent="center">
            {item}
          </Box>
        ))}
      </>
    </Box>
  </Grid>
);

export const CheckboxRow: FC<{
  header?: any;
  isChecked: (entry: string) => boolean;
  update: (entry: string, value: boolean) => any;
  entries: (string | null)[];
}> = observer(({ header, isChecked, update, entries }) => (
  <GenericRow
    header={header}
    items={entries.map((entry) => entry ? (
      <Checkbox
        checked={isChecked(entry)}
        onChange={(event) => update(entry, event.target.checked)}
      />
    ) : <Typography />)}
  />
));

export const HeaderRow: FC<{
  header?: any;
  names: string[];
}> = ({ header, names }) => (
  <GenericRow
    header={header}
    items={names.map((name) => <Typography>{name}</Typography>)}
  />
);

export const ThresholdRow: FC<{
  label: string;
  types: (AlarmTypes | null)[];
  getter: (type: AlarmTypes) => number | null;
  setter: (type: AlarmTypes, value: number | null) => any;
}> = observer(({ label, types, getter, setter }) => (
  <GenericRow
    header={<Typography>{label}</Typography>}
    items={types.map((type) => type ? (
      <Box mx={2}>
        <NumericInput
          style={{textAlign: "center"}}
          disableArrows={false}
          fractionDigits={2}
          onChangeNullable={(value) => setter(type, value)}
          value={getter(type)}
        />
      </Box>
    ) : <Typography />)}
  />
));
