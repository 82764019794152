/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FC, Fragment } from 'react';
import { BASIN_TYPE, FEEDING_METHOD, IFishBasin } from 'models/fishbasin';
import { FormattedMessage as M, useIntl } from 'react-intl';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid, IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import { Controller, UseFormMethods } from 'react-hook-form';
import { useStore } from 'stores/store-hooks';
import get from 'lodash.get';
import {DatePicker} from "@material-ui/pickers";
import {format} from "date-fns";
import {dateFormatString} from "../../translations/locales";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

/**
 * Sub components for various form fields
 */

interface IInputProps
  extends Partial<Pick<UseFormMethods, 'register' | 'errors' | 'control'>> {
  basin?: IFishBasin | null;
  accessorPrefix?: string;
  size?: 1 | 2 | 3 | 4 | 6 | 12;
  label?: string;
}

export const BasinNameRow: FC<IInputProps> = ({
  register,
  errors = {},
  accessorPrefix = '',
}) => {
  const errorObj = get(errors, accessorPrefix) || errors;
  return (
    <Grid item xs={12} id="basin-dialog-name-row" key="basin-dialog-name-row">
      <TextField
        size="small"
        name={`${accessorPrefix ? accessorPrefix + '.' : ''}name`}
        label={<M id="BasinName" />}
        inputRef={register}
        error={!!errorObj.name}
        helperText={errorObj.name && <M id={`${errorObj.name.message}`} />}
        autoFocus
        margin="dense"
        inputProps={{
          style: { fontSize: 12 },
        }}
        fullWidth
      />
    </Grid>
  );
};

export const BasinTypeSelection: FC<IInputProps> = ({
  control,
  accessorPrefix = '',
  size = 12,
}) => {
  const intl = useIntl();
  return (
    <Grid item xs={size}>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          <div
            css={css`
              font-size: 12px;
            `}
          >
            <M id="BasinType" />
          </div>
        </FormLabel>
        <Controller
          name={`${accessorPrefix ? accessorPrefix + '.' : ''}basintype`}
          control={control}
          as={
            <RadioGroup
              aria-label="basin type selection"
              css={(theme) => css`
                margin-top: ${theme.spacing(1)}px;
                margin-left: ${theme.spacing(4)}px;
                &.MuiFormGroup-root {
                  flex-direction: row;
                }
              `}
            >
              <FormControlLabel
                value={BASIN_TYPE.Round}
                control={<Radio />}
                label={
                  <div
                    css={css`
                      font-size: 12px;
                    `}
                  >
                    {intl.formatMessage({ id: 'Round' })}
                  </div>
                }
              />
              <FormControlLabel
                value={BASIN_TYPE.Square}
                control={<Radio />}
                label={
                  <div
                    css={css`
                      font-size: 12px;
                    `}
                  >
                    {intl.formatMessage({ id: 'Square' })}
                  </div>
                }
              />
            </RadioGroup>
          }
        />
      </FormControl>
    </Grid>
  );
};

export const BasinDimensionsRound: FC<IInputProps> = ({
  register,
  errors = {},
  accessorPrefix = '',
  size = 6,
}) => {
  const errorObj = get(errors, accessorPrefix) || errors;
  return (
    <Fragment>
      <Grid item xs={size} key="basin-dialog-dimension-diameter">
        <TextField
          size="small"
          name={`${
            accessorPrefix ? accessorPrefix + '.' : ''
          }dimensions.diameter`}
          label={<M id="Diameter" />}
          inputRef={register}
          type="number"
          error={!!errorObj?.dimensions?.diameter}
          helperText={
            errorObj?.dimensions?.diameter && (
              <M id={`${errorObj?.dimensions?.diameter.message}`} />
            )
          }
          inputProps={{
            style: { fontSize: 12 },
          }}
          margin="dense"
          fullWidth
        />
      </Grid>
      <Grid item xs={size} key="basin-dialog-depth">
        <TextField
          size="small"
          name={`${accessorPrefix ? accessorPrefix + '.' : ''}dimensions.depth`}
          label={<M id="Depth" />}
          type="number"
          inputRef={register}
          error={!!errorObj?.dimensions?.depth}
          inputProps={{
            style: { fontSize: 12 },
          }}
          helperText={
            errorObj?.dimensions?.depth && (
              <M id={`${errorObj?.dimensions?.depth.message}`} />
            )
          }
          margin="dense"
          fullWidth
        />
      </Grid>
    </Fragment>
  );
};

export const BasinDimensionsSquare: FC<IInputProps> = ({
  register,
  errors = {},
  accessorPrefix = '',
  size = 4,
}) => {
  const errorObj = get(errors, accessorPrefix) || errors;
  return (
    <Fragment>
      <Grid item xs={size} key="basin-dialog-dimension-width">
        <TextField
          size="small"
          name={`${accessorPrefix ? accessorPrefix + '.' : ''}dimensions.width`}
          label={<M id="Width" />}
          type="number"
          inputRef={register}
          error={!!errorObj?.dimensions?.width}
          inputProps={{
            style: { fontSize: 12 },
          }}
          helperText={
            errorObj?.dimensions?.width && (
              <M id={`${errorObj?.dimensions?.width.message}`} />
            )
          }
          margin="dense"
          fullWidth
        />
      </Grid>
      <Grid item xs={size} key="basin-dialog-length">
        <TextField
          size="small"
          name={`${
            accessorPrefix ? accessorPrefix + '.' : ''
          }dimensions.length`}
          type="dimensions.number"
          label={<M id="Length" />}
          inputRef={register}
          error={!!errorObj?.dimensions?.length}
          inputProps={{
            style: { fontSize: 12 },
          }}
          helperText={
            get(errorObj, accessorPrefix)?.dimensions?.length && (
              <M id={`${errorObj?.dimensions?.length.message}`} />
            )
          }
          margin="dense"
          fullWidth
        />
      </Grid>
      <Grid item xs={size} key="basin-dialog-depth">
        <TextField
          size="small"
          name={`${accessorPrefix ? accessorPrefix + '.' : ''}dimensions.depth`}
          type="number"
          label={<M id="Depth" />}
          inputRef={register}
          error={!!errorObj?.dimensions?.depth}
          inputProps={{
            style: { fontSize: 12 },
          }}
          helperText={
            errorObj?.dimensions?.depth && (
              <M id={`${errorObj?.dimensions?.depth.message}`} />
            )
          }
          margin="dense"
          fullWidth
        />
      </Grid>
    </Fragment>
  );
};

export const FeedingMethodSelection: FC<IInputProps> = ({
  control,
  accessorPrefix = '',
}) => {
  const intl = useIntl();
  return (
    <Grid item xs={12}>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          <div
            css={css`
              font-size: 12px;
            `}
          >
            <M id="FeedingMethod" />
          </div>
        </FormLabel>
        <Controller
          name={`${accessorPrefix ? accessorPrefix + '.' : ''}feedingmethod`}
          control={control}
          as={
            <RadioGroup
              aria-label="feeding method selection"
              css={(theme) => css`
                margin-top: ${theme.spacing(1)}px;
                margin-left: ${theme.spacing(4)}px;
                &.MuiFormGroup-root {
                  flex-direction: row;
                }
              `}
            >
              <FormControlLabel
                value={FEEDING_METHOD.Automatic}
                control={<Radio />}
                label={
                  <div
                    css={css`
                      font-size: 12px;
                    `}
                  >
                    {intl.formatMessage({ id: 'Automatic' })}
                  </div>
                }
              />
              <FormControlLabel
                value={FEEDING_METHOD.HandFeeding}
                control={<Radio />}
                label={
                  <div
                    css={css`
                      font-size: 12px;
                    `}
                  >
                    {intl.formatMessage({ id: 'HandFeeding' })}
                  </div>
                }
              />
              <FormControlLabel
                value={FEEDING_METHOD.Mixed}
                control={<Radio />}
                label={
                  <div
                    css={css`
                      font-size: 12px;
                    `}
                  >
                    {intl.formatMessage({ id: 'Mixed' })}
                  </div>
                }
              />
            </RadioGroup>
          }
        />
      </FormControl>
    </Grid>
  );
};

export const FishDetails: FC<IInputProps> = ({
  register,
  errors = {},
  control,
  accessorPrefix = '',
}) => {
  const intl = useIntl();
  const basinStore = useStore('basinStore');
  const { fishTypes } = basinStore;
  const errorObj = get(errors, accessorPrefix) || errors;
  return (
    <Fragment>
      <Grid
        item
        xs={6}
        key="basin-dialog-fish-details-type"
        css={(theme) => css`
          display: flex;
        `}
      >
        <Controller
          name="fishtype_id"
          control={control}
          render={({ onChange, onBlur, value, ref }) => (
            <Select
              onBlur={onBlur}
              onChange={onChange}
              value={value || fishTypes?.[0]?.id || -1}
              inputRef={ref}
              id="fish-type-select"
              margin="dense"
              fullWidth
            >
              {Object.values(fishTypes).map((fishType) => {
                return (
                  <MenuItem key={fishType.id} value={fishType.id}>
                    {intl.formatMessage({
                      id:
                        fishType.name.charAt(0).toUpperCase() +
                        fishType.name.slice(1),
                    })}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        />
      </Grid>
      <Grid item xs={6} key="basin-dialog-fish-details-brand">
        <TextField
          size="small"
          name="brand"
          id="basin-add-dialog-fish-brand"
          label={<M id="FishBrand" />}
          inputRef={register}
          inputProps={{
            style: { fontSize: 12 },
          }}
          error={!!errorObj.brand}
          helperText={errorObj.brand && <M id={`${errorObj.brand.message}`} />}
          fullWidth
          css={(theme) => css`
            display: flex;
          `}
        />
      </Grid>
    </Fragment>
  );
};

export const FishInitialWeight: FC<IInputProps> = ({
  register,
  errors = {},
  accessorPrefix = '',
  size = 4,
}) => {
  const errorObj = get(errors, accessorPrefix) || errors;

  return (
    <Fragment>
      <Grid item xs={size} key="basin-dialog-fish-initial-weight">
        <TextField
          size="small"
          name={`${accessorPrefix ? accessorPrefix + '.' : ''}startweight`}
          label={<M id="StartWeight" />}
          type="number"
          inputRef={register}
          error={!!errorObj.startweight}
          inputProps={{
            style: { fontSize: 12 },
          }}
          helperText={
            errorObj.startweight && <M id={`${errorObj.startweight.message}`} />
          }
          margin="dense"
          fullWidth
        />
      </Grid>
    </Fragment>
  );
};

export const FishStartAmount: FC<IInputProps> = ({
  register,
  errors = {},
  accessorPrefix = '',
  size = 4,
  label = 'StartAmount',
}) => {
  const errorObj = get(errors, accessorPrefix) || errors;
  return (
    <Grid item xs={size} key="basin-dialog-fish-amount">
      <TextField
        size="small"
        name={`${accessorPrefix ? accessorPrefix + '.' : ''}startamount`}
        label={<M id={label} />}
        type="number"
        inputRef={register}
        error={!!errorObj.startamount}
        helperText={
          errorObj.startamount && <M id={`${errorObj.startamount.message}`} />
        }
        margin="dense"
        inputProps={{
          style: { fontSize: 12 },
        }}
        fullWidth
      />
    </Grid>
  );
};

export const Biomass: FC<IInputProps> = ({
  register,
  errors = {},
  accessorPrefix = '',
  size = 4,
}) => {
  const errorObj = get(errors, accessorPrefix) || errors;
  return (
    <Grid item xs={size} key="basin-dialog-fish-amount">
      <TextField
        size="small"
        name={`${accessorPrefix ? accessorPrefix + '.' : ''}biomass`}
        label={<M id="Biomass" />}
        type="number"
        inputRef={register}
        error={!!errorObj.biomass}
        inputProps={{
          style: { fontSize: 12 },
        }}
        helperText={
          errorObj.biomass && <M id={`${errorObj.biomass.message}`} />
        }
        margin="dense"
        fullWidth
      />
    </Grid>
  );
};

export const FishInitialPrice: FC<IInputProps> = ({
  register,
  errors = {},
  accessorPrefix = '',
}) => {
  const errorObj = get(errors, accessorPrefix) || errors;
  return (
    <Grid item xs={4} key="basin-dialog-fish-price">
      <TextField
        size="small"
        name={`${accessorPrefix ? accessorPrefix + '.' : ''}purchaseprice`}
        label={<M id="PurchasePrice" />}
        type="number"
        inputRef={register}
        error={!!errorObj.purchaseprice}
        inputProps={{
          style: { fontSize: 12 },
        }}
        helperText={
          errorObj.purchaseprice && (
            <M id={`${errorObj.purchaseprice.message}`} />
          )
        }
        margin="dense"
        fullWidth
      />
    </Grid>
  );
};

export const BasinCreated: FC<IInputProps> = ({ control }) => {
  const intl = useIntl();
  return (
    <Fragment>
      <Grid item xs={4} key="basin-dialog-created">
        <Controller
          name="created"
          control={control}
          render={({ onChange, onBlur, value, ref }) => (
            <DatePicker
              onBlur={onBlur}
              onChange={(date) =>
                date ? onChange(date) : date
              }
              value={value}
              inputRef={ref}
              disableToolbar
              autoOk
              variant="inline"
              format={dateFormatString(intl)}
              margin="dense"
              label={intl.formatMessage({ id: 'BasinCreated' })}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="select basin created date"
                    onClick={() => {}}
                  >
                    <CalendarTodayIcon />
                  </IconButton>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Fragment>
  );
};

export const GrowingSeason: FC<IInputProps> = ({ control }) => {
  const intl = useIntl();
  return (
    <Fragment>
      <Grid item xs={4} key="basin-dialog-growing-season">
        <Controller
          name="growingseason_start"
          control={control}
          render={({ onChange, onBlur, value, ref }) => (
            <DatePicker
              onBlur={onBlur}
              onChange={(date) =>
                date ? onChange(format(date, 'yyyy-MM-dd')) : date
              }
              value={value}
              inputRef={ref}
              disableToolbar
              autoOk
              variant="inline"
              format={dateFormatString(intl)}
              margin="dense"
              label={intl.formatMessage({ id: 'GrowingSeasonStart' })}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="select season summer start date"
                    onClick={() => {}}
                  >
                    <CalendarTodayIcon />
                  </IconButton>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={4} key="basin-dialog-growing-season">
        <Controller
          name="growingseason_end"
          control={control}
          render={({ onChange, onBlur, value, ref }) => (
            <DatePicker
              onBlur={onBlur}
              onChange={(date) =>
                date ? onChange(format(date, 'yyyy-MM-dd')) : date
              }
              value={value}
              inputRef={ref}
              disableToolbar
              autoOk
              variant="inline"
              format={dateFormatString(intl)}
              margin="dense"
              label={intl.formatMessage({ id: 'GrowingSeasonEnd' })}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="select season autumn start date"
                    onClick={() => {}}
                  >
                    <CalendarTodayIcon />
                  </IconButton>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Fragment>
  );
};
