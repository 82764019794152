/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { BasinListTable } from './basinlist-table';
import { BasinsHeader } from './basins-header';
import { Box, CircularProgress, useTheme } from '@material-ui/core';
import { getColumns } from './columns';
import { IFishBasin } from 'models/fishbasin';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useStore } from 'stores/store-hooks';
import { dateAndTime } from 'translations/locales';
import { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

export const BasinsComponent = observer(() => {
  const basinStore = useStore('basinStore');
  const { isRaisioAdminOrSuperuser, thousandSeparator } = useStore('userStore');
  const history = useHistory();
  const intl = useIntl();

  const [updatedCustomOrder, setUpdatedCustomOrder] = useState<number[]>([]);

  useEffect(() => {
    if (basinStore.basins || !basinStore.basinsIncludesIot) {
      basinStore.loadSimpleActiveBasins(true);
    }
  }, [basinStore]);

  const basins = basinStore.filteredBasins;

  const openBasin = (basin: IFishBasin) => {
    basinStore.setSelectedBasin(basin);
    history.push(`/basin/${basin.id}`);
  };

  const columns = useMemo(
    () => getColumns(
      intl,
      openBasin,
      setUpdatedCustomOrder,
      isRaisioAdminOrSuperuser,
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updatedCustomOrder]
  );

  const columnAccessors = columns.map((c) => c.accessor as string || '');

  // List of indexes of columns for which we need to calculate a total
  const columnsToSum = [
    'currentfeedinfo.feed_amount',
    'currentamount',
    'currentbiomasses.0',
    'laststatus.cumulativefeed_user',
  ].map((a) => columnAccessors.indexOf(a));

  const generatePDF = () => {
    const doc = new jsPDF({ orientation: 'landscape', unit: 'pt' });
    const marginleft = 30;
    doc.setFontSize(14);

    const title = `${intl.formatMessage({
      id: 'BasinListPrintTitle',
    })} ${dateAndTime(intl, new Date())}`;
    doc.text(title, marginleft, 30);

    const body: string[][] = [];
    const sums: number[] = [];
    let head: string[] = [];

    autoTable(new jsPDF({ orientation: 'landscape', unit: 'pt' }), {
      html: '#basintable',
      didParseCell: ({ cell, column, row, table }) => {
        if (!head.length) {
          head = (table.head[0].raw as any[]).map((x) => x.content);
        }

        if (!body[row.index]) {
          body[row.index] = [];
        }

        body[row.index][column.index] = cell.text[0];

        if (columnsToSum.indexOf(column.index) >= 0) {
          const strValue = cell.text[0]?.replace(/\s/g, "")
            .replace(thousandSeparator, "").replace(",", '.');
          const value = strValue === "-" ? 0 : parseFloat(strValue);
          sums[column.index] = (sums[column.index] || 0) + value;
        }
      },
    });

    body.push([]); // Blank row to make totals row easier to see
    body.push(columnAccessors.map((a, i) => {
        if (i === 0) return intl.formatMessage({ id: "Total" });
        if (sums[i] === undefined) return "";

        if (a === "currentamount") {
          return intl.formatNumber(sums[i]) + " " + intl.formatMessage({
            id: "Pieces"
          });
        }

        if (a === "currentfeedinfo.feedamount") {
          return intl.formatNumber(sums[i], {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " " + intl.formatMessage({ id: "UnitKg" });
        }

        return intl.formatNumber(sums[i]) + " " + intl.formatMessage({
          id: "UnitKg"
        });
    }));

    autoTable(doc, {
      startY: 50,
      margin: marginleft,
      styles: { halign: 'center', textColor: `${theme.palette.primary.main}` },
      head: [ head ],
      headStyles: {
        fillColor: `${theme.palette.primary.main}`,
        textColor: `${theme.palette.common.white}`,
      },
      body,
    });

    const filename = `${intl.formatMessage({
      id: 'BasinListFilename',
    })}.pdf`;
    doc.save(filename);
  };

  if (basinStore.isLoading) {
    return (
      <LoadingRoot>
        <CircularProgress size={50} />
      </LoadingRoot>
    );
  }

  const theme = useTheme();
  return (
    <Root>
      <BasinsHeader printBasinList={generatePDF} />
      <Box
        css={css`
          overflow-y: auto;
          padding: 0 ${theme.spacing(0)}px;
        `}
      >
        <BasinListTable columns={columns} basins={basins} archiveMode={false} />
      </Box>
    </Root>
  );
});

const LoadingRoot = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.spacing(3)}px;
`;

const Root = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  margin-left: ${({ theme }) => theme.spacing(0)}px;
  margin-right: ${({ theme }) => theme.spacing(0)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  border-radius: 5px;
  max-height: 100%;
  overflow: hidden;
`;
