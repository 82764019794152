/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/react';
import { IWidgetComponent } from 'models/widget';
import { FC } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useStore } from 'stores/store-hooks';
import { observer } from 'mobx-react-lite';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  align-items: baseline;
  justify-content: flex-start;
  padding: 0 ${({ theme }) => theme.spacing(3)}px;
`;
const Title = styled.span`
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.primary.main};
  :first-of-type {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`;

export const BiomassWidget: FC<IWidgetComponent> = observer(({ widget }) => {
  const intl = useIntl();
  const dashboardStore = useStore('dashboardStore');
  const { basinListLoading } = dashboardStore;
  if (basinListLoading) return <CircularProgress size={20} />;

  return (
    <Root>
      <Box
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          width: 100%;
          align-items: center;
          justify-content: center;
          text-align: center;
        `}
      >
        <Title>
          {`${intl.formatNumber(
            dashboardStore.totalCumulativeFeedAmount / (1000 * 1000),
            {
              style: 'decimal',
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }
          )} ${intl.formatMessage({ id: 'Tons' })}`}
          <Typography component="div" variant="h5" color="textPrimary">
            {intl.formatMessage({ id: 'CumulativeFeedMassTot' })}
          </Typography>
        </Title>
        <Title>
          {
            intl.formatNumber(dashboardStore.totalFishCount) + ' ' + intl.formatMessage({ id: 'Pieces' })
          }
          <Typography component="div" variant="h5" color="textPrimary">
            {intl.formatMessage({ id: 'TotalFishCount' })}
          </Typography>
        </Title>
      </Box>
    </Root>
  );
});
