/* For linking and copying growth factor tables to other basins */
import { makeAutoObservable, runInAction } from 'mobx';
import {
  getLinkableFishBasins,
  linkFishBasins,
  copyToFishBasins,
} from 'services/api';
import basinStore from './basin-store';
import {
  ILinkableFishBasin,
  LinkAndCopyAction,
} from 'models/link-and-copy';
import toastStore from './toast-store';

class LinkAndCopyStore {
  constructor() {
    makeAutoObservable(this);
  }

  linkableBasins: ILinkableFishBasin[] = [];
  action: LinkAndCopyAction = LinkAndCopyAction.NOTHING;

  private targets: Set<ILinkableFishBasin> = new Set<ILinkableFishBasin>();

  get targetCount(): number {
    return this.targets.size;
  }

  loadLinkableFishBasins = async () => {
    try {
      if (!basinStore.selectedBasin?.id) throw new Error ('No basin selected');
      const { data } = await getLinkableFishBasins(basinStore.selectedBasin.id);
      runInAction(() => {
        this.linkableBasins = data;
      });
    } catch (error) {
      toastStore.setToast('LoadLinkableFishBasinsFailed');
    }
  };

  performAction = async () => {
    try {
      if (!basinStore.selectedBasin?.id) throw new Error ('No basin selected');
      const targetList = Array.from(this.targets.values()).map((t) => t.id);

      if (this.action === LinkAndCopyAction.LINK) {
        await linkFishBasins(basinStore.selectedBasin.id, targetList);
      } else if (this.action === LinkAndCopyAction.COPY) {
        await copyToFishBasins(basinStore.selectedBasin.id, targetList);
      } else {
        throw new Error('No action selected');
      }

      runInAction(() => {
        this.clearAll();
      });
    } catch (error) {
      toastStore.setToast(
        this.action === LinkAndCopyAction.LINK
          ? "GrowthFactorTableLinkActionFailed"
          : "GrowthFactorTableCopyActionFailed"
      );
      return false;
    }
  };

  setActionLink = () => {
    this.action = LinkAndCopyAction.LINK;
  };

  setActionCopy = () => {
    this.action = LinkAndCopyAction.COPY;
  };

  toggleTarget = (target: ILinkableFishBasin) => {
    if (this.targets.has(target)) {
      this.targets.delete(target);
    } else {
      this.targets.add(target);
    }
  };

  isSelected = (target: ILinkableFishBasin) => {
    return this.targets.has(target);
  };

  clearAll = () => {
    this.targets.clear();
    this.action = LinkAndCopyAction.NOTHING;
  };
}

export default new LinkAndCopyStore();
