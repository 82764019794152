import {Box, Button, Typography} from "@material-ui/core";
import {NumericInput} from "../../input-fields";
import React, {FC, useEffect, useState} from "react";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {FormattedMessage as M} from 'react-intl';

interface IExtraFeedField {
  onSubmit: (value: number) => void;
  defaultValue: number;
}

export const ExtraFeedField: FC<IExtraFeedField> = ({onSubmit, defaultValue}) => {
  const [value, setValue] = useState(defaultValue);
  const [inputReady, setInputReady] = useState(false);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue])

  return (
    <Box marginTop="5px">
      <Typography variant="caption">
        <M id="AddExtraFeed"/>
      </Typography>
      <Box
        display="flex"
        maxWidth="100px"
      >
        <NumericInput
          fractionDigits={2}
          onChange={(n) => setValue(n)}
          value={value}
          onFocus={() => { setInputReady(true); setValue(0); }}
        />
        <Box marginLeft={"5px"}>
          <Button
            size="small"
            disabled={Number.isNaN(value) || value === 0 || !inputReady}
            style={buttonSizeStyles}
            onClick={() => {
              onSubmit(value);
              setValue(defaultValue);
              setInputReady(false);
            }}>
            <AddIcon/>
          </Button>
          <Button
            size="small"
            disabled={Number.isNaN(value) || value === 0 || !inputReady || value > defaultValue}
            style={buttonSizeStyles}
            onClick={() => {
              const negativeValue = value * -1;
              onSubmit(negativeValue);
              setValue(defaultValue);
              setInputReady(false);
            }}>
            <RemoveIcon/>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const buttonSizeStyles = {
  maxWidth: '15px', maxHeight: '15px', minWidth: '15px', minHeight: '15px'
}
