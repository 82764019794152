/** @jsxRuntime classic */
/* @jsx jsx */
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import { useStore } from 'stores/store-hooks';
import { IPackage, IProduct } from 'models/orders';
import { NumericInput } from 'components/input-fields';
import { Modal, ModalContent, ModalActions } from 'components/modal-dialog';
import { isPackageBulk } from './orders-utils';
import {EditableTextField} from "../basin/gadgets/editable-text-field";

const PackageEntry: FC<{
  newProduct?: IProduct; // Passed only if product is new
  pkg: IPackage;
  productId: number;
  state: string;
}> = observer(({ newProduct, pkg, productId, state }) => {
  const intl = useIntl();
  const theme = useTheme();
  const styles = state === "deleted"
    ? css`color: ${theme.palette.error.main}; opacity: 0.5;`
    : (state === "added" ? css`color: ${theme.palette.secondary.main}` : null);

  const {
    addPackageToDeleteList,
    removePackageFromDeleteList,
    removePackageFromProductInAddList,
    removePackageFromProductList,
  } = useStore('productListStore');

  return (
    <Grid container direction="row" item xs={12}>
      <Grid css={styles} item xs={3}>
        <Typography>
          {pkg.description}
        </Typography>
      </Grid>
      <Grid css={styles} item xs={2}>
        <Typography>
          {pkg.productCode}
        </Typography>
      </Grid>
      <Grid css={styles} item xs={1}>
        <Typography>
          {pkg.phosphorPercentage === null
            ? ""
            : intl.formatNumber(
                pkg.phosphorPercentage,
                {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                },
              ) + "%"}
        </Typography>
      </Grid>
      <Grid css={styles} item xs={1}>
        <Typography>
          {pkg.nitrogenPercentage === null
            ? ""
            : intl.formatNumber(
                pkg.nitrogenPercentage,
                {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                },
              ) + "%"}
        </Typography>
      </Grid>
      <Grid css={styles} item xs={2}>
        <Typography>
          {isPackageBulk(pkg)
            ? intl.formatMessage({ id: "Bulk" })
            : intl.formatMessage({ id: "Unit" })}
        </Typography>
      </Grid>
      <Grid css={styles} item xs={2}>
        <Typography>
          {isPackageBulk(pkg) ? "-" : (
            intl.formatNumber(pkg.weight, {
              style: 'decimal',
              maximumFractionDigits: 2,
            }) + " " + intl.formatMessage({ id: "UnitKg" })
          )}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        {state === "deleted" ? (
          <IconButton
            aria-label="restore"
            color="primary"
            onClick={() => removePackageFromDeleteList(pkg.id)}
            size="small"
          >
            <RestoreFromTrashIcon />
          </IconButton>
        ) : (
          <IconButton
            aria-label="remove"
            color="primary"
            onClick={
              newProduct ? () => {
                removePackageFromProductInAddList(newProduct, pkg);
              } : state === "added"
                    ? () => removePackageFromProductList(productId, pkg)
                    : () => addPackageToDeleteList(pkg.id)
            }
            size="small"
          >
            <ClearIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
});

const computeMaterialGroup = (isBulk: boolean, weight: number) => {
  if (isBulk) {
    return "100";
  } else if (weight < 1) {
    return "510";
  } else if (weight < 5) {
    return "509";
  } else if (weight < 10) {
    return "508";
  } else if (weight >= 25 && weight < 40) {
    return "505";
  } else if (weight >= 500 && weight < 1000) {
    return "502";
  } else {
    return "999"; // Default that doesn't actually exist
  }
};

const OneProductEntry: FC<{
  isNew: boolean;
  product: IProduct;
}> = observer(({ isNew, product }) => {
  const {
    addPackageToProductInAddList,
    addPackageToProductList,
    packageIdsToDelete,
    packagesToAdd,
    renameProductDescription
  } = useStore('productListStore');
  const intl = useIntl();
  const theme = useTheme();

  const [newPackageDescription, setNewPackageDescription] = useState("");
  const [newPackageProductCode, setNewPackageProductCode] = useState("");
  const [newPackageIsBulk, setNewPackageIsBulk] = useState(false);
  const [newPackageWeight, setNewPackageWeight] = useState<number | null>(null);
  const [newPackagePhosphor, setNewPackagePhosphor] = useState<number | null>(
    null
  );
  const [newPackageNitrogen, setNewPackageNitrogen] = useState<number | null>(
    null
  );

  return (
    <Grid container direction="column" item xs={12}>
      <EditableTextField
        component="div"
        variant="subtitle1"
        color="primary"
        value={product.description}
        inputProps={{
          style: {
            ...theme.typography.subtitle1,
            color: theme.palette.primary.main,
          },
        }}
        onChange={(event) => {
          renameProductDescription(product, event.target.value);
        }}
      />
      <Grid container direction="column" spacing={1} item xs={12}>
        <Grid container direction="row" spacing={1} item xs={12}>
          <Grid item xs={3}>
            <TextField
              aria-label={intl.formatMessage({ id: "PackageName" })}
              label={intl.formatMessage({ id: "PackageName" })}
              onChange={(event) => setNewPackageDescription(event.target.value)}
              value={newPackageDescription}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              aria-label={intl.formatMessage({ id: "ProductCode" })}
              label={intl.formatMessage({ id: "ProductCode" })}
              onChange={(event) => setNewPackageProductCode(event.target.value)}
              value={newPackageProductCode}
            />
          </Grid>
          <Grid item xs={1}>
            <NumericInput
              fractionDigits={2}
              label={intl.formatMessage({ id: "PhosphorPercentage" })}
              onChange={setNewPackagePhosphor}
              value={newPackagePhosphor}
            />
          </Grid>
          <Grid item xs={1}>
            <NumericInput
              fractionDigits={2}
              label={intl.formatMessage({ id: "NitrogenPercentage" })}
              onChange={setNewPackageNitrogen}
              value={newPackageNitrogen}
            />
          </Grid>
          <Grid container alignItems="flex-end" item xs={2}>
            <Select
              onChange={(event) => setNewPackageIsBulk(
                !!Number(event.target.value)
              )}
              value={newPackageIsBulk ? 1 : 0}
            >
              <MenuItem value={0}>
                {intl.formatMessage({ id: "Unit" })}
              </MenuItem>
              <MenuItem value={1}>
                {intl.formatMessage({ id: "Bulk" })}
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={2}>
            {newPackageIsBulk ? null : (
              <NumericInput
                fractionDigits={2}
                label={intl.formatMessage({ id: "WeightKg" })}
                onChange={setNewPackageWeight}
                value={newPackageWeight}
              />
            )}
          </Grid>
          <Grid container alignItems="flex-end" item xs={1}>
            <IconButton
              aria-label="add"
              color="primary"
              disabled={!newPackageDescription || !newPackageProductCode}
              onClick={() => {
                const newPackage: IPackage = {
                    id: -1,
                    description: newPackageDescription,
                    productCode: newPackageProductCode,
                    materialGroup: computeMaterialGroup(
                      newPackageIsBulk,
                      newPackageWeight === null ? 1 : newPackageWeight
                    ),
                    materialGroupDescription: "",
                    weight: (newPackageWeight === null) ? 1 : newPackageWeight,
                    phosphorPercentage: newPackagePhosphor,
                    nitrogenPercentage: newPackageNitrogen,
                };

                if (isNew) {
                  addPackageToProductInAddList(product, newPackage);
                } else {
                  addPackageToProductList(product.id, newPackage);
                }

                setNewPackageDescription("");
                setNewPackageProductCode("");
                setNewPackageIsBulk(false);
                setNewPackageWeight(null);
              }}
              size="small"
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
        {(packagesToAdd[product.id] || []).map((pkg, i) => (
          <PackageEntry
            key={0 - i}
            newProduct={product}
            pkg={pkg}
            productId={product.id}
            state="added"
          />
        ))}
        {product.packages.map((pkg) => (
          <PackageEntry
            key={pkg.id}
            pkg={pkg}
            productId={product.id}
            state={
              isNew
                ? "added"
                : packageIdsToDelete.includes(pkg.id) ? "deleted" : "in_db"
            }
          />
        ))}
      </Grid>
    </Grid>
  );
});

const NewProductRow = observer(() => {
  const intl = useIntl();
  const { products } = useStore('orderStore');
  const { addProductToAddList, productsToAdd } = useStore('productListStore');
  const [newProductName, setNewProductName] = useState("");
  const [materialGroup1, setMaterialGroup1] = useState("65");
  const [materialGroup2, setMaterialGroup2] = useState("610");
  const [materialGroup3, setMaterialGroup3] = useState("65B");

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={3}>
        <TextField
          aria-label={intl.formatMessage({ id: "ProductName" })}
          fullWidth
          label={intl.formatMessage({ id: "ProductName" })}
          onChange={(event) => setNewProductName(event.target.value)}
          value={newProductName}
        />
      </Grid>
      <Grid container alignItems="flex-end" item xs={3}>
        <Select
          onChange={(e) => setMaterialGroup1(e.target.value as string)}
          value={materialGroup1}
        >
          <MenuItem value="65">
            {intl.formatMessage({ id: "MaterialGroup1FishFeed" })}
          </MenuItem>
          <MenuItem value="82">
            {intl.formatMessage({ id: "MaterialGroup1FarmingEquipment" })}
          </MenuItem>
        </Select>
      </Grid>
      <Grid container alignItems="flex-end" item xs={3}>
        <Select
          onChange={(e) => setMaterialGroup2(e.target.value as string)}
          value={materialGroup2}
        >
          <MenuItem value="610">
            {intl.formatMessage({ id: "MaterialGroup2RainbowTroutFeed" })}
          </MenuItem>
          <MenuItem value="612">
            {intl.formatMessage({ id: "MaterialGroup2WhitefishFeed" })}
          </MenuItem>
          <MenuItem value="615">
            {intl.formatMessage({ id: "MaterialGroup2OtherFishFeed" })}
          </MenuItem>
          <MenuItem value="616">
            {intl.formatMessage({ id: "MaterialGroup2TestFeed" })}
          </MenuItem>
          <MenuItem value="822">
            {intl.formatMessage({ id: "MaterialGroup2ProtectiveGear" })}
          </MenuItem>
          <MenuItem value="824">
            {intl.formatMessage({ id: "MaterialGroup2OtherEquipment" })}
          </MenuItem>
        </Select>
      </Grid>
      <Grid container alignItems="flex-end" item xs={2}>
        <Select
          onChange={(e) => setMaterialGroup3(e.target.value as string)}
          value={materialGroup3}
        >
          <MenuItem value="65D">
            {intl.formatMessage({ id: "MaterialGroup3BroodstockFeed" })}
          </MenuItem>
          <MenuItem value="65A">
            {intl.formatMessage({ id: "MaterialGroup3FingerlingFeed" })}
          </MenuItem>
          <MenuItem value="65B">
            {intl.formatMessage({ id: "MaterialGroup3GrowerFeed" })}
          </MenuItem>
          <MenuItem value="65C">
            {intl.formatMessage({ id: "MaterialGroup3HealthFeed" })}
          </MenuItem>
          <MenuItem value="65E">
            {intl.formatMessage({ id: "MaterialGroup3MedicalFeed" })}
          </MenuItem>
          <MenuItem value="65F">
            {intl.formatMessage({ id: "MaterialGroup3StarterFeed" })}
          </MenuItem>
          <MenuItem value="82C">
            {intl.formatMessage({ id: "MaterialGroup3Textiles" })}
          </MenuItem>
          <MenuItem value="82D">
            {intl.formatMessage({ id: "MaterialGroup3OtherEquipment" })}
          </MenuItem>
        </Select>
      </Grid>
      <Grid container alignItems="flex-end" item xs={1}>
        <IconButton
          aria-label="add"
          color="primary"
          disabled={!newProductName}
          onClick={() => {
            addProductToAddList({
              id: products.length + productsToAdd.length,
              description: newProductName,
              materialGroups: [materialGroup1, materialGroup2, materialGroup3],
              packages: [],
            });
            setNewProductName("");
            setMaterialGroup1("65");
            setMaterialGroup2("610");
            setMaterialGroup3("65B");
          }}
          size="small"
        >
          <AddIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
});

export const UpdateProductsDialog = observer(() => {
  const intl = useIntl();
  const { loadProducts, productsOrdered } = useStore('orderStore');
  const {
    noUpdates,
    productsToAdd,
    resetUpdates,
    saveUpdates,
  } = useStore('productListStore');
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    resetUpdates();
    setIsOpen(false);
  };

  return isOpen ? (
    <Modal handleClose={handleClose}>
      <ModalContent>
        <NewProductRow />
        <Grid container><Box my={2} /></Grid>
        <Grid container direction="column" spacing={2}>
          {productsToAdd.map((product) => (
            <OneProductEntry
              key={product.id}
              isNew={true}
              product={product}
            />
          ))}
          {productsOrdered.map((product) => (
            <OneProductEntry
              key={product.id}
              isNew={false}
              product={product}
            />
          ))}
        </Grid>
      </ModalContent>
      <ModalActions>
        <Button
          color="primary"
          disabled={noUpdates}
          onClick={async () => {
            await saveUpdates();
            loadProducts();
            handleClose();
          }}
        >
          {intl.formatMessage({ id: "Save" })}
        </Button>
        <Button color="primary" onClick={handleClose}>
          {intl.formatMessage({ id: "Cancel" })}
        </Button>
      </ModalActions>
    </Modal>
  ) : (
    <Button
      color="primary"
      onClick={() => setIsOpen(true)}
      startIcon={<EditIcon />}
      variant="outlined"
    >
      {intl.formatMessage({ id: "UpdateProductList" })}
    </Button>
  );
});
