/** @jsxRuntime classic */
/** @jsx jsx */
import React, { ReactNode } from 'react';
import { css, jsx } from '@emotion/react';
import {Box, Theme, Tooltip, Typography} from '@material-ui/core';
import { IntlShape } from 'react-intl';
import { Column, Row } from 'react-table';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ICompanyListWidgetRow } from 'models/widget';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { Remove } from "@material-ui/icons";
import WarningIcon from "@material-ui/icons/Warning";

const TEMPERATURE_DELTA_THRESHOLD_UPPER= 0.1;
const TEMPERATURE_DELTA_THRESHOLD_LOWER= -0.1;
const OXYGEN_DELTA_THRESHOLD_UPPER = 0.1;
const OXYGEN_DELTA_THRESHOLD_LOWER = -0.1;

const RowTextComponent = ({
  children,
  align = 'center',
  width = 'auto',
  color = 'textPrimary',
  fontWeight = 600,
}: {
  children: ReactNode;
  align?: 'center' | 'left';
  width?: string;
  color?: 'textPrimary' | 'primary';
  fontWeight?: 600 | 700;
}) => (
  <Typography
    color={color}
    variant="h4"
    align={align}
    css={css`
      &.MuiTypography-h4 {
        overflow: hidden;
        width: ${width};
        font-weight: ${fontWeight};
      }
    `}
  >
    {children}
  </Typography>
);

export const getColumns = (
  intl: IntlShape,
  navigate: (companyId: number) => void,
  theme: Theme,
) => {
  return [
    {
      Header: () => (
        <Typography variant="h6" color="primary" align="left">
          {intl.formatMessage({ id: 'Name' })}
        </Typography>
      ),
      accessor: 'companyName',
      Cell: ({ value, row }: { value: any; row: Row<{}> }) => {
        const {
          activeAlarms,
        } = row.original as ICompanyListWidgetRow;
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            onClick={() => {
              const data = row.original as ICompanyListWidgetRow;
              navigate(data.companyId);
            }}
            css={(theme) => css`
            cursor: pointer;
            > svg {
              margin-left: ${theme.spacing(1)}px;
              color: ${theme.palette.primary.main};
              width: 1.1em;
              height: 1.2em;
            }
          `}
          >
            { activeAlarms &&
              <WarningIcon
                style={{color: theme.palette.error.main}}
              /> }
            <RowTextComponent
              color="primary"
              align="left"
              width="auto"
              fontWeight={700}
            >
              {value}
            </RowTextComponent>
            <ArrowForwardIosIcon fontSize={'small'} />
          </Box>
        );
      },
    },
    {
      Header: (
        <Typography variant="h6" color="primary">
          {intl.formatMessage(
            {
              id: 'WaterTemperature',
            },
            { br: ' ' }
          )}
        </Typography>
      ),
      accessor: 'averageTemperature',
      Cell: ({ value, row }: { value: any; row: Row<{}> }) => {
        const {
          temperatureDelta,
          temperatureTooltipLine1,
          temperatureTooltipLine2,
        } = row.original as ICompanyListWidgetRow;
        const temperatureDeltaIcon = (temperatureDelta: number) => {
          if (temperatureDelta >= TEMPERATURE_DELTA_THRESHOLD_UPPER) return <ArrowUpwardIcon color="primary" />;
          if (temperatureDelta <= TEMPERATURE_DELTA_THRESHOLD_LOWER)
            return <ArrowDownwardIcon color="primary" />;
          return <Remove color="primary" />;
        };
        return (
          <Box
            css={(theme) => css`
              display: flex;
              > svg {
                margin-left: ${theme.spacing(2)}px;
              }
            `}
          >
            <Box style={{
              minWidth: '60px'
            }}>
              <RowTextComponent align={'left'}>
                {typeof value === 'string'
                  ? value
                  : `${intl.formatNumber(value, {
                      style: 'decimal',
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}`}
              </RowTextComponent>
            </Box>
            <Box style={{
              minWidth: '10px'
            }}>
              <RowTextComponent align={'left'}>
                {` °C`}
              </RowTextComponent>
            </Box>
            {
              <Tooltip
                title={temperatureTooltipLine1 && temperatureTooltipLine2 ?
                  <div>
                    <div>{temperatureTooltipLine2}</div>
                    <div>{temperatureTooltipLine1}</div>
                  </div>
                  : ''
                }
                arrow
              >
                {temperatureDeltaIcon(temperatureDelta)}
              </Tooltip>
            }
          </Box>
        );
      },
    },
    {
      Header: (
        <Typography variant="h6" color="primary">
          {intl.formatMessage({ id: 'Oxygen' })}
        </Typography>
      ),
      accessor: 'averageOxygen',
      Cell: ({ value, row }: { value: number | string; row: Row<{}> }) => {
        const {
          oxygenDelta,
          oxygenTooltipLine1,
          oxygenTooltipLine2,
        } = row.original as ICompanyListWidgetRow;
        const oxygenDeltaIcon = (oxygenDelta: number) => {
          if (oxygenDelta >= OXYGEN_DELTA_THRESHOLD_UPPER) return <ArrowUpwardIcon color="primary" />;
          if (oxygenDelta <= OXYGEN_DELTA_THRESHOLD_LOWER) return <ArrowDownwardIcon color="primary" />;
          return <Remove color="primary" />;
        };
        return (
          <Box
            css={(theme) => css`
              display: flex;
              > svg {
                margin-left: ${theme.spacing(2)}px;
              }
            `}
          >
            <Box style={{
              minWidth: '60px'
            }}>
              <RowTextComponent align={'left'}>
                {typeof value === 'string'
                  ? value
                  : `${intl.formatNumber(value, {
                      style: 'decimal',
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}`}
              </RowTextComponent>
            </Box>
            <Box style={{
              minWidth: '10px'
            }}>
              <RowTextComponent align={'left'}>
                {` ${intl.formatMessage({ id: "UnitMgPerLiter" })}`}
              </RowTextComponent>
            </Box>
            {<Tooltip
                title={oxygenTooltipLine1 && oxygenTooltipLine2 ?
                  <div>
                    <div>{oxygenTooltipLine2}</div>
                    <div>{oxygenTooltipLine1}</div>
                  </div>
                  : ''
                }
                arrow
              >
                {oxygenDeltaIcon(oxygenDelta)}
              </Tooltip>
            }
          </Box>
        );
      },
    },
    // {
    //   Header: (
    //     <Typography variant="h6" color="primary" align="center">
    //       {intl.formatMessage({ id: 'FacilityCount' })}
    //     </Typography>
    //   ),
    //   id: 'facilityCount',
    //   Cell: ({ value, row }: { value: number; row: Row<{}> }) => {
    //     const data = row.original as ICompanyListWidgetRow;
    //     return (
    //       <Box
    //         css={(theme) => css`
    //           display: flex;
    //           align-items: center;
    //           justify-content: center;
    //           > svg {
    //             margin-left: ${theme.spacing(2)}px;
    //           }
    //         `}
    //       >
    //         <RowTextComponent>{data.facilities.size}</RowTextComponent>
    //       </Box>
    //     );
    //   },
    // },
    {
      Header: (
        <Typography variant="h6" color="primary" align="center">
          {intl.formatMessage({ id: 'SiteCount' })}
        </Typography>
      ),
      id: 'basinCount',
      Cell: ({ value, row }: { value: number; row: Row<{}> }) => {
        const data = row.original as ICompanyListWidgetRow;
        return (
          <Box
            css={(theme) => css`
              display: flex;
              align-items: center;
              justify-content: center;
              > svg {
                margin-left: ${theme.spacing(2)}px;
              }
            `}
          >
            <RowTextComponent>{data.sites.size}</RowTextComponent>
          </Box>
        );
      },
    },
    {
      Header: (
        <Typography variant="h6" color="primary" align="center">
          {intl.formatMessage({ id: 'BiomassTot' })}
        </Typography>
      ),
      accessor: 'biomassTotal',
      Cell: ({ value }: { value: number }) => (
        <RowTextComponent>
          {Math.round(value / 1000)} {intl.formatMessage({ id: 'Tons' })}
        </RowTextComponent>
      ),
    },
    {
      Header: (
        <Typography variant="h6" color="primary" align="center">
          {intl.formatMessage({ id: 'FeedUsageDay' })}
        </Typography>
      ),
      accessor: 'feeUsageTotal',
      Cell: ({ value }: { value: number }) => (
        <RowTextComponent>
          {`${intl.formatNumber(value / 1000, {
            style: 'decimal',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })} ${intl.formatMessage({ id: "UnitKg" })}`}
        </RowTextComponent>
      ),
    },
  ] as Column[];
};
