/** @jsxRuntime classic */
/* @jsx jsx */
import React, { useEffect } from 'react';
import { css, jsx } from '@emotion/react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Box, Button, Grid, Typography, useTheme } from '@material-ui/core';
import { useStore } from 'stores/store-hooks';
import { AddToCartDialog } from './add-to-cart-dialog';
import { ContactInfoInput } from './contact-info-input';
import { DeliveryInfoInput } from './delivery-info-input';
import { OrderHistoryView } from './order-history-view';
import { OrderPackageTable } from './order-package-table';
import { UpdateProductsDialog } from './update-products-dialog';

export const OrdersView = observer(() => {
  const intl = useIntl();
  const {
    canPlaceOrder,
    cart,
    editCartItem,
    loadProducts,
    placeOrder,
    products,
    removeFromCart,
    resetContactAndDeliveryInfo,
    setDefaultContactAndDeliveryInfo,
  } = useStore('orderStore');
  const { isRaisioAdminOrSuperuser } = useStore('userStore');

  useEffect(() => {
    const loadData = async () => {
      if (products.length === 0) {
        await loadProducts();
      }
    };
    loadData();
  }, [loadProducts, products.length]);

  // Set default values on first render, as some are time-sensitive
  useEffect(resetContactAndDeliveryInfo, [resetContactAndDeliveryInfo]);

  // Some default values are only available after current user has been loaded
  const { currentUser } = useStore('userStore');
  useEffect(setDefaultContactAndDeliveryInfo, [
    currentUser,
    setDefaultContactAndDeliveryInfo,
  ]);

  // Other default values come from the company store
  const companyStore = useStore('companyStore');
  useEffect(() => {
    const loadData = async () => {
      await companyStore.loadCompanies();
      setDefaultContactAndDeliveryInfo();
    };
    loadData();
  }, [companyStore, setDefaultContactAndDeliveryInfo]);

  const theme = useTheme();
  return (
    <Box
      css={css`
        // Element added to get spacing between vertical scrollbar and page content
        height: calc(100% - 32px);
        margin-bottom: ${theme.spacing(2)}px;
        margin-top: ${theme.spacing(2)}px;
        margin-right: 0;
        overflow-y: auto;
        overflow-x: hidden;
      `}
    >
      <Box
        css={css`
          border-radius: 5px;
          margin-right: ${theme.spacing(1)}px;
          padding: ${theme.spacing(2)}px;
          background-color: ${theme.palette.common.white};
        `}
      >
        <Grid
          container
          justify="space-around"
          spacing={4}
        >
          <Grid
            container
            direction="column"
            spacing={3}
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
            xl={7}
          >
            <Grid container justify="space-between" alignItems="center" item>
              <Grid item>
                <Typography variant="h2" color="primary">
                  {intl.formatMessage({ id: 'Cart' })}
                </Typography>
              </Grid>
              {isRaisioAdminOrSuperuser ? (
                <Grid item>
                  <UpdateProductsDialog />
                </Grid>
              ) : null}
              <Grid item>
                <AddToCartDialog />
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={12}>
                <Box
                  css={css`
                    max-height: 280px;
                    overflow-y: auto;
                  `}
                >
                  <OrderPackageTable
                    actions={{
                      editCartItem,
                      removeFromCart,
                    }}
                    data={cart}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={12}>
                <OrderHistoryView />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            spacing={3}
            item
            xs={12}
            sm={12}
            md={12}
            lg={5}
            xl={5}
          >
            <ContactInfoInput />
            <DeliveryInfoInput />
            <Grid item>
              <Button
                color="primary"
                disabled={!canPlaceOrder}
                fullWidth
                onClick={placeOrder}
                variant="outlined"
              >
                {intl.formatMessage({ id: 'PlaceOrder' })}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});
