/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Typography, useTheme, Box } from '@material-ui/core';
import { IFishBasin } from 'models/fishbasin';
import { formatDate } from 'translations/locales';
import { useIntl } from 'react-intl';
import { FC, Fragment } from 'react';

interface IBasinDetail {
  basin: IFishBasin;
}

export const BasinArchivedNotice: FC<IBasinDetail> = ({ basin }) => {
  const intl = useIntl();
  const theme = useTheme();
  return (
    <Fragment>
      {basin.deleted && (
        <Box
          css={css`
            background-color: ${theme.palette.common.white};
            border-radius: 5px;
            min-height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: pre;
          `}
        >
          <Typography component="span" variant="caption" color="textPrimary">
            {`${intl.formatMessage({ id: 'ArchivedNotice' })} `}
          </Typography>
          <Typography component="span" variant="h5" color="primary">
            {basin.deleted_timestamp
              ? formatDate(intl, new Date(basin.deleted_timestamp))
              : '-'}
          </Typography>
        </Box>
      )}
    </Fragment>
  );
};
