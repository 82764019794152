import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { useStore } from 'stores/store-hooks';
import { ICleaningResult } from 'models/harvesting';
import { KLTable } from 'components/tables';
import {MoveToFastingButton} from './fasting-dialog-and-button';
import { CleaningDialog } from './cleaning-dialog';
import { getCleaningColumns } from './cleaning-columns';
import { getFastingColumns } from './fasting-columns';
import {FastAndCleanButton} from "./fast-and-clean-dialog-and-button";
import {getRemovingColumns} from "./removing-columns";

export const HarvestingTab = observer(() => {
  const intl = useIntl();
  const harvestingStore = useStore('harvestingStore');
  const {
    cleaningResults,
    cleaningResultsSummary,
    removedResultsSummary,
    ongoingFasts,
    setActionHarvest,
    setActionEditCleaningResult,
    setActionRemoveRestFromHarvest
  } = harvestingStore;

  const filteredCleaningResults: ICleaningResult[] = cleaningResults.filter(result => result.harvested !== false)
  const removedFishes: ICleaningResult[] = cleaningResults.filter(result => result.harvested === false)

  useEffect(() => {
    const loadData = async () => {
      await harvestingStore.loadFastingEvents();
    };
    loadData();
  }, [harvestingStore]);

  return (
    <Box display="flex" flex="1 1 100%" flexDirection="column" overflow="auto">
      <Box display="flex">
        <MoveToFastingButton />
        <FastAndCleanButton />
      </Box>
      <Box display="flex" flexDirection="column" m={2}>
        <Typography variant="subtitle2">
          {intl.formatMessage({ id: "HarvestingFastingFish" })}
        </Typography>
        {ongoingFasts.length === 0
          ? <Typography>
              {intl.formatMessage({ id: "NoFishFasting" })}
            </Typography>
          : <KLTable
              columns={getFastingColumns(intl, setActionHarvest, setActionRemoveRestFromHarvest)}
              data={ongoingFasts}
            />}
      </Box>
      {cleaningResults.length === 0 ? null : (<Box display="flex" flexDirection="column" m={2}>
            <Typography variant="subtitle2">
              {intl.formatMessage({ id : "HarvestingCleaningHistory" })}
            </Typography>
            <KLTable<ICleaningResult>
              columns={getCleaningColumns(intl, 1, setActionEditCleaningResult)}
              data={[cleaningResultsSummary].concat(filteredCleaningResults)}
              summaryRowCount={1}
            />
          </Box>
        )}
      {removedFishes.length > 0 && (
        <Box display="flex" flexDirection="column" m={2}>
          <Typography variant="subtitle2">
            {intl.formatMessage({ id : "RemovedFromFastWithoutHarvest" })}
          </Typography>
          <KLTable<ICleaningResult>
            columns={getRemovingColumns(intl, 1, setActionEditCleaningResult)}
            data={[removedResultsSummary].concat(removedFishes)}
            summaryRowCount={1}
          />
        </Box>
      )}
      <CleaningDialog />
    </Box>
  );
});
