/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FormattedMessage as M } from 'react-intl';
import * as yup from 'yup';
import { ChangeEvent, ElementType, FC, useState } from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import pencilLine from '@iconify/icons-clarity/pencil-line';
import { Variant } from '@material-ui/core/styles/createTypography';
import { InlineIcon } from '@iconify/react';
import Popper from '@material-ui/core/Popper';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  usePopupState,
  bindToggle,
  bindPopper,
  PopupState,
} from 'material-ui-popup-state/hooks';
import { useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { useStore } from 'stores/store-hooks';

interface IEditableTextField {
  amount: string;
  onChange: (data: object) => void;
  component?: ElementType<any>;
  variant?: Variant;
  suffix?: string;
  inputProps?: object;
  color?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
}

interface IUpdateData {
  currentamount: number;
  comment?: string;
  eventtype?: string;
}

const SUBTRACTION = 0;
const ADDITION = 1;

export const FishAmountPopover: FC<IEditableTextField> = ({
  amount,
  variant = 'h6',
  component = 'span',
  color,
  suffix = '',
  onChange,
}) => {
  const theme = useTheme();
  const userStore = useStore('userStore');
  const { canEdit } = userStore;
  const intl = useIntl();
  const [tabValue, setTabValue] = useState(SUBTRACTION);
  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'fishAmountPopper',
  });

  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const { thousandSeparator } = userStore;

  const updateData = (data: IUpdateData) => {
    const parsedAmount = amount
      ? parseInt(
          amount
            .replace(thousandSeparator, '')
            .replace(/\s/g, '')
        )
      : 0;
    let currentamount = parsedAmount;
    if (tabValue === SUBTRACTION) {
      // tavValue 1, subtract from fish amount
      currentamount -= data.currentamount;
      if (currentamount < 0) currentamount = 0;
    }
    if (tabValue === ADDITION) {
      // tabValue 0, add to fish amount
      currentamount += data.currentamount;
    }
    const d = {
      eventtype: tabValue === SUBTRACTION ? data.eventtype : 'R', // for tabValue 1 (addition), whe should always use event type 'R' (other reason)
      comment: data.comment || undefined, // empty string to undefined to keep the API happy
      currentamount,
    };
    onChange(d as object);
    popupState.close();
  };

  return (
    <Box display="flex" alignItems="center">
      {canEdit && (
        <Box
          color="primary"
          {...bindToggle(popupState)}
          css={css`
            display: flex;
            margin-right: 8px;
            cursor: pointer;
          `}
        >
          <InlineIcon
            height={16}
            icon={pencilLine}
            color={theme.palette.line2.main}
            hFlip
          />
        </Box>
      )}
      <Typography variant={variant} component={component} color={color}>
        {`${amount} ${suffix}`}
      </Typography>
      <Popper
        {...bindPopper(popupState)}
        transition
        css={css`
          z-index: 1; // ensure that popper is on top of video component in Firefox
        `}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={popupState.close}
            mouseEvent="onMouseDown"
          >
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <Box
                  display="flex"
                  p={1}
                  pl={2}
                  bgcolor={theme.palette.grey[200]}
                >
                  <Typography component="span" variant="h4">
                    <M id="AddRemoveFishes" />
                  </Typography>
                </Box>
                <Tabs value={tabValue} onChange={handleTabChange}>
                  <Tab label={intl.formatMessage({ id: 'Removal' })} />
                  <Tab label={intl.formatMessage({ id: 'Addition' })} />
                </Tabs>
                <PanelOneContent
                  value={tabValue}
                  popupState={popupState}
                  amount={amount}
                  updateData={updateData}
                />
                <PanelTwoContent
                  value={tabValue}
                  popupState={popupState}
                  amount={amount}
                  updateData={updateData}
                />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      css={css`
        max-width: 300px;
      `}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

interface IPanelContent {
  value: any;
  popupState: PopupState;
  amount: string;
  updateData: (data: IUpdateData) => void;
}

const PanelOneContent = ({ value, popupState, updateData }: IPanelContent) => {
  const schema = yup.object().shape({
    currentamount: yup
      .number()
      .integer('PleaseEnterAnInteger')
      .min(0, 'error.validation.currentamount')
      .max(2147483647, 'error.validation.currentamount')
      .typeError('error.validation.currentamount')
      .required('error.validation.currentamount'),
    comment: yup.string().max(200, 'error.validation.comment'),
  });
  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      currentamount: '',
      eventtype: 'D',
      comment: '',
    },
  });

  const eventtype = watch('eventtype');

  return (
    <form noValidate onSubmit={handleSubmit(updateData)}>
      <TabPanel value={value} index={0}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              name="currentamount"
              margin="dense"
              id="edit-current-fish-amount"
              label={<M id="AmountOfFishToRemove" />}
              inputRef={register}
              error={!!errors.currentamount}
              helperText={
                errors.currentamount && (
                  <M id={`${errors?.currentamount?.message}`} />
                )
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <M id="Reason" />
              </FormLabel>
              <Controller
                name="eventtype"
                control={control}
                as={
                  <RadioGroup
                    aria-label="eventtype selection"
                    css={(theme) => css`
                      margin-top: ${theme.spacing(1)}px;
                      margin-left: ${theme.spacing(4)}px;
                      &.MuiFormGroup-root {
                        flex-direction: row;
                      }
                    `}
                  >
                    <FormControlLabel
                      value={'D'}
                      control={<Radio />}
                      label={<M id="DeadFish" />}
                    />
                    <FormControlLabel
                      value={'R'}
                      control={<Radio />}
                      label={<M id="OtherReason" />}
                    />
                  </RadioGroup>
                }
              />
            </FormControl>
          </Grid>
          {eventtype === 'R' && (
            <Grid item xs={12}>
              <TextField
                name="comment"
                margin="dense"
                onChange={() => clearErrors()}
                id="fish-amount-edit-reason"
                label={<M id="Reason" />}
                inputRef={register}
                error={!!errors.comment}
                helperText={
                  errors.comment && <M id={`${errors?.comment?.message}`} />
                }
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              color="primary"
              type="submit"
              disabled={!!Object.keys(errors).length}
            >
              <M id="Save" />
            </Button>
            <Button onClick={popupState.close} color="primary">
              <M id="Cancel" />
            </Button>
          </Grid>
        </Grid>
      </TabPanel>
    </form>
  );
};

const PanelTwoContent = ({
  value,
  popupState,
  amount,
  updateData,
}: IPanelContent) => {
  const schema = yup.object().shape({
    currentamount: yup
      .number()
      .integer('PleaseEnterAnInteger')
      .min(0, 'error.validation.currentamount')
      .max(2147483647, 'error.validation.currentamount')
      .typeError('PleaseEnterAnInteger')
      .required('error.validation.currentamount'),
    comment: yup.string().max(200, 'error.validation.comment'),
  });
  const { register, handleSubmit, errors, clearErrors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  return (
    <form noValidate onSubmit={handleSubmit(updateData)}>
      <TabPanel value={value} index={1}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              name="currentamount"
              margin="dense"
              id="edit-current-fish-amount"
              label={<M id="AmountOfFishToAdd" />}
              inputRef={register}
              error={!!errors.currentamount}
              helperText={
                errors.currentamount && (
                  <M id={`${errors?.currentamount?.message}`} />
                )
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="comment"
              margin="dense"
              onChange={() => clearErrors()}
              id="fish-amount-edit-reason"
              label={<M id="Reason" />}
              inputRef={register}
              error={!!errors.comment}
              helperText={
                errors.comment && <M id={`${errors?.comment?.message}`} />
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              type="submit"
              disabled={!!Object.keys(errors).length}
            >
              <M id="Save" />
            </Button>
            <Button onClick={popupState.close} color="primary">
              <M id="Cancel" />
            </Button>
          </Grid>
        </Grid>
      </TabPanel>
    </form>
  );
};
