import {IReportBasin} from "../../../models/removed-fish-report";
import React, {FC} from "react";
import {FormattedMessage as M, useIntl} from "react-intl";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from "@material-ui/core";
import {dateAndTime} from "../../../translations/locales";
import {round2} from "./removed-fish-utils";

interface IEventTable {
  basin: IReportBasin;
  expanded: boolean;
  color: string;
}

export const EventTable: FC<IEventTable> = ({basin, expanded, color}) => {

  const intl = useIntl();

  const TableCell = withStyles({
    root: {
      borderBottom: "none",
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: color
    }
  })(MuiTableCell);

  return (
    <TableRow>
      <TableCell colSpan={6}>
        <Typography variant="h6" gutterBottom component="div">
          <M id="Events"/>
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h5">
                  <M id="Date"/>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5">
                  <M id="UnitKg"/>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5">
                  <M id="Count"/>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5">
                  <M id="DeadFish"/>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5">
                  <M id="OtherReason"/>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {basin.events.map((event) => (
              <TableRow key={event.id}>
                <TableCell component="th" scope="row">
                  {dateAndTime(intl, new Date(event.timestamp))}
                </TableCell>
                <TableCell>{round2(event.fishKgCorrection)}</TableCell>
                <TableCell>{event.fishamountcorrection}</TableCell>
                <TableCell>
                  {event.eventtype === 'D'
                    ? <Checkbox style={{padding: 0}} disabled checked={true}/>
                    : <Checkbox style={{padding: 0}} disabled checked={false}/>}
                </TableCell>
                <TableCell>{event.comment} </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableCell>
    </TableRow>
  )
}
