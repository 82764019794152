import { FC, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { bioMassOptions, biomassConfig } from './chart-config';
import { useStore } from 'stores/store-hooks';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

export const BiomassGraph: FC = observer(() => {
  const graphStore = useStore('graphStore');
  const { biomassChartData, basinChartLoading } = graphStore;
  const intl = useIntl();
  useEffect(() => {
    graphStore.loadBasinChartData();
  });
  return (
    <ReactECharts
      showLoading={basinChartLoading}
      style={{ height: '420px', width: '100%' }}
      option={{ ...bioMassOptions(intl), ...biomassChartData }}
      {...biomassConfig}
    />
  );
});
