import { makeAutoObservable, runInAction } from 'mobx';
import {
  getGrowthFactorTableByBasin,
  getGrowthFactorTableById,
  updateGrowthFactorTable,
} from 'services/api';
import basinStore from './basin-store';
import {
  IGrowthFactorEntry,
  IGrowthFactorTable,
} from '../models/growth-factor-table';
import toastStore from './toast-store';

class GrowthFactorTableStore {
  constructor() {
    makeAutoObservable(this);
  }

  table: IGrowthFactorTable | null = null;
  originalTable: IGrowthFactorTable | null = null;
  editedRow: IGrowthFactorEntry | null = null;

  get currentEntries(): IGrowthFactorEntry[] {
    return JSON.parse(this.table?.values || "[]") as IGrowthFactorEntry[];
  }

  get originalEntries(): IGrowthFactorEntry[] {
    return JSON.parse(
      this.originalTable?.values || this.table?.values || "[]"
    ) as IGrowthFactorEntry[];
  }

  get hasOriginal(): boolean {
    return !!this.originalTable;
  }

  loadTable = async () => {
    try {
      const { selectedBasin } = basinStore;
      if (!selectedBasin?.id) throw new Error('No basin selected');
      const response = await getGrowthFactorTableByBasin(selectedBasin.id);
      const { data } = response;
      if (data.original) {
        const origResponse = await getGrowthFactorTableById(data.original.id);
        runInAction(() => {
          this.originalTable = origResponse.data;
          this.table = data;
        });
      } else {
        runInAction(() => {
          this.originalTable = null;
          this.table = data;
        });
      }
    } catch (error) {
      toastStore.setToast('GrowthFactorTableLoadFailed');
      runInAction(() => {
        this.table = null;
        this.originalTable = null;
      });
    }
  };

  saveTable = async () => {
    try {
      const basinId = basinStore.selectedBasin?.id;
      if (!basinId) throw new Error('No basin selected');
      if (!this.editedRow) throw new Error('No edited row');
      const editedRow = this.editedRow as IGrowthFactorEntry;

      const updatedTable = {
        id: this.table?.id,
        fishtype: this.table?.fishtype,
        original: this.table?.original || null,
        values: JSON.stringify(this.currentEntries.map((v) =>
          (v.max === editedRow.max && v.min === editedRow.min) ? editedRow : v
        )),
      } as IGrowthFactorTable;

      await updateGrowthFactorTable(basinId, updatedTable.values);

      runInAction(() => {
        this.originalTable = this.table;
        this.table = updatedTable;
        this.editedRow = null;
      });
    } catch (error) {
      toastStore.setToast('GrowthFactorTableUpdateFailed');
      return false;
    }
  };

  setEditedRow = (row: IGrowthFactorEntry | null) => {
    runInAction(() => {
      this.editedRow = row;
    });
  };
}

export default new GrowthFactorTableStore();
