import {observer} from 'mobx-react-lite';
import {useStore} from '../../../stores/store-hooks';
import {useTheme} from '@emotion/react';
import {
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableRow,
  Typography, withStyles,
} from '@material-ui/core';
import React, {FC, Fragment} from 'react';
import {ICompany} from '../../../models/company';
import {FormattedMessage as M} from 'react-intl';
import {SiteRow} from "./report-site-row";
import {FishbasinRow} from "./fishbasin-row";


export const RemovedFishReport: FC<ICompany> = observer((company) => {
  const reportStore = useStore('reportStore');
  const theme = useTheme();

  const report = reportStore.getReportByCompanyId(company.id);
  if (!report) return <></>;

  const sites = report.facilities.flatMap((facility) => facility.sites);
  let count = 0;
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {sites.map((site) => {
            const color = count++ % 2 === 0 ? theme.palette.background.default : theme.palette.background2.main;
            return (
              <Fragment>
                <SiteRow key={site.id} site={site} color={color}>
                  <Fragment>
                    <TableRow>
                      <TableCell style={{paddingTop: 0, paddingBottom: 0, backgroundColor: color}}/>
                      <TableCell colSpan={4} style={{paddingTop: 0, paddingBottom: 0, backgroundColor: color}}>
                        <Typography variant="h6" color="textPrimary">
                          <M id="Basin"/>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {site.fishbasins.map((basin) => (
                      <FishbasinRow key={basin.id} basin={basin} color={color} />
                    ))}
                  </Fragment>
                </SiteRow>
              </Fragment>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  }
})(MuiTableCell);
