/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import styled from '@emotion/styled';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import MuiTableRow from '@material-ui/core/TableRow';
import { Column, useSortBy, useTable, useFilters } from 'react-table';
import { IFishBasin } from 'models/fishbasin';

interface IBasinTable {
  basins: IFishBasin[];
  columns: Column<IFishBasin>[];
  archiveMode?: boolean; // Is archive mode active
}

export const BasinListTable: FC<IBasinTable> = observer(
  ({ basins, columns, archiveMode }) => {
    const tableInstance = useTable(
      {
        columns,
        data: basins,
        initialState: {
          // @ts-ignore
          sortBy: [
            {
              id: 'custom_ordering_index',
              desc: false,
            },
          ],
        },
      },
      useFilters,
      useSortBy
    );

    const {
      getTableProps,
      headerGroups,
      prepareRow,
      rows,
      getTableBodyProps,
    } = tableInstance;

    const getColumnDirection = (column: any) => {
      if (column.isSorted) {
        return column.isSortedDesc ? 'desc' : 'asc';
      }
    };
    return (
      <Root>
        <TableContainer
          css={css`
            overflow-x: auto;
            max-height: calc(100vh - 130px);
            padding-right: 8px;
          `}
        >
          <Table
            id="basintable"
            stickyHeader
            aria-label="user table"
            {...getTableProps()}
            // First column is sticky
            // Before-element is used to hide scrolling row from peaking out behind rounded corners of name cell (hacky solution)
            css={(theme) => css`
              &.MuiTable-root {
                border-collapse: inherit;
                border-spacing: 0 ${theme.spacing(1)}px;

                .MuiTableHead-root .MuiTableRow-root {
                  vertical-align: baseline;
                }

                .MuiTableHead-root .MuiTableCell-root {
                  padding-bottom: ${theme.spacing(1)}px;
                  border-bottom: none;
                }

                .MuiTableCell-body {
                  min-width: 110px;
                }

                // Hide scrolling row
                .MuiTableCell-body:first-of-type::before {
                  content: '';
                  position: absolute;
                  background-color: ${theme.palette.common.white};
                  width: 20px;
                  height: 110%;
                  left: -5px;
                  top: -5%;
                  box-sizing: content-box;
                }

                .MuiTableCell-head:nth-of-type(2) {
                }

                .MuiTableCell-head {
                  background-color: ${theme.palette.common.white};
                  white-space: nowrap;
                  color: ${theme.palette.primary.main};
                  .MuiTableSortLabel-root.MuiTableSortLabel-active
                    .MuiTableSortLabel-icon {
                    color: ${theme.palette.primary.main};
                  }
                  z-index: 100;
                }

                .MuiTableCell-head:first-of-type,
                .MuiTableCell-body:first-of-type {
                  position: -webkit-sticky;
                  position: sticky;
                  left: 0;
                  z-index: 50;
                }

                .MuiTableCell-head:first-of-type {
                  z-index: 200;
                }
              }
            `}
          >
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      component="th"
                      align="center"
                      // @ts-ignore
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {
                        <div
                          css={css`
                            display: flex;
                            justify-content: center;
                          `}
                        >
                          {column.render('Header')}
                          {
                            // @ts-ignore
                            column.isSorted && (
                              <TableSortLabel
                                active
                                direction={getColumnDirection(column)}
                              />
                            )
                          }
                        </div>
                      }
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <StyledTableRow
                    archiveMode={archiveMode}
                    {...row.getRowProps()}
                    hover
                  >
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          component="td"
                          {...cell.getCellProps()}
                          align="center"
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Root>
    );
  }
);

const Root = styled(Box)`
  width: 100%;
`;

const RowBorderStyle = (
  archiveMode?: boolean,
  highlightBorder?: string,
  defaultBorder?: string
): string => {
  if (archiveMode && highlightBorder) return highlightBorder;
  return defaultBorder || 'transparent';
};

const StyledTableRow = styled(
  ({
    //@ts-ignore - FIXME: styled component specific typing issues
    archiveMode,
    //@ts-ignore
    ...props
  }) => <MuiTableRow {...props} />
)<{ archiveMode?: boolean }>`
  &.MuiTableRow-root {
    .MuiTableCell-body:first-of-type {
      border-radius: 15px 0 0 15px;
      border-left: ${({ theme, archiveMode }) =>
        `1px solid ${RowBorderStyle(
          archiveMode,
          theme.palette.warning.main,
          theme.palette.line2.main
        )}`};
    }
    .MuiTableCell-body:last-child {
      border-right: ${({ theme, archiveMode }) =>
        `1px solid ${RowBorderStyle(
          archiveMode,
          theme.palette.warning.main,
          theme.palette.line2.main
        )}`};
      border-radius: 0 15px 15px 0;
    }
    .MuiTableCell-body {
      background-color: ${({ theme }) => theme.palette.common.white};
      border-top: ${({ theme, archiveMode }) =>
        `1px solid ${RowBorderStyle(
          archiveMode,
          theme.palette.warning.main,
          theme.palette.line2.main
        )}`};
      border-bottom: ${({ theme, archiveMode }) =>
        `1px solid ${RowBorderStyle(
          archiveMode,
          theme.palette.warning.main,
          theme.palette.line2.main
        )}`};
      box-shadow: 0px 6px 5px -3px rgba(29, 54, 61, 0.05);
      margin: 0;
      padding: 0;
    }
  }
`;
