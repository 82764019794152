export interface IPackage {
  id: number;
  description: string;
  productCode: string;
  materialGroup: string;
  materialGroupDescription: string;
  updated?: string;
  weight: number;
  phosphorPercentage: number | null;
  nitrogenPercentage: number | null;
}

export interface IProduct {
  id: number;
  description: string;
  materialGroups: string[];
  packages: IPackage[];
}

// Corresponds to CustomerOrderPackage in microservice
export interface IOrderPackage {
  id?: number;
  packageId?: number;
  orderId?: number;
  amount: number;
  amountDelivered?: number;
  package?: IPackage;
  product?: IProduct; // Only used on client
}

export enum DeliveryType {
  DELIVERY = 'DELIVERY',
  PICKUP = 'PICKUP',
  NOTHING = 'NOTHING',
}

export enum DeliveryStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  DELIVERED = 'DELIVERED',
  NOTHING = 'NOTHING',
}

export interface IDeliveryInfo {
  additionalInformation?: string;
  deliveryDateFrom?: string;
  deliveryDateTo?: string;
  invoiceRef?: string;
  pickupDate?: string;
  status?: DeliveryStatus;
  type: DeliveryType;
}

export interface IContactInfo {
  address1: string;
  address2?: string;
  businessId?: string;
  city: string;
  company?: string;
  country: string;
  email: string;
  firstName: string;
  lastName: string;
  language: string;
  phone: string;
  zipCode: string;
}

export interface IOrder {
  id: number;
  created: string;
  updated: string;
  billingContact: IContactInfo;
  deliveryContact: IContactInfo;
  deliveryInfo: IDeliveryInfo;
  customerOrderPackages: IOrderPackage[];
  companyId: number;
  creatingUserId: number;
}
