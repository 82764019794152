/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { debounce, Grid, Typography, useTheme, Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { BASIN_TYPE, IFishBasin } from 'models/fishbasin';
import { FormattedNumber } from 'react-intl';
import { differenceInCalendarDays } from 'date-fns';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { additionalGrowth } from '../basin-utils';
import { EditableTextField } from './editable-text-field';
import styled from '@emotion/styled';
import { useStore } from 'stores/store-hooks';
import { formatDate } from 'translations/locales';
import { MEDIA_BREAKPOINT_LG } from '../../../constants';
import {LoadingIcon} from "../../common/loading-icon";

interface IBasinDetail {
  selectedBasin: IFishBasin;
}

export const BasinDetails: FC<IBasinDetail> = observer(({ selectedBasin }) => {
  const intl = useIntl();
  const theme = useTheme();
  const basinStore = useStore('basinStore');
  const harvestingStore = useStore('harvestingStore');
  const { ongoingFasts } = harvestingStore;
  const toastStore = useStore('toastStore');
  const [basin, setBasin] = useState(selectedBasin);
  const [atuValue, setAtuValue] = useState(-1)
  const [removedFish, setRemovedFish] = useState<number>(0);

  useEffect(() => {
    const loadData = async () => {
      await harvestingStore.loadFastingEvents();
    };
    loadData();
  }, [harvestingStore]);

  useEffect(() => {
    const loadData = async () => {
      const atu = await basinStore.getAtu(basinStore.selectedBasin);
      const removedFish = await basinStore.getRemovedFish(basinStore.selectedBasin);
      setAtuValue(atu);
      setRemovedFish(removedFish[0]);
    };
    loadData();
  }, [basinStore, intl]);

  // debounced update to db, so changes are not saved too often on every keypress
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(
    debounce(async (name: string) => {
      const prevBasin = basin;
      const response = await basinStore.updateBasin(basin.id, {
        name,
      } as IFishBasin);

      if (response.errors) {
        toastStore.setToast('ErrorSavingBasin');
        setBasin(prevBasin); // restore old basin on error
      }
    }, 1500),
    []
  );

  const updateBasinName = (name: string) => {
    const modifiedBasin = { ...basin, name };
    setBasin(modifiedBasin);
    debouncedUpdate(name);
  };

  // XXX The "diameter" field of a basin is actually the circumference.
  const volume = basin.basintype === BASIN_TYPE.Round
    ? Math.pow(basin.dimensions?.diameter || 0, 2)
        * (basin.dimensions?.depth || 0) / 4 / Math.PI
    : (basin.dimensions?.width || 0) * (basin.dimensions?.length || 0)
                                     * (basin.dimensions?.depth || 0);

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      css={(theme) => css`
        overflow-y: auto;
        padding: ${theme.spacing(2)}px;
        min-height: 265px;
        @media (max-width: ${MEDIA_BREAKPOINT_LG}px) {
          min-height: 250px;
        }
      `}
    >
      <Grid
        item
        xs={12}
        css={(theme) =>
          css`
            height: 50%;
          `
        }
      >
        {
          <Box
            css={(theme) => css`
              padding: 0;
              margin-bottom: ${theme.spacing(1)}px;
            `}
          >
            <EditableTextField
              component="div"
              variant="subtitle1"
              color="primary"
              value={basin.name}
              inputProps={{
                style: {
                  ...theme.typography.subtitle1,
                  color: theme.palette.primary.main,
                },
              }}
              onChange={(event) => {
                updateBasinName(event.target.value);
              }}
            />
          </Box>
        }
        <div>
          <Typography component="span" variant="caption" color="textPrimary">
            {`${intl.formatMessage({ id: 'Site' })}: `}
          </Typography>
          <Typography
            component="span"
            variant="h5"
            color="textPrimary"
            style={{ fontWeight: 700 }}
          >
            {basin.site.name}
          </Typography>
        </div>
        <div>
          <Typography component="span" variant="caption" color="textPrimary">
            {`${intl.formatMessage({ id: 'Facility' })}: `}
          </Typography>
          <Typography
            component="span"
            variant="h5"
            color="textPrimary"
            style={{ fontWeight: 700 }}
          >
            {basin.site.facility.name}
          </Typography>
        </div>
      </Grid>
      <Divider />
      <Grid
        item
        xs={12}
        css={(theme) => css`
          height: 50%;
        `}
      >
        <Grid container spacing={0} direction="row">
          <Grid item xs={12} md={6}>
            <Grid
              container
              spacing={0}
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={7}>
                <Typography
                  component="span"
                  variant="caption"
                  color="textPrimary"
                >
                  {`${intl.formatMessage({ id: 'BasinCreated' })} `}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  component="span"
                  variant="h5"
                  color="textPrimary"
                  style={{ fontWeight: 700 }}
                >
                  {basin.created
                    ? formatDate(intl, new Date(basin.created))
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={0} direction="row" alignItems="center">
              <Grid item xs={7}>
                <Typography
                  component="span"
                  variant="caption"
                  color="textPrimary"
                >
                  {`${intl.formatMessage({ id: 'DatesOfGrowth' })} `}
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  component="span"
                  variant="h5"
                  color="textPrimary"
                  style={{ fontWeight: 700 }}
                >
                  {basin.created
                    ? `${differenceInCalendarDays(
                        new Date(),
                        new Date(basin.created)
                      )} ${intl.formatMessage({ id: 'DayAbbreviation' })}`
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={0} direction="row" alignItems="center">
              <Grid item xs={7}>
                <Typography
                  component="span"
                  variant="caption"
                  color="textPrimary"
                >
                  {`${intl.formatMessage({ id: 'StartingWeightAverage' })} `}
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  component="span"
                  variant="h5"
                  color="textPrimary"
                  style={{ fontWeight: 700 }}
                >
                  {basin.startweight ? (
                    <Fragment>
                      <FormattedNumber value={Number(basin.startweight) || 0} />
                      {` ` + intl.formatMessage({ id: "UnitGrams" })}
                    </Fragment>
                  ) : (
                    '-'
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={0} direction="row" alignItems="center">
              <Grid item xs={7}>
                <Typography
                  component="span"
                  variant="caption"
                  color="textPrimary"
                >
                  {`${intl.formatMessage({ id: 'AdditionalGrowth' })} `}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  component="span"
                  variant="h5"
                  color="textPrimary"
                  style={{ fontWeight: 700 }}
                >
                  {`${intl.formatNumber(additionalGrowth(basin), {
                    style: 'decimal',
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })} ${intl.formatMessage({ id: "UnitKg" })}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={0} direction="row" alignItems="center">
              <Grid item xs={7}>
                <Typography
                  component="span"
                  variant="caption"
                  color="textPrimary"
                >
                  {`${intl.formatMessage({ id: 'StartingFishCount' })} `}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  component="span"
                  variant="h5"
                  color="textPrimary"
                  style={{ fontWeight: 700 }}
                >
                  {basin.startamount
                    ? `${intl.formatNumber(basin.startamount, {
                        style: 'decimal',
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })} ${intl.formatMessage({
                        id: 'Pieces',
                      })}`
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={0} direction="row" alignItems="center">
              <Grid item xs={7}>
                <Typography
                  component="span"
                  variant="caption"
                  color="textPrimary"
                >
                  {`${intl.formatMessage({ id: 'FastingCount' })} `}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  component="span"
                  variant="h5"
                  color="textPrimary"
                  style={{ fontWeight: 700 }}
                >
                  {`${intl.formatNumber(
                    Math.round(
                      ongoingFasts.reduce((n, f) => n + f.biomass, 0) * 100
                    ) / 100,
                    {
                      style: 'decimal',
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }
                  )} ${intl.formatMessage({ id: "UnitKg" })}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={0} direction="row" alignItems="center">
              <Grid item xs={7}>
                <Typography
                  component="span"
                  variant="caption"
                  color="textPrimary"
                >
                  {`${intl.formatMessage({ id: 'CurrentSeason' })} `}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  component="span"
                  variant="h5"
                  color="textPrimary"
                  style={{ fontWeight: 700 }}
                >
                  {basin?.site?.facility?.season
                    ? intl.formatMessage({
                        id:
                          basin.site.facility.season.charAt(0).toUpperCase() +
                          basin.site.facility.season.slice(1),
                      })
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={0} direction="row" alignItems="center">
              <Grid item xs={7}>
                <Typography
                  component="span"
                  variant="caption"
                  color="textPrimary"
                >
                  {intl.formatMessage({ id: "RemovedFishReportName" })}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  component="span"
                  variant="h5"
                  color="textPrimary"
                  style={{ fontWeight: 700 }}
                >
                  {intl.formatNumber(removedFish * 0.001, {
                    style: "decimal",
                    maximumFractionDigits: 1
                  })} {intl.formatMessage({ id: "UnitKg" })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={0} direction="row" alignItems="center">
              <Grid item xs={7}>
                <Typography
                  component="span"
                  variant="caption"
                  color="textPrimary"
                >
                  {intl.formatMessage({ id: "AccumulatedThermalUnit" })}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  component="span"
                  variant="h5"
                  color="textPrimary"
                  style={{ fontWeight: 700 }}
                >
                  {atuValue === -1
                    ? <LoadingIcon/>
                    : intl.formatNumber(atuValue, {
                      style: "decimal",
                      maximumFractionDigits: 1,
                    })} {intl.formatMessage({ id: "UnitCelsius" })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={0} direction="row" alignItems="center">
              <Grid item xs={7}>
                <Typography
                  component="span"
                  variant="caption"
                  color="textPrimary"
                >
                  {intl.formatMessage({ id: "BiomassPerUnitVolume" }, {
                    unit: intl.formatMessage({ id: "UnitMeters" })
                  })}<sup>3</sup> {}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  component="span"
                  variant="h5"
                  color="textPrimary"
                  style={{ fontWeight: 700 }}
                >
                  {basin?.currentbiomasses
                  && basin?.currentbiomasses?.length > 0
                    ? intl.formatNumber(
                    (basin.currentbiomasses[0] || 0) / volume,
                    {
                      style: 'decimal',
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }
                  ) + " " + intl.formatMessage({ id: "UnitKg" })
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  margin: ${({ theme }) => theme.spacing(2)}px 0;
  padding: 0;
`;
