import {Grid} from "@material-ui/core";
import {NumericInput} from "../../../input-fields";
import React, {FC, Fragment} from "react";
import {useIntl} from "react-intl";
import {useStore} from "../../../../stores/store-hooks";
import {observer} from "mobx-react-lite";

interface ICleaningMenu {
  isEditing: boolean;
}

export const CleaningFields: FC<ICleaningMenu> = observer(({isEditing}) => {

  const intl = useIntl();
  const {
    localCleaningResult,
    setCleanedWeight,
    setLiveWeight,
    setGutPercentage,
    setGutWeight,
    setRoePercentage,
    setRoeWeight,
    setLiveWeightToFact, setCleanedWeightToFact, setRoeWeightToFact, setGutWeightToFact,
    updateLiveWeight
  } = useStore('harvestingStore');

  return (
    <Fragment>
      <Grid container alignItems="center" spacing={4} item xs={12}>
        <Grid item xs={4}>
          <NumericInput
            fractionDigits={2}
            label={intl.formatMessage({ id: "HarvestingCleanedWeight" })}
            onChange={(n) => {
              setCleanedWeightToFact();
              setCleanedWeight(n);
              isEditing && updateLiveWeight();
            }}
            value={localCleaningResult.cleanedweight}
          />
        </Grid>
        <Grid item xs={4}>
          <NumericInput
            disabled={isEditing}
            fractionDigits={2}
            label={intl.formatMessage({ id: "HarvestingLiveWeight" })}
            // TODO it should not be possible for user to input mathematically incorrect data
            // By mathematically incorrect I mean data that breaks the equation
            // liveweight = cleanedweight + roeweight + gutweight
            onChange={(n) => {
              setLiveWeightToFact();
              setLiveWeight(n);
            }}
            value={localCleaningResult.liveweight}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={4} item xs={12}>
        <Grid item xs={4}>
          <NumericInput
            fractionDigits={2}
            label={intl.formatMessage({ id: "HarvestingRoeWeight" })}
            max={localCleaningResult.liveweight || 0}
            onChange={(n) => {
              setRoeWeightToFact();
              setRoeWeight(n);
              isEditing && updateLiveWeight();
            }}
            value={localCleaningResult.roe_weight}
          />
        </Grid>
        <Grid item xs={4}>
          <NumericInput
            fractionDigits={2}
            disabled={isEditing}
            label={intl.formatMessage({ id: "HarvestingRoePercentage" })}
            max={100}
            onChange={(n) => {
              setRoeWeightToFact();
              setRoePercentage(n);
              isEditing && updateLiveWeight();
            }}
            value={localCleaningResult.roe_percentage}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={4} item xs={12}>
        <Grid item xs={4}>
          <NumericInput
            fractionDigits={2}
            label={intl.formatMessage({ id: "HarvestingGutWeight" })}
            max={localCleaningResult.liveweight || 0}
            onChange={(n) => {
              setGutWeightToFact();
              setGutWeight(n);
              isEditing && updateLiveWeight();
            }}
            value={localCleaningResult.gut_weight}
          />
        </Grid>
        <Grid item xs={4}>
          <NumericInput
            fractionDigits={2}
            disabled={isEditing}
            label={intl.formatMessage({ id: "HarvestingGutPercentage" })}
            max={100}
            onChange={(n) => {
              setGutWeightToFact();
              setGutPercentage(n);
              isEditing && updateLiveWeight();
            }}
            value={localCleaningResult.gut_percentage}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
});
