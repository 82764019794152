/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { FC, FormEvent, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { FormattedMessage as M, useIntl } from 'react-intl';
import {Box, Divider, Grid, TextField, Typography} from '@material-ui/core';
import { Modal, ModalActions, ModalContent } from 'components/modal-dialog';
import { FeedingScheduleHeader } from './schedule-header';
import { FeedingScheduleSummary } from './schedule-summary';
import { FeedingScheduleEntryRow } from './feeding-schedule-entry-row';
import AddIcon from '@material-ui/icons/Add';
import { useStore } from 'stores/store-hooks';
import { observer } from 'mobx-react-lite';
import {KLTable} from '../../../../tables';
import {Column} from 'react-table';
import {getFeedingScheduleProfileColumns} from './feeding-schedule-profile-columns';
import {IFeedingSchedule} from '../../../../../models/feeding-schedule';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface IFeedingScheduleModal {
  handleClose: () => void;
}

export const FeedingScheduleModal: FC<IFeedingScheduleModal> = observer(
  ({ handleClose }) => {
    const toastStore = useStore('toastStore');
    const feedingScheduleStore = useStore('feedingScheduleStore');
    // load schedule from the APi when component loads
    const intl = useIntl();
    useEffect(() => {
      const loadInitialData = async () =>
        await feedingScheduleStore.loadSchedule();
      loadInitialData();
    }, [feedingScheduleStore]);

    const { schedule } = feedingScheduleStore;
    const { modifiedTimeStamp } = feedingScheduleStore;
    const totalFeedPercentage = schedule?.totalPercentage || 0;

    const submitData = async (event: FormEvent) => {
      event.preventDefault();
      if (schedule) {
        const response = await feedingScheduleStore.updateSchedule(
          schedule.toJS()
        );
        if (response.status !== 200) {
          toastStore.setToast('FeedingScheduleSaveFailed');
        } else {
          handleClose();
        }
      } else {
        toastStore.setToast('NoFeedingScheduleDefined');
      }
    };

    const getSchema = () => {
      const schema = {
        profile_name: yup.string().max(255, 'error.validation.profileNameMax'),
      };
      return yup.object().shape(schema);
    }

    const { register, handleSubmit, errors } = useForm({
      resolver: yupResolver(getSchema()),
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      defaultValues: {
        profile_name: ''
      },
    });

    const saveAsFeedingScheduleProfile = async (data: any) => {
      if (schedule) {
        schedule.name = data.profile_name;
        const response = await feedingScheduleStore.saveCompanyFeedingScheduleProfile(
          schedule.toJS()
        );
        if (response.status !== 200) {
          toastStore.setToast('FeedingScheduleSaveAsProfileFailed');
        } else {
          toastStore.setToast('FeedingScheduleSaveAsProfileSuccess', 'success');
          await feedingScheduleStore.loadScheduleProfiles();
        }
      }
    };

    const onProfileClick = (index: number) => () => {
      const profile = feedingScheduleStore.scheduleProfiles[index];
      if (profile.id) {
        return feedingScheduleStore.setScheduleFromProfile(profile).then(() => {
          toastStore.setToast('FeedingScheduleProfileInUseSuccess', 'success');
        }).catch(() => {
          toastStore.setToast('FeedingScheduleProfileInUseFailed');
        });
      }
    };

    const onDeleteProfile = (index: number) => () => {
      const profile = feedingScheduleStore.scheduleProfiles[index];
      if (profile.id) {
        return feedingScheduleStore.deleteCompanyFeedingScheduleProfile(profile.id)
          .then(response => {
            if (response.status !== 200) {
              toastStore.setToast('FeedingScheduleProfileDeletionFailed');
            } else {
              toastStore.setToast('FeedingScheduleProfileDeletionSuccess', 'success');
              return feedingScheduleStore.loadScheduleProfiles();
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
    };

    const columns = getFeedingScheduleProfileColumns(
      intl,
      onProfileClick,
      onDeleteProfile
    ) as Column<IFeedingSchedule>[];

    return (
      <Modal handleClose={handleClose} size="lg">
        <form noValidate onSubmit={submitData}>
          <ModalContent>
            <Box
              css={css`
                min-height: 400px;
                min-width: 900px;
              `}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography component="span" variant="h1">
                    <M id={'Edit'} />
                  </Typography>
                </Grid>
                {schedule?.entries.length ? (
                  <Grid item xs={12}>
                    <FeedingScheduleHeader
                      isHw2={schedule.deviceInfo.version === '2.0'}
                    />
                  </Grid>
                ) : null}
                {schedule &&
                  modifiedTimeStamp &&
                  schedule.entries.map((entry, index) => (
                    <Grid key={entry.rowId} item xs={12}>
                      <FeedingScheduleEntryRow
                        entry={entry}
                        calibration={
                          schedule.deviceInfo.deviceConfiguration?.fCal || 0
                        }
                        isHw2={schedule.deviceInfo.version === '2.0'}
                      />
                    </Grid>
                  ))}
                {schedule?.entries.length ? (
                  <Grid item xs={12}>
                    <Divider
                      variant="middle"
                      css={(theme) => css`
                        &.MuiDivider-root {
                          background-color: ${theme.palette.primary.main};
                          margin: ${theme.spacing(4)}px;
                        }
                      `}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  {schedule?.entries.length ? (
                    <FeedingScheduleSummary
                      totalPeriods={schedule.totalPeriods}
                      totalPercentage={schedule.totalPercentage}
                      totalFed={schedule.totalFeedAmount}
                    />
                  ) : (
                    <Box display="flex" justifyContent="center" m={8}>
                      <Typography component="div" variant="h3">
                        {intl.formatMessage({ id: 'NoScheduleSet' })}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={totalFeedPercentage >= 100}
                      onClick={feedingScheduleStore.addScheduleEntry}
                      css={css`
                        &.MuiButton-root {
                          height: 60px;
                          width: 300px;
                        }
                      `}
                    >
                      <AddIcon fontSize="large" />
                      <Typography component="span" variant="h3" color="primary">
                        {totalFeedPercentage >= 100
                          ? intl.formatMessage({ id: 'FeedingLimitReached' })
                          : intl.formatMessage({ id: 'AddNewFeedingEntry' })}
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    marginTop={2}
                  >
                    <Grid item xs={5}>
                      <TextField
                        name="profile_name"
                        id="feeding-schedule-profile-name"
                        label={intl.formatMessage({id: 'FeedingProfileName'})}
                        inputRef={register}
                        error={!!errors.profile_name}
                        helperText={
                          errors.profile_name && (
                            <M id={'GiveFeedingProfileName'}/>
                          )
                        }
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      onClick={
                        handleSubmit(saveAsFeedingScheduleProfile)
                      }
                    >
                      <M id="SaveAsFeedingScheduleProfile"/>
                    </Button>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    marginTop={4}
                  >
                    <Grid item xs={5}>
                      <Typography variant="h5" component="span">
                        {intl.formatMessage({ id: 'FeedingScheduleProfiles' })}
                      </Typography>
                      <KLTable columns={columns} data={feedingScheduleStore.scheduleProfiles} summaryRowCount={0} />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </ModalContent>
          <ModalActions>
            <Button
              color="primary"
              type="submit"
              disabled={!schedule?.isValidSchedule()}
            >
              <M id="Save" />
            </Button>
            <Button onClick={handleClose} color="primary">
              <M id="Cancel" />
            </Button>
          </ModalActions>
        </form>
      </Modal>
    );
  }
);
