import {
  Checkbox,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow, Typography,
  withStyles
} from "@material-ui/core";
import {useStore} from "../../stores/store-hooks";
import {FC} from "react";
import {IFishBasin} from "../../models/fishbasin";
import styled from "@emotion/styled";
import {observer} from "mobx-react-lite";
import {useIntl} from "react-intl";

interface IBasinCompareList {
  selectedBasins: IFishBasin[];
  setSelectedBasins: (basins: IFishBasin[]) => void;
}

export const BasinCompareList: FC<IBasinCompareList> = observer(({selectedBasins, setSelectedBasins}) => {

  const intl = useIntl();
  const basinStore = useStore('basinStore');

  const onCheckboxClick = (selected: boolean, basin: IFishBasin) => {
    if(selected) {
      if(selectedBasins.find(b => b.id === basin.id)) return;
      else setSelectedBasins([...selectedBasins, basin]);
    }
    else {
      setSelectedBasins(selectedBasins.filter(b => b.id !== basin.id));
    }
  };

  const TableCell = withStyles({
    root: {
      borderBottom: "none",
      padding: "5px",
    }
  })(MuiTableCell);

  return (
    <ScrollableDiv>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Checkbox
                checked={selectedBasins.length > 0}
                indeterminate={true}
                onClick={() => selectedBasins.length > 0 && setSelectedBasins([])}
              />
            </TableCell>
            <TableCell>
              <Typography variant="h4">
                {intl.formatMessage({id: "Name"})}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h4">
                {intl.formatMessage({id: "AverageWeight"})}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h4">
                {intl.formatMessage({id: "Amount"})}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h4">
                {intl.formatMessage({id: "Biomass"})}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {basinStore.filteredBasins.map(basin => {
            return (
              <TableRow key={basin.id}>
                <TableCell align="center">
                  <Checkbox
                    size="small"
                    onChange={event => onCheckboxClick(event.target.checked, basin)}
                    checked={!!selectedBasins.find(b => b === basin)}
                  />
                </TableCell>
                <TableCell>{basin.name}</TableCell>
                <TableCell>
                  {`${intl.formatNumber(basin.currentaverageweights ? basin.currentaverageweights[0] : 0, {
                    style: 'decimal',
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })} ${intl.formatMessage({ id: "UnitGrams" })}`}
                </TableCell>
                <TableCell>{basin.currentamount || 0}</TableCell>
                <TableCell>
                  {`${intl.formatNumber(basin.biomass || 0, {
                    style: 'decimal',
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })} ${intl.formatMessage({ id: "UnitKg" })}`}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </ScrollableDiv>
  );
});

const ScrollableDiv = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 75vh;
`;

