import styled from '@emotion/styled';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { FC } from 'react';

const Button = styled.div`
  height: 56px;
  width: 56px;
  margin: 10px;
  margin-left: -5px;
  background-color: ${({ theme }) => theme.palette.buttonbg.main};
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExpandButton: FC<{ isExpanded: boolean }> = ({ isExpanded }) => {
  return (
    <Button role="button">
      {isExpanded ? (
        <ExpandLessIcon fontSize={'large'} />
      ) : (
        <ExpandMoreIcon fontSize={'large'} />
      )}
    </Button>
  );
};
