/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { ChangeEvent, ElementType, FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, TextField, Typography, useTheme } from '@material-ui/core';
import pencilLine from '@iconify/icons-clarity/pencil-line';
import { Variant } from '@material-ui/core/styles/createTypography';
import { InlineIcon } from '@iconify/react';
import { useStore } from 'stores/store-hooks';
interface IEditableTextField {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  component?: ElementType<any>;
  variant?: Variant;
  type?: 'integer' |  'number' | 'text';
  suffix?: string;
  inputProps?: object;
  color?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
  visiblePencil?: boolean;
  disabled?: boolean;
}

export const EditableTextField: FC<IEditableTextField> = ({
  value,
  variant = 'h6',
  component = 'span',
  color,
  inputProps,
  onChange,
  suffix = '',
  type = 'text',
  visiblePencil = true,
  disabled = false,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const userStore = useStore('userStore');
  const { canEdit } = userStore;
  const { thousandSeparator } = userStore;
  const [isEditing, setEdit] = useState(false);
  // convert decimal separator to dot and remove spaced
  const parseValue = (value: string) => {
    const result = Number(
      value.replace(thousandSeparator, '').replace(',', '.').replace(/\s/g, '')
    ) || 0;
    return type === 'integer' ? Math.round(result) : result;
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      onMouseDown={(e) => {
        if (!isEditing) {
          e.stopPropagation();
          e.preventDefault();
          if (canEdit && !disabled) {
            setEdit((prevValue) => !prevValue);
          }
        }
      }}
    >
      {canEdit && visiblePencil && (
        <Box
          display="flex"
          color="primary"
          css={css`
            margin-right: 8px;
            cursor: pointer;
          `}
        >
          <InlineIcon
            height={16}
            icon={pencilLine}
            color={theme.palette.line2.main}
            hFlip
          />
        </Box>
      )}
      {isEditing ? (
        <TextField
          inputProps={inputProps}
          margin="none"
          defaultValue={
            type === 'integer' ? intl.formatNumber(parseValue(value)) :
              type === 'number'
                ? intl.formatNumber(parseValue(value), {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : value
          }
          autoFocus
          onBlur={() => setEdit(false)}
          onChange={onChange}
        />
      ) : (
        <Typography
          variant={variant}
          component={component}
          color={color}
          onDoubleClick={() => {
            !disabled && setEdit((prevValue) => !prevValue);
          }}
        >
          {`${value} ${suffix}`}
        </Typography>
      )}
    </Box>
  );
};
