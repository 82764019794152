import {makeAutoObservable, runInAction} from 'mobx';
import {IFishBasinEvent} from 'models/fishbasin';
import {IIotDeviceEvents, IotCommandResponse} from 'models/iot-devices';
import {nanoid} from 'nanoid';
import {
  cancelOperation,
  clearAlarm,
  getBasinEvents,
  getBasinEventsByEventType,
  getIotDeviceAlarms,
  getIotDeviceEvents,
  getOperationById,
  getOperations,
} from 'services/api';
import basinStore from './basin-store';
import toastStore from './toast-store';

/**
 * Mobx store to handle event and command histories from IoT device and from fishbasinEvents
 */

export interface IEvent extends IIotDeviceEvents, IFishBasinEvent {
  uuid?: string;
}

export interface ICommand extends IotCommandResponse {
  uuid?: string;
}

class EventStore {
  constructor() {
    makeAutoObservable(this);
  }

  events: IEvent[] = [];
  commands: ICommand[] = [];
  hideSensorEvents: boolean = false;
  hideGeneratedEvents: boolean = false;
  loading = true;
  hasAlarms = false;
  private pendingCommands: ICommand[] = [];
  private refreshing = false;
  private updateTimer: number | null = null;
  private refreshOffset: number = 3000;

  get filteredEvents() {
    return this.events.filter((e) => {
      if (this.hideGeneratedEvents && this.hideSensorEvents) {
        return e.userevent && e.eventtype.slice(0, 3) !== 'c8y';
      }
      if (this.hideGeneratedEvents) {
        return e.userevent;
      }
      if (this.hideSensorEvents) {
        return e.eventtype.slice(0, 3) !== 'c8y';
      }
      return true;
    });
  }

  loadCommandHistory = async () => {
    runInAction(() => {
      this.events = [];
      this.loading = true;
    });
    try {
      const selectedBasin = basinStore.selectedBasin;
      const deviceId = selectedBasin?.supportunit_id;
      if (!deviceId) throw new Error('No device ID');
      const { data: operations } = await getOperations(deviceId);
      runInAction(() => {
        this.commands = operations;
        this.commands = this.commands.map((c) => {
          c.uuid = nanoid();
          return c;
        });
        this.loading = false;
        this.refreshCommandHistory();
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.commands = [];
        this.loading = false;
      });
    }
  };

  cancelCommand = async (command: ICommand) => {
    try {
      await cancelOperation(command.deviceId, command.id);
      return true;
    } catch (error) {
      console.log(error);
      toastStore.setToast('CommandCancelFailed');
      return false;
    }
  };

  /* TODO: refreshCommandHistory logic copied from KL1.0, could probably be simplified */
  private refreshCommandHistory = () => {
    this.refreshing = true;
    this.updateTimer = window.setTimeout(async () => {
      try {
        const selectedBasin = basinStore.selectedBasin;
        const deviceId = selectedBasin?.supportunit_id;
        if (!deviceId) throw new Error('No device ID');
        const { data: operations } = await getOperations(deviceId);
        runInAction(() => {
          this.commands = operations;
          this.commands = this.commands.map((c) => {
            c.uuid = nanoid();
            return c;
          });
          this.pendingCommands = operations.filter(
            (command) =>
              command.status !== 'SUCCESSFUL' && command.status !== 'FAILED'
          );
          // Try to refresh pending commands in 3 seconds
          if (this.pendingCommands.length > 0 && this.updateTimer) {
            clearTimeout(this.updateTimer);
            this.refreshOffset = 3000;
            this.refreshPending();
          } else if (this.pendingCommands.length > 0) {
            this.refreshPending();
          } else {
            this.refreshing = false;
            this.updateTimer = null;
          }
        });
      } catch (error) {
        console.log(error);
        runInAction(() => {
          this.refreshing = false;
        });
      }
    }, 1200);
  };

  /* TODO: refreshPending logic copied from KL1.0, could probably be simplified */
  refreshPending = async () => {
    const promises = this.pendingCommands.map((command) =>
      getOperationById(command.deviceId, command.id)
    );
    const results = await Promise.all(promises);
    try {
      runInAction(() => {
        this.pendingCommands = [];
        results.forEach((result) => {
          const { data: c } = result;
          if (c.status !== 'SUCCESSFUL' && c.status !== 'FAILED') {
            this.pendingCommands.push(c);
          } else {
            // no longer pending, update the command in command list
            const index = this.commands.findIndex((item) => item.id === c.id);
            this.commands[index] = c;
            this.commands = [...this.commands]; // this is done to force mobx to re-render
          }
        });
        if (this.refreshOffset > 100000) {
          this.refreshOffset = 3000;
          this.updateTimer = null;
          return;
        }
        if (this.pendingCommands.length > 0) {
          this.refreshOffset += this.refreshOffset;
          this.updateTimer = window.setTimeout(
            this.refreshPending,
            this.refreshOffset
          );
        } else {
          this.refreshOffset = 3000;
          this.updateTimer = null;
          this.refreshing = false;
        }
      });
    } catch (error) {
      console.error('Updating a command failed', error);
      runInAction(() => {
        this.refreshOffset = 3000;
        this.updateTimer = null;
        this.refreshing = false;
      });
      // Start again in a few
      window.setTimeout(this.refreshCommandHistory, 3000);
    }
  };

  loadRemoveOrAddFishEvents = async () => {
    runInAction(() => {
      this.events = [];
      this.loading = true;
    });
  }

  loadEventsByType = async (basinId: number, eventType: string) => {
    const response = await getBasinEventsByEventType(basinId, eventType);
    return response.data;
  }

  loadEvents = async () => {
    runInAction(() => {
      this.events = [];
      this.loading = true;
    });
    try {
      const selectedBasin = basinStore.selectedBasin;
      const promises = [this.loadFishBasinEvents()];
      if (basinStore.hasIotAutomation) {
        promises.push(this.loadIotDeviceEvents());
        promises.push(this.loadIotDeviceAlarms(selectedBasin?.supportunit_id));
        promises.push(
          this.loadIotDeviceAlarms(selectedBasin?.site.mainunit_id)
        );
      }
      const [
        basinEventsResponse,
        iotDeviceEventResponse,
        deviceAlarmSupportUnitResponse,
        deviceAlarmMainUnitResponse,
      ] = await Promise.all(promises as any);
      runInAction(() => {
        this.events = (basinEventsResponse as any).data;
        if (basinStore.hasIotAutomation) {
          this.addDeviceEvents((iotDeviceEventResponse as any).data);
          this.addDeviceAlarms(
            (deviceAlarmMainUnitResponse as any).data,
            (deviceAlarmSupportUnitResponse as any).data
          );
          this.checkIotDeviceAlarms();
        } else {
          this.hasAlarms = false;
          // ensure that events are sorted correctly if no IoT device is connected
          this.events.sort(function (x, y) {
            // Reverse sort!
            return (
              new Date(y.timestamp).getTime() - new Date(x.timestamp).getTime()
            );
          });
        }
        this.events = this.events.map((e) => {
          e.uuid = nanoid();
          return e;
        });
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.events = [];
        this.loading = false;
        this.hasAlarms = false;
      });
    }
  };

  toggleSensorEventHide = () => {
    runInAction(() => {
      this.hideSensorEvents = !this.hideSensorEvents;
    });
  };

  toggleGeneratedEventHide = () => {
    runInAction(() => {
      this.hideGeneratedEvents = !this.hideGeneratedEvents;
    });
  };

  clearAllAlarmEvents = async () => {
    this.loading = true;
    const promises: Promise<any>[] = [];
    this.filteredEvents.forEach(event => {
      if(event.eventtype !== 'K' && event.status !== 'CLEARED' && event.severity !== 'WARNING') {
        if (event.id) promises.push(this.clearAlarmEvent(event.source, event.id));
      }
    });

    Promise.all(promises).then(() => {
      this.loadEvents();
    });
  }

  clearAlarmEvent = async (deviceId: string | undefined, eventId: string) => {
    try {
      if (!deviceId) throw new Error('No device id available');
      await clearAlarm(deviceId, eventId);
      runInAction(() => {
        const index = this.events.findIndex((e) => e.id === eventId);
        if (index >= 0) {
          this.events[index].status = 'CLEARED';
        }
      });
      return true; // success
    } catch (error) {
      console.log(error);
      toastStore.setToast('EventClearingFailed');
      return false; // error
    }
  };

  checkIotDeviceAlarms = () => {
    this.hasAlarms = !!this.events.find(event => event.status === 'ACTIVE');
  };

  private loadFishBasinEvents = () => {
    const { selectedBasin } = basinStore;
    const basinId = selectedBasin?.id;
    if (!basinId) throw new Error('No basin selected');
    return getBasinEvents(basinId);
  };

  private loadIotDeviceEvents = async () => {
    const {selectedBasin} = basinStore;
    const deviceId = selectedBasin?.supportunit_id;
    if (!deviceId) throw new Error('No device ID');
    return await getIotDeviceEvents(deviceId);
  };

  private loadIotDeviceAlarms = (unitId: string | undefined) => {
    if (!unitId) throw new Error('No support unit ID');
    return getIotDeviceAlarms([unitId]);
  };

  /**
   * This function & sorting logic is copied from KL1.0.
   *
   * Idea here has been to sort ongoing feeding events separately. I trust the logic in KL1.0 is actually what we want to use :)
   *
   * @param iotDeviceEvents
   */
  private addDeviceEvents = async (iotDeviceEvents: IIotDeviceEvents[]) => {
    // const systemRequestFiltered = iotDeviceEvents.filter(event => event.description !== "System State Request")
    // const cameraPowerStateEventFiltered = systemRequestFiltered.filter(event => event.eventCode === 162)
    // const nullFinishedFiltered = systemRequestFiltered.filter(event => event.feedingdetails?.amount !== 0)
    let feedingEvents = [];
    for (let event of iotDeviceEvents) {
      if (
        event.eventtype === 'c8y_FeedingStarted' ||
        event.eventtype === 'c8y_FeedingFinished'
      ) {
        feedingEvents.push(event);
      } else {
        this.events.push(event);
      }
    }

    let currentBatch = [];
    feedingEvents.sort((x, y) => {
      return new Date(x.timestamp).getTime() - new Date(y.timestamp).getTime();
    });
    for (let i = 0; i < feedingEvents.length; ++i) {
      if (
        feedingEvents[i].eventtype === 'c8y_FeedingStarted' &&
        currentBatch.length < 1
      ) {
        currentBatch.push(feedingEvents[i]);
        this.events.push(feedingEvents[i]);
      } else if (
        i < feedingEvents.length - 1 &&
        new Date(feedingEvents[i + 1].timestamp).getTime() -
        new Date(feedingEvents[i].timestamp).getTime() >
        200000 // 200s
      ) {
        currentBatch.push(feedingEvents[i]);
        this.events.push(feedingEvents[i]);
        currentBatch = [];
      } else {
        currentBatch.push(feedingEvents[i]);
      }
    }

    if (currentBatch.length > 0) {
      this.events.push(currentBatch[currentBatch.length - 1]);
    }
  };

  /**
   * Alarm filtering logic copied from KL1.0. Trusting that KL1.0 guys had this nailed down and using the logic as is :D
   *
   * @param mainUnitAlarms: IIotDeviceEvents[]
   * @param supportUnitAlarms: IIotDeviceEvents[]
   */

  private addDeviceAlarms = (
    mainUnitAlarms: IIotDeviceEvents[],
    supportUnitAlarms: IIotDeviceEvents[]
  ) => {
    let active = supportUnitAlarms.filter((alert) => alert.status === 'ACTIVE');
    active.push(...mainUnitAlarms.filter((alarm) => alarm.status === 'ACTIVE'));
    let cleared = supportUnitAlarms.filter(
      (alert) => alert.status === 'CLEARED'
    );
    cleared.push(
      ...mainUnitAlarms.filter((alarm) => alarm.status === 'CLEARED')
    );
    for (let alert of cleared) {
      this.events.push(alert);
    }
    this.events.sort(function (x, y) {
      // Reverse sort!
      return new Date(y.timestamp).getTime() - new Date(x.timestamp).getTime();
    });
    for (let alert of active) {
      // Alarms no longer have a severity; display all as critical
      alert.severity = 'CRITICAL';
      this.events.splice(0, 0, alert);
    }
  };
}

export default new EventStore();
