/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
} from '@material-ui/core';
import { ChangeEvent, FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useStore } from 'stores/store-hooks';
import { TermsOfService } from './terms-of-service';
import { PrivacyPolicy } from '../privacy-policy/privacy-policy';

export const AcceptTerms: FC = () => {
  const intl = useIntl();
  const userStore = useStore('userStore');
  const history = useHistory();
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleAcceptTerms = (event: ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(event.target.checked);
  };

  return (
    <Box
      css={(theme) => css`
        display: flex;
        margin: ${theme.spacing(2)}px;
        justify-content: center;
        overflow: hidden;
        height: 100%;
      `}
    >
      <Paper
        css={(theme) => css`
          display: flex;
          flex-direction: column;
          padding: ${theme.spacing(1)}px;
          &.MuiPaper-root {
            height: 70%;
            align-self: center;
          }
        `}
      >
        <Box m={3}>
          <Typography variant="h1" component="div">
            {intl.formatMessage({ id: 'TermsOfService' })}
          </Typography>
        </Box>
        <TermsOfService/>

        <Box m={3}>
          <Typography variant="h1" component="div">
            {intl.formatMessage({ id: 'PrivacyPolicy' })}
          </Typography>
        </Box>
        <PrivacyPolicy/>

        <Box display="flex" flexDirection="column" m={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={handleAcceptTerms}
                name="termsAccepted"
                color="primary"
              />
            }
            label={intl.formatMessage({ id: 'AcceptTermsAndPrivacyPolicy' })}
          />
          <Box ml="auto">
            <Button
              variant="outlined"
              disabled={!termsAccepted}
              onClick={async () => {
                const success = await userStore.updateUserData({
                  terms_accepted: true,
                });
                if (success) history.push('/dashboard');
              }}
            >
              {intl.formatMessage({ id: 'AcceptTerms' })}
            </Button>
          </Box>
        </Box>

      </Paper>
    </Box>
  );
};
