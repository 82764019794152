export enum AlarmTypes {
  BATTERY_VOLTAGE = 'BATTERY_VOLTAGE',
  BOAT = 'BOAT',
  FEEDING = 'FEEDING',
  MOTOR_FUSE = 'MOTOR_FUSE',
  OXYGEN = 'OXYGEN',
  SEAL = 'SEAL',
  SILO = 'SILO',
  TEMPERATURE = 'TEMPERATURE',
  UNAVAILABILITY = 'UNAVAILABILITY',
}

export interface IRule {
  uid?: string;
  alarmThreshold?: string;
  eventTypes: string[];
  emailAddresses: string[];
  phoneNumbers: string[];
}

export interface IAlarmSettings {
  deviceId: string;
  rules: IRule[];
}

export interface IAlarmThreshold {
  deviceAlarm: IAlarmSettings;
  event: AlarmTypes;
  upper: number | null;
  lower: number | null;
  depth?: string | null;
}

export interface IAlarmThresholdDto {
  deviceId: string;
  event: AlarmTypes;
  upper?: number | null;
  lower?: number | null;
  depth: string | null;
}

export interface INotificationSettings {
  thresholds: IThresholdsSettings[];
  devices: IDeviceSettings[];
  receivers: IReceiverSettings;
  conflict: boolean;
}

export interface IThresholdsSettings {
  event: AlarmTypes;
  upper: number | null;
  lower: number | null;
  preferredDepth: string | null;
  smsEnabled: boolean;
  emailEnabled: boolean;
}

export interface IDeviceSettings {
  deviceId: string;
  checked: boolean;
}

export interface IReceiverSettings {
  emailAddresses: string[];
  phoneNumbers: string[];
}

// List of alarm types that have an upper or lower threshold
export const upperThresholdTypes = [
  AlarmTypes.TEMPERATURE,
];

export const lowerThresholdTypes = [
  AlarmTypes.BATTERY_VOLTAGE,
  AlarmTypes.OXYGEN,
  AlarmTypes.SILO,
  AlarmTypes.TEMPERATURE,
];
