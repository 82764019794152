import React, {FC} from "react";
import {IReportBasin} from "../../../models/removed-fish-report";
import {ExpandableTableRow} from "../expandable-table-row";
import {IconButton, TableCell as MuiTableCell, Typography, withStyles} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {EventTable} from "./event-table";
import {useIntl} from "react-intl";
import {getAmountString} from "./removed-fish-utils";

interface IFishBasinRow {
  basin: IReportBasin;
  color: string;
}

export const FishbasinRow: FC<IFishBasinRow> = ({basin, color}) => {
  const intl = useIntl();
  const [expanded, setExpanded] = React.useState(false);
  const totalFishRemovedKg = (basin.deadFishKg || 0) + (basin.removedFishKg || 0);
  const totalFishRemoved = (basin.deadFish || 0) + (basin.removedFish || 0);

  const TableCell = withStyles({
    root: {
      borderBottom: "none",
      paddingTop: "5px",
      paddingBottom: "5px",
      backgroundColor: color,
    }
  })(MuiTableCell);

  return (
    <>
      <ExpandableTableRow expanded={expanded} expandComponent={<EventTable basin={basin} expanded={expanded} color={color}/>}>
        <TableCell align="right">
          <IconButton disabled={!totalFishRemoved} onClick={() => setExpanded(!expanded)} size="small">
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography variant="h4">
            {basin.name}
          </Typography>
        </TableCell>
        <TableCell>{getAmountString(intl, basin.deadFishKg, basin.deadFish)}</TableCell>
        <TableCell>{getAmountString(intl, basin.removedFishKg, basin.removedFish)}</TableCell>
        <TableCell>{getAmountString(intl, totalFishRemovedKg, totalFishRemoved)}</TableCell>
      </ExpandableTableRow>
    </>
  );
};
