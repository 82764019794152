export enum MEASUREMENT_TYPE {
  BatteryLevel = 'c8y_Battery',
  CumulativeFeedAmount = 'c8y_CumulativeFeedAmount',
  OxygenSensor = 'c8y_Oxygen',
  OxygenSensorTemp = 'c8y_Oxygen_Temperature',
  SiloLevelSensor = 'c8y_FeedSiloLevel',
  TemperatureSensor = 'c8y_Temperature',
}

export interface C8yCommand {
  text?: string;
}

export interface Delivery {
  time?: Date;
  log?: any[];
  status?: string;
}

export interface IotCommandResponse {
  delivery?: Delivery;
  creationTime?: Date;
  deviceId: string;
  deviceName?: string;
  self?: string;
  id: string;
  status?: string;
  c8y_Command?: C8yCommand;
  description?: string;
  failureReason?: string;
}

export interface IAlarms {
  major?: number;
  critical?: number;
  minor?: number;
  warning?: number;
}

export interface ICustomPropsStatus {
  status: string;
  dfc: number;
  fm: number;
  timestamp: number;
  batt_charge: number;
  battery_voltage: number;
  amount_fed: number; // amount fed so far (in grams)
}

export interface IMeasurementResult {
  name: number;
  value: readonly [timestamp: number, value: number, type: string];
}

export interface ICalibration {
  calibration: number;
}

export interface ICustomProps {
  status: string;
  parsedStatus: ICustomPropsStatus;
}

export interface IDeviceConfiguration {
  autofeed: number;
  camera_power: number;
  emergency: number;
  fCal: number;
  fSch_temp: number;
  fSch_max_amount: number;
  fSch_num_events: number; // feedingTarget i.e. amount of feed (in grams) that should be given
  fSch_data: string;
  fSch_CRC16: number;
  power_source: number;
  camera_azimuth: number;
  camera_elevation: number;
  camera_zoom: number;
  deny_voltage: number;
  allow_voltage: number;
}

export interface IDeviceInfoResponse {
  id: string;
  creationTime?: Date;
  lastUpdated?: Date;
  name?: string;
  type?: string;
  lastMessage?: Date;
  availability?: string;
  connection?: string;
  alarms?: IAlarms;
  deviceChildren?: any[];
  configuration?: string;
  deviceConfiguration?: IDeviceConfiguration;
  custom_props?: ICustomProps;
  feedingSchedule?: any[];
  version: string;
}

export interface IFeedingDetails {
  amount?: number;
  feedingType: string;
  temperature?: number;
}

export interface IIotDeviceEvents {
  id?: string;
  eventCode?: number;
  description?: string;
  timestamp: string;
  userevent: boolean;
  eventtype: string;
  status?: string;
  severity?: string;
  value?: string;
  source?: string;
  count?: any;
  feedingdetails?: IFeedingDetails;
}

export interface IBatterySave {
  allow: number;
  deny: number;
}
