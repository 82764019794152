/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {
  Box,
  Divider as MuiDivider,
  Typography,
  Theme,
} from '@material-ui/core';
import {
  CustomProgressBar,
  ICustomProgressBar,
} from 'components/widgets/custom-progress-bar';
import { FC } from 'react';

interface IAutomationStatusGadget
  extends ICustomProgressBar,
    IGadgetHeader,
    IGadgetFooter {}

export const AutomationStatusGadget: FC<IAutomationStatusGadget> = ({
  value,
  progressColor,
  headerLabel,
  headerValue,
  startValue,
  midValue,
  endValue,
  hideFooter,
}) => {
  return (
    <Box
      css={(theme) => css`
        margin: ${theme.spacing(2)}px 0;
      `}
    >
      <GadgetHeader headerLabel={headerLabel} headerValue={headerValue} />
      <CustomProgressBar value={value} progressColor={progressColor} />
      <GadgetFooter
        startValue={startValue}
        midValue={midValue}
        endValue={endValue}
        hideFooter={hideFooter}
      />
    </Box>
  );
};

// Custom child components for progress gadget

interface IGadgetHeader {
  headerLabel: string;
  headerValue: string;
}

const GadgetHeader: FC<IGadgetHeader> = ({ headerLabel, headerValue }) => {
  return (
    <Box
      css={(theme) => css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: ${theme.spacing(1)}px;
      `}
    >
      <Typography component="span" variant="h5">
        {headerLabel}
      </Typography>
      <Typography component="span" variant="h2">
        {headerValue}
      </Typography>
    </Box>
  );
};

interface IGadgetFooter {
  startValue: string;
  midValue: string;
  endValue: string;
  hideFooter?: boolean;
}

const GadgetFooter: FC<IGadgetFooter> = ({
  startValue,
  midValue,
  endValue,
  hideFooter,
}) => {
  const typographyStyle = (theme: Theme) => css`
    color: ${theme.palette.line2.main};
    font-size: 12px !important;
    white-space: nowrap;
    min-width: 45px;
    text-align: center;
  `;

  const footerStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: ${theme.spacing(1)}px;
  `;

  return hideFooter ? (
    <Box css={footerStyle}>
      <Typography component="span" variant="h5" css={typographyStyle}>
        {"\xa0"}
      </Typography>
    </Box>
  ) : (
    <Box css={footerStyle}>
      <Typography component="span" variant="h5" css={typographyStyle}>
        {startValue}
      </Typography>
      <Divider />
      <Typography component="span" variant="h5" css={typographyStyle}>
        {midValue}
      </Typography>
      <Divider />
      <Typography component="span" variant="h5" css={typographyStyle}>
        {endValue}
      </Typography>
    </Box>
  );
};

const Divider = () => (
  <MuiDivider
    variant="middle"
    css={(theme) => css`
      &.MuiDivider-root {
        display: flex;
        flex: 1 1 100%;
        background-color: ${theme.palette.line2.main};
        margin: ${theme.spacing(1)}px;
      }
    `}
  />
);
