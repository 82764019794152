/** @jsxRuntime classic */
/** @jsx jsx */
import React, { FC, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Button, Grid } from '@material-ui/core';
import { useStore } from 'stores/store-hooks';
import { localizedLogo } from 'translations/locales';
import { WhiteTextField } from './misc-components';

const StyledLink = styled(Link)`
  && {
    display: flex;
    color: ${({ theme }) => theme.palette.common.white};
    justify-content: center;
  }
`;

export const LoginComponent: FC = observer(() => {
  const intl = useIntl();
  const userStore = useStore('userStore');
  const { selectedLanguage } = userStore;
  const inputUsername = useRef<HTMLInputElement>(null);
  const inputPassword = useRef<HTMLInputElement>(null);

  const handleLogin = () => {
    const password = inputPassword.current?.value;
    const username = inputUsername.current?.value;
    if (password && username) {
      const credentials = {
        username,
        password,
      };
      userStore.login(credentials);
    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        css={css`
          height: 100vh;
        `}
      >
        <Grid
          item
          xs={false}
          md={7}
          css={() => css`
            background-image: ${Math.random() <= 0.5
              ? "url('/raisio-bg-1.jpg')"
              : "url('/raisio-bg-2.jpg')"};
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          `}
        />
        <Grid item xs={12} md={5}>
          <Box
            css={(theme) => css`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-around;
              height: 100%;
              justify-content: center;
              background-color: ${theme.palette.primary.main};
            `}
          >
            <form
              style={{ width: '100%' }}
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}
            >
              <Box
                justifyContent="center"
                display="flex"
                flexDirection="column"
                padding={6}
                width="100%"
              >
                <img
                  src={localizedLogo(intl)}
                  alt="logo"
                  width={selectedLanguage === 'ru' ? 290 : 270}
                  css={css`
                    align-self: center;
                  `}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mt={4}
                >
                  <WhiteTextField
                    label={intl.formatMessage({ id: 'Username' })}
                    id="login-email"
                    variant="outlined"
                    inputRef={inputUsername}
                    css={css`
                      width: 70%;
                    `}
                  />
                  <WhiteTextField
                    label={intl.formatMessage({ id: 'Password' })}
                    id="login-password"
                    type="password"
                    variant="outlined"
                    css={css`
                      width: 70%;
                    `}
                    inputRef={inputPassword}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mt={8}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    css={css`
                      width: 35%;
                    `}
                  >
                    {intl.formatMessage({ id: 'Login' })}
                  </Button>
                </Box>
              </Box>
              <StyledLink href="#" to="/forgot-password">
                {intl.formatMessage({ id: 'ForgotPassword' })}
              </StyledLink>
            </form>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
});
