/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FC, useEffect } from 'react';
import { Box, Button, Divider, Typography, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStore } from 'stores/store-hooks';
import styled from '@emotion/styled';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useIntl } from 'react-intl';
import { MEDIA_BREAKPOINT_LG } from '../../constants';

const StyledDivider = styled(Divider)`
  && {
    background-color: ${({ theme }) => theme.palette.grey[300]};
    margin: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const NavigationButtons: FC = observer(() => {
  const siteStore = useStore('siteStore');
  const basinStore = useStore('basinStore');
  const history = useHistory();
  const intl = useIntl();
  const { selectedBasin } = basinStore;
  const { selectedSite } = siteStore;
  const basinIdsForSite = selectedSite?.fishbasins
    ? selectedSite.fishbasins
        .filter((b) => !b.deleted)
        .sort((x, y) => y.name.localeCompare(x.name))
        .map((basin) => basin.id)
    : [];
  const selectedBasinIndex = basinIdsForSite.findIndex(
    (id) => id === selectedBasin?.id
  );
  const theme = useTheme();

  useEffect(() => {
    if (selectedBasin?.site.id) {
      siteStore.loadSite(selectedBasin?.site.id);
    }
  }, [selectedBasin?.site.id, siteStore]);

  return (
    <Box
      css={css`
        display: flex;
        flex-direction: row;
        padding: 0 ${theme.spacing(2)}px;
        background: ${theme.palette.common.white};
        border-radius: 5px;
        margin: auto ${theme.spacing(1)}px;
        padding: 8px;
        margin-top: ${theme.spacing(2)}px;
        @media (max-width: ${MEDIA_BREAKPOINT_LG}px) {
          padding: 4px;
        }
        white-space: nowrap;
      `}
    >
      <Button
        color="primary"
        onClick={() => {
          history.goBack();
        }}
        disabled={history.length === 0}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <NavigateBeforeIcon />
          <Typography variant="h5" component="span">
            {intl.formatMessage({ id: 'Back' })}
          </Typography>
        </Box>
      </Button>
      <StyledDivider flexItem orientation="vertical" />
      <Button
        color="primary"
        onClick={async () => {
          await basinStore.setSelectedBasin(null); // clear previous selected basin
          const nextIndex =
            selectedBasinIndex >= basinIdsForSite.length - 1
              ? 0
              : selectedBasinIndex + 1;
          history.push(`/basin/${basinIdsForSite[nextIndex]}`);
        }}
        disabled={basinIdsForSite.length <= 1}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="h5" component="span">
            {intl.formatMessage({ id: 'NextBasin' })}
          </Typography>
          <NavigateNextIcon />
        </Box>
      </Button>
    </Box>
  );
});
