/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage as M, useIntl } from 'react-intl';
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { UserModel } from 'models/user';
import { Modal, ModalActions, ModalContent } from 'components/modal-dialog';
import { useStore } from 'stores/store-hooks';
import zxcvbn from 'zxcvbn';
import { observer } from 'mobx-react-lite';
import { RAISIO_COMPANY } from 'models/company';

interface IUserEditDialog {
  updateUserData: (data: UserModel) => Promise<any>;
}

const phoneRegExp = /^$|^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;
const usernameRegExp = /^[a-zA-Z0-9@.+_-]+$/g;
const getSchema = (editedUser: UserModel | null) => {
  const schema = {
    username: yup // Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
      .string()
      .required('error.validation.username')
      .max(150, 'error.validation.username')
      .matches(usernameRegExp, 'error.validation.username'),
    email: yup.string().email('error.validation.email'),
    phone: yup
      .string()
      .max(32, 'error.validation.phone')
      .matches(phoneRegExp, 'error.validation.phone'),
    first_name: yup.string().max(150, 'error.validation.firstName'),
    last_name: yup.string().max(150, 'error.validation.lastName'),
    password: yup
      .string()
      .test('password-strength', 'error.validation.passwordStrength', (value) =>
        value ? zxcvbn(value).score > 2 : true
      )
      .required('error.validation.password'),
  };
  if (editedUser !== null && schema.password) {
    // password schema property not needed when editing a user
    // extra fields cause issues in validation
    delete (schema as any).password;
  }
  return yup.object().shape(schema);
};

export const UserEditDialog = observer(
  ({ updateUserData }: IUserEditDialog) => {
    const intl = useIntl();
    const toastStore = useStore('toastStore');
    const userStore = useStore('userStore');
    const { editedUser, editedUserCompanyId } = userStore;
    const { register, handleSubmit, errors, control, setError } = useForm({
      resolver: yupResolver(getSchema(editedUser)),
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      defaultValues: {
        ...editedUser,
        language: editedUser?.language || 'fi',
        group: editedUser?.group ? Number(editedUser.group).toString() : '1',
      },
    });

    const closeEditDialog = () => {
      userStore.setEditDialogOpen(false);
    };

    return (
      <Modal handleClose={closeEditDialog}>
        <form
          noValidate
          onSubmit={handleSubmit(async (data) => {
            const result = await userStore.modifyUser(data as any);
            if (result?.status === 201 || result?.status === 200) {
              closeEditDialog();
              updateUserData(result.user as UserModel);
            } else {
              // handle errors
              const { errors } = result as any;
              if (errors?.username) {
                setError('username', {
                  type: 'server',
                  message: 'error.validation.username.notAccepted',
                });
              } else {
                toastStore.setToast('UserSaveError');
              }
            }
          })}
        >
          <ModalContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography component="span" variant="subtitle2">
                  {editedUser?.id ? <M id="EditUser" /> : <M id="AddUser" />}
                </Typography>
              </Grid>
              {
                // hide group selection button for RAiSO_COMPANY
                editedUserCompanyId !== RAISIO_COMPANY.id && (
                  <Grid item xs={12}>
                    <Controller
                      name="group"
                      control={control}
                      as={
                        <RadioGroup
                          aria-label="user group"
                          css={css`
                            &.MuiFormGroup-root {
                              flex-direction: row;
                            }
                          `}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={intl.formatMessage({ id: 'User' })}
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label={intl.formatMessage({ id: 'Admin' })}
                          />
                        </RadioGroup>
                      }
                    />
                  </Grid>
                )
              }
              <Grid item xs={12}>
                <Controller
                  name="language"
                  control={control}
                  as={
                    <RadioGroup
                      aria-label="language selection"
                      css={css`
                        &.MuiFormGroup-root {
                          flex-direction: row;
                        }
                      `}
                    >
                      <FormControlLabel
                        value="fi"
                        control={<Radio />}
                        label={intl.formatMessage({ id: 'Finnish' })}
                      />
                      <FormControlLabel
                        value="sv"
                        control={<Radio />}
                        label={intl.formatMessage({ id: 'Swedish' })}
                      />
                      <FormControlLabel
                        value="en"
                        control={<Radio />}
                        label={intl.formatMessage({ id: 'English' })}
                      />
                      <FormControlLabel
                        value="ru"
                        control={<Radio />}
                        label={intl.formatMessage({ id: 'Russian' })}
                      />
                    </RadioGroup>
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  name="username"
                  margin="dense"
                  id="user-edit-dialog-username"
                  label={<M id="Username" />}
                  inputRef={register}
                  error={!!errors.username}
                  helperText={
                    errors.username && <M id={`${errors?.username?.message}`} />
                  }
                  fullWidth
                />
                <TextField
                  margin="dense"
                  name="first_name"
                  id="user-edit-dialog-first-name"
                  label={<M id="FirstName" />}
                  error={!!errors.first_name}
                  helperText={
                    errors.first_name && (
                      <M id={`${errors?.first_name?.message}`} />
                    )
                  }
                  inputRef={register}
                  fullWidth
                />

                <TextField
                  margin="dense"
                  id="user-edit-dialog-phone"
                  label={<M id="PhoneNumber" />}
                  name="phone"
                  inputRef={register}
                  error={!!errors.phone}
                  helperText={
                    errors.phone && <M id={`${errors?.phone?.message}`} />
                  }
                  type="tel"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  id="user-edit-dialog-email"
                  name="email"
                  label={<M id="Email" />}
                  type="email"
                  inputRef={register}
                  error={!!errors.email}
                  helperText={
                    errors.email && <M id={`${errors?.email?.message}`} />
                  }
                  fullWidth
                />
                <TextField
                  margin="dense"
                  name="last_name"
                  id="user-edit-dialog-last-name"
                  label={<M id="LastName" />}
                  inputRef={register}
                  error={!!errors.last_name}
                  helperText={
                    errors.last_name && (
                      <M id={`${errors?.last_name?.message}`} />
                    )
                  }
                  fullWidth
                />
                {editedUser == null && (
                  <TextField
                    margin="dense"
                    id="user-edit-dialog-password"
                    label={<M id="Password" />}
                    name="password"
                    inputRef={register}
                    error={!!errors.password}
                    helperText={
                      errors.password && (
                        <M id={`${errors?.password?.message}`} />
                      )
                    }
                    type="password"
                    fullWidth
                  />
                )}
              </Grid>
            </Grid>
          </ModalContent>
          <ModalActions>
            <Button
              color="primary"
              type="submit"
              disabled={!!Object.keys(errors).length}
            >
              <M id="Save" />
            </Button>
            <Button onClick={closeEditDialog} color="primary">
              <M id="Cancel" />
            </Button>
          </ModalActions>
        </form>
      </Modal>
    );
  }
);
