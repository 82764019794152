/** @jsxRuntime classic */
/** @jsx jsx */
import { Box, CircularProgress } from '@material-ui/core';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'stores/store-hooks';
import { ArchivedBasinList } from './archived-basin-list';
import { Accordion } from 'components/accordion';
import { ArchiveCompanyHeader } from './archive-company-header';

const LoadingRoot = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.spacing(3)}px;
`;

const Root = styled.div`
  height: auto;
  background-color: ${({ theme }) => theme.palette.common.white};
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  margin-left: 0;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  border-radius: 5px;
  overflow: hidden;
`;

export const ArchiveComponent = observer(() => {
  const userStore = useStore('userStore');
  const archiveStore = useStore('archiveStore');
  const companyStore = useStore('companyStore');

  useEffect(() => {
    companyStore.loadCompanies();
  }, [companyStore]);
  const { companies } = companyStore;

  useEffect(() => {
    const loadArchivedBasins = async () => {
      archiveStore.loadBasins();
    };
    loadArchivedBasins();
  }, [archiveStore]);

  if (archiveStore.isLoading) {
    return (
      <LoadingRoot>
        <CircularProgress size={50} />
      </LoadingRoot>
    );
  }

  return (
    <Fragment>
      <Box
        css={css`
          overflow-y: auto;
          height: 100%;
        `}
      >
        {!userStore.isRaisioAdminOrSuperuser &&
        companyStore.companies.length === 1 ? (
          // if current user is not admin and has only one company render archive list directly
          <Root>
            <ArchivedBasinList companyId={companyStore.companies[0].id} />
          </Root>
        ) : (
          // if current user has multiple companies render them as a company list
          // (under which each companies archive will be)
          <Fragment>
            {companies.map(
              (c) =>
                // Don't render accordian if company doesn't have any archived basins
                archiveStore.companyArchiveNotEmpty(c.id) && (
                  <Accordion
                    key={c.id}
                    id={c.id}
                    accordionHeader={
                      <ArchiveCompanyHeader company={c} expanded={false} />
                    }
                    accordionContent={<ArchivedBasinList companyId={c.id} />}
                    isExpandedInitially={false}
                  />
                )
            )}
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
});
