/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {Box, Grid, Typography} from '@material-ui/core';
import { MEDIA_BREAKPOINT_XL } from '../../../constants';
import { observer } from 'mobx-react-lite';
import { IFishBasin } from 'models/fishbasin';
import {FC} from 'react';
import { useIntl } from 'react-intl';
import { useStore } from 'stores/store-hooks';
import {ExtraFeedField} from "./extra-feed-field";

interface IBasinDetail {
  basin: IFishBasin;
}

export const BasinFeedAmount: FC<IBasinDetail> = observer(() => {
  const intl = useIntl();
  const basinStore = useStore('basinStore');

  const extraFeedAmount = (basinStore.selectedBasin?.extra_feed_weight || 0) * 0.001;
  const feedAmount = (basinStore.selectedBasin?.currentfeedinfo?.feed_amount || 0) * 0.001;

  const saveExtraFeed = (weightInKg: number) => {
    basinStore.addExtraFeedForToday(weightInKg * 1000);
  }

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      css={(theme) => css`
        overflow-y: auto;
        padding: ${theme.spacing(2)}px;
        min-width: 150px;
        @media (max-width: ${MEDIA_BREAKPOINT_XL}px) {
          min-width: 100px;
          padding: ${theme.spacing(1.5)}px;
        }
      `}
    >
      <Grid item xs={12}>
        <Box>
          <Typography component="div" variant="h5" color="primary">
            {intl.formatMessage({ id: 'FeedAmount' })}
          </Typography>
          <Typography component="span" variant="subtitle2" color="primary">
            {
              <span
                css={css`
                    white-space: nowrap;
                  `}
              >
                  {
                    intl.formatNumber(feedAmount + extraFeedAmount, {
                      style: 'decimal',
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                {intl.formatMessage({ id: "UnitKg" })}
                </span>
            }
          </Typography>
        </Box>
        <ExtraFeedField onSubmit={saveExtraFeed} defaultValue={extraFeedAmount}/>
      </Grid>
    </Grid>
  );
});
