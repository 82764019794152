import React from 'react';
import { useEffect, useState } from 'react';
import { useStore } from 'stores/store-hooks';
import { getColumns } from './feed-usage-columns';
import { useIntl } from 'react-intl';
import { Column } from 'react-table';
import { ISiloEntry } from 'models/silo';
import { AlertDialog } from 'components/alert-dialog/alert';
import { observer } from 'mobx-react-lite';
import { Box, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { KLTable } from 'components/tables';
import { AddSiloEventDialog } from './add-silo-event-dialog';
import {UpdateFeedTypesDialog} from "./update-feedtypes-dialog";

export const SiloTab = observer(() => {
  const userStore = useStore('userStore');
  const { canEdit } = userStore;
  const [showAddEventDialog, setShowAddEventDialog] = useState(false);
  const siloStore = useStore('siloStore');
  const [pendingDeleteId, setPendingDeleteId] = useState<number | null>(null);
  const { siloEntries } = siloStore;
  const intl = useIntl();
  siloStore.addSummaryRow(intl);

  useEffect(() => {
    const loadData = async () => {
      await Promise.all([siloStore.loadSiloEntries(), siloStore.loadFeedTypes()]);
    };
    loadData();
  }, [intl, siloStore]);

  const closeConfirmDialog = (isOpen: boolean) => {
    setPendingDeleteId(null);
  };

  const deleteEntryCallback = () => {
    if (pendingDeleteId) {
      siloStore.deleteEntry(pendingDeleteId);
      setPendingDeleteId(null);
    }
  };

  const toggleAlertDialog = (entryId: number) => () => {
    setPendingDeleteId(entryId);
  };

  const editSiloEvent = (entry: ISiloEntry) => () => {
    siloStore.setEditedEntry(entry);
    setShowAddEventDialog(true);
  };

  const addSiloEvent = () => {
    siloStore.setEditedEntry(null);
    setShowAddEventDialog(true);
  };

  const columns = getColumns(
    intl,
    toggleAlertDialog,
    editSiloEvent,
    canEdit
  ) as Column<ISiloEntry>[];

  return (
    <Box
      display="flex"
      flex="1 1 100%"
      maxHeight={520}
      flexDirection="column"
      overflow="auto"
      pr={1}
    >
      {canEdit && (
        <Box display="flex" m={2} style={{ gap: '10px' }}>
          <Button variant="outlined" onClick={addSiloEvent}>
            <AddIcon />
            {intl.formatMessage({ id: 'AddSiloEvent' })}
          </Button>
          <UpdateFeedTypesDialog/>
        </Box>
      )}
      <KLTable columns={columns} data={siloEntries} summaryRowCount={1} />
      {pendingDeleteId && (
        <AlertDialog
          isOpen={!!pendingDeleteId}
          setOpen={closeConfirmDialog}
          titleTextId="UnCancellableAction"
          primaryButtonTextId="Delete"
          primaryButtonCallback={deleteEntryCallback}
        >
          {`${intl.formatMessage({ id: 'DeleteFeedUsage' })}`}
        </AlertDialog>
      )}
      {showAddEventDialog && (
        <AddSiloEventDialog handleClose={() => setShowAddEventDialog(false)} />
      )}
    </Box>
  );
});
