import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { BasinTable } from './basin-table';
import { ISimpleSite } from 'models/site';
import { getColumns } from './columns';
import styled from '@emotion/styled';
import { useStore } from 'stores/store-hooks';
import { Button, useTheme } from '@material-ui/core';
import { FormattedMessage as M } from 'react-intl';
import { BasinDialog } from './basin-add-dialog';
import { BasinSplitDialog } from './basin-split-dialog';
import { IFishBasin, IBasinStartValues } from 'models/fishbasin';
import { useHistory } from 'react-router-dom';
import { BasinArchiveDialog } from 'components/archive-dialog/basin-archive-dialog';
import toastStore from 'stores/toast-store';
import siteStore from 'stores/site-store';

interface IBasinList {
  site: ISimpleSite;
  updateSites: (site: any) => void;
}

export const BasinList: FC<IBasinList> = observer(({ site, updateSites }) => {
  const intl = useIntl();
  const userStore = useStore('userStore');
  const basinStore = useStore('basinStore');
  const facilityStore = useStore('facilityStore');
  const iotStore = useStore('iotStore');
  const history = useHistory();
  const theme = useTheme();
  const [basins, setBasins] = useState(
    (site.fishbasins || []).filter((b) => !b.deleted)
  );
  const [basinDialog, setBasinDialog] = useState<{
    visible: boolean;
    editedBasin: IFishBasin | null;
  }>({
    visible: false,
    editedBasin: null,
  });

  const [basinArchiveDialog, setBasinArchiveDialog] = useState<{
    visible: boolean;
    basinToArchive: IFishBasin | null;
  }>({
    visible: false,
    basinToArchive: null,
  });

  const [basinSplitDialog, setBasinSplitDialog] = useState<{
    visible: boolean;
    originalBasin: IFishBasin | null;
  }>({
    visible: false,
    originalBasin: null,
  });

  useEffect(() => {
    if (basinStore.fishTypes.length === 0) {
      basinStore.loadFishTypes();
    }
  }, [basinStore]);

  const [archiveModeActive, setBasinArchiveMode] = useState(false);

  const startArchiveMode = () => {
    basinStore.clearArchiveList();
    setBasinArchiveMode(true);
  };

  const cancelArchiveChanges = () => {
    setBasinArchiveMode(false);
    basinStore.clearArchiveList();
    setBasins((site.fishbasins || []).filter((b) => !b.deleted)); // Set basins back to what they were
  };

  const acceptArchiveChanges = async () => {
    setBasinArchiveMode(false);
    const result = await basinStore.archiveBasins();

    if (result.length) {
      const errorIds = result.join(', ');
      toastStore.setToast('BasinArchiveError', 'error', {
        errorIds,
      });
      // Set basins back to what they were (no filtering out)
      setBasins((site.fishbasins || []).filter((b) => !b.deleted));
    }

    // reload facilities data to update basin count in FacilityRowHeader component
    await facilityStore.loadFacilities();
    // Reload site details to get copies created while archiving basins
    const res = await siteStore.loadSite(site.id);
    if (res.data) updateSites(res.data);
    if (siteStore.selectedSite) {
      setBasins(
        (siteStore.selectedSite.fishbasins || []).filter((b) => !b.deleted)
      );
    }
  };

  // Handler function provided to basinArchiveDialog
  const updateArchivedBasin = (
    copy: boolean,
    startValues: IBasinStartValues
  ) => {
    const { basinToArchive } = basinArchiveDialog;
    if (basinToArchive) {
      basinStore.updateArchiveList(
        { id: basinToArchive.id, startvalues: copy ? startValues : undefined },
        'add'
      );
      setBasins([...basins].filter((b) => b.id !== basinToArchive.id));
      // Currently, the new basin created from the archived one, is not immediately shown
      return;
    }
    return { errors: 'Basin to archive missing' }; //
  };

  const handleEdit = (basin: IFishBasin) => {
    setBasinDialog({ visible: true, editedBasin: basin });
  };

  const handleSplit = (basin: IFishBasin) => {
    setBasinSplitDialog({ visible: true, originalBasin: basin });
  };

  const openBasin = async (basin: IFishBasin) => {
    if (!archiveModeActive) {
      const refreshedBasin = await basinStore.loadBasin(String(basin.id));
      basinStore.setSelectedBasin(refreshedBasin.data);
      facilityStore.setFacilityScrollToTarget(basin.site.facility.id);
      history.push(`/basin/${basin.id}`);
    }
  };

  const handleArchive = (basin: IFishBasin) => {
    setBasinArchiveDialog({ visible: true, basinToArchive: basin });
  };

  let controlButtonColor = archiveModeActive
    ? theme.palette.warning.main
    : undefined;

  const toggleCalculation = async (basin: IFishBasin) => {
    await basinStore.setSelectedBasin(basin);
    const response = await basinStore.toggleCalculation(basin.id);
    if (response.status === 200 && response.data) {
      const index = basins.findIndex((b) => basin.id === b.id);
      basins[index] = response.data;
      setBasins([...basins]); // spread here shallow copies the list (triggers a new render)
    }
    await basinStore.setSelectedBasin(null);
    return response;
  };

  const toggleAutomation = async (
    basin: IFishBasin,
    nextIsAutomatic: boolean
  ) => {
    await basinStore.setSelectedBasin(basin);
    const response = nextIsAutomatic
      ? await iotStore.startFeeding(basinStore.selectedBasin)
      : await iotStore.stopFeeding(basinStore.selectedBasin);

    let calcResp = null;
    if (response.status === 200 || response.status === 201) {
      if (nextIsAutomatic && !basin.feedingenabled) {
        // automatically enable calculation if it is disabled
        calcResp = await basinStore.toggleCalculation(basin.id);
      }
      if (!nextIsAutomatic && basin.feedingenabled) {
        // automatically disable calculation if it is enabled
        calcResp = await basinStore.toggleCalculation(basin.id);
      }
      if (calcResp !== null && calcResp.status === 200 && calcResp.data) {
        console.log(`Feeding automation status: ${calcResp.data.feedingenabled}`);
        const index = basins.findIndex((b) => basin.id === b.id);
        basins[index] = calcResp.data;
        setBasins([...basins]); // spread here shallow copies the list (triggers a new render)
      }
      await basinStore.setSelectedBasin(null);
      return nextIsAutomatic;
    }
    // Command failed, do not update value
    return !nextIsAutomatic;
  };

  const columns = useMemo(
    () =>
      getColumns(
        intl,
        handleEdit,
        openBasin,
        handleSplit,
        basinStore.downloadSeasonReport,
        handleArchive,
        toggleCalculation,
        toggleAutomation,
        controlButtonColor
      ),
    // columns needed to be re-created, when basin list updates (otherwise old basins list is used in toggleCalculation and toggleAutomation).
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [controlButtonColor, basins]
  );

  const updateBasin = async (siteId: number, modifiedData: IFishBasin) => {
    const { editedBasin } = basinDialog;
    if (editedBasin) {
      const response = await basinStore.updateBasin(
        editedBasin.id,
        modifiedData
      );
      if (response.errors !== null && response.data) {
        const index = basins.findIndex((b) => editedBasin.id === b.id);
        if (index >= 0) {
          basins[index] = response.data;
          setBasins([...basins]); // spread here shallow copies the list (triggers a new render)
        } else {
          setBasins([...basins, response.data]);
        }
      }
      return response;
    }
    const response = await basinStore.createBasin(siteId, modifiedData);
    if (response.errors !== null && response.data) {
      // reload facilities data to update basin count in FacilityRowHeader component
      await facilityStore.loadFacilities();
      setBasins([...basins, response.data]);
    }
    return response;
  };

  const updateSplitBasins = (newBasins: IFishBasin[]) => {
    const { originalBasin } = basinSplitDialog;
    if (newBasins) {
      setBasins([
        ...basins.filter((basin: IFishBasin) => basin.id !== originalBasin?.id),
        ...newBasins,
      ]);
    }
  };

  return (
    <Root>
      <BasinTable
        siteId={site.id}
        columns={columns}
        basins={basins}
        archiveMode={archiveModeActive}
      />
      {(userStore.isCompanyAdmin || userStore.isRaisioAdminOrSuperuser) && (
        <Footer>
          {archiveModeActive ? (
            <Fragment>
              <Button onClick={acceptArchiveChanges}>
                <M id="AcceptChanges" />
              </Button>
              |{' '}
              <Button onClick={cancelArchiveChanges}>
                <M id="CancelChanges" />
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Button
                onClick={() =>
                  setBasinDialog({ visible: true, editedBasin: null })
                }
              >
                <M id="AddNewBasin" />
              </Button>
              |{' '}
              <Button onClick={startArchiveMode} disabled={!basins.length}>
                <M id="ArchiveBasin" />
              </Button>
            </Fragment>
          )}
        </Footer>
      )}
      {basinDialog.visible && (
        <BasinDialog
          siteId={site.id}
          editedBasin={basinDialog.editedBasin}
          handleClose={() =>
            setBasinDialog({ visible: false, editedBasin: null })
          }
          updateBasin={updateBasin}
        />
      )}
      {basinArchiveDialog.visible && (
        <BasinArchiveDialog
          basinToArchive={basinArchiveDialog.basinToArchive}
          handleClose={() =>
            setBasinArchiveDialog({ visible: false, basinToArchive: null })
          }
          updateBasin={updateArchivedBasin}
        />
      )}
      {basinSplitDialog.visible && (
        <BasinSplitDialog
          siteId={site.id}
          originalBasin={basinSplitDialog.originalBasin}
          handleClose={() =>
            setBasinSplitDialog({ visible: false, originalBasin: null })
          }
          updateBasin={updateSplitBasins}
        />
      )}
    </Root>
  );
});

const Root = styled.div`
  width: 100%;
  border-top: ${({ theme }) => `1px solid ${theme.palette.grey[500]}`};
`;

const Footer = styled.div`
  margin: ${({ theme }) => theme.spacing(1)}px;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => -theme.spacing(1)}px;
  margin-top: ${({ theme }) => -theme.spacing(1)}px;
`;
