/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Box } from '@material-ui/core';
import { TabListMenu } from './tab-list-menu';
import { TabContentContainer } from './tab-content';
import { FC } from 'react';

export const TabAreaContainer: FC<{
  gadgetAreaHeight: number;
}> = ({ gadgetAreaHeight}) => {
  return (
    <Box display="flex" flexDirection="column" flexShrink={0}>
      <Box
        flexGrow={2}
        css={(theme) => css`
          border-radius: 5px;
          margin-left: 0px;
          padding-right: ${theme.spacing(2)}px;
          flex-direction: row;
          flex: 1 1 30%;
          display: flex;
        `}
      >
        <TabListMenu />
        <TabContentContainer />
      </Box>
    </Box>
  );
};
