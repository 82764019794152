/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Box, Typography } from '@material-ui/core';
import { FC, ReactNode } from 'react';
import { useSummaryItems } from './feeding-schedule-hooks';

export const FeedingScheduleSummary: FC<{
  totalPeriods: number;
  totalPercentage: number;
  totalFed: number;
}> = ({ totalPeriods, totalPercentage, totalFed }) => {
  const items = useSummaryItems(totalPeriods, totalPercentage, totalFed);

  return (
    <Box display="flex" flexDirection="row" mb={2}>
      {items.map((item) => (
        <Summary key={item.id} {...item} />
      ))}
    </Box>
  );
};

interface ISummary {
  flexBasis: string;
  value?: ReactNode;
}

const Summary: FC<ISummary> = ({ value, flexBasis }) => {
  return (
    <Box
      css={(theme) => css`
        flex: 1 1 ${flexBasis};
        justify-content: center;
        display: flex;
        margin-bottom: ${theme.spacing(2)}px;
      `}
    >
      <Typography component="div" variant="h5">
        {value}
      </Typography>
    </Box>
  );
};
