import React, {FC} from "react";
import {observer} from "mobx-react-lite";
import {useIntl} from "react-intl";
import {useStore} from "../../stores/store-hooks";
import ReactECharts from "echarts-for-react";
import {
  averageWeightConfig,
  averageWeightOptions,
  biomassConfig,
  bioMassOptions,
  cumFeedAmountOptions,
  feedAmountConfig,
  feedAmountOptions,
  feedPercentageConfig,
  feedPercentageOptions
} from "../basin/tabs/graphs/chart-config";
import {IFishBasin} from "../../models/fishbasin";

interface IGraph {
  fetchSelectedBasins: () => IFishBasin[];
}

export const BiomassGraph: FC<IGraph> = observer(({fetchSelectedBasins}) => {

  const intl = useIntl();
  const graphStore = useStore('graphStore');
  const { biomassChartData, basinChartLoading } = graphStore;

  return (
    <ReactECharts
      showLoading={basinChartLoading}
      style={style}
      option={{ ...bioMassOptions(intl), ...biomassChartData }}
      {...biomassConfig}
      notMerge={true}
    />
  );
});

export const AverageWeightGraph: FC<IGraph> = observer(({fetchSelectedBasins}) => {

  const intl = useIntl();
  const graphStore = useStore('graphStore');
  const { averageWeightData, basinChartLoading } = graphStore;

  return (
    <ReactECharts
      showLoading={basinChartLoading}
      style={style}
      option={{ ...averageWeightOptions(intl), ...averageWeightData }}
      {...averageWeightConfig}
      notMerge={true}
    />
  );
});

export const FeedAmountGraph: FC<IGraph> = observer(({fetchSelectedBasins}) => {

  const intl = useIntl();
  const graphStore = useStore('graphStore');
  const { feedAmountChartData, basinChartLoading } = graphStore;

  return (
    <ReactECharts
      showLoading={basinChartLoading}
      style={style}
      option={{ ...feedAmountOptions(intl), ...feedAmountChartData }}
      {...feedAmountConfig}
      notMerge={true}
    />
  );
});

export const FeedPercentageGraph: FC<IGraph> = observer(({fetchSelectedBasins}) => {

  const intl = useIntl();
  const graphStore = useStore('graphStore');
  const { feedPercentageChartData, basinChartLoading } = graphStore;

  return (
    <ReactECharts
      showLoading={basinChartLoading}
      style={style}
      option={{ ...feedPercentageOptions(intl), ...feedPercentageChartData }}
      {...feedPercentageConfig}
      notMerge={true}
    />
  );
});

export const CumFeedAmount: FC<IGraph> = observer(({fetchSelectedBasins}) => {

  const intl = useIntl();
  const graphStore = useStore('graphStore');
  const { cumulativeFeedAmountChartData, basinChartLoading } = graphStore;

  return (
    <ReactECharts
      showLoading={basinChartLoading}
      style={style}
      option={{ ...cumFeedAmountOptions(intl), ...cumulativeFeedAmountChartData }}
      {...feedPercentageConfig}
      notMerge={true}
    />
  );
});

const style = {minHeight: '200px', height: '100%', width: '100%' };
