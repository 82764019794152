/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import { MEDIA_BREAKPOINT_LG, MEDIA_BREAKPOINT_XL } from '../../../constants';
import { observer } from 'mobx-react-lite';
import { IFishBasin } from 'models/fishbasin';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useStore } from 'stores/store-hooks';

interface IBasinDetail {
  basin: IFishBasin;
}

export const BasinControlAutomatic: FC<IBasinDetail> = observer(({ basin }) => {
  const intl = useIntl();
  const [automationStatusFetched, setAutomationStatusFetched] = useState(false);
  const userStore = useStore('userStore');
  const { canEdit } = userStore;
  const basinStore = useStore('basinStore');
  const iotStore = useStore('iotStore');

  useEffect(() => {
    if (!automationStatusFetched && iotStore.deviceInfoResponses.length) {
      const isFeedingEnabled = iotStore.getFeedAutomationStatus(basin);
      setAutomaticFeeding(isFeedingEnabled);
      setAutomationStatusFetched(true);
    }
  }, [
    automationStatusFetched,
    basin,
    iotStore,
    iotStore.deviceInfoResponses.length,
  ]);

  const [automaticFeeding, setAutomaticFeeding] = useState(false);

  const handleToggleChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const nextIsAutomatic = event.target.checked;
    const response = nextIsAutomatic
      ? await iotStore.startFeeding(basinStore.selectedBasin)
      : await iotStore.stopFeeding(basinStore.selectedBasin);
    if (response.status === 200 || response.status === 201) {
      if (nextIsAutomatic && !basin.feedingenabled) {
        // automatically enable calculation if it is disabled
        basinStore.toggleCalculation(basin.id);
      }
      if (!nextIsAutomatic && basin.feedingenabled) {
        // automatically disable calculation if it is enabled
        basinStore.toggleCalculation(basin.id);
      }
      setAutomaticFeeding(nextIsAutomatic);
    }
    await basinStore.addAutomationToggleEvent(basin, nextIsAutomatic);
  };

  if (!basinStore.hasIotAutomation) return null;

  const breakpointLg = useMediaQuery(`(min-width:${MEDIA_BREAKPOINT_LG}px)`, {
    noSsr: true,
  });

  const breakpointSd = useMediaQuery(`(max-width:${1280}px)`, {
    noSsr: true,
  });

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      css={(theme) => css`
        overflow-y: auto;
        padding: ${theme.spacing(2)}px;
        min-width: 150px;
        @media (max-width: ${MEDIA_BREAKPOINT_XL}px) {
          min-width: 100px;
          padding: ${theme.spacing(1.5)}px;
        }
      `}
    >
      <Grid item xs={12}>
        <Typography component="span" variant="h5" color="textPrimary">
          {intl.formatMessage({ id: 'Automatic' })}
        </Typography>
        <Grid item xs={12}>
          <Tooltip
            title={
              !canEdit
                ? intl.formatMessage({
                    id: 'ModifyingSettingsNotAllowed',
                  })
                : ''
            }
            aria-label="editing not allowed"
            arrow
          >
            <FormControlLabel
              labelPlacement={breakpointLg || breakpointSd ? 'end' : 'bottom'}
              control={
                <Switch
                  size={breakpointLg || breakpointSd ? 'medium' : 'small'}
                  checked={automaticFeeding}
                  onChange={handleToggleChange}
                  disabled={!canEdit}
                />
              }
              label={
                <Typography
                  component="span"
                  variant="caption"
                  color="textPrimary"
                >
                  {automaticFeeding
                    ? intl.formatMessage({ id: 'On' })
                    : intl.formatMessage({ id: 'Off' })}
                </Typography>
              }
            />
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
});
