import React from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Slider,
  TextField,
  Typography,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useStore } from 'stores/store-hooks';
import { NumericInput } from 'components/input-fields';
import { Modal, ModalActions, ModalContent } from 'components/modal-dialog';
import { HarvestingAction } from 'models/harvesting';

const MoveToFastingDialog = observer(() => {
  const intl = useIntl();
  const { selectedBasin } = useStore('basinStore');
  const {
    amountToFastOrHarvest,
    clearAll,
    details,
    fastOrHarvestAll,
    performAction,
    setActionMoveToFast,
    setAmountToFastOrHarvest,
    setDetails,
  } = useStore('harvestingStore');

  const avgFishWeight = (selectedBasin?.currentaverageweights || [0])[0];

  return (
    <Modal handleClose={clearAll}>
      <ModalContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="span" variant="subtitle2">
              {intl.formatMessage({ id: "HarvestingMoveFishToFast" })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="body1">
              {intl.formatMessage({ id: "HarvestingSelectAmountToMoveToFast" })}
            </Typography>
          </Grid>
          <Grid container alignItems="center" spacing={4} item xs={12}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={fastOrHarvestAll}
                    onChange={() => {
                      if (fastOrHarvestAll) {
                        // This also sets fastOrHarvestAll to false
                        setAmountToFastOrHarvest(amountToFastOrHarvest);
                      } else {
                        setActionMoveToFast(); // Sets fastOrHarvestAll to true
                      }
                    }}
                  />
                }
                label={intl.formatMessage({ id: "All" })}
              />
            </Grid>
            <Grid item xs={4}>
              <NumericInput
                disabled={fastOrHarvestAll}
                label={intl.formatMessage({ id: "Amount" })}
                max={selectedBasin?.currentamount}
                onChange={setAmountToFastOrHarvest}
                value={amountToFastOrHarvest}
              />
            </Grid>
          </Grid>
          {fastOrHarvestAll
            ? null
            : <Grid item xs={12}>
                <Slider
                  aria-label={intl.formatMessage({ id: "FastingSlider" })}
                  getAriaValueText={(v) =>
                    v + " " + intl.formatMessage({ id: "Pieces" })
                  }
                  min={0}
                  max={selectedBasin?.currentamount || 1}
                  onChange={(e, v) => setAmountToFastOrHarvest(v as number)}
                  step={1}
                  value={amountToFastOrHarvest}
                />
              </Grid>}
          <Grid item xs={12}>
            <Typography component="p" variant="body1">
              {intl.formatMessage(
                { id: "HarvestingBiomassMovingToFast" },
                { amount: intl.formatNumber(
                    avgFishWeight * amountToFastOrHarvest / 1000,
                    {
                      style: "decimal",
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }
                )},
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              aria-label={intl.formatMessage({ id: "AdditionalInformation" })}
              fullWidth={true}
              label={intl.formatMessage({ id: "AdditionalInformation" })}
              onChange={(event) => setDetails(event.target.value)}
              value={details || ""}
            />
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions>
        <Button
          color="primary"
          disabled={
            amountToFastOrHarvest === 0 || Number.isNaN(amountToFastOrHarvest)
          }
          onClick={performAction}
        >
          {intl.formatMessage({ id: "Save" })}
        </Button>
        <Button color="primary" onClick={clearAll}>
          {intl.formatMessage({ id: "Cancel" })}
        </Button>
      </ModalActions>
    </Modal>
  );
});

export const MoveToFastingButton = observer(() => {
  const intl = useIntl();
  const { selectedBasin } = useStore('basinStore');
  const harvestingStore = useStore('harvestingStore');

  return (!selectedBasin?.currentamount) ? null : (
    <Box display="flex" m={2}>
      <Button
        onClick={harvestingStore.setActionMoveToFast}
        startIcon={<ArrowForwardIcon />}
        variant="outlined"
      >
        {intl.formatMessage({ id: "HarvestingMoveFishToFast" })}
      </Button>
      {harvestingStore.action !== HarvestingAction.FAST ? null : <MoveToFastingDialog />}
    </Box>
  );
});
