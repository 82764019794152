/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { IWidgetComponent } from 'models/widget';
import { FC, useEffect, useState } from 'react';
import { CircularProgress, Typography, useTheme, Box } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useStore } from 'stores/store-hooks';
import { observer } from 'mobx-react-lite';
import { AddCommentPopover } from './comment-widget-add-popover';
import { dateAndTime } from 'translations/locales';
import { useHistory } from 'react-router';

export const CommentWidget: FC<IWidgetComponent> = observer(({ widget }) => {
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();
  const dashboardStore = useStore('dashboardStore');
  const userStore = useStore('userStore');
  const { isRaisioAdminOrSuperuser } = userStore;
  const { filteredComments } = dashboardStore;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let componentIsMounted = true;
    const loadData = async () => {
      setLoading(true);
      await dashboardStore.loadAllComments();
      if (componentIsMounted) {
        setLoading(false);
      }
    };
    // for raisio admin data is not refreshed automatically. RaisioUser needs to reload the page to refresh dashboard.
    if (
      (isRaisioAdminOrSuperuser &&
        dashboardStore.filteredComments.length === 0) ||
      !isRaisioAdminOrSuperuser
    )
      loadData();

    return () => {
      componentIsMounted = false;
    };
  }, [dashboardStore, isRaisioAdminOrSuperuser]);

  if (loading) return <CircularProgress />;

  return (
    <Box
      css={css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding: 0 ${theme.spacing(2)}px;
      `}
    >
      <Box
        css={css`
          display: flex;
          flex-direction: row;
          flex: 1 1 auto;
          width: calc(100% - ${theme.spacing(2)}px);
          align-self: flex-start;
          justify-content: space-between;
          padding-top: ${theme.spacing(2)}px;
          padding-bottom: ${theme.spacing(1)}px;
          border-bottom: 1px solid ${theme.palette.line2.main};
          align-items: center;
        `}
      >
        <Typography variant="h4" color="textPrimary">
          {intl.formatMessage({ id: 'RecentComments' })}
        </Typography>
        <AddCommentPopover />
      </Box>
      <Box
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1 1 100%;
          width: 100%;
          align-items: center;
          justify-content: flex-start;
          overflow-y: auto;
          padding-right: ${theme.spacing(1)}px;
          margin-bottom: ${theme.spacing(3)}px;
        `}
      >
        {filteredComments.map((comment) => {
          return (
            <Box
              key={comment.id}
              onClick={() => {
                history.push(`/basin/${comment.fishbasin.id}/comments`);
              }}
              css={css`
                display: flex;
                flex-direction: column;
                width: 100%;
                flex: 0 0 auto;
                padding: ${theme.spacing(1)}px 0;
                padding-right: ${theme.spacing(1)}px;
                border-bottom: 1px solid ${theme.palette.line2.main};
                :hover {
                  background-color: ${theme.palette.hover.main};
                }
                cursor: pointer;
              `}
            >
              <Box>
                <Typography component="span" variant="h6" color="primary">
                  {`${comment.user.full_name || comment.user.username}`}
                </Typography>
                <Typography
                  component="span"
                  color="textPrimary"
                  css={css`
                    &.MuiTypography-root {
                      font-size: 12px;
                      font-weight: 600;
                      margin-left: ${theme.spacing(1)}px;
                    }
                  `}
                >
                  {dateAndTime(intl, new Date(comment.timestamp))}
                </Typography>
                <Typography
                  component="span"
                  css={css`
                    &.MuiTypography-root {
                      font-size: 12px;
                      font-weight: 600;
                      margin-left: ${theme.spacing(1)}px;
                      color: ${theme.palette.primary.main};
                    }
                  `}
                >
                  {comment.fishbasin.name}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography
                  component="span"
                  color="textPrimary"
                  variant="h6"
                  css={css`
                    &.MuiTypography-root {
                      font-weight: 600;
                    }
                  `}
                >
                  {comment.comment}
                </Typography>
                <ArrowForwardIosIcon
                  fontSize={'small'}
                  css={css`
                    color: ${theme.palette.primary.main};
                  `}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
});
