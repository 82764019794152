import { IWidget } from 'models/widget';
import { FishCountWidget } from './fish-count-widget';
import { BasinCountWidget } from './basin-count-widget';
import { FilterWidget } from './filter-widget';
import { CommentWidget } from './comment-widget';
import { SiteListWidget } from './site-list-widget';
import { CompanyListWidget } from './company-list-widget';
import { FeedStockWidget } from './feed-stock-widget';
import { BiomassWidget } from './biomass-widget';
import { MenuWidget } from './menu-widget';

export const widgets: IWidget[] = [
  { id: 'filter-widget', name: 'filter-widget', component: FilterWidget },
  { id: 'menu-widget', name: 'menu-widget', component: MenuWidget },
  { id: 'site-list', name: 'site-list', component: SiteListWidget },
  { id: 'fish-count', name: 'fish-count', component: FishCountWidget },
  { id: 'basin-count', name: 'basin-count', component: BasinCountWidget },
  { id: 'biomass', name: 'biomass', component: BiomassWidget },
  { id: 'comments', name: 'comments', component: CommentWidget },
  { id: 'feed-stock', name: 'feed-stock', component: FeedStockWidget },
];

export const raisioWidgets: IWidget[] = [
  { id: 'filter-widget', name: 'filter-widget', component: FilterWidget },
  { id: 'menu-widget', name: 'menu-widget', component: MenuWidget },
  { id: 'company-list', name: 'company-list', component: CompanyListWidget },
  { id: 'fish-count', name: 'fish-count', component: FishCountWidget },
  { id: 'basin-count', name: 'basin-count', component: BasinCountWidget },
  { id: 'biomass', name: 'biomass', component: BiomassWidget },
  { id: 'comments', name: 'comments', component: CommentWidget },
  { id: 'feed-stock', name: 'feed-stock', component: FeedStockWidget },
];
