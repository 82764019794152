/** @jsxRuntime classic */
/** @jsx jsx */
import { FormattedMessage as M } from 'react-intl';
import { css, jsx } from '@emotion/react';
import { FC } from 'react';
import { ICompany } from '../../models/company';
import EditIcon from '@material-ui/icons/Edit';
import { Box, IconButton, Typography } from '@material-ui/core';

interface ICompanyListItem {
  company: ICompany;
  handleEditCompany: (company: ICompany) => void;
}

export const CompanyListItem: FC<ICompanyListItem> = ({
  company,
  handleEditCompany,
}) => {
  const onEditClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleEditCompany(company);
  };

  return (
    <Box
      css={(theme) => css`
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        margin: 0 ${theme.spacing(6)}px;
      `}
    >
      <Box
        css={(theme) => css`
          flex: 1;
          margin: 0 ${theme.spacing(1)}px;
        `}
      >
        <Typography variant="h4">{company.name || <span>-</span>}</Typography>
        <Typography variant="body2">
          <M id="Company" />
        </Typography>
      </Box>
      <Box
        css={(theme) => css`
          flex: 3;
          margin: 0 ${theme.spacing(1)}px;
        `}
      >
        <Typography variant="h4">
          {company.address || <span>-</span>}
        </Typography>
        <Typography variant="body2">
          <M id="Address" />
        </Typography>
      </Box>
      <Box
        css={(theme) => css`
          flex: 1;
          margin: 0 ${theme.spacing(1)}px;
        `}
      >
        <Typography variant="h4">
          {company.locality || <span>-</span>}
        </Typography>
        <Typography variant="body2">
          <M id="Town" />
        </Typography>
      </Box>
      <IconButton aria-label="edit" color="primary" onClick={onEditClick}>
        <EditIcon />
      </IconButton>
    </Box>
  );
};
