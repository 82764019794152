import TextField from '@material-ui/core/TextField';
import {AdditionalCustomerAccount} from "./additional-customer-account";

type TypeSize = 6 | 12 | undefined;

interface IInputItem {
  size: TypeSize;
  component: any;
  labelId: string;
  name: string;
  componentProps: object;
}

interface ICompanyDialogModel {
  mainContent: IInputItem[];
  additionalContent: IInputItem[];
}

const commonProps = {
  autoFocus: false,
  margin: 'dense',
  fullWidth: true,
};

export const companyDialogModel: ICompanyDialogModel = {
  mainContent: [
    {
      size: 6,
      component: TextField,
      labelId: 'CompanyName',
      name: 'name',
      componentProps: { ...commonProps, autoFocus: true },
    },
    {
      size: 6,
      component: TextField,
      labelId: 'BusinessCode',
      name: 'businesscode',
      componentProps: commonProps,
    },
    {
      size: 6,
      component: TextField,
      labelId: 'DeliveryCustomerId',
      name: 'customerid',
      componentProps: commonProps,
    },
    {
      size: 6,
      component: TextField,
      labelId: 'Address',
      name: 'address',
      componentProps: commonProps,
    },
  ],
  additionalContent: [
    {
      size: 12,
      component: AdditionalCustomerAccount,
      labelId: 'AdditionalCustomerIds',
      name: 'additional_customer_accounts',
      componentProps: commonProps,
    },
    {
      size: 12,
      component: TextField,
      labelId: 'ContactPersons',
      name: 'contactperson',
      componentProps: {
        ...commonProps,
        multiline: true,
        variant: 'outlined',
        rows: 10,
      },
    },
    {
      size: 6,
      component: TextField,
      labelId: 'PhoneNumber',
      name: 'phonenumber',
      componentProps: commonProps,
    },
    {
      size: 6,
      component: TextField,
      labelId: 'Website',
      name: 'website',
      componentProps: commonProps,
    },
    {
      size: 12,
      component: TextField,
      labelId: 'Description',
      name: 'description',
      componentProps: {
        ...commonProps,
        multiline: true,
        variant: 'outlined',
        rows: 10,
      },
    },
  ],
};

export const additionalCustomerIdModel: IInputItem[] = [
  {
    size: 6,
    component: TextField,
    labelId: 'DeliveryCustomerId',
    name: 'additional_customerid',
    componentProps: { ...commonProps, autoFocus: true },
  },
  {
    size: 6,
    component: TextField,
    labelId: 'Name',
    name: 'description',
    componentProps: { ...commonProps },
  },
  {
    size: 12,
    component: TextField,
    labelId: 'Address',
    name: 'address',
    componentProps: { ...commonProps },
  },
  {
    size: 6,
    component: TextField,
    labelId: 'ZipCode',
    name: 'postal_code',
    componentProps: { ...commonProps },
  },
  {
    size: 6,
    component: TextField,
    labelId: 'PostalCity',
    name: 'locality',
    componentProps: { ...commonProps },
  }
]
