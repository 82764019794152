import styled from '@emotion/styled';
import {Box, Button, debounce, Menu, MenuItem, Typography, useTheme} from '@material-ui/core';
import { ISimpleSite } from 'models/site';
import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  MouseEvent,
  useState,
  useEffect,
  Fragment,
} from 'react';
import { FormattedMessage as M, useIntl } from 'react-intl';
import { ExpandButton } from '../../common/expand-button';
import { useStore } from 'stores/store-hooks';
import siteStore from 'stores/site-store';
import { TemperatureSelection } from './site-temperature-selection';
import { OxygenSelection } from './site-oxygen-selection';
import { NotificationsSettingsModal } from 'components/alarm-settings/notifications-modal';
import { SettingsButton } from '../settings-button';
import { MEDIA_BREAKPOINT_LG } from '../../../constants';
import {Videocam} from '@material-ui/icons';
import {useHistory} from 'react-router-dom';
import WarningIcon from '@material-ui/icons/Warning';
import {css} from '@emotion/react';


interface IFacilitySiteRowHeader {
  expanded?: boolean;
  site: ISimpleSite;
  setSiteDialog: Dispatch<
    SetStateAction<{
      visible: boolean;
      editedSite: ISimpleSite | null;
    }>
  >;
}

export const FacilitySiteRowHeader: FC<IFacilitySiteRowHeader> = ({
  expanded,
  site,
  setSiteDialog,
}) => {
  const intl = useIntl();
  const userStore = useStore('userStore');
  const history = useHistory();
  const theme = useTheme();
  const [showNotificationsSettings, setShowNotificationsSettings] = useState(
    false
  );
  const { canEdit } = userStore;
  const [settingsAnchorEl, setSettingsAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [temperature, setTemperature] = useState<number | string>('');
  const [oxygen, setOxygen] = useState<number | string>('');

  // initialize oxygen and temperature value by selected depth if available
  useEffect(() => {
    const updateOxygen = async () => {
      let oxygen = site.last_oxygen_value?.oxygenvalue || '';
      let cachedValue = null;
      if (site.mainunit_id && site.preferred_depth_oxygen
            && site.preferred_depth_oxygen !== "-1") {
        cachedValue = await siteStore.getOxygenValueForDepth(
          site.mainunit_id,
          site.preferred_depth_oxygen
        );
      }
      setOxygen(cachedValue || oxygen);
    };
    const updateTemperature = async () => {
      let temperature = site.last_temperature?.temperature || '';
      let cachedValue = null;
      if (
        site.mainunit_id &&
        site.preferred_depth &&
        site.preferred_depth !== "-1"
      ) {
        cachedValue = await siteStore.getTemperatureForDepth(
          site.mainunit_id,
          site.preferred_depth
        );
      }
      setTemperature(cachedValue || temperature);
    };
    updateOxygen();
    updateTemperature();
  }, [site]);

  const handleClose = () => {
    setSettingsAnchorEl(null);
  };

  const openSite = (site: ISimpleSite) => {
    siteStore.setSelectedSite(site);
    history.push(`/site/${site.id}`);
  };

  const feedAmountTotal =
    site?.fishbasins?.reduce((acc, basin) => {
      if (
        basin &&
        !basin.deleted &&
        basin.currentfeedinfo &&
        basin.currentfeedinfo.feed_amount
      ) {
        return acc + basin.currentfeedinfo.feed_amount || 0;
      }
      return acc;
    }, 0) || 0;

  const handleTempChange = (temperature: string | number) => {
    setTemperature(temperature || '');
    updateTemperature(Number(String(temperature).replace(',', '.')));
  };

  const handleTempChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
    // max value for user-provided temp is 22
    const str = event.target.value.replace(',', '.');
    handleTempChange(Number(str) > 30 ? 30 : event.target.value);
  };

  const handleOxygenChange = (oxygen: string | number) => {
    setOxygen(oxygen || '');
    updateOxygen(Number(String(oxygen).replace(',', '.')));
  };

  const handleOxygenChangeEvent = (event: ChangeEvent<HTMLInputElement>) =>
    handleOxygenChange(event.target.value);

  const handleSettingsClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setSettingsAnchorEl(event.currentTarget);
  };

  const handleEdit = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setSettingsAnchorEl(null);
    setSiteDialog({ visible: true, editedSite: site });
  };

  const handleNotificationsSettings = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setSettingsAnchorEl(null);
    setShowNotificationsSettings(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateTemperature = useCallback(
    debounce((temp: number) => {
      if (temp) siteStore.updateTemperature(site, temp);
    }, 1500),
    []
  );

  const automationAvailable = () => {
    if(!site || !site.fishbasins) return false;
    return site.fishbasins.some((b) => b.supportunit_id);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateOxygen = useCallback(
    debounce((oxygenValue: number) => {
      if (oxygenValue) siteStore.updateOxygen(site, oxygenValue);
    }, 1500),
    []
  );

  const getSiteAlarmCount = (site: ISimpleSite): number => {
    if (!site.fishbasins) {
      return 0;
    }

    return site.fishbasins
      .filter(basin => !basin.deleted && basin.activeAlarmsCount !== undefined)
      .reduce((sum, basin) => sum + (basin.activeAlarmsCount || 0), 0);
  };

  return (
    <Root>
      <ExpandButton isExpanded={!!expanded} />
      <ContentLeft
        style={{
          minWidth: 20,
          maxWidth: 30,
        }}>
        {
          <Box
            css={(theme) => css`
              align-items: center;
              display: flex;
              padding-left: ${theme.spacing(1)}px;
            `}
          >
            { getSiteAlarmCount(site) > 0 &&
              <WarningIcon
                style={{
                  color: theme.palette.error.main,
                }}
              />
            }
          </Box>
        }
      </ContentLeft>
      <ContentLeft
        onClick={(event: any) => event.stopPropagation()}
        onFocus={(event: any) => event.stopPropagation()}
      >
        <Typography variant="h6" color="textPrimary">
          <M id="Site" />
        </Typography>
        <Typography variant="subtitle2" color="primary">
          {site.name}
        </Typography>
      </ContentLeft>
      <ContentRight
        onClick={(event: any) => event.stopPropagation()}
        onFocus={(event: any) => event.stopPropagation()}
      >
        {automationAvailable() && (
            <Button
              onClick={() => openSite(site)}
              style={{
                margin: '15px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Videocam/>
            </Button>
        )}
        <Divider/>
        <Box>
          <TemperatureSelection
            siteId={site.id}
            iotDeviceId={site.mainunit_id}
            automaticTempEnabled={!!site.automatic_temperature_update}
            temperature={temperature}
            setTemperature={handleTempChange}
            preferredDepth={site.preferred_depth}
            handleTempChange={handleTempChangeEvent}
          />
        </Box>
        <Divider />
        {site.preferred_depth_oxygen && (
          <OxygenStatus>
            <Typography variant="subtitle2" color="primary">
              {
                <OxygenSelection
                  siteId={site.id}
                  iotDeviceId={site.mainunit_id}
                  automaticOxygenEnabled={!!site.automatic_oxygen_update}
                  preferredDepth={site.preferred_depth_oxygen}
                  oxygen={oxygen}
                  setOxygen={handleOxygenChange}
                  handleOxygenChange={handleOxygenChangeEvent}
                />
              }
            </Typography>
          </OxygenStatus>
        )}
        <Divider />
        <TotalBiomass>
          <Typography variant="subtitle2" color="primary">
            {site.total_biomass ? Math.round(site.total_biomass / 1000) : '-'}{' '}
            {intl.formatMessage({ id: 'Tons' })}
          </Typography>
          <Typography variant="h6" color="primary">
            <M id="BiomassTot" />
          </Typography>
        </TotalBiomass>
        <Divider />
        <TotalFeedAmount>
          <Typography variant="subtitle2" color="primary">
            {intl.formatNumber(feedAmountTotal / 1000, {
              style: 'decimal',
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}
            {' ' + intl.formatMessage({ id: "UnitKg" })}
          </Typography>
          <Typography variant="h6" color="primary">
            <M id="FeedUsageDay" />
          </Typography>
        </TotalFeedAmount>
        <Divider />
        <FacilitySettings>
          {site.mainunit_id ? (
            <Fragment>
              <SettingsButton
                disabled={!canEdit}
                onClick={handleSettingsClick}
                disabledTooltipTextId={'ModifyingSettingsNotAllowed'}
              />
              <Menu
                id="settings-menu"
                anchorEl={settingsAnchorEl}
                keepMounted
                open={Boolean(settingsAnchorEl)}
                onClose={handleClose}
                onClick={(event) => event.stopPropagation()}
              >
                <MenuItem onClick={handleEdit} disabled={!canEdit}>
                  <M id="Edit" />
                </MenuItem>
                {site.mainunit_id && (
                  <MenuItem
                    onClick={handleNotificationsSettings}
                    disabled={!canEdit}
                  >
                    <M id="NotificationSettings" />
                  </MenuItem>
                )}
              </Menu>
            </Fragment>
          ) : (
            <SettingsButton
              disabled={!canEdit}
              onClick={handleEdit}
              disabledTooltipTextId={'ModifyingSettingsNotAllowed'}
            />
          )}
        </FacilitySettings>
      </ContentRight>
      {showNotificationsSettings && (
        <NotificationsSettingsModal
          site={site}
          handleClose={() => setShowNotificationsSettings(false)}
        />
      )}
    </Root>
  );
};

// Custom styled components for this view

const Root = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  box-sizing: border-box;
  border-radius: 5px;
  align-items: center;
`;
const ContentLeft = styled.div`
  flex-grow: 1;
  cursor: default;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;
const ContentRight = styled.div`
  display: flex;
  cursor: default;
  align-items: center;
`;
const OxygenStatus = styled.div`
  display: flex;
  align-items: center;
  min-width: 120px;
  @media (max-width: ${MEDIA_BREAKPOINT_LG}px) {
    min-width: 60px;
  }
`;
const TotalBiomass = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 120px;
  @media (max-width: ${MEDIA_BREAKPOINT_LG}px) {
    min-width: 60px;
  }
`;
const TotalFeedAmount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 120px;
  @media (max-width: ${MEDIA_BREAKPOINT_LG}px) {
    min-width: 60px;
  }
`;
const FacilitySettings = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
`;

const Divider = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  @media (max-width: ${MEDIA_BREAKPOINT_LG}px) {
    margin-left: 8px;
    margin-right: 8px;
  }
  border-right: 1px solid ${({ theme }) => theme.palette.primary.main};
  min-height: 70px;
`;
