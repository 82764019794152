/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FC, Fragment } from 'react';
import { FormattedMessage as M } from 'react-intl';
import { Box, Button, Divider } from '@material-ui/core';
import { SearchField } from 'components/search/search';

import styled from '@emotion/styled';

export enum CompanyListActions {
  RemoveCompanies = 'RemoveCompanies',
  AddCompany = 'AddCompany',
  CancelChanges = 'CancelChanges',
  AcceptChanges = 'AcceptChanges',
}

export interface ICompanyListHeader {
  isEditing?: boolean;
  setSearchTerm: (searchTerm: string) => void;
  onHeaderButtonClick?: (type: CompanyListActions) => void;
}

const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    background-color: ${({ theme }) => theme.palette.text.primary};
    margin: ${({ theme }) => theme.spacing(1)}px 0;
  }
`;

export const CompanyListHeader: FC<ICompanyListHeader> = ({
  isEditing,
  setSearchTerm,
  onHeaderButtonClick,
}) => {
  const handleClick = (type: CompanyListActions) => () => {
    if (onHeaderButtonClick) onHeaderButtonClick(type);
  };
  return (
    <Box
      css={(theme) => css`
        display: flex;
        flex-direction: row;
        margin-top: ${theme.spacing(1)}px;
        margin-left: ${theme.spacing(0)}px;
        align-content: space-space-between;
        justify-content: space-between;
      `}
    >
      <Box
        css={() => css`
          display: flex;
          flex-direction: row;
        `}
      >
        {!isEditing ? (
          <Fragment>
            <Button
              component="h6"
              onClick={handleClick(CompanyListActions.AddCompany)}
            >
              <M id="AddCompany" />
            </Button>
            <StyledDivider orientation="vertical" flexItem />
            <Button
              component="h6"
              onClick={handleClick(CompanyListActions.RemoveCompanies)}
            >
              <M id="RemoveCompanies" />
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            <Button
              component="h6"
              onClick={handleClick(CompanyListActions.AcceptChanges)}
            >
              <M id="AcceptChanges" />
            </Button>
            <StyledDivider orientation="vertical" flexItem />
            <Button
              component="h6"
              onClick={handleClick(CompanyListActions.CancelChanges)}
            >
              <M id="CancelChanges" />
            </Button>
          </Fragment>
        )}
      </Box>
      <SearchField setSearchTerm={setSearchTerm} />
    </Box>
  );
};
