import React, {useEffect, useState} from "react";
import {Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography, useTheme} from "@material-ui/core";
import {BasinCompareList} from "./basin-compare-list";
import {IFishBasin} from "../../models/fishbasin";
import {BasinCompareGraphs} from "./basin-compare-graphs";
import {useStore} from "../../stores/store-hooks";
import {observer} from "mobx-react-lite";
import {css} from "@emotion/react";
import {DRAWER_WIDTH_MIN} from "../../constants";
import {useIntl} from "react-intl";

export const BasinCompareView = observer(() => {

  const intl = useIntl();
  const [selectedBasins, setSelectedBasins] = useState<IFishBasin[]>([]);
  const graphStore = useStore('graphStore');
  const settingsStore = useStore('settingsStore');
  const basinStore = useStore('basinStore');
  const theme = useTheme();

  useEffect(
    () => settingsStore.setDrawerOpenStatus(false, DRAWER_WIDTH_MIN),
    [settingsStore]
  );

  useEffect(() => {
    const load = async () => {
      await Promise.all([basinStore.loadFishTypes(), basinStore.loadSimpleActiveBasins()]);
    }
    load();
    graphStore.clearChartData();
    return () => graphStore.clearChartData();
  }, [basinStore, graphStore]);

  const loadGraphs = () => {
    graphStore.clearChartData();
    graphStore.loadBasinsChartData(selectedBasins);
  };

  const onSelectFishType = (fishTypeId: number) => {
    basinStore.setFishTypeFilter(fishTypeId);
    setSelectedBasins([]);
  }

  return (
    <Box
      css={css`
        // Element added to get spacing between vertical scrollbar and page content
        height: calc(100% - 32px);
        margin-bottom: ${theme.spacing(2)}px;
        margin-top: ${theme.spacing(2)}px;
        margin-right: 0;
      `}
    >
      <Box
        style={{
          borderRadius: "5px",
          marginRight: theme.spacing(1)+"px",
          padding: theme.spacing(2)+"px",
          backgroundColor: theme.palette.common.white,
        }}
      >
          <Box
            display="flex"
            alignItems="center"
            style={{
              gap: 30,
              paddingBottom: theme.spacing(2)+"px",
              borderBottom: "1px solid "+theme.palette.primary.main,
            }}>
            <Typography
              variant="h1"
            >
              {intl.formatMessage({id: "CompareBasins"})}
            </Typography>
            <FormControl variant="standard">
              <InputLabel variant="standard">
                {intl.formatMessage({id: "Species"})}
              </InputLabel>
              <Select
                value={basinStore.fishtypeFilter.id}
                onChange={(event: any) => onSelectFishType(event.target.value)}
              >
                <MenuItem value={-1} >
                  <em>{intl.formatMessage({id: "All"})}</em>
                </MenuItem>
                {basinStore.fishTypes.map(fishtype => (
                  <MenuItem key={fishtype.id} value={fishtype.id}>
                    <em>{fishtype.translated_name}</em>
                  </MenuItem>
                ))};
              </Select>
            </FormControl>
            <Button
              onClick={loadGraphs}
              disabled={selectedBasins.length === 0}
              variant="contained"
              color="primary"
            >
              {intl.formatMessage({id: "LoadCharts"})}
            </Button>
          </Box>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <BasinCompareList selectedBasins={selectedBasins} setSelectedBasins={(basins: IFishBasin[]) => setSelectedBasins(basins)}/>
          </Grid>
          <Grid item xs={7}>
            <BasinCompareGraphs fetchSelectedBasins={() => selectedBasins}/>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});
