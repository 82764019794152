// This is only used on the client, so you can freely change the numerical value
// of each option if necessary.
export enum FeedTypeUsageStatus {
  COMPANY_SPECIFIC = 3, // Feed type tied to a company. Not orderable.
  USED = 2,
  ORDERED = 1,
  NONE = 0,
}


export interface IFeedType {
  id?: number;
  deleted: boolean;
  url: string;
  productid?: number;
  name: string;
  kind?: string;
  phosphoruspercentage: string;
  nitrogenpercentage: string;
  company?: SimpleCompany;
  usageStatus?: FeedTypeUsageStatus; // Only used on the client
}

export interface SimpleCompany {
  id: number;
  name: string;
  url: string;
}

export interface ISiloEntry {
  url: string;
  id: number;
  timestamp: string;
  feederamount: number;
  feedtype: IFeedType;
  nitrogen: number;
  phosphorus: number;
}

export interface IFeedStockData {
  timestamp: string;
  feedtype_id: number | null;
  feederamount: number;
  eventtype?: string;
}
