import { IntlShape } from 'react-intl';
import { chartOptions } from './chart-options';
import {formatter, formatterNoYears} from './tools';

const baseConfig = {
  theme: 'walden',
  event: [],
  dataLoaded: false,
};

export const biomassConfig = { ...baseConfig };
export const bioMassOptions = (intl: IntlShape) =>
  chartOptions(
    {
      tooltip: {
        formatter: formatter(intl, "UnitKg"),
      },
    },
    intl
  );

export const averageWeightConfig = { ...baseConfig };
export const averageWeightOptions = (intl: IntlShape) =>
  chartOptions(
    {
      tooltip: {
        formatter: formatter(intl, "UnitGrams"),
      },
    },
    intl
  );

export const feedAmountConfig = { ...baseConfig };
export const feedAmountOptions = (intl: IntlShape) =>
  chartOptions(
    {
      tooltip: {
        formatter: formatter(intl, "UnitKg"),
      },
    },
    intl
  );

export const feedPercentageConfig = { ...baseConfig };
export const feedPercentageOptions = (intl: IntlShape) =>
  chartOptions(
    {
      tooltip: {
        formatter: formatter(intl, "%"),
      },
    },
    intl
  );

export const cumFeedAmountConfig = { ...baseConfig };
export const cumFeedAmountOptions = (intl: IntlShape) =>
  chartOptions(
    {
      tooltip: {
        formatter: formatter(intl, "UnitKg"),
      },
    },
    intl
  );

export const deviationConfig = { ...baseConfig };
export const deviationOptions = (intl: IntlShape) =>
  chartOptions(
    {
      tooltip: {
        formatter: formatter(intl),
      },
    },
    intl
  );

export const mainUnitOxygenConfig = { ...baseConfig };
export const mainUnitOxygenOption = (intl: IntlShape, start = 0, end = 0) =>
  chartOptions(
    {
      toolbox: {
        show: false,
      },
      dataZoom: {
        startValue: start,
        endValue: end,
        filterMode: 'none',
        labelFormatter: (value: string) => {
          const asDate = new Date(value);
          const label = `${asDate.getUTCDate()}.${
            asDate.getUTCMonth() + 1
          }.`;
          return label;
        },
      },
      tooltip: {
        formatter: formatterNoYears(intl, 'UnitMgPerLiter')
      },
      yAxis: {
        nameGap: 50,
      },
      xAxis: {
        min: 0,
        max: 31465819000, // end of year 1970 in epoch
        axisLabel: {
          formatter: (value: string) => {
            const asDate = new Date(value);
            const label = `${asDate.getUTCDate()}.${
              asDate.getUTCMonth() + 1
            }.`;
            return label;
          },
        }
      }
    },
    intl
  );

export const mainUnitTemperatureConfig = { ...baseConfig };
export const mainUnitTemperatureOption = (intl: IntlShape) =>
  chartOptions(
    {
      yAxis: {
        nameGap: 50,
      },
    },
    intl
  );

export const temperatureConfig = { ...baseConfig };
export const temperatureOption = (intl: IntlShape, start = 0, end = 0) =>
  chartOptions(
    {
      toolbox: {
        show: false,
      },
      dataZoom: {
        startValue: start,
        endValue: end,
        filterMode: 'none',
        labelFormatter: (value: string) => {
          const asDate = new Date(value);
          const label = `${asDate.getUTCDate()}.${
            asDate.getUTCMonth() + 1
          }.`;
          return label;
        },
      },
      tooltip: {
        formatter: formatterNoYears(intl, 'UnitCelsius')
      },
      yAxis: {
        nameGap: 50,
      },
      xAxis: {
        min: 0,
        max: 31465819000, // end of year 1970 in epoch
        axisLabel: {
          formatter: (value: string) => {
            const asDate = new Date(value);
            const label = `${asDate.getUTCDate()}.${
              asDate.getUTCMonth() + 1
            }.`;
            return label;
          },
        }
      }
    },
    intl
  );

export const siloLevelConfig = { ...baseConfig };
export const siloLevelOption = (intl: IntlShape) =>
  chartOptions(
    {
      tooltip: {
        formatter: formatter(intl),
      },
      toolbox: {
        show: false,
      },
      legend: {
        show: false,
      },
      title: {
        show: false,
      },
      yAxis: {
        min: 0,
        max: 100,
      },
    },
    intl
  );

export const supportUnitBatteryConfig = { ...baseConfig };
export const supportUnitBatteryOption = (intl: IntlShape) =>
  chartOptions(
    {
      yAxis: {
        nameGap: 50,
      },
      tooltip: {
        formatter: formatter(intl, "UnitVolts"),
      },
    },
    intl
  );
