import { makeAutoObservable, runInAction } from 'mobx';
import { ICredentials, UserModel, UserNameModel } from 'models/user';
import {
  createRaisioAdminUser,
  createUserForCompany,
  deleteUser,
  fetchAllUsers,
  fetchRaisioUsers,
  fetchRaisioUsersNames,
  fetchUsersById, getCompanyById,
  getCurrentUser,
  login,
  logout,
  patchCurrentUser,
  patchUser,
} from 'services/api';
import { StoreState } from './store-state';
import toastStore from 'stores/toast-store';
import {ICompany, RAISIO_COMPANY} from 'models/company';
import dashboardStore from './dashboard-store';
import facilityStore from './facility-store';
import basinStore from './basin-store';
import filterStore from './filter-store';

export enum USER_GROUP {
  CompanyUser = 1,
  CompanyAdmin = 2,
  RaisioAdmin = 3,
}

class UserStore {
  constructor() {
    makeAutoObservable(this);
    this.token = localStorage.getItem('auth_token');
  }

  currentUser: UserModel | null = null;
  currentUserCompany: ICompany | null = null
  token: string | null = null;
  state: StoreState = StoreState.pending;
  localLang: string | null = null;
  editDialogOpen: boolean = false;
  editedUserCompanyId: number | null = null;
  editedUser: UserModel | null = null;

  // get is considered computed observable by mobx (makeAutoObservable)
  get isAuthenticated() {
    return !!this.token;
  }

  updateUserData = async (data: object) => {
    try {
      const response = await patchCurrentUser(data);
      runInAction(() => {
        this.currentUser = response.data;
      });
      return true;
    } catch (error) {
      console.log('user update failed', error);
      return false;
    }
  };

  setEditDialogOpen(value: boolean) {
    this.editDialogOpen = value;
  }

  setEditedUserCompanyId(value: number | null) {
    this.editedUserCompanyId = value;
  }

  /**
   * Computed property getter for selected UI language. Used language is determined
   * by user settings (if authenticated),
   * by saved localStorage value or
   * by browser language,
   */
  set selectedLanguage(language: string) {
    localStorage.setItem('kasvuluotain_lang', language);
    runInAction(() => {
      this.localLang = language;
    });
    if (this.currentUser) {
      this.updateUserData({ language });
    }
  }

  get hasAcceptedTermsOfService() {
    return !!this.currentUser?.terms_accepted;
  }

  /**
   *
   * User is considered to be admin if it is either a super user or a user with RaisioAdmin group.
   * This logic is implemented to user serializer in the backend.
   */
  get isRaisioAdminOrSuperuser() {
    return !!this.currentUser?.admin;
  }

  /**
   *
   * Concern is a company which has is_concern flag set to true. Concern can include many companies. These
   * child companies have concern_company set to the company id in the backend
   */
  get isConcernCompanyAdmin(): boolean {
    return (this.currentUserCompany?.is_concern || false) && this.isCompanyAdmin;
  }

  /**
   * User is considered to be a company level admin if it belong to CompanyAdmin group.
   */

  get isCompanyAdmin() {
    return !!this.currentUser?.groups?.find(
      (group) => group.name === 'CompanyAdmin'
    );
  }

  get canEdit() {
    return this.isRaisioAdminOrSuperuser || this.isCompanyAdmin;
  }

  get thousandSeparator() {
    return this.selectedLanguage === 'en' ? ',' : ' '; // TODO: this is not perfect, used to parse decimal strings when editing values
  }

  /**
   * Computed property setter for selected UI language. Used language is determined
   * by user settings (if authenticated),
   * by saved localStorage value or
   * by browser language,
   */
  get selectedLanguage() {
    const browserLan = navigator.language.split(/[-_]/)[0];
    // used language can be stored to localStorage from login page
    runInAction(() => {
      this.localLang = localStorage.getItem('kasvuluotain_lang');
    });
    const userLanguage = this.currentUser?.language;
    return userLanguage || this.localLang || browserLan;
  }

  /**
   * Login functionality, calls login API and saves received token to store and to localStorage.
   * @param credentials
   */
  async login(credentials: ICredentials) {
    this.state = StoreState.pending;
    this.token = '';
    try {
      const loginResponse = await login(credentials);
      this.token = loginResponse.data.token;
      localStorage.setItem('auth_token', this.token || '');
      runInAction(() => {
        this.state = StoreState.done;
      });
    } catch (e) {
      runInAction(() => {
        this.token = null;
        toastStore.setToast('loginError');
        this.state = StoreState.error;
      });
    }
  }

  async getCurrentUser() {
    this.state = StoreState.pending;
    try {
      const currentUserResp = await getCurrentUser();

      const currentUserCompanyResp = currentUserResp.data.company
        ? await getCompanyById(currentUserResp.data.company)
        : null;
      runInAction(() => {
        this.currentUser = currentUserResp.data;
        this.currentUserCompany = currentUserCompanyResp?.data || null;
        localStorage.setItem('kasvuluotain_lang', this.currentUser.language);
        this.state = StoreState.done;
      });
    } catch (e) {
      runInAction(() => {
        this.state = StoreState.error;
      });
    }
  }

  /**
   *
   * Fetch users by company id. Returns Raisio users if company is id RAISIO_COMPANY.id (-1)
   *
   * @param companyId company id or -1 for RAISIO_COMPANY
   */

  fetchUsersByCompanyId = async (companyId: number): Promise<UserModel[]> => {
    this.state = StoreState.pending;
    let users: UserModel[] = [];
    try {
      if (companyId === RAISIO_COMPANY.id) {
        const usersResp = await fetchRaisioUsers();
        users = usersResp.data;
        // filter only active users in RaisioAdmin group. API returns also company users, which is an error int he API impl.
        users = users.filter(
          (u) => u.active && (u.group === 3 || u.group == null)
        );
      } else {
        const usersResp = await fetchUsersById(companyId);
        users = usersResp?.data;
      }
      runInAction(() => {
        this.state = StoreState.done;
      });
    } catch (e) {
      runInAction(() => {
        this.state = StoreState.error;
      });
    }
    // only return active users
    return users.filter((u) => u.active);
  };

  fetchAllUsers = async (): Promise<UserModel[]> => {
    try {
      const response = await fetchAllUsers();
      return response.data.filter((u) => u.active);
    } catch (e) {
      runInAction(() => {
        this.state = StoreState.error;
      });
      return Promise.resolve([]);
    }
  };

  fetchRaisioUsersNames = async (): Promise<UserNameModel[]> => {
    try {
      const response = await fetchRaisioUsersNames();
      return response.data;
    } catch (e) {
      runInAction(() => {
        this.state = StoreState.error;
      });
      return Promise.resolve([]);
    }
  };

  /**
   * Logs out user and removed the saved API token
   */
  logout = async () => {
    try {
      await logout(); // delete API token from the backend
    } catch (error) {
      console.log(error);
    }
    this.token = null;
    runInAction(() => {
      this.currentUser = null;
      this.currentUserCompany = null;
    });
    localStorage.removeItem('auth_token');
    // remove cached data on logout
    dashboardStore.reset();
    facilityStore.reset();
    basinStore.reset();
    filterStore.reset();
  };

  deleteUser = async (userId: number) => {
    const failedIds: number[] = [];
    try {
      await deleteUser(userId);
    } catch (error) {
      failedIds.push(userId);
    }
    return failedIds;
  };

  modifyUser = async (user: UserModel) => {
    if (this.editedUserCompanyId) {
      if (!this.editedUser) {
        // create a new user (either RaisioAdmin user or user for selected company)
        if (this.editedUserCompanyId === RAISIO_COMPANY.id) {
          user.group = USER_GROUP.RaisioAdmin;
          try {
            const result = await createRaisioAdminUser(user);
            return { user: result.data, errors: null, status: result?.status };
          } catch (error) {
            return {
              user: null,
              errors: error?.response?.data,
              status: error?.response?.status,
            };
          }
        } else {
          try {
            const result = await createUserForCompany(
              this.editedUserCompanyId,
              user
            );
            return { user: result.data, errors: null, status: result?.status };
          } catch (error) {
            return {
              user: null,
              errors: error?.response?.data,
              status: error?.response?.status,
            };
          }
        }
      } else {
        // handle editing of existing user
        try {
          const result = await patchUser(this.editedUser.id, user);
          return { user: result.data, errors: null, status: result?.status };
        } catch (error) {
          return {
            user: null,
            errors: error?.response?.data,
            status: error?.response?.status,
          };
        }
      }
    }
  };

  updatePassword = async (editedUser: UserModel, password: string) => {
    try {
      const user: UserModel = { password } as UserModel;
      await patchUser(editedUser.id, user);
    } catch (error) {
      toastStore.setToast('PasswordChangeFailed');
    }
  };
}

export default new UserStore();
