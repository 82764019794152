/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FormattedMessage as M, useIntl } from 'react-intl';
import * as yup from 'yup';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Fade,
  Grid,
  Paper,
  TextField,
  Typography,
  useTheme,
  Theme,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  usePopupState,
  bindToggle,
  bindPopper,
  PopupState,
} from 'material-ui-popup-state/hooks';
import { Controller, useForm } from 'react-hook-form';
import { useStore } from 'stores/store-hooks';
import { observer } from 'mobx-react-lite';
import { IDeviceInfoResponse } from 'models/iot-devices';

interface IUpdateData {
  feedmass: number;
  calibrationTime: number;
}

export const CalibrationPopOver: FC = observer(() => {
  const basinStore = useStore('basinStore');
  const iotStore = useStore('iotStore');
  const [deviceInfo, setDeviceInfo] = useState<IDeviceInfoResponse | null>(
    null
  );
  const { selectedBasin } = basinStore;
  const loadDeviceInfo = useCallback(async () => {
    if (selectedBasin) {
      const responseDInfo = await iotStore.loadDeviceInfo(selectedBasin);
      if (responseDInfo.status === 200) {
        setDeviceInfo(responseDInfo.data);
      }
    }
  }, [iotStore, selectedBasin]);
  useEffect(() => {
    loadDeviceInfo();
  }, [loadDeviceInfo]);

  const intl = useIntl();
  const calibrationDisplayValue =
    intl.formatNumber(deviceInfo?.deviceConfiguration?.fCal || 0, {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }) + ' ' + intl.formatMessage({ id: "UnitGramsPerSecond" });

  const buttonCss = (theme: Theme) => css`
    &.MuiButton-root {
      margin-top: ${theme.spacing(2)}px;
      box-shadow: 0px 3px 3px rgba(0, 88, 100, 0.15);
      :last-child {
        margin-bottom: ${theme.spacing(1)}px;
      }
      border: 1px solid ${theme.palette.primary.main};
      background-color: ${theme.palette.background2.main};
      color: ${theme.palette.primary.main};
      :hover {
        background-color: ${theme.palette.hover.main};
      }
    }
  `;

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'calibrationPopper',
  });

  const updateData = async (data: IUpdateData) => {
    const calibration = (data.feedmass * 1000) / (data.calibrationTime * 60);
    const { selectedBasin } = basinStore;
    if (selectedBasin) {
      const response = await iotStore.calibration(selectedBasin, {
        calibration,
      });
      if (response.status === 200 && deviceInfo?.deviceConfiguration) {
        deviceInfo.deviceConfiguration.fCal = calibration;
        setDeviceInfo(deviceInfo);
      }
    }
    window.setTimeout(() => {
      loadDeviceInfo();
    }, 5000);
    popupState.close();
  };

  return (
    <Fragment>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        type="submit"
        css={buttonCss}
        {...bindToggle(popupState)}
      >
        <Typography component="span" variant="h5">
          {` ${intl.formatMessage({
            id: 'Calibration',
          })} (${calibrationDisplayValue})`}
        </Typography>
      </Button>
      <Popper {...bindPopper(popupState)} transition>
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={popupState.close}
            mouseEvent="onMouseDown"
          >
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <PopoverContent
                  popupState={popupState}
                  updateData={updateData}
                  deviceInfo={deviceInfo}
                />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Fragment>
  );
});

interface IContent {
  popupState: PopupState;
  updateData: (data: IUpdateData) => void;
  deviceInfo: IDeviceInfoResponse | null;
}

const PopoverContent = ({ popupState, updateData, deviceInfo }: IContent) => {
  const theme = useTheme();
  const intl = useIntl();
  const schema = yup.object().shape({
    feedmass: yup
      .number()
      .transform((_, value) => {
        return +value.replace(/,/, '.');
      })
      .min(0, 'error.validation.feedmass')
      .max(2147483647, 'error.validation.feedmass')
      .typeError('error.validation.feedmass')
      .required('error.validation.feedmass'),
    calibrationTime: yup.number(),
  });
  const { register, handleSubmit, errors, control, watch } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      calibrationTime: 1,
      feedmass: undefined,
    },
  });

  const calibrationTime = watch('calibrationTime') || 1;
  let feedmass: string = watch('feedmass') || '0';
  feedmass = feedmass
    .replace(',', '.') // convert ',' decimal separator to dot
    .replace(/\s/g, ''); // remove white space
  const newCalibration = intl.formatNumber(
    (Number(feedmass) * 1000) / (calibrationTime * 60),
    {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }
  );

  return (
    <form noValidate onSubmit={handleSubmit(updateData)}>
      <Box p={1} pl={2} bgcolor={theme.palette.grey[200]}>
        <Typography component="div" variant="h4">
          <M id="Calibration" />
        </Typography>
        <Typography component="div" variant="h6">
          {`${intl.formatMessage({
            id: 'UsedCalibration',
          })}: ${intl.formatNumber(deviceInfo?.deviceConfiguration?.fCal || 0, {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          })} ${intl.formatMessage({ id: "UnitGramsPerSecond" })}`}
        </Typography>
      </Box>
      <Box p={2} maxWidth={450}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel id="calibrationTime-label">
              {intl.formatMessage({
                id: 'CalibrationTime',
              })}
            </InputLabel>
            <Controller
              name="calibrationTime"
              control={control}
              render={({ onChange, onBlur, value, ref }) => (
                <Select
                  onBlur={onBlur}
                  onChange={onChange}
                  defaultValue={1}
                  inputRef={ref}
                  id="calibrationTime-select"
                  margin="dense"
                  fullWidth
                >
                  {[1, 2, 3, 4, 5, 10].map((value) => {
                    return (
                      <MenuItem key={value} value={value}>
                        {`${value}`}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
            <TextField
              name="feedmass"
              margin="dense"
              id="feed-mass"
              label={<M id="Feedmass" />}
              inputRef={register}
              error={!!errors.feedmass}
              helperText={
                errors.feedmass && <M id={`${errors?.feedmass?.message}`} />
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component="div" variant="h6">
              {`${intl.formatMessage({
                id: 'NewCalibration',
              })}: ${newCalibration} ${intl.formatMessage({
                id: "UnitGramsPerSecond"
              })}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              type="submit"
              disabled={!!Object.keys(errors).length}
            >
              <M id="Save" />
            </Button>
            <Button onClick={popupState.close} color="primary">
              <M id="Cancel" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
