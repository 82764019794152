/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FC } from 'react';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { Box, Tooltip } from '@material-ui/core';
import { useIntl } from 'react-intl';

export interface IControlButton {
  id: number;
  isDisabled?: boolean;
  controlButtonColor?: string;
  disabledTooltipTextId?: string;
  onControlButtonClick?: (id: number) => void;
  leftOffset?: number;
}

export const ControlButton: FC<IControlButton> = ({
  controlButtonColor,
  onControlButtonClick,
  disabledTooltipTextId,
  isDisabled,
  leftOffset,
  id,
}) => {
  const intl = useIntl();
  const handleClick = () => {
    if (!isDisabled) {
      onControlButtonClick && onControlButtonClick(id);
    }
  };

  return (
    <Box
      key={id}
      component="span"
      css={(theme) => css`
        padding: ${theme.spacing(1)}px;
        margin-bottom: 10px;
        height: ${theme.spacing(6)}px;
        position: relative;
        left: -${leftOffset || 0}px;
        // left: ${controlButtonColor ? `-${leftOffset || 0}px` : '0px'};
        min-width: 64px;
        background-color: ${controlButtonColor || 'unset'};
        border-radius: 15px 0 0 15px;
        border: 1px solid ${controlButtonColor};
        display: flex;
        visibility: ${controlButtonColor ? 'visible' : 'hidden'};
        transition: margin-left 1s ease-in;
        justify-items: center;
        align-items: center;
      `}
    >
      <Tooltip
        title={
          isDisabled ? intl.formatMessage({ id: disabledTooltipTextId }) : ''
        }
        aria-label="delete not allowed"
        arrow
        css={css`
          &&& {
            font-size: 28px;
          }
        `}
      >
        <ClearRoundedIcon
          onClick={handleClick}
          css={(theme) => css`
            color: ${theme.palette.common.white};
            cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
          `}
        />
      </Tooltip>
    </Box>
  );
};
