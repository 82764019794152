import { makeAutoObservable, runInAction } from 'mobx';
import {
  getSiloEntries,
  deleteFishbasinEvent,
  loadFeedTypes,
  createFeedStockEvent,
  patchFeedStockEvent,
} from 'services/api';
import basinStore from './basin-store';
import orderStore from './orders-store';
import {
  FeedTypeUsageStatus,
  IFeedStockData,
  IFeedType,
  ISiloEntry,
} from '../models/silo';
import toastStore from './toast-store';
import { IntlShape } from 'react-intl';

class SiloStore {
  constructor() {
    makeAutoObservable(this);
  }
  siloEntries: ISiloEntry[] = [];
  feedTypes: IFeedType[] = [];
  editedEntry: ISiloEntry | null = null;

  get summary() {
    return this.siloEntries.reduce(
      (acc, currentEntry) => {
        acc.amount += currentEntry.feederamount;
        acc.amountN += currentEntry.nitrogen;
        acc.amountP += currentEntry.phosphorus;
        return acc;
      },
      {
        amount: 0,
        amountP: 0,
        amountN: 0,
      }
    );
  }

  get siloEntriesNoSummary() {
    if (this.siloEntries.length && this.siloEntries[0].id === -1) {
      return this.siloEntries.slice(1);
    }
    return this.siloEntries;
  }

  get companySpecificFeedTypes() {
    return this.feedTypes.filter(
      (feedType) => feedType.usageStatus === FeedTypeUsageStatus.COMPANY_SPECIFIC
    );
  }

  /** Filter feeds based on fish type name, some feeds can be used for several fish types, feed is visible if the type is null */
  get filteredFeedTypes() {
    const basinFishType = basinStore.selectedBasin?.fishtype?.name;
    if (!basinFishType) return this.feedTypes;
    return this.feedTypes.filter((t) => {
      return (
        !t.kind || t.kind.toLowerCase().includes(basinFishType.toLowerCase())
      );
    });
  }

  get totalFeederAmount() {
    return this.siloEntriesNoSummary.reduce((acc, currentEntry) => {
      acc += currentEntry.feederamount;
      return acc;
    }, 0);
  };

  loadSiloEntries = async () => {
    try {
      const { selectedBasin } = basinStore;
      if (!selectedBasin?.id) throw new Error('No basin selected');
      const response = await getSiloEntries(selectedBasin.id);
      const { data } = response;
      runInAction(() => {
        this.siloEntries = data;
      });
    } catch (error) {
      toastStore.setToast('SiloDataLoadFailed');
      runInAction(() => {
        this.siloEntries = [];
      });
    }
  };

  addSummaryRow = (intl: IntlShape) => {
    runInAction(() => {
      if (this.siloEntries.length && this.siloEntries[0].id === -1) {
        // remove old summary row if it exists
        this.siloEntries.splice(0, 1);
      }
      this.siloEntries.unshift({
        id: -1,
        feedtype: {
          name: intl.formatMessage({ id: 'Summary' }),
        },
        nitrogen: this.summary.amountN,
        phosphorus: this.summary.amountP,
        feederamount: this.summary.amount,
      } as ISiloEntry);
    });
  };

  deleteEntry = async (entryId: number) => {
    try {
      await deleteFishbasinEvent(entryId);
      runInAction(() => {
        this.siloEntries = this.siloEntries.filter(
          (entry) => entry.id !== entryId
        );
      });
    } catch (error) {
      console.log('Silo entry delete failed');
      toastStore.setToast('SiloEntryDeleteFailed');
    }
  };

  loadFeedTypes = async () => {
    try {
      const { data } = await loadFeedTypes();
      runInAction(() => {
        data.forEach((t) => {
          if (t.company?.id) {
            t.usageStatus = FeedTypeUsageStatus.COMPANY_SPECIFIC;
          } else if (
            this.siloEntries.find((e) => e.feedtype.id === t.id)
          ) {
            t.usageStatus = FeedTypeUsageStatus.USED;
          } else if (
            orderStore.orders
              .flatMap((o) => o.customerOrderPackages)
              .find((p) => p.package?.productCode === String(t.productid))
          ) {
            t.usageStatus = FeedTypeUsageStatus.ORDERED;
          } else {
            t.usageStatus = FeedTypeUsageStatus.NONE;
          }
        });

        data.sort((a, b) =>
          a.usageStatus === b.usageStatus
            ? a.name.localeCompare(b.name)
            : (b.usageStatus || 0) - (a.usageStatus || 0)
        );

        this.feedTypes = data.filter((t) => !t.deleted);
      });
    } catch (error) {
      console.log('Feed type load failed');
      toastStore.setToast('FeedTypeLoadFailed');
    }
  };

  saveFeedStockEvent = async (eventData: IFeedStockData) => {
    try {
      const basinId = basinStore.selectedBasin?.id;
      if (!basinId) throw new Error('No basin selected');
      eventData.eventtype = 'S'; // event S is for feed stock event
      await createFeedStockEvent(basinId, eventData);
      runInAction(() => {
        this.loadSiloEntries(); // reload entries to get N and P amounts correctly
      });
      return true; // success
    } catch (error) {
      console.log('Feed type post failed');
      toastStore.setToast('FeedTypeSaveFailed');
      return false; // fail
    }
  };

  updateFeedStockEvent = async (eventData: IFeedStockData) => {
    try {
      const basinId = basinStore.selectedBasin?.id;
      if (!basinId) throw new Error('No basin selected');
      const eventId = this.editedEntry?.id;
      if (!eventId) throw new Error('No event id available');
      await patchFeedStockEvent(basinId, eventId, eventData);
      runInAction(() => {
        this.loadSiloEntries(); // reload entries to get N and P amounts correctly
        this.editedEntry = null;
      });
      return true; // success
    } catch (error) {
      console.log('Feed type update failed');
      toastStore.setToast('FeedTypeSaveFailed');
      return false; // fail
    }
  };

  setEditedEntry = (entry: ISiloEntry | null) => {
    runInAction(() => {
      this.editedEntry = entry;
    });
  };
}

export default new SiloStore();
