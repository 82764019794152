/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect } from 'react';
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useStore } from 'stores/store-hooks';
import { dateAndTime } from 'translations/locales';
import {
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';

export const CommentsTab = observer(() => {
  const commentStore = useStore('commentStore');
  const { comments, draft, postComment, setDraft } = commentStore;

  useEffect(() => {
    commentStore.loadComments();
  }, [commentStore]);

  const theme = useTheme();
  const intl = useIntl();
  const topLevelItemCss = css`
    display: flex;
    flex-direction: column;
    margin-left: ${theme.spacing(2)}px;
    margin-right: ${theme.spacing(2)}px;
    padding-top: ${theme.spacing(1.3)}px;
    padding-bottom: ${theme.spacing(1.3)}px;
  `;
  const withTopBorderCss = css`
    ${topLevelItemCss};
    border-top: 1px solid ${theme.palette.grey[500]};
  `;

  return (
    <Box
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
        overflow: auto;
        margin-bottom: ${theme.spacing(2)}px;
      `}
    >
      <Box
        css={css`
          ${topLevelItemCss};
          padding-top: ${theme.spacing(2)}px;
        `}
      >
        <TextField
          multiline={true}
          onChange={(event) => setDraft(event.target.value)}
          placeholder={intl.formatMessage({ id: 'CommentPlaceholder' })}
          value={draft}
          variant="outlined"
        />
        <div>
          <Button
            color="primary"
            css={css`
              &.MuiButton-root {
                margin: ${theme.spacing(1)}px 0;
              }
            `}
            disabled={!draft}
            onClick={postComment}
            variant="outlined"
          >
            <Typography variant="h5">
              {intl.formatMessage({ id: 'PostComment' })}
            </Typography>
          </Button>
        </div>
      </Box>
      <Box css={topLevelItemCss}>
        <Typography variant="h6">
          {intl.formatMessage({ id: 'MostRecentComments' })}
        </Typography>
      </Box>
      {comments.map((comment) => (
        <Box css={withTopBorderCss}>
          <Box
            css={css`
              display: flex;
              flex-direction: row;
              align-items: baseline;
            `}
          >
            <Box
              css={css`
                margin-right: ${theme.spacing(2)}px;
              `}
            >
              <Typography variant="h5" color="primary">
                {comment.user.full_name || comment.user.username}
              </Typography>
            </Box>
            <Typography variant="body2">
              {dateAndTime(intl, new Date(comment.timestamp))}
            </Typography>
          </Box>
          <Typography>{comment.comment}</Typography>
        </Box>
      ))}
    </Box>
  );
});
