import { IconButton, Typography } from '@material-ui/core';
import { IntlShape } from 'react-intl';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Column } from 'react-table';
import { ISiloEntry } from 'models/silo';
import { formatDate } from 'translations/locales';

export const getColumns = (
  intl: IntlShape,
  toggleAlert: (entryId: number) => () => void,
  editSiloEvent: (siloEntry: ISiloEntry) => () => void,
  canEdit: boolean
) => {
  const baseColumns = [
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Date' })}
        </Typography>
      ),
      accessor: 'timestamp',
      Cell: ({ value }: { value: number }) => (
        <Typography>
          {value ? formatDate(intl, new Date(value)) : ''}
        </Typography>
      ),
    },
    {
      Header: (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'FeedType' })}
        </Typography>
      ),
      accessor: 'feedtype.name',
    },
    {
      Header: (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'AmountKg' })}
        </Typography>
      ),
      accessor: 'feederamount',
      Cell: ({ value }: { value: number }) => (
        <Typography>
          {value
            ? intl.formatNumber(value, {
                style: 'decimal',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              })
            : ''}
        </Typography>
      ),
    },
    {
      Header: (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Nitrogen' })}
        </Typography>
      ),
      accessor: 'nitrogen',
      Cell: ({ value }: { value: number }) => (
        <Typography>
          {value
            ? intl.formatNumber(value, {
                style: 'decimal',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            : ''}
        </Typography>
      ),
    },
    {
      Header: (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Phosphor' })}
        </Typography>
      ),
      accessor: 'phosphorus',
      Cell: ({ value }: { value: number }) => (
        <Typography>
          {value
            ? intl.formatNumber(value, {
                style: 'decimal',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            : ''}
        </Typography>
      ),
    },
  ] as Column[];

  const editControlColumns = [
    {
      Header: (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Edit' })}
        </Typography>
      ),
      id: 'edit',
      Cell: ({ cell, row }) => {
        if (row.id === '0' || !canEdit) return null; // don't render edit icon for summary row or if user has now edit access
        return (
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={editSiloEvent(row.original as ISiloEntry)}
            size="small"
          >
            <EditIcon />
          </IconButton>
        );
      },
    },
    {
      Header: (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Delete' })}
        </Typography>
      ),
      id: 'delete',
      disableSortBy: true,
      Cell: ({ cell, row }) => {
        if (row.id === '0' || !canEdit) return null; // don't render delete icon for summary row or if user has now edit access
        return (
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={toggleAlert((row.original as ISiloEntry).id)}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ] as Column[];

  if (canEdit) return [...baseColumns, ...editControlColumns];
  return baseColumns;
};
