/** @jsxRuntime classic */
/** @jsx jsx */
import { useState } from 'react';
import { css, jsx } from '@emotion/react';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { KeyboardTimePicker } from '@material-ui/pickers'

export const FeedTimePicker = ({
  hours = null,
  minutes = 0,
  seconds = 0,
  views,
  infoText = '',
  startTime = 0,
  onAccept,
  max = null,
}: {
  startTime?: number;
  hours?: number | null;
  minutes?: number;
  seconds?: number;
  views: ('hours' | 'minutes' | 'seconds')[];
  infoText?: string;
  onAccept: (date: { hours: number; minutes: number; seconds: number }) => void;
  max?: number | null;
}) => {
  const intl = useIntl();
  let updatedDate = new Date();
  if (startTime) {
    updatedDate = new Date(startTime);
  } else {
    if (hours) updatedDate.setUTCHours(hours);
    updatedDate.setUTCMinutes(minutes);
    updatedDate.setUTCSeconds(seconds);
  }

  const getHours = (date: Date | null) =>
    views.includes('hours') ? date?.getUTCHours() || 0 : 0;
  const getMinutes = (date: Date | null) =>
    views.includes('minutes') ? date?.getUTCMinutes() || 0 : 0;
  const getSeconds = (date: Date | null) =>
    views.includes('seconds') ? date?.getUTCSeconds() || 0 : 0;

  const getHoursOrMax = (date: Date | null) => {
    const hoursMax = max === null ? 23 : Math.floor(max / 3600);
    const hours = getHours(date);
    return hours > hoursMax ? hoursMax : hours;
  }

  const getMinutesOrMax = (date: Date | null) => {
    const hours = getHoursOrMax(date);
    const minutesMax =
      (max === null || max - hours * 3600 > 59 * 60)
        ? 59
        : Math.floor((max % 3600) / 60);
    const minutes = getHours(date) > hours ? 59 : getMinutes(date);
    return minutes > minutesMax ? minutesMax : minutes;
  };

  const getSecondsOrMax = (date: Date | null) => {
    const hours = getHoursOrMax(date);
    const minutes = getMinutesOrMax(date);
    const secondsMax =
      (max === null || max - hours * 3600 - minutes * 60 > 59)
        ? 59
        : Math.floor(max % 60);
    const seconds =
      getHours(date) > hours || getMinutes(date) > minutes
        ? 59
        : getSeconds(date);
    return seconds > secondsMax ? secondsMax : seconds;
  };

  const [inputHours, setInputHours] = useState(getHours(updatedDate));
  const [inputMinutes, setInputMinutes] = useState(getMinutes(updatedDate));
  const [inputSeconds, setInputSeconds] = useState(getSeconds(updatedDate));

  const onBlur = () => {
    const time = { hours: 0, minutes: 0, seconds: 0 };
    if (views.includes('hours')) time.hours = inputHours || 0;
    if (views.includes('minutes')) time.minutes = inputMinutes || 0;
    if (views.includes('seconds')) time.seconds = inputSeconds || 0;
    onAccept(time);
  };

  const displayDate = new Date();
  if (views.includes('hours')) displayDate.setUTCHours(inputHours);
  if (views.includes('minutes')) displayDate.setUTCMinutes(inputMinutes);
  if (views.includes('seconds')) displayDate.setUTCSeconds(inputSeconds);

  const formatStringBuilder = [];
  if (views.includes('hours')) formatStringBuilder.push("HH");
  if (views.includes('minutes')) formatStringBuilder.push("mm");
  if (views.includes('seconds')) formatStringBuilder.push("ss");
  const formatString = formatStringBuilder.join(":");

  const [dateValid, setDateValid] = useState(true);

  const onChange = (date: Date | null) => {
    if (date && !isNaN(date.valueOf())) {
      setInputHours(views.includes('hours') ? getHoursOrMax(date) : 0);
      setInputMinutes(views.includes('minutes') ? getMinutesOrMax(date) : 0);
      setInputSeconds(views.includes('seconds') ? getSecondsOrMax(date) : 0);
      setDateValid(true);
    } else {
      setDateValid(false);
    }
  };

  return (
    <Box display="flex" alignItems="flex-start">
      <Box css={css`width: 100px;`}>
        <KeyboardTimePicker
          ampm={false}
          autoOk
          error={!dateValid}
          format={formatString}
          helperText={
            !dateValid ? intl.formatMessage({ id: "feeder.error_5500" }) : null
          }
          keyboardIcon={<ScheduleIcon />}
          onAccept={(date) => {
            onChange(date);
            onAccept({
              hours: (views.includes('hours') ? getHoursOrMax(date) : 0),
              minutes: (views.includes('minutes') ? getMinutesOrMax(date) : 0),
              seconds: (views.includes('seconds') ? getSecondsOrMax(date) : 0),
            });
          }}
          onBlur={onBlur}
          onChange={onChange}
          value={displayDate}
          views={views}
        />
      </Box>
      &nbsp;
      <Box css={css`margin-top: 2px;`}>
        {infoText && (
          <Typography
            component="span"
            css={css`
              align-self: center;
            `}
          >
            {infoText}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
