import { FC } from 'react';
import { IEvent } from 'stores/events-store';
import { UserEvent } from './user-event';
import { GeneratedEvent } from './generated-event';

interface IListItem {
  event: IEvent;
}

export const EventListItem: FC<IListItem> = ({ event }) => {
  if (event.userevent) {
    return (
      <UserEvent
        text={event!.text}
        cssText={event.css}
        username={event?.values?.username}
        timestamp={event.timestamp}
      />
    );
  }

  if (!event.userevent) {
    return (
      <GeneratedEvent
        severity={event!.severity}
        timestamp={event.timestamp}
        eventType={event.eventtype}
        value={event.value}
        count={event.count}
        text={event.text}
        status={event.status}
        eventId={event.id}
        source={event.source}
        feedingDetails={event.feedingdetails}
      />
    );
  }

  return null;
};
