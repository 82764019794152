import {useParams} from "react-router-dom";
import React, {FC, useEffect} from "react";
import styled from "@emotion/styled";
import {useStore} from "../../../stores/store-hooks";
import {observer} from "mobx-react-lite";
import {IFishBasin} from "../../../models/fishbasin";
import {MultipleCameraView} from "../../multiple-camera-view/multiple-camera-view";

export const FacilityVideoView: FC = observer(() => {
  const id = parseInt(useParams<{ id: string }>().id);
  const {getFacilitysBasinsWithIot, loadSites} = useStore('siteStore');
  const facilityStore = useStore('facilityStore');
  const iotStore = useStore('iotStore');

  const facility = facilityStore.facilities.find(f => f.id === id);

  useEffect(() => {
    const initView = async () => {
      if (facilityStore.facilities.length === 0) {
        await facilityStore.loadFacilities();
      }
      await loadSites();
      getFacilitysBasinsWithIot(id).forEach((basin: IFishBasin) => {
        iotStore.loadDeviceInfo(basin);
      });
    };

    initView();

  }, [facilityStore, getFacilitysBasinsWithIot, id, iotStore, loadSites]);

  return (
    <Root>
      {facility && (
        <MultipleCameraView basins={getFacilitysBasinsWithIot(id)} header={facility.name || ''} showSite/>
      )}
    </Root>
  );
});

const Root = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 5px;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  margin-left: ${({ theme }) => theme.spacing(0)}px;
  margin-right: ${({ theme }) => theme.spacing(0)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
`;
