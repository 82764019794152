import { makeAutoObservable, runInAction } from 'mobx';

type MessageType = 'error' | 'success' | 'info' | 'warning';

class ToastStore {
  constructor() {
    makeAutoObservable(this);
  }

  messageId: string = '';
  type: MessageType = 'error';
  values: object | undefined;
  isOpen = false;
  showRefreshButton: boolean = false;

  setToast(messageId: string, type: MessageType = 'error', values?: object, showRefreshButton: boolean = false) {
    runInAction(() => {
      this.values = values;
      this.messageId = messageId;
      this.type = type;
      this.isOpen = true;
      this.showRefreshButton = showRefreshButton;
    });
  }
  clearToast = () => {
    this.isOpen = false;
    this.showRefreshButton = false;
  };
  refreshPage = () => {
    window.location.reload();
  };
}

export default new ToastStore();
