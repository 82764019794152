import {BaseVideoComponent, VideoContext} from "./base-video-component";
import {ForceFeedPopOver} from "./forcefeed-popover";
import {EmergencyStopPopover} from "./emergency-stop-popover";
import {CameraControlsPopover} from "./cameracontrols-popover";
import { useStore } from 'stores/store-hooks';
import {FC, useContext, useEffect, useState} from "react";
import {ICameraDetails} from "./props";
import {StopFeedingPopover} from "./stop-feeding-popover";

export const DetailedVideoComponent: FC<ICameraDetails> = (cameraDetails) => {
  const userStore = useStore('userStore');
  const iotStore = useStore('iotStore');
  const isCameraStreaming = useContext(VideoContext);
  const [isHw2, setHw2] = useState(false); //is device new BHI hardware

  useEffect(() => {
    const loadHwVersion = async () => {
      const info = await iotStore.loadDeviceInfoWithUnitId(cameraDetails.supportunit_id);
      info.data && info.data.version.includes('2') && setHw2(true);
    }
    loadHwVersion();
  }, [cameraDetails.supportunit_id, iotStore]);

  return (
    <BaseVideoComponent {...cameraDetails}>
      {userStore.canEdit && <ForceFeedPopOver movePopoverDown={!isCameraStreaming} />}
      {userStore.canEdit && <StopFeedingPopover />}
      {userStore.canEdit && <EmergencyStopPopover />}
      {userStore.canEdit && isHw2 && <CameraControlsPopover/>}
    </BaseVideoComponent>
  );
}
