import { DRAWER_WIDTH_MAX } from '../constants';
import { makeAutoObservable, runInAction } from 'mobx';

/**
 * Store for various generic user and UI settings that are used in multiple places e.g. side-menu width or side-menu open status.
 */

class SettingsStore {
  constructor() {
    makeAutoObservable(this);
  }

  drawerWidth: number = DRAWER_WIDTH_MAX; // default side-menu component drawer width
  drawerOpen: boolean | null = null; // is side menu drawer open or not

  setDrawerOpenStatus = (isOpen: boolean, width = DRAWER_WIDTH_MAX) => {
    runInAction(() => {
      this.drawerOpen = isOpen;
      this.drawerWidth = width;
    });
  };
}

export default new SettingsStore();
