/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import NotificationsIcon from '@material-ui/icons/Notifications';
import WarningIcon from '@material-ui/icons/Warning';
import { format } from 'date-fns';
import { useStore } from 'stores/store-hooks';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import eventsStore from 'stores/events-store';
import { IFeedingDetails } from 'models/iot-devices';
import { formatDate } from 'translations/locales';

export const GeneratedEvent: FC<{
  severity: string | undefined;
  eventType: string | undefined;
  timestamp: string;
  value: string | undefined;
  count: number | undefined;
  text: string | undefined;
  status: string | undefined;
  eventId: string | undefined;
  source: string | undefined;
  feedingDetails: IFeedingDetails | undefined;
}> = ({
  timestamp,
  severity,
  eventType,
  value,
  count,
  text,
  status,
  eventId,
  source,
  feedingDetails,
}) => {
  const theme = useTheme();
  const userStore = useStore('userStore');
  const { canEdit } = userStore;
  const intl = useIntl();
  const date = new Date(timestamp);
  const time = format(date, 'HH:mm');
  const formattedDate = formatDate(intl, date);

  const severityTranslationMap: Record<string, string> = {
    INFORMATION: 'InformationEvent',
    WARNING: 'WarningEvent',
    MINOR: 'MinorEvent',
    MAJOR: 'MajorEvent',
    CRITICAL: 'CriticalEvent',
  };

  const eventTypeTranslationMap: Record<string, string> = {
    c8y_SealAlarm_major: 'SealAlarmDescription',
    c8y_BoatAlarm_critical: 'BoatAlarmDescription',
    c8y_BatteryAlarm_major: 'BatteryWarningDescription',
    c8y_BatteryAlarm_critical: 'BatteryAlarmDescription',
    c8y_SiloAlarm_major: 'SiloWarningDescription',
    c8y_SiloAlarm_critical: 'SiloAlarmDescription',
    c8y_FeedingFailed_critical: 'FeedingFailedAlarmDescription',
    c8y_OxygenAlarm_major: 'OxygenWarningDescription',
    c8y_OxygenAlarm_critical: 'OxygenAlarmDescription',
    c8y_TemperatureAlarmLow_major: 'TemperatureWarningDescription',
    c8y_TemperatureAlarmLow_critical: 'TemperatureAlarmDescription',
    c8y_TemperatureAlarmHigh_major: 'TemperatureWarningDescription',
    c8y_TemperatureAlarmHigh_critical: 'TemperatureAlarmDescription',
    c8y_TemperatureChangeAlarm_critical: 'TemperatureChangeAlarmDescription',
    c8y_UnavailabilityAlarm: 'UnavailabilityAlarmDescription',
    c8y_FeedingStarted: 'FeedingStartedDescription',
    c8y_FeedingFinished: 'FeedingFinishedDescription',
    BATTERY: 'BatteryAlarmDescription',
    BATTERY_VOLTAGE: 'BatteryAlarmDescription',
    BOAT: 'BoatAlarmDescription',
    FEEDING: 'FeedingFailedAlarmDescription',
    MOTOR_FUSE: 'MotorFuseAlarmDescription',
    OXYGEN: 'OxygenAlarmDescription',
    SEAL: 'SealAlarmDescription',
    SILO: 'SiloAlarmDescription',
    TEMPERATURE: 'TemperatureAlarmDescription',
    UNAVAILABILITY: 'UnavailabilityAlarmDescription',
  };

  const getIcon = useCallback(
    (eventType: string | undefined) => {
      const iconStyle = (color: string) => css`
        min-width: 50px;
        min-height: 50px;
        color: ${color};
      `;
      if (eventType === 'K') {
        return (
          <TrackChangesIcon css={iconStyle(theme.palette.primary.light)} />
        );
      }
      if (severity === 'INFORMATION') {
        return (
          <NotificationsIcon css={iconStyle(theme.palette.primary.light)} />
        );
      }
      if (severity === 'WARNING') {
        return (
          <NotificationsIcon css={iconStyle(theme.palette.primary.light)} />
        );
      }
      if (severity === 'MINOR') {
        return <NotificationsIcon css={iconStyle('#FFFF00')} />;
      }
      if (severity === 'MAJOR') {
        return <WarningIcon css={iconStyle(theme.palette.warning.main)} />;
      }
      if (severity === 'CRITICAL') {
        return <WarningIcon css={iconStyle(theme.palette.error.main)} />;
      }
      return null;
    },
    [
      severity,
      theme.palette.error.main,
      theme.palette.primary.light,
      theme.palette.warning.main,
    ]
  );

  const feederEventParser = useCallback(
    (eventtype: string, details: IFeedingDetails | undefined) => {
      if (eventtype === 'c8y_FeedingFinished') {
        return intl.formatNumber((details?.amount || 0) / 1000, {
          style: 'decimal',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + ' ' + intl.formatMessage({ id: "UnitKg" });
      } else {
        return intl.formatMessage({
          id: details?.feedingType === 'scheduled' ? 'Scheduled' : 'ForceFeed'
        });
      }
    },
    [intl]
  );

  const severityTextId = severityTranslationMap[severity || ''];
  const eventTypeTextId = eventTypeTranslationMap[eventType || ''];

  return (
    <Box
      css={css`
        display: flex;
        background-color: ${theme.palette.grey[100]};
        margin-bottom: ${theme.spacing(3)}px;
      `}
    >
      <Box
        css={css`
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.12);
          min-width: 90px;
          min-height: 120px;
        `}
      >
        <Typography component="span" variant="overline">
          {formattedDate}
        </Typography>
        <Typography component="span" variant="overline">
          {time}
        </Typography>
        {getIcon(eventType)}
      </Box>
      <Box display="flex" flexDirection="column" flex="1 1 auto">
        <Box
          css={(theme) => css`
            border-radius: 4px;
            padding: ${theme.spacing(2)}px;
          `}
        >
          {severityTextId && (
            <Typography component="div" variant="body1" color="primary">
              {`${intl.formatMessage({ id: severityTextId })}`}
            </Typography>
          )}
          <Box
            css={css`
              max-width: 70%;
            `}
          >
            {eventTypeTextId && (
              <Typography component="div" variant="body1" color="primary">
                {`${intl.formatMessage({ id: eventTypeTextId })}`}
              </Typography>
            )}
            {value &&
              eventType !== 'c8y_FeedingFinished' &&
              eventType !== 'c8y_FeedingStarted' && (
                <Typography component="div" variant="body1" color="primary">
                  {`${intl.formatMessage({ id: 'Level' })}: ${intl.formatMessage({ id: value })}`}
                </Typography>
              )}
            {value &&
              (eventType === 'c8y_FeedingFinished' ||
                eventType === 'c8y_FeedingStarted') && (
                <Typography component="div" variant="body1" color="primary">
                  {`${feederEventParser(eventType, feedingDetails)}`}
                </Typography>
              )}
            {count && (
              <Typography component="div" variant="body1" color="primary">
                {`${intl.formatMessage({ id: 'AlarmCount' })}: ${count}`}
              </Typography>
            )}
            {text && (
              <Box
                css={css`
                  color: ${theme.palette.primary.main};
                  font-size: ${theme.typography.body1.fontSize};
                  font-family: ${theme.typography.body1.fontFamily};
                  line-height: ${theme.typography.body1.lineHeight};
                  font-weight: ${theme.typography.body1.fontWeight};
                `}
                dangerouslySetInnerHTML={{ __html: text || '' }}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" mr={2}>
        {canEdit &&
          eventType !== 'K' &&
          status !== 'CLEARED' &&
          severity !== 'WARNING' && (
            <Button
              variant="outlined"
              onClick={async () => {
                if (eventId) {
                  await eventsStore.clearAlarmEvent(
                    source,
                    eventId
                  );
                }
              }}
            >
              <Typography component="div" variant="h6" color="primary">
                {intl.formatMessage({ id: 'Clear' })}
              </Typography>
            </Button>
          )}
        {canEdit &&
          status === 'CLEARED' &&
          severity !== 'WARNING' &&
          severity !== 'INFORMATION' && (
            <Box display="flex" alignItems="center">
              <CheckCircleIcon
                fontSize="small"
                css={css`
                  color: ${theme.palette.success.main};
                  margin-right: 4px;
                `}
              />
              <Typography component="div" variant="h6" color="primary">
                {intl.formatMessage({ id: 'Cleared' })}
              </Typography>
            </Box>
          )}
      </Box>
    </Box>
  );
};
