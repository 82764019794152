import { IntlShape } from 'react-intl';
import {
  createDepthKeyLegend,
  parseDepthKeys,
  serializeEchartData,
} from 'components/basin/tabs/graphs/tools';
import { makeAutoObservable, runInAction } from 'mobx';
import { MEASUREMENT_TYPE } from 'models/iot-devices';
import {
  getBasinBatteryGraphs,
  getBasinGraphs,
  getIotMeasurements,
  getWaterTemperatureData,
  getOxygenValuesData, getBasinsGraphs,
} from 'services/api';
import { ChartOptions, IChartData } from '../models/chart-data';
import basinStore from './basin-store';
import {IFishBasin} from "../models/fishbasin";

class GraphStore {
  constructor() {
    makeAutoObservable(this);
  }
  biomassChartData: ChartOptions | undefined;
  averageWeightData: ChartOptions | undefined;
  feedAmountChartData: ChartOptions | undefined;
  feedPercentageChartData: ChartOptions | undefined;
  cumulativeFeedAmountChartData: ChartOptions | undefined;
  deviationFromRecommendationChartData: ChartOptions | undefined;
  oxygenChartData: ChartOptions | undefined;
  deviceTemperatureChartData: ChartOptions | undefined;
  siteTemperatureChartData: ChartOptions | undefined;
  siloLevelChartData: ChartOptions | undefined;
  batteryChartData: ChartOptions | undefined;

  lastUpdated = 0;
  oxygenChartLoading = false;
  deviceTemperaturesLoading = false;
  basinChartLoading = false;
  siteTemperatureLoading = false;
  siloLevelLoading = false;
  batteryChartLoading = false;

  resetLoadTimer = () => {
    this.lastUpdated = 0;
  };

  loadBasinsChartData = async (basins: IFishBasin[]) => {
    try {
      runInAction(() => {
        this.basinChartLoading = true;
      });
      if(basins.length === 0) throw new Error('No basins selected');
      const response = await getBasinsGraphs(basins.map(basin => basin.id));
      const { data } = response;
      runInAction(() => {
        this.biomassChartData = this.getChartOptions(data[0]);
        this.averageWeightData = this.getChartOptions(data[1]);
        this.feedAmountChartData = this.getChartOptions(data[2]);
        this.feedPercentageChartData = this.getChartOptions(data[3]);
        this.cumulativeFeedAmountChartData = this.getChartOptions(data[4]);
        this.lastUpdated = new Date().getTime();
        this.basinChartLoading = false;
      });
    } catch (error) {
      console.log('basin chart data basinChartLoading failed', error);
      runInAction(() => {
        this.basinChartLoading = false;
      });
    }
  }

  loadBasinChartData = async () => {
    try {
      // only update chart data, if 15s has passed since last data loads
      if (new Date().getTime() - this.lastUpdated < 15 * 1000) return;
      runInAction(() => {
        this.basinChartLoading = true;
      });
      const { selectedBasin } = basinStore;
      if (!selectedBasin?.id) throw new Error('No basin selected, id missing');
      const response = await getBasinGraphs(selectedBasin.id);
      const { data } = response;
      runInAction(() => {
        this.biomassChartData = this.getChartOptions(data[0]);
        this.averageWeightData = this.getChartOptions(data[1]);
        this.feedAmountChartData = this.getChartOptions(data[2]);
        this.feedPercentageChartData = this.getChartOptions(data[3]);
        this.cumulativeFeedAmountChartData = this.getChartOptions(data[4]);
        this.deviationFromRecommendationChartData = this.getChartOptions(data[5]);
        this.lastUpdated = new Date().getTime();
        this.basinChartLoading = false;
      });
    } catch (error) {
      console.log('basin chart data basinChartLoading failed', error);
      runInAction(() => {
        this.basinChartLoading = false;
      });
    }
  };

  loadSiloLevelChart = async () => {
    try {
      runInAction(() => {
        this.siloLevelLoading = true;
      });
      const deviceId = basinStore.selectedBasin?.supportunit_id;
      if (!deviceId) throw new Error('No device id specified');
      const lastTwoWeeks = new Date().getTime() - 14 * 24 * 60 * 60 * 1000;
      const maxResults = 3000;
      const { data } = await getIotMeasurements(
        deviceId,
        lastTwoWeeks,
        maxResults,
        MEASUREMENT_TYPE.SiloLevelSensor
      );
      runInAction(() => {
        const seriesData = data.map((dataPoint) => {
          return {
            name: dataPoint.name,
            value: [dataPoint.value[0], dataPoint.value[1], '%'],
          };
        });
        this.siloLevelChartData = {
          legend: { data: ['foo'] },
          series: [
            {
              name: '',
              type: 'line',
              data: seriesData,
            },
          ],
          yAxis: {
            name: '%',
            nameLocation: 'middle',
            nameGap: 70,
          },
          title: {
            text: '',
          },
        };
        this.siloLevelLoading = false;
      });
    } catch (error) {
      console.log('silo chart data loading failed', error);
      runInAction(() => {
        this.siloLevelLoading = false;
      });
    }
  };

  loadIoTDeviceTemperatures = async (intl: IntlShape) => {
    try {
      runInAction(() => {
        this.deviceTemperaturesLoading = true;
      });

      const deviceId = basinStore.selectedBasin?.site.mainunit_id;
      if (!deviceId) throw new Error('No device id specified');
      const lastTwoWeeks = new Date().getTime() - 14 * 24 * 60 * 60 * 1000;
      const maxResults = 3000;
      const { data: oxygenSensorData } = await getIotMeasurements(
        deviceId,
        lastTwoWeeks,
        maxResults,
        MEASUREMENT_TYPE.OxygenSensorTemp
      );
      const { data: mainUnitTemperatureData } = await getIotMeasurements(
        deviceId,
        lastTwoWeeks,
        maxResults,
        MEASUREMENT_TYPE.TemperatureSensor
      );
      const combined = [...oxygenSensorData, ...mainUnitTemperatureData];
      const keys = parseDepthKeys(combined);
      const serializedData = serializeEchartData(combined, keys, intl);
      const legend = createDepthKeyLegend(keys, intl);
      runInAction(() => {
        this.deviceTemperatureChartData = {
          legend,
          series: [...serializedData],
          yAxis: {
            name: '°C',
            nameLocation: 'middle',
            nameGap: 70,
          },
          title: {
            text: '',
          },
        };
        this.deviceTemperaturesLoading = false;
      });
    } catch (error) {
      console.log('iot device temperature data loading failed', error);
      runInAction(() => {
        this.deviceTemperaturesLoading = false;
      });
    }
  };

  loadSiteWaterTemperature = async () => {
    try {
      runInAction(() => {
        this.siteTemperatureLoading = true;
      });
      const siteId = basinStore.selectedBasin?.site.id;
      if (!siteId) throw new Error('No site id specified');
      const basinId = basinStore.selectedBasin?.id;
      if (!basinId) throw new Error('No basin id specified');
      const { data } = await getWaterTemperatureData(siteId, basinId);
      runInAction(() => {
        if (data.length) {
          this.siteTemperatureChartData = {
            legend: data[0].legend,
            series: data[0].series,
            yAxis: {
              name: '°C',
              nameLocation: 'middle',
              nameGap: 70,
            },
            title: {
              text: '',
            },
          };
        }
        this.siteTemperatureLoading = false;
      });
    } catch (error) {
      console.log('water temperature data loading failed', error);
      runInAction(() => {
        this.siteTemperatureLoading = false;
      });
    }
  };

  loadSiteOxygenValues = async (intl: IntlShape) => {
    try {
      runInAction(() => {
        this.oxygenChartLoading = true;
      });
      const siteId = basinStore.selectedBasin?.site.id;
      if (!siteId) throw new Error('No site id specified');
      const basinId = basinStore.selectedBasin?.id;
      if (!basinId) throw new Error('No basin id specified');
      const { data } = await getOxygenValuesData(siteId, basinId);
      runInAction(() => {
        if (data.length) {
          this.oxygenChartData = {
            legend: data[0].legend,
            series: data[0].series,
            yAxis: {
              name: intl.formatMessage({ id: "UnitMgPerLiter" }),
              nameLocation: 'middle',
              nameGap: 70,
            },
            title: {
              text: '',
            },
          };
        }
        this.oxygenChartLoading = false;
      });
    } catch (error) {
      console.log('site oxygen value data loading failed', error);
      runInAction(() => {
        this.oxygenChartLoading = false;
      });
    }
  };

  loadBatteryValues = async (intl: IntlShape) => {
    try {
      runInAction(() => {
        this.batteryChartLoading = true;
      });
      const basinId = basinStore.selectedBasin?.id;
      if (!basinId) throw new Error('No basin id specified');
      const { data } = await getBasinBatteryGraphs(basinId);
      runInAction(() => {
        if (data.length) {
          this.batteryChartData = {
            legend: data[0].legend,
            series: data[0].series,
            yAxis: {
              name: intl.formatMessage({ id: "UnitVolts" }),
              nameLocation: 'middle',
              nameGap: 70,
            },
            title: {
              text: '',
            },
          };
        }
        this.batteryChartLoading = false;
      });
    } catch (error) {
      console.log('basin battery value data loading failed', error);
      runInAction(() => {
        this.batteryChartLoading = false;
      });
    }
  };

  getChartOptions = (chartData: IChartData) => {
    const legend: Record<string, string[]> = { data: [] };
    chartData.graphs.forEach((graph) => {
      legend.data.push(graph.name);
    });
    return {
      legend,
      series: chartData.graphs,
      yAxis: {
        name: chartData.yAxis,
        nameLocation: 'middle',
        nameGap: 70,
      },
      title: {
        text: chartData.title,
      },
    };
  };

  clearChartData = () => {
    runInAction(() => {
      this.biomassChartData = undefined;
      this.averageWeightData = undefined;
      this.feedAmountChartData = undefined;
      this.feedPercentageChartData = undefined;
      this.cumulativeFeedAmountChartData = undefined;
      this.deviationFromRecommendationChartData = undefined;
      this.oxygenChartData = undefined;
      this.deviceTemperatureChartData = undefined;
      this.siteTemperatureChartData = undefined;
      this.siloLevelChartData = undefined;
      this.batteryChartData = undefined;
    });
  };
}

export default new GraphStore();
