/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Fragment, FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { AccessLevel, menuItems } from './menu-items';
import { Icon } from '@iconify/react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { useStore } from 'stores/store-hooks';
import { localizedLogo } from 'translations/locales';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { observer } from 'mobx-react-lite';
import logoSmall from '../../img/raisioaqua_kasvuluotain_logo_small.png';
import { DRAWER_WIDTH_MAX, DRAWER_WIDTH_MIN } from '../../constants';
import {HelpCenterText} from "./help-center-text";

const LogoAlt = styled.img`
  width: 200px;
  margin: 15px auto;
  display: block;
`;

const LogoAltSmall = styled.img`
  width: 50px;
  display: flex;
  justify-content: center;
  margin: 45px auto;
`;

export const SideMenuComponent: FC = observer(() => {
  const intl = useIntl();
  const { drawerOpen, setDrawerOpenStatus, drawerWidth } = useStore(
    'settingsStore'
  );
  const [selectedItem, setSelectedItem] = useState(0);
  const { canEdit, isRaisioAdminOrSuperuser, isConcernCompanyAdmin } = useStore('userStore');
  const location = useLocation();

  useEffect(() => {
    const selectedMenu = menuItems.find((item) =>
      location.pathname.includes(item.path)
    );
    if (selectedMenu) setSelectedItem(selectedMenu.id);
  }, [setSelectedItem, location.pathname]);

  const handleDrawerOpen = () => {
    setDrawerOpenStatus(true, DRAWER_WIDTH_MAX);
  };

  const handleDrawerClose = () => {
    setDrawerOpenStatus(false, DRAWER_WIDTH_MIN);
  };

  return (
    <Drawer
      PaperProps={{ elevation: 0 }}
      css={(theme) => css`
        width: ${drawerWidth}px;
        flex-shrink: 0;
        .MuiDrawer-paper {
          width: ${drawerWidth}px;
          background-color: ${theme.palette.primary.main};
          color: ${theme.palette.primary.contrastText};
          overflow-x: hidden;
          transition: ${theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          })};
        }
      `}
      variant="permanent"
      anchor="left"
    >
      <List>
        {drawerOpen ? (
          <LogoAlt src={localizedLogo(intl)} alt="logo" />
        ) : (
          <LogoAltSmall src={logoSmall} alt="logo" />
        )}
        {menuItems.map((menuItem) => {
          const isSelected = menuItem.id === selectedItem;
          if ((menuItem.accessLevel === AccessLevel.PROTECTED && !canEdit)
              || (menuItem.accessLevel === AccessLevel.RAISIO_ADMIN
                    && !isRaisioAdminOrSuperuser && !isConcernCompanyAdmin)) {
            return null;
          }
          return (
            <Fragment key={menuItem.id}>
              <ListItem
                button
                key={menuItem.id}
                component={Link}
                to={`${menuItem.path}`}
                selected={isSelected}
                onClick={() => setSelectedItem(menuItem.id)}
                css={css`
                  &.MuiListItem-gutters {
                    padding-left: 0;
                    padding-right: 0;
                    padding-left: ${drawerOpen ? '56px' : '24px'};
                  }
                `}
              >
                <ListItemText disableTypography>
                  <Box
                    css={(theme) => css`
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      > svg {
                        margin-right: ${theme.spacing(2)}px;
                      }
                    `}
                  >
                    {drawerOpen ? (
                      <Icon height={24} icon={menuItem.icon} />
                    ) : (
                      <Tooltip
                        title={intl.formatMessage({ id: menuItem.itemTextId })}
                        arrow
                      >
                        <div>
                          <Icon height={24} icon={menuItem.icon} />
                        </div>
                      </Tooltip>
                    )}
                    {drawerOpen && (
                      <Typography
                        variant="h5"
                        component="span"
                        css={css`
                          && {
                            font-weight: ${isSelected && '900'};
                          }
                        `}
                      >
                        {intl.formatMessage({ id: menuItem.itemTextId })}
                      </Typography>
                    )}
                  </Box>
                </ListItemText>
              </ListItem>
              {menuItem.divider && (
                <Divider
                  variant="middle"
                  css={(theme) => css`
                    &.MuiDivider-root {
                      margin: ${theme.spacing(1)}px;
                    }
                  `}
                />
              )}
            </Fragment>
          );
        })}
      </List>
      <Box
        css={css`
          display: flex;
          margin-top: auto;
          flex-direction: column;
        `}
      >
        {drawerOpen && <HelpCenterText/>}
        <Box
          css={css`
          display: flex;
          justify-items: flex-end;
          margin-top: auto;
          margin-left: auto;
          flex-direction: row;
        `}
        >
          <IconButton onClick={drawerOpen ? handleDrawerClose : handleDrawerOpen}>
            {!drawerOpen ? (
              <ChevronRightIcon
                css={(theme) => css`
                && {
                  color: ${theme.palette.common.white};
                  font-size: 3rem;
                }
              `}
              />
            ) : (
              <ChevronLeftIcon
                css={(theme) => css`
                && {
                  color: ${theme.palette.common.white};
                  font-size: 3rem;
                }
              `}
              />
            )}
          </IconButton>
        </Box>
      </Box>
    </Drawer>
  );
});
