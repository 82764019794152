import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage as M } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Modal, ModalActions, ModalContent } from 'components/modal-dialog';
import zxcvbn from 'zxcvbn';

interface IPasswordChangeDialog {
  handleClose: () => void;
  submitPassword: (password: string) => void;
}

const schema = yup.object().shape({
  password: yup
    .string()
    .test('password-strength', 'error.validation.passwordStrength', (value) =>
      value ? zxcvbn(value).score > 2 : true
    )
    .required('error.validation.password'),
});

export const PasswordChangeDialog = ({
  handleClose,
  submitPassword,
}: IPasswordChangeDialog) => {
  const { register, handleSubmit, errors, setError, clearErrors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  return (
    <Modal handleClose={handleClose} size="sm">
      <form
        noValidate
        onSubmit={handleSubmit((data) => {
          if (data.password && data.password === data.confirmPassword) {
            submitPassword(data?.password);
            handleClose();
            return;
          }
          setError('password', {
            type: 'server',
            message: 'error.validation.password.mismatch',
          });
        })}
      >
        <ModalContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography component="span" variant="subtitle2">
                <M id="ChangePassword" />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                name="password"
                margin="dense"
                id="change-password-dialog"
                label={<M id="Password" />}
                inputRef={register}
                type="password"
                error={!!errors.password}
                helperText={
                  errors.password && <M id={`${errors?.password?.message}`} />
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="confirmPassword"
                margin="dense"
                onChange={() => clearErrors()}
                id="change-password-dialog-confirm"
                label={<M id="ConfirmPassword" />}
                inputRef={register}
                type="password"
                fullWidth
              />
            </Grid>
          </Grid>
        </ModalContent>
        <ModalActions>
          <Button
            color="primary"
            type="submit"
            disabled={!!Object.keys(errors).length}
          >
            <M id="Change" />
          </Button>
          <Button onClick={handleClose} color="primary">
            <M id="Cancel" />
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};
