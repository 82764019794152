/** @jsxRuntime classic */
/** @jsx jsx */
import React, { FC, useEffect } from 'react';
import { css, jsx } from '@emotion/react';
import { useIntl } from 'react-intl';
import { useStore } from 'stores/store-hooks';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@material-ui/core';
import { GrowthFactorTableRow } from './growth-factor-table-row';
import { GrowthFactorTableLinkAndCopyButtons } from './link-and-copy-buttons';

const GrowthFactorTableHeaderItem: FC<{
  intlId: string;
  suffix?: string;
}> = ({ intlId, suffix }) => {
  const intl = useIntl();

  return (
    <TableCell component="th">
      <div css={css`display: flex`}>
        <Typography variant="h6">
          {intl.formatMessage({ id: intlId }) + (suffix || "")}
        </Typography>
      </div>
    </TableCell>
  );
}

export const GrowthFactorsTab = observer(() => {
  const intl = useIntl();
  const theme = useTheme();
  const growthFactorTableStore = useStore('growthFactorTableStore');
  const {
    currentEntries,
    originalEntries,
    editedRow,
    hasOriginal,
    setEditedRow,
    saveTable
  } = growthFactorTableStore;

  useEffect(() => {
    const loadData = async () => {
      await growthFactorTableStore.loadTable();
    };
    loadData();
  }, [growthFactorTableStore]);

  const rowData = currentEntries.map((c, i) => ({
    entry: c,
    originalGrowth: originalEntries[i].growth
  }));

  return (
    <Box display="flex" flex="1 1 100%" flexDirection="column" overflow="auto">
      <GrowthFactorTableLinkAndCopyButtons tableIsModified={hasOriginal} />
      <Box display="flex" flex="1 1 100%" flexDirection="column" m={2}>
        <TableContainer>
          <Table aria-label="user table" size="small">
            <TableHead>
              <TableRow style={{
                borderBottom: `2px solid ${theme.palette.primary.main}`
              }}>
                <GrowthFactorTableHeaderItem
                  intlId="Weight"
                  suffix={` (${intl.formatMessage({ id: "UnitGrams" })})`}
                />
                <GrowthFactorTableHeaderItem intlId="GrowthFactor" />
                <GrowthFactorTableHeaderItem intlId="Original" />
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.map((r) =>
                <GrowthFactorTableRow
                  key={r.entry.min}
                  data={r}
                  editedRow={editedRow}
                  setEditedRow={setEditedRow}
                  save={saveTable}
                />)}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
});
