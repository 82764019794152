import {css, keyframes} from "@emotion/react";
import AutorenewIcon from "@material-ui/icons/Autorenew";

export const LoadingIcon = () => {
  const spin = keyframes`
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  `;

  return (
    <AutorenewIcon
      css={(theme) => css`
                color: ${theme.palette.line2.main};
                animation: ${spin} 1s ease infinite;
              `}
    />
  );
}
