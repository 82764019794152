import { makeAutoObservable, runInAction } from 'mobx';
import { IFishBasinComment } from 'models/comments';
import { getFishBasinComments, postFishBasinComment } from 'services/api';
import basinStore from './basin-store';
import toastStore from './toast-store';

class CommentStore {
  constructor() {
    makeAutoObservable(this);
  }

  comments: IFishBasinComment[] = [];
  draft: string = "";

  loadComments = async () => {
    try {
      if (!basinStore.selectedBasin?.id) throw new Error('No basin selected');
      const { data } = await getFishBasinComments(basinStore.selectedBasin.id);
      runInAction(() => {
        this.comments = data;
      });
    } catch (error) {
      toastStore.setToast('CommentsLoadFailed');
    }
  };

  postComment = async () => {
    try {
      if (!basinStore.selectedBasin?.id) throw new Error('No basin selected');
      const { data } = await postFishBasinComment(
        basinStore.selectedBasin.id,
        this.draft
      );
      runInAction(() => {
        this.comments.unshift(data);
        this.draft = "";
      });
    } catch (error) {
      toastStore.setToast('CommentPostFailed');
      return false;
    }
  };

  setDraft = (draft: string) => {
    this.draft = draft;
  }
}

export default new CommentStore();
