import {useParams} from "react-router-dom";
import React, {FC, useEffect} from "react";
import styled from "@emotion/styled";
import {useStore} from "../../stores/store-hooks";
import {observer} from "mobx-react-lite";
import {MultipleCameraView} from "../multiple-camera-view/multiple-camera-view";

export const SiteView: FC = observer(() => {
  const id = parseInt(useParams<{ id: string }>().id);
  const {selectedSite, selectedSitesBasinsWithIot, loadSite} = useStore('siteStore');
  const iotStore = useStore('iotStore');

  useEffect(() => {
    const getBasinsForSite = async () => {
      if (!selectedSite || selectedSite.id !== id) {
        await loadSite(id);
      }
    }

    const loadDeviceInfos = async () => {
      selectedSitesBasinsWithIot.forEach(basin => {
        iotStore.loadDeviceInfo(basin);
      });
    }

    getBasinsForSite().then(loadDeviceInfos);
  }, [id, iotStore, loadSite, selectedSite, selectedSitesBasinsWithIot]);

  return (
    <Root>
      {selectedSite && (
        <MultipleCameraView basins={selectedSitesBasinsWithIot} header={selectedSite.name || ''}/>
      )}
    </Root>
  );
});

const Root = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 5px;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  margin-left: ${({ theme }) => theme.spacing(0)}px;
  margin-right: ${({ theme }) => theme.spacing(0)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
`;
