/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Box,
  ClickAwayListener,
  Grow,
  Input as MuiInput,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { ChangeEvent, FC, useState, useRef, useEffect } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { useIntl } from 'react-intl';
import { useStore } from 'stores/store-hooks';
import NestedMenuItem from 'material-ui-nested-menu-item';
import { MEDIA_BREAKPOINT_LG } from '../../../constants';

const Temperature = styled.div`
  display: flex;
  align-items: center;
  min-width: 120px;
  @media (max-width: ${MEDIA_BREAKPOINT_LG}px) {
    min-width: 85px;
  }
`;

const Input = styled(MuiInput)`
  width: 116px;
  @media (max-width: ${MEDIA_BREAKPOINT_LG}px) {
    width: 80px;
  }
  height: 48px;
  background: ${({ theme, disabled }) =>
    disabled ? '#F5F5F5' : theme.palette.common.white};
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? '#C7C7C7' : theme.palette.line2.main};
  box-sizing: border-box;
  border-radius: 7px;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme, disabled }) =>
    disabled ? '#909090' : theme.palette.primary.main};
  .MuiInputBase-input {
    font-size: ${({ theme }) => theme.typography.subtitle2.fontSize}px;
    @media (max-width: ${MEDIA_BREAKPOINT_LG}px) {
      font-size: 14px;
    }
    font-weight: ${({ theme }) => theme.typography.subtitle2.fontWeight};
    font-family: ${({ theme }) => theme.typography.subtitle2.fontFamily};
    text-align: center;
    margin-left: ${({ theme }) => theme.spacing(1)}px;
    color: ${({ theme, disabled }) =>
      disabled ? '#909090' : theme.palette.primary.main};
    -webkit-text-fill-color: ${({ theme, disabled }) =>
      disabled
        ? '#909090'
        : theme.palette.primary.main}; // this is needed for safari
  }
  .MuiInputAdornment-root {
    color: ${({ theme, disabled }) =>
      disabled ? '#909090' : theme.palette.primary.main};
  }
`;

interface ITemperatureSelection {
  siteId: number;
  temperature: number | string;
  iotDeviceId?: string;
  automaticTempEnabled: boolean;
  preferredDepth: string | undefined;
  setTemperature: (temperature: number | string) => void;
  handleTempChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const TemperatureSelection: FC<ITemperatureSelection> = ({
  siteId,
  iotDeviceId,
  automaticTempEnabled,
  temperature,
  preferredDepth,
  setTemperature,
  handleTempChange,
}) => {
  const spin = keyframes`
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  `;
  const intl = useIntl();
  const siteStore = useStore('siteStore');
  const userStore = useStore('userStore');
  const { canEdit } = userStore;
  const [temperatureDepths, setTemperatureDepths] = useState<string[]>([]);
  const [selectedDepth, setSelectedDepth] = useState(preferredDepth);
  const [open, setOpen] = useState(false);
  const [isAutomatic, setAutomatic] = useState(automaticTempEnabled);
  const anchorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadData = async () => {
      if (iotDeviceId && temperatureDepths.length === 0) {
        setTemperatureDepths(
          await siteStore.loadSensorDepthValues(iotDeviceId, 'temperature')
        );
      }
    }

    loadData();
  }, [iotDeviceId, siteStore, temperature, temperatureDepths.length]);

  const handleToggle = () => {
    if (!canEdit || !iotDeviceId) return;
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const updateLastTemperatureValue = async (depth: string) => {
    if (iotDeviceId) {
      const temperatureValue = await siteStore.getTemperatureForDepth(
        iotDeviceId,
        depth
      );
      if (temperatureValue) setTemperature(temperatureValue);
    }
  };

  const handleSelection = (
    depth: string,
    selection: 'automatic' | 'manual'
  ) => (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    if (selection === 'automatic') {
      setAutomatic(() => {
        siteStore.setAutoTemperatureUpdate(siteId, iotDeviceId, true, depth);
        updateLastTemperatureValue(depth);
        return true;
      });
    }
    if (selection === 'manual') {
      setAutomatic(() => {
        siteStore.setAutoTemperatureUpdate(siteId, iotDeviceId, false, depth);
        return false;
      });
    }
    setSelectedDepth(depth);
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  return (
    <Temperature>
      {isAutomatic ? (
          <AutorenewIcon
            css={(theme) => css`
            color: ${theme.palette.line2.main};
            animation: ${spin} 1s ease infinite;
          `}
          />
        ) : (
        <AutorenewIcon
          // This is just to align the divider nicely
          css={(theme) => css`
            opacity: 0;
          `}
        />
      )}
      <Input
        disableUnderline
        disabled={isAutomatic || !canEdit}
        endAdornment={
          <InputAdornment position="end">
            <Typography variant="subtitle2">&#176;C&nbsp;</Typography>
          </InputAdornment>
        }
        value={typeof temperature === "string"
          ? temperature
          : intl.formatNumber(Number(temperature), {
              style: "decimal",
              maximumFractionDigits: 2,
            })}
        onChange={handleTempChange}
      />
      <Box
        css={(theme) => css`
          width: 64px;
          margin-left: -32px;
          height: 48px;
          background-color: ${theme.palette.buttonbg.main};
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-right: ${theme.spacing(2)}px;
          cursor: pointer;
        `}
      >
        <div
          role="button"
          ref={anchorRef}
          onClick={handleToggle}
          css={(theme) => css`
            display: flex;
            align-items: baseline;
            color: ${theme.palette.primary.main};
          `}
        >
          <ExpandMoreIcon style={{ fontSize: '2rem' }} />
        </div>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          css={css`
            z-index: 1000;
          `}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={handleClose}
                  mouseEvent="onMouseDown"
                >
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-temperatures"
                    onKeyDown={handleListKeyDown}
                  >
                    {temperatureDepths.length === 0 ? (
                      <MenuItem onClick={handleSelection("-1", 'automatic')}>
                        {intl.formatMessage({
                          id: 'Automatic',
                        })}
                      </MenuItem>
                    ) : (
                      <NestedMenuItem
                        label={intl.formatMessage({
                          id: 'Automatic',
                        })}
                        parentMenuOpen
                      >
                        {temperatureDepths.map((depth) => (
                          <MenuItem
                            key={depth}
                            selected={depth === selectedDepth}
                            onClick={handleSelection(depth, 'automatic')}
                          >
                            {depth}m
                          </MenuItem>
                        ))}
                      </NestedMenuItem>
                    )}
                    <MenuItem onClick={handleSelection("-1", 'manual')}>
                      {intl.formatMessage({
                        id: 'Manual',
                      })}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Temperature>
  );
};
