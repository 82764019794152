import { FC } from 'react';
import Button from '@material-ui/core/Button';
import { INewSite, ISimpleSite } from 'models/site';
import { FormattedMessage as M } from 'react-intl';
import { Grid, TextField, Typography } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Modal, ModalActions, ModalContent } from 'components/modal-dialog';
import { useForm, UseFormMethods } from 'react-hook-form';
import { useStore } from 'stores/store-hooks';

interface ISiteAddDialog {
  handleClose: () => void;
  editedSite?: ISimpleSite | null;
  updateSite: (siteId: number | null, data: INewSite) => Promise<any>;
}

const nameValidator = yup
  .string()
  .required('error.validation.name')
  .max(256, 'error.validation.name');

const temperatureValidator = yup
  .number()
  .typeError('error.validation.temperature')
  .required('error.validation.temperature')
  .min(0, 'error.validation.temperature')
  .max(22, 'error.validation.temperature');

const schemaCreate = yup.object().shape({
  name: nameValidator,
  temperature: temperatureValidator,
});

const schemaEdit = yup.object().shape({
  name: nameValidator,
});

export const SiteDialog = ({
  editedSite,
  handleClose,
  updateSite,
}: ISiteAddDialog) => {
  const { register, handleSubmit, errors, clearErrors } = useForm<ISimpleSite>({
    resolver: yupResolver(editedSite ? schemaEdit : schemaCreate),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: editedSite || undefined,
  });
  const toastStore = useStore('toastStore');

  const submitData = async (data: INewSite) => {
    const { errors } = await updateSite(
      editedSite ? editedSite.id : null,
      data
    );
    if (errors) {
      // some other error -> show generic error note
      toastStore.setToast('validation.error.unexpected');
      return;
    }
    // success
    handleClose();
  };

  return (
    <Modal handleClose={handleClose}>
      <form noValidate onSubmit={handleSubmit(submitData)}>
        <ModalContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography component="span" variant="subtitle2">
                <M id={editedSite ? 'EditSite' : 'AddSite'} />
              </Typography>
            </Grid>
            <SiteNameRow register={register} errors={errors} />
            {!editedSite && (
              <SiteTemperatureRow register={register} errors={errors} />
            )}
          </Grid>
        </ModalContent>
        <ModalActions>
          <Button
            color="primary"
            type="submit"
            disabled={!!Object.keys(errors).length}
          >
            <M id="Save" />
          </Button>
          <Button
            onClick={() => {
              clearErrors();
              handleClose();
            }}
            color="primary"
          >
            <M id="Cancel" />
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};

/**
 * Sub components for various form fields
 */

interface IInputProps
  extends Partial<Pick<UseFormMethods, 'register' | 'errors' | 'control'>> {
  site?: ISimpleSite | null;
}

/**
 * Site name row component
 */
const SiteNameRow: FC<IInputProps> = ({ register, errors = {} }) => (
  <Grid item xs={12} id="site-dialog-name-row" key="site-dialog-name-row">
    <TextField
      name="name"
      id="site-add-dialog-name"
      label={<M id="SiteName" />}
      inputRef={register}
      error={!!errors.name}
      helperText={errors.name && <M id={`${errors.name.message}`} />}
      autoFocus
      margin="dense"
      fullWidth
    />
  </Grid>
);

/**
 * Site temperature row component
 */
const SiteTemperatureRow: FC<IInputProps> = ({ register, errors = {} }) => (
  <Grid
    item
    xs={12}
    id="site-dialog-temperature-row"
    key="site-dialog-temperature-row"
  >
    <TextField
      name="temperature"
      type="number"
      id="site-add-dialog-initial-temperature"
      label={<M id="InitialTemperature" />}
      inputRef={register}
      error={!!errors.temperature}
      helperText={
        errors.temperature && <M id={`${errors.temperature.message}`} />
      }
      margin="dense"
    />
  </Grid>
);
