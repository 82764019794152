/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FormattedMessage as M, useIntl } from 'react-intl';
import * as yup from 'yup';
import AddIcon from '@material-ui/icons/Add';
import { FC, Fragment } from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Fade,
  Grid,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  usePopupState,
  bindToggle,
  bindPopper,
  PopupState,
} from 'material-ui-popup-state/hooks';
import { Controller, useForm } from 'react-hook-form';
import { useStore } from 'stores/store-hooks';
import { observer } from 'mobx-react-lite';
import { Autocomplete } from '@material-ui/lab';

interface ICommentData {
  comment: string;
  basinId: number;
}

export const AddCommentPopover: FC = observer(() => {
  const theme = useTheme();
  const intl = useIntl();
  const dashboardStore = useStore('dashboardStore');
  const { filteredBasinNames } = dashboardStore;

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'addCommentPopper',
  });

  const postComment = async (data: ICommentData) => {
    dashboardStore.addNewComment(data.basinId, data.comment);
    popupState.close();
  };

  return (
    <Fragment>
      <Button {...bindToggle(popupState)}>
        <AddIcon
          css={css`
            color: ${theme.palette.primary.main};
          `}
        />
        <Typography variant="h6" color="primary" align="center">
          {intl.formatMessage({ id: 'AddComment' })}
        </Typography>
      </Button>
      <Popper {...bindPopper(popupState)} transition>
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={popupState.close}
            mouseEvent="onMouseDown"
          >
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <PopoverContent
                  popupState={popupState}
                  postComment={postComment}
                  basinNames={filteredBasinNames}
                />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Fragment>
  );
});

interface IContent {
  popupState: PopupState;
  postComment: (data: ICommentData) => void;
  basinNames: {
    name: string;
    id: number;
  }[];
}

const PopoverContent = ({ popupState, postComment, basinNames }: IContent) => {
  const theme = useTheme();
  const intl = useIntl();
  const schema = yup.object().shape({
    comment: yup
      .string()
      .max(200, 'error.validation.comment')
      .required('error.validation.comment'),
    basinId: yup.number().required('error.validation.comment'),
  });
  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      comment: '',
      basinId: null,
    },
  });

  return (
    <form noValidate onSubmit={handleSubmit(postComment)}>
      <Box p={1} pl={2} bgcolor={theme.palette.grey[200]}>
        <Typography component="div" variant="h4">
          <M id="NewComment" />
        </Typography>
      </Box>
      <Box p={2} width={650}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="basinId"
              control={control}
              render={({ onChange, onBlur, value, ref }) => (
                <Autocomplete
                  id="basin-id-selection"
                  onBlur={onBlur}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={(event, value) => {
                    if (value?.id) onChange(value.id);
                  }}
                  options={basinNames.map((basin) => ({
                    title: basin.name,
                    id: basin.id,
                  }))}
                  getOptionLabel={(option) => option.title}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={intl.formatMessage({
                        id: `SelectFishBasin`,
                      })}
                    />
                  )}
                />
              )}
            />
            <TextField
              name="comment"
              margin="dense"
              id="comment-text-field"
              multiline
              rowsMax={4}
              rows={1}
              label={<M id="Comment" />}
              inputRef={register}
              error={!!errors.comment}
              helperText={
                errors.comment && <M id={`${errors?.comment?.message}`} />
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              type="submit"
              disabled={!!Object.keys(errors).length}
            >
              <M id="Add" />
            </Button>
            <Button onClick={popupState.close} color="primary">
              <M id="Cancel" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
