/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  LinearProgress,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC, Fragment, useEffect } from 'react';
import { useStore } from 'stores/store-hooks';
import { EventListItem } from './event-list-item';
import { Virtuoso } from 'react-virtuoso';
import { useIntl } from 'react-intl';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import eventsStore from 'stores/events-store';
import { CommandEvent } from './command-event';

export type HistoryViewType = 'CommandHistory' | 'EventHistory';

interface IHistoryListView {
  type: HistoryViewType;
  hideReload?: boolean;
  hideFilters?: boolean;
}

export const HistoryListView: FC<IHistoryListView> = observer(
  ({ type, hideReload = false, hideFilters = false }) => {
    const intl = useIntl();
    const eventStore = useStore('eventStore');
    const userStore = useStore('userStore');
    const { selectedLanguage } = userStore;
    const { loading } = eventStore;
    const {
      filteredEvents: events,
      commands,
      hideGeneratedEvents,
      hideSensorEvents,
    } = eventStore;
    useEffect(() => {
      const loadEvents = async () => {
        type === 'CommandHistory'
          ? await eventStore.loadCommandHistory()
          : await eventStore.loadEvents();
      };
      loadEvents();
    }, [eventStore, type, selectedLanguage]); // language change will cause reload to get correct event text translation from the API

    return (
      <Box
        display="flex"
        flex="1 1 100%"
        flexDirection="column"
        overflow="auto"
        m={2}
      >
        {loading ? (
          <LinearProgress />
        ) : (
          <Fragment>
            {!hideFilters && (
              <Box mb={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hideGeneratedEvents}
                      onChange={eventsStore.toggleGeneratedEventHide}
                    />
                  }
                  label={intl.formatMessage({
                    id: 'HideAutomaticallyGeneratedEvents',
                  })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hideSensorEvents}
                      onChange={eventsStore.toggleSensorEventHide}
                    />
                  }
                  label={intl.formatMessage({
                    id: 'HideSensorGeneratedEvents',
                  })}
                />
              </Box>
            )}
            <Box display="flex">
              {!hideReload && (
                <Box mb={3}>
                  <Button
                    variant="outlined"
                    onClick={
                      type === 'CommandHistory'
                        ? eventStore.loadCommandHistory
                        : eventStore.loadEvents
                    }
                  >
                    <AutorenewIcon
                      css={(theme) => css`
                      color: ${theme.palette.line2.main};
                      margin-right: ${theme.spacing(1)}px;
                    `}
                    />
                    {intl.formatMessage({ id: 'ReloadEvents' })}
                  </Button>
                </Box>
              )}
              {userStore.canEdit && type !== 'CommandHistory' && (
                <Box mb={3}>
                  <Button
                    variant="outlined"
                    onClick={eventStore.clearAllAlarmEvents}
                  >
                    {intl.formatMessage({ id: 'ClearAll' })}
                  </Button>
                </Box>
              )}
            </Box>
            {type === 'EventHistory' && (
              <Virtuoso
                data={events}
                itemContent={(index, event) => {
                  return <EventListItem key={event.uuid} event={event} />;
                }}
              />
            )}
            {type === 'CommandHistory' && (
              <Virtuoso
                data={commands}
                itemContent={(index, command) => {
                  return <CommandEvent key={command.uuid} command={command} />;
                }}
              />
            )}
          </Fragment>
        )}
      </Box>
    );
  }
);
