import {FC, useEffect, useState} from 'react';
import ReactECharts from 'echarts-for-react';
import {
  temperatureConfig,
  temperatureOption,
  mainUnitOxygenConfig,
  mainUnitOxygenOption,
} from './chart-config';
import { useStore } from 'stores/store-hooks';
import { observer } from 'mobx-react-lite';
import {useIntl} from 'react-intl';
import {Box, ClickAwayListener, Fade, Paper, Typography} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import {DatePicker} from "@material-ui/pickers";
import Popper from "@material-ui/core/Popper";
import {bindPopper, bindToggle, usePopupState} from "material-ui-popup-state/hooks";
import {css} from "@emotion/react";

export const SiteGraphs: FC = observer(() => {
  const graphStore = useStore('graphStore');
  const {
    siteTemperatureChartData,
    siteTemperatureLoading,
    oxygenChartData,
    oxygenChartLoading,
  } = graphStore;

  const intl = useIntl();
  const [zoomStart, setZoomStart] = useState(0);
  const [zoomEnd, setZoomEnd] = useState(31465819000);

  useEffect(() => {
    graphStore.loadSiteWaterTemperature();
    graphStore.loadSiteOxygenValues(intl);
    graphStore.loadIoTDeviceTemperatures(intl);
  }, [graphStore, intl]);

  const setZoomValuesByDaysBefore = (daysBefore: number) => {

    // The year in the chart data is in 1970, to have the different years show up correctly
    const now = new Date();
    now.setDate(now.getDate() + 1);
    const endDate = new Date(1970, now.getMonth(), now.getDate());
    const startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - daysBefore);

    setZoomStart(startDate.getTime());
    setZoomEnd(endDate.getTime());
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1 1 100%"
      height="100%"
      width="100%"
    >
      <Box
        display="flex"
        style={{ alignItems: 'center', gap: '10px' }}
      >
        <Typography component="span" variant="h5">
          {intl.formatMessage({ id: 'IntervalQuickSelect' })}
        </Typography>
        <QuickSelectButton days={1} selectZoom={setZoomValuesByDaysBefore} />
        <QuickSelectButton days={3} selectZoom={setZoomValuesByDaysBefore} />
        <QuickSelectButton days={7} selectZoom={setZoomValuesByDaysBefore} />
        <QuickSelectButton days={30} selectZoom={setZoomValuesByDaysBefore} />
        <IntervalSelectPopoverButton setInterval={(start: Date, end: Date) => {
          setZoomStart(new Date(1970, start.getMonth(), start.getDate()).getTime());
          console.log("main:" + new Date(1970, start.getMonth(), start.getDate()).toISOString());
          setZoomEnd(new Date(1970, end.getMonth(), end.getDate()).getTime());
        }} />
      </Box>
      <Box ml={2} display="flex" flexDirection="column">
        <Typography align="center" component="span" variant="h3">
          {intl.formatMessage({ id: 'SiteTemperature' })}
        </Typography>
        <ReactECharts
          style={{ minHeight: '420px', width: '100%' }}
          showLoading={siteTemperatureLoading}
          option={{
            ...temperatureOption(intl, zoomStart, zoomEnd),
            ...siteTemperatureChartData,
          }}
          {...temperatureConfig}
        />
      </Box>
      <Box ml={2} mt={3} display="flex" flexDirection="column">
        <Typography align="center" component="span" variant="h3">
          {intl.formatMessage({ id: 'Oxygen' })}
        </Typography>
        <ReactECharts
          style={{ height: '420px', width: '100%' }}
          showLoading={oxygenChartLoading}
          option={{ ...mainUnitOxygenOption(intl, zoomStart, zoomEnd), ...oxygenChartData}}
          {...mainUnitOxygenConfig}
        />
      </Box>
    </Box>
  );
});

const QuickSelectButton: FC<{ days: number, selectZoom: (days: number) => void }> = ({ days, selectZoom }) => {
  const intl = useIntl();
  return (
    <Button variant="outlined" size="small" color="primary" onClick={() => selectZoom(days - 1)}>
      {days} {intl.formatMessage({ id: 'DayAbbreviation' })}
    </Button>
  );
}

const IntervalSelectPopoverButton: FC<{setInterval: (start: Date, end: Date) => void}> = ({setInterval}) => {

  const intl = useIntl();
  const [startDate, setStartDate] = useState<Date | null>(new Date(new Date().getFullYear(), 0, 1));
  console.log("popover:" + (startDate && startDate.toISOString()));
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'siteGraphIntervalPopper',
  });

  return (
    <>
      <Button variant="outlined" size="small" color="primary" {...bindToggle(popupState)}>
        {intl.formatMessage({ id: 'CustomInterval' })}
      </Button>
      <Box>
        <Popper
          {...bindPopper(popupState)}
          transition
        >
          {({ TransitionProps }) => (
            <ClickAwayListener
              onClickAway={popupState.close}
              mouseEvent="onMouseDown"
            >
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <Box
                    display="flex"
                    p={1}
                    pl={2}
                  >
                    <DatePicker
                      css={css`margin-left: 10px;`}
                      label={intl.formatMessage({ id: 'DateStart' })}
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                    <DatePicker
                      css={css`margin-left: 10px;`}
                      label={intl.formatMessage({ id: 'DateEnd' })}
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                    <Button
                      color="primary"
                      variant="outlined"
                      disabled={!startDate || !endDate}
                      onClick={() => {
                        if (!startDate || !endDate) return;
                        setInterval(startDate, endDate);
                        popupState.close();
                      }}
                    >
                      OK
                    </Button>
                  </Box>
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      </Box>
    </>
  );
}
