import { IMeasurementResult } from 'models/iot-devices';
import { IntlShape } from 'react-intl';

export const formatter = (intl: IntlShape,
                          unitId: string | null = null) => (params: any) => {
  let date = new Date(params[0].axisValue);
  const dateLabel = `${date.getDate()}.${
    date.getMonth() + 1
  }.${date.getFullYear()}  ${date.getHours()}:${
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  }`;
  let retval: any[] = [];
  params.forEach((param: any) => {
    retval.push(
      param.marker +
        ' ' +
        param.seriesName +
        ': ' +
        intl.formatNumber(param.value[1], {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) +
        ' ' +
        (unitId ? intl.formatMessage({ id: unitId || "" }) : param.value[2])
    );
  });
  return dateLabel + '<br>' + retval.join('<br>');
};

export const formatterNoYears = (intl: IntlShape,
                          unitId: string | null = null) => (params: any) => {
  let date = new Date(params[0].axisValue);
  const dateLabel = `${date.getDate()}.${
    date.getMonth() + 1
  }.    ${date.getHours()}:${
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  }`;
  let retval: any[] = [];
  params.forEach((param: any) => {
    retval.push(
      param.marker +
      ' ' +
      param.seriesName +
      ': ' +
      intl.formatNumber(param.value[1], {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) +
      ' ' +
      (unitId ? intl.formatMessage({ id: unitId || "" }) : param.value[2])
    );
  });
  return dateLabel + '<br>' + retval.join('<br>');
};

export const parseDepthKeys = (data: IMeasurementResult[]) => {
  return data
    .reduce((prev: string[], curr) => {
      if (prev.includes(curr.value[2])) {
        return prev;
      } else {
        return [...prev, curr.value[2]];
      }
    }, [])
    .sort((a, b) => parseInt(a) - parseInt(b));
};

export const serializeEchartData = (
  data: IMeasurementResult[],
  keys: string[],
  intl: IntlShape,
) => {
  return keys.map((key) => {
    return {
      data: data.filter((point) => key === point.value[2]),
      name: key + intl.formatMessage({ id: "UnitMeters" }),
      type: 'line',
    };
  });
};

export const createDepthKeyLegend = (keys: string[], intl: IntlShape) => {
  return {
    data: [...keys.map((k) => k + intl.formatMessage({ id: "UnitMeters" }))],
  };
};
