/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/react';
import { FormattedMessage as M, useIntl } from 'react-intl';
import { InlineIcon } from '@iconify/react';
import fastForwardLine from '@iconify-icons/clarity/fast-forward-line';
import { FC, useState } from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Fade,
  Grid,
  Paper,
  Typography,
  useTheme,
  Theme,
} from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import {
  usePopupState,
  bindToggle,
  bindPopper,
  PopupState,
} from 'material-ui-popup-state/hooks';
import { useStore } from 'stores/store-hooks';
import { observer } from 'mobx-react-lite';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { MEDIA_BREAKPOINT_SD } from '../../constants';
import { NumericInput } from 'components/input-fields';

export const ForceFeedPopOver: FC<{
  movePopoverDown: boolean;
}> = observer(({ movePopoverDown }) => {
  const iotStore = useStore('iotStore');
  const basinStore = useStore('basinStore');
  const toastStore = useStore('toastStore');

  const spin = keyframes`
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  `;

  const buttonTypographyStyle = (theme: Theme) => css`
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: ${theme.spacing(1)}px;
    > svg {
      margin-right: ${theme.spacing(1)}px;
    }
  `;

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'forcefeedPopper',
  });

  const updateData = async (forcefeedTime: number) => {
    let commandSent = false;
    if (basinStore.selectedBasin) {
      const response = await iotStore.startForceFeeding(
        basinStore.selectedBasin,
        forcefeedTime
      );
      if (response.status === 200) commandSent = true;
    }
    if (!commandSent) {
      toastStore.setToast('IotCommandSendingFailed');
    } else {
      await basinStore.addForceFeedingEvent(basinStore.selectedBasin, forcefeedTime);
    }
    popupState.close();
  };

  return (
    <Box display="flex" alignItems="center">
      <Button
        fullWidth
        color="primary"
        variant="contained"
        type="submit"
        {...bindToggle(popupState)}
        css={(theme) => css`
          &.MuiButton-root {
            position: absolute;
            top: ${theme.spacing(4)}px;
            left: ${theme.spacing(4)}px;
            box-shadow: 0px 3px 3px rgba(0, 88, 100, 0.15);
            background-color: ${theme.palette.background2.main};
            :hover {
              background-color: ${theme.palette.hover.main};
            }
            color: ${theme.palette.primary.main};
            width: 150px;
            @media (max-width: ${MEDIA_BREAKPOINT_SD}px) {
              width: 150px;
            }
            z-index: 1;
          }
        `}
      >
        <Typography component="span" variant="h5" css={buttonTypographyStyle}>
          {iotStore.hasPendingForceFeedCommand ? (
            <AutorenewIcon
              css={(theme) => css`
                color: ${theme.palette.line2.main};
                animation: ${spin} 1s ease infinite;
              `}
            />
          ) : (
            <InlineIcon icon={fastForwardLine} height={24} />
          )}
          <M id="ForceFeed" />
        </Typography>
      </Button>
      <Popper
        {...bindPopper(popupState)}
        css={movePopoverDown && css`z-index: 2; margin-top: 90px;`}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={popupState.close}
            mouseEvent="onMouseDown"
          >
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <PopoverContent
                  popupState={popupState}
                  updateData={updateData}
                />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
});

interface IContent {
  popupState: PopupState;
  updateData: (data: number) => void;
}

const PopoverContent = observer(({ popupState, updateData }: IContent) => {
  const theme = useTheme();
  const intl = useIntl();
  const iotStore = useStore('iotStore');

  const [timeMinutes, setTimeMinutes] = useState(0);
  const [timeSeconds, setTimeSeconds] = useState<number | null>(null);
  const forcefeedTime = ((timeMinutes * 60) + (timeSeconds || 0)) || 0;

  return (
    <Box>
      <Box display="flex" p={1} pl={2} bgcolor={theme.palette.grey[200]}>
        <Typography component="span" variant="h4">
          <M id="ForceFeed" />
        </Typography>
      </Box>
      <Box p={2} maxWidth={450}>
        <Grid container spacing={2}>
          <Grid container direction="column" item xs={12}>
            <Grid container direction="row" spacing={1} item xs={12}>
              <Grid item xs={6}>
                <NumericInput
                  label={intl.formatMessage({ id: "Minutes" })}
                  max={59}
                  onChange={setTimeMinutes}
                  value={timeMinutes}
                />
              </Grid>
              <Grid item xs={6}>
                <NumericInput
                  autoFocus
                  label={intl.formatMessage({ id: "SecondsPl" })}
                  max={59}
                  onChange={setTimeSeconds}
                  value={timeSeconds}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid spacing={1} item>
            <Typography variant="h6" component="span">
              {intl.formatMessage({ id: 'GramsOfFeed' })}
            </Typography>
            <Typography variant="h6" component="span" color="primary">
              {
                `: ${intl.formatNumber(
                  forcefeedTime * (iotStore.deviceCalibration || 0),
                  {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )} `
                + intl.formatMessage({ id: "UnitGrams" })
              }
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              disabled={
                isNaN(timeMinutes) || timeSeconds === null || isNaN(timeSeconds)
              }
              onClick={() => updateData(forcefeedTime)}
            >
              <M id="Save" />
            </Button>
            <Button onClick={popupState.close} color="primary">
              <M id="Cancel" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});
