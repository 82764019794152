import React, { FC } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Column, Row } from 'react-table';
import { IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { IOrderPackage } from 'models/orders';
import { KLTable } from 'components/tables';
import { isBulk } from './orders-utils';

export const getOrderPackageColumns = (
  intl: IntlShape,
  {
    editCartItem,
    removeFromCart,
  }: {
    editCartItem?: (item: IOrderPackage) => any;
    removeFromCart?: (orderPackage: IOrderPackage) => any;
  }
) => {
  const baseColumns = [
    {
      id: 'rowNumber',
      Cell: ({ row }: { row: Row }) =>
        row.id === '0' ? null : <Typography variant="h6">{row.id}</Typography>,
    },
    {
      Header: (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'ProductCode' })}
        </Typography>
      ),
      accessor: 'package.productCode',
    },
    {
      Header: (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'ProductName' })}
        </Typography>
      ),
      accessor: 'product.description',
      Cell: ({ value, row }: { value: string; row: Row }) =>
        // Show "Total" if this is the summary row
        row.id === '0' ? intl.formatMessage({ id: 'Total' }) : value || '',
    },
    {
      Header: (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'PackageName' })}
        </Typography>
      ),
      accessor: 'package.description',
    },
    {
      Header: (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Amount' })}
        </Typography>
      ),
      accessor: 'amount',
      Cell: ({ value, row }: { value: number; row: Row }) =>
        !value
          ? null
          : intl.formatNumber(value) +
            (isBulk(row.original as IOrderPackage)
              ? ' ' + intl.formatMessage({ id: "UnitKg" })
              : ''),
    },
    {
      Header: (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Weight' })}
        </Typography>
      ),
      accessor: 'package.weight',
      Cell: ({ value, row }: { value: number; row: Row }) => {
        const p = row.original as IOrderPackage;
        const fmtkg = (n: number) =>
          intl.formatNumber(n) + ' ' + intl.formatMessage({ id: "UnitKg" });
        if (row.id === '0') {
          return fmtkg(value);
        } else if (isBulk(p)) {
          return fmtkg(value * p.amount);
        } else {
          return (
            fmtkg(value * p.amount) +
            ' (' +
            intl.formatNumber(p.amount) +
            ' x ' +
            fmtkg(value) +
            ')'
          );
        }
      },
    },
  ] as Column<IOrderPackage>[];

  const cartColumns = [
    {
      id: 'edit',
      Cell: ({ row }: { row: Row }) =>
        row.id === '0' ? null : (
          <IconButton
            onClick={() => editCartItem!(row.original as IOrderPackage)}
            size="small"
          >
            <EditIcon />
          </IconButton>
        ),
    },
    {
      id: 'remove',
      Cell: ({ row }: { row: Row }) =>
        row.id === '0' ? null : (
          <IconButton
            onClick={() => removeFromCart!(row.original as IOrderPackage)}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        ),
    },
  ] as Column<IOrderPackage>[];

  if (removeFromCart && editCartItem) {
    return [...baseColumns, ...cartColumns];
  } else {
    return baseColumns;
  }
};

export const OrderPackageTable: FC<{
  actions?: {
    editCartItem?: (item: IOrderPackage) => any;
    removeFromCart?: (orderPackage: IOrderPackage) => any;
  };
  data: IOrderPackage[];
}> = ({ actions, data }) => {
  const intl = useIntl();

  const dataWithSummary = [
    {
      amount: 0,
      package: {
        id: 0,
        description: '',
        productCode: '',
        materialGroup: '',
        materialGroupDescription: '',
        weight: data.reduce(
          (n, p) => p.amount * (p.package?.weight || 0) + n,
          0
        ),
      },
    } as IOrderPackage,
  ].concat(data);

  return (
    <KLTable<IOrderPackage>
      columns={getOrderPackageColumns(intl, actions || {})}
      data={dataWithSummary}
      summaryRowCount={1}
      narrow
    />
  );
};
