/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useCallback } from 'react';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { IWidgetComponent } from 'models/widget';
import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import {Box, CircularProgress, Theme, Typography, useTheme} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { getColumns } from './company-list-columns';
import { Column } from 'react-table';
import { useStore } from 'stores/store-hooks';
import { useHistory } from 'react-router';
import { CompanyListTable } from './company-list-table';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(3)}px;
`;

export const CompanyListWidget: FC<IWidgetComponent> = observer(
  ({ widget }) => {
    const intl = useIntl();
    const history = useHistory();
    const dashboardStore = useStore('dashboardStore');
    const filterStore = useStore('filterStore');
    const theme: Theme = useTheme();

    const navigate = useCallback(
      (companyId: number) => {
        filterStore.setDataFilter(companyId, 'company');
        history.push(`/facilities/`);
      },
      [filterStore, history]
    );

    const { basinListLoading, getCompanyWidgetData } = dashboardStore;
    const columns = getColumns(intl, navigate, theme) as Column<any>[];

    return (
      <Root>
        <Box
          css={(theme) => css`
            display: flex;
            flex: 1 1 auto;
            width: 100%;
            margin-bottom: ${theme.spacing(1)}px;
          `}
        >
          <Typography
            variant="h4"
            color="primary"
            css={css`
              &.MuiTypography-h4 {
                font-weight: 700;
              }
            `}
          >
            {intl.formatMessage({ id: 'Companies' })}
          </Typography>
        </Box>
        <Box display="flex" flex="1 1 100%" width="100%" overflow="auto">
          {basinListLoading ? (
            <Box
              display="flex"
              flex="1 1 100%"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <CompanyListTable
              columns={columns}
              data={getCompanyWidgetData(intl)}
            />
          )}
        </Box>
      </Root>
    );
  }
);
