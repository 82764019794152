import { makeAutoObservable, runInAction } from 'mobx';
import { IFishBasin, ISimpleFishBasin } from 'models/fishbasin';
import { StoreState } from './store-state';
import {
  getArchivedFishBasins,
  deleteFishBasin,
  unarchiveFishBasin,
  IApiResponse,
} from 'services/api';

class ArchiveStore {
  constructor() {
    makeAutoObservable(this);
  }

  basins: ISimpleFishBasin[] = []; // archived basins
  state: StoreState = StoreState.pending;
  isLoading = false;
  basinToDelete: IFishBasin | null = null;
  basinToUnarchive: IFishBasin | null = null;

  setBasins = (basins: IFishBasin[]) => {
    runInAction(() => {
      this.basins = basins;
    });
  };

  // Loading all basins (that user has access to) and then getting the deleted (=archived ones)
  // is fine for avarage user, but slow for Raisio admins
  loadBasins = async () => {
    runInAction(() => {
      this.isLoading = true;
    });
    const response = await getArchivedFishBasins();
    const allBasins = response.data; //.data.filter((b) => b.deleted);
    this.setBasins(allBasins);
    runInAction(() => {
      this.isLoading = false;
    });
  };

  deleteBasin = async (basinId: number) => {
    const failedIds: number[] = [];
    try {
      await deleteFishBasin(basinId);
    } catch (error) {
      failedIds.push(basinId);
    }
    return failedIds;
  };

  unarchiveBasin = async (
    basinId: number,
    basin: IFishBasin
  ): Promise<IApiResponse<IFishBasin>> => {
    try {
      const response = await unarchiveFishBasin(basinId, basin);
      return {
        status: response?.status,
        data: response.data,
      };
    } catch (error) {
      return {
        errors: error?.response?.data,
        status: error?.response?.status,
        data: null,
      };
    }
  };

  getBasinsByCompanyId(companyId: number) {
    return this.basins.filter((b) => b.site.facility.company?.id === companyId);
  }

  companyArchiveNotEmpty(companyId: number): Boolean {
    return this.basins.some((b) => b.site.facility.company?.id === companyId);
  }
}

export default new ArchiveStore();
