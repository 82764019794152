/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FormattedMessage as M, useIntl } from 'react-intl';
import * as yup from 'yup';
import { ElementType, FC } from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Fade,
  Grid,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import pencilLine from '@iconify/icons-clarity/pencil-line';
import { Variant } from '@material-ui/core/styles/createTypography';
import { InlineIcon } from '@iconify/react';
import Popper from '@material-ui/core/Popper';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  usePopupState,
  bindToggle,
  bindPopper,
  PopupState,
} from 'material-ui-popup-state/hooks';
import { useForm } from 'react-hook-form';
import { useStore } from 'stores/store-hooks';

interface IEditableTextField {
  amount: string;
  onChange: (data: object) => void;
  component?: ElementType<any>;
  variant?: Variant;
  suffix?: string;
  inputProps?: object;
  color?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
}

interface IUpdateData {
  new_cumulative_amount: number;
}

export const CumulativeFeedAmountPopOver: FC<IEditableTextField> = ({
  amount,
  variant = 'h6',
  component = 'span',
  color,
  suffix = '',
  onChange,
}) => {
  const theme = useTheme();
  const userStore = useStore('userStore');
  const { canEdit } = userStore;
  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'fishAmountPopper',
  });

  const updateData = (data: IUpdateData) => {
    onChange({ new_cumulative_amount: data.new_cumulative_amount * 1000 }); // convert kg -> g
    popupState.close();
  };

  return (
    <Box display="flex" alignItems="center">
      {canEdit && (
        <Box
          color="primary"
          {...bindToggle(popupState)}
          css={css`
            display: flex;
            margin-right: 8px;
            cursor: pointer;
          `}
        >
          <InlineIcon
            height={16}
            icon={pencilLine}
            color={theme.palette.line2.main}
            hFlip
          />
        </Box>
      )}
      <Typography variant={variant} component={component} color={color}>
        {`${amount} ${suffix}`}
      </Typography>
      <Popper
        {...bindPopper(popupState)}
        transition
        css={css`
          z-index: 1; // ensure that popper is on top of video component in Firefox
        `}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={popupState.close}
            mouseEvent="onMouseDown"
          >
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <PopoverContent
                  popupState={popupState}
                  updateData={updateData}
                />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
};

interface IContent {
  popupState: PopupState;
  updateData: (data: IUpdateData) => void;
}

const PopoverContent = ({ popupState, updateData }: IContent) => {
  const theme = useTheme();
  const intl = useIntl();
  const { thousandSeparator } = useStore('userStore');
  const schema = yup.object().shape({
    new_cumulative_amount: yup
      .number()
      .transform((_, v) => Number(
        v.replace(thousandSeparator, '').replace(',', '.').replace(/\s/g, '')
      ))
      .min(0, 'error.validation.new_cumulative_amount')
      .max(2147483647, 'error.validation.new_cumulative_amount')
      .typeError('error.validation.new_cumulative_amount')
      .required('error.validation.new_cumulative_amount'),
  });
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  return (
    <form noValidate onSubmit={handleSubmit(updateData)}>
      <Box display="flex" p={1} pl={2} bgcolor={theme.palette.grey[200]}>
        <Typography component="span" variant="h4">
          <M id="ModifyCumulativeAmount" />
        </Typography>
      </Box>
      <Box p={3}>
        <Typography
          css={css`
            max-width: 350px;
          `}
        >
          {intl.formatMessage({ id: 'NewCumulativeAmountInfo' })}
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              name="new_cumulative_amount"
              margin="dense"
              id="edit-cumulative-feed-amount"
              label={<M id="NewCumulativeAmount" />}
              inputRef={register}
              error={!!errors.new_cumulative_amount}
              helperText={
                errors.new_cumulative_amount && (
                  <M id={`${errors?.new_cumulative_amount?.message}`} />
                )
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              type="submit"
              disabled={!!Object.keys(errors).length}
            >
              <M id="Save" />
            </Button>
            <Button onClick={popupState.close} color="primary">
              <M id="Cancel" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
