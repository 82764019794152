import React from 'react';
import {useIntl} from 'react-intl';
import {observer} from 'mobx-react-lite';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {DatePicker} from '@material-ui/pickers';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {format} from 'date-fns';
import {NumericInput} from 'components/input-fields';
import {useStore} from 'stores/store-hooks';
import {formatDate, localizedDuration} from 'translations/locales';
import {Modal, ModalActions, ModalContent} from 'components/modal-dialog';
import {HarvestingAction, IOngoingFast} from 'models/harvesting';
import {CleaningFields} from "./cleaning-fields";

export const CleaningDialog = observer(() => {
  const intl = useIntl();
  const {
    action,
    amountToFastOrHarvest,
    batchCompletelyGutted,
    clearAll,
    details,
    eventToEdit,
    fastOrHarvestAll,
    localCleaningResult,
    fastToHarvest,
    originalweight,
    performAction,
    setActionHarvest,
    setAmountToFastOrHarvest,
    setCleaningDate,
    setDetails,
    toggleBatchCompletelyGutted,
    calculateHarvestingValues,
    estimateLiveWeight,
  } = useStore('harvestingStore');

  const hidden = (action !== HarvestingAction.HARVEST && action !== HarvestingAction.CLEAR);
  const isEditing = eventToEdit >= 0;
  const clearRemaining: boolean = action === HarvestingAction.CLEAR;

  React.useEffect(() => {
    const handleEnterPress = (event: any) => {
      if(!isEditing && event.key === 'Enter') calculateHarvestingValues();
    }

    window.addEventListener('keydown', handleEnterPress);

    return () => {
      window.removeEventListener('keydown', handleEnterPress);
    };
  }, [calculateHarvestingValues, isEditing]);

  return hidden ? null : (
    <Modal handleClose={clearAll}>
      <ModalContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="span" variant="subtitle2">
              {isEditing && !clearRemaining && intl.formatMessage({ id: "HarvestingEditCleaningResults" })}
              {clearRemaining && intl.formatMessage({ id: "RemoveFishes" })}
              {!clearRemaining && !isEditing && intl.formatMessage({ id: "HarvestingFishCleaning" })}
            </Typography>
          </Grid>
          {isEditing ? null :
            <Grid item xs={12}>
              <Typography component="p" variant="body1">
                {clearRemaining ?
                  intl.formatMessage({ id: "RemovedFromFastWithoutHarvest" }) :
                  intl.formatMessage({ id: "HarvestingRecordCleaningResults" })
                }
              </Typography>
            </Grid>}
          {isEditing ? null :
            <Grid item xs={12} direction="column" container>
              <Grid container item>
                <Grid item xs={4}>
                  <Typography variant="h6">
                    {intl.formatMessage({ id: "HarvestingRemainingFish" })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    {intl.formatNumber(fastToHarvest?.biomass || 0, {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + " kg"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={4}>
                  <Typography variant="h6">
                    {intl.formatMessage({ id: "HarvestingFastLength" })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    {localizedDuration(
                      intl,
                      new Date(),
                      new Date(fastToHarvest?.timestamp || ""),
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>}
          {isEditing ? null :
            <Grid item xs={12}>
              <DatePicker
                disableToolbar
                fullWidth
                label={intl.formatMessage({ id: "Date" })}
                labelFunc={(date) => formatDate(intl, date || new Date())}
                onChange={(date) =>
                  setCleaningDate(format(date || new Date(), 'yyyy-MM-dd'))
                }
                value={localCleaningResult.cleaningdate}
                variant="inline"
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => {}}>
                      <CalendarTodayIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>}
          <Grid container alignItems="center" spacing={4} item xs={12}>
            {isEditing ? null :
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fastOrHarvestAll}
                      disabled={clearRemaining}
                      onChange={() => {
                        if (fastOrHarvestAll) {
                          // This also sets fastOrHarvestAll to false
                          setAmountToFastOrHarvest(amountToFastOrHarvest);
                        } else {
                          // sets fastOrHarvestAll to true
                          setActionHarvest(fastToHarvest as IOngoingFast);
                        }
                      }}
                    />
                  }
                  label={intl.formatMessage({ id: "All" })}
                />
              </Grid>}
            <Grid item xs={4}>
              <NumericInput
                disabled={fastOrHarvestAll || isEditing}
                label={intl.formatMessage({ id: "Amount" })}
                max={fastToHarvest?.amount || 1}
                onChange={setAmountToFastOrHarvest}
                value={amountToFastOrHarvest}
              />
            </Grid>
            {eventToEdit < 0 ? null :
              <Grid item xs={4}>
                <NumericInput
                  disabled={true}
                  label={intl.formatMessage({ id: "HarvestingStartBiomass" })}
                  value={Math.round(originalweight * 100) / 100}
                />
              </Grid>}
            {fastOrHarvestAll || isEditing
              ? null
              : <Grid item xs={12}>
                  <Slider
                    aria-label={intl.formatMessage({ id: "HarvestingSlider" })}
                    getAriaValueText={(v) =>
                      v + " " + intl.formatMessage({ id: "Pieces" })
                    }
                    min={0}
                    max={fastToHarvest?.amount || 1}
                    onChange={(e, v) => setAmountToFastOrHarvest(v as number)}
                    step={1}
                    value={amountToFastOrHarvest}
                  />
                </Grid>}
          </Grid>
          {isEditing || clearRemaining ? null : (
            <Grid item xs={12}>
              <Typography component="p" variant="body1">
                {intl.formatMessage(
                  { id: "HarvestingBiomassToHarvest" },
                  { amount: intl.formatNumber(
                      estimateLiveWeight,
                      {
                        style: "decimal",
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }
                  )},
                )}
              </Typography>
            </Grid>
          )}
          {!clearRemaining &&
            <CleaningFields isEditing={isEditing}/>
          }

          {isEditing ? null :
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={batchCompletelyGutted || fastOrHarvestAll}
                    disabled={fastOrHarvestAll}
                    onChange={toggleBatchCompletelyGutted}
                  />
                }
                label={intl.formatMessage({
                  id: "HarvestingBatchCompletelyGutted"
                })}
              />
            </Grid>}
          <Grid item xs={12}>
            <TextField
              aria-label={intl.formatMessage({ id: "AdditionalInformation" })}
              fullWidth={true}
              label={intl.formatMessage({ id: "AdditionalInformation" })}
              onChange={(event) => setDetails(event.target.value)}
              value={details || ""}
            />
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions>
        {isEditing || clearRemaining ? null : (
          <Tooltip title={intl.formatMessage({id: "HotkeyEnter"})}>
            <Button color="primary" onClick={() => calculateHarvestingValues()}>
              {intl.formatMessage({ id: "HarvestingEstimateValues" })}
            </Button>
          </Tooltip>
        )}
        <Button
          color="primary"
          disabled={
            amountToFastOrHarvest === 0
              || Number.isNaN(localCleaningResult.gut_weight)
              || Number.isNaN(localCleaningResult.gut_percentage)
              || Number.isNaN(localCleaningResult.roe_weight)
              || Number.isNaN(localCleaningResult.roe_percentage)
              || Number.isNaN(localCleaningResult.amount)
              || Number.isNaN(localCleaningResult.cleanedweight)
          }
          onClick={performAction}
        >
          {intl.formatMessage({ id: "Save" })}
        </Button>
        <Button color="primary" onClick={clearAll}>
          {intl.formatMessage({ id: "Cancel" })}
        </Button>
      </ModalActions>
    </Modal>
  );
});
