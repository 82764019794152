/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { jsx } from '@emotion/react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Box, Button } from '@material-ui/core';
import zxcvbn from 'zxcvbn';
import { useStore } from 'stores/store-hooks';
import { WhiteTextField } from './misc-components';

export const ResetPasswordComponent = observer(() => {
  const intl = useIntl();
  const params = new URLSearchParams(useLocation().search);
  const { confirmNewPassword } = useStore('passwordResetStore');
  const toastStore = useStore('toastStore');
  const history = useHistory();

  const [password, setPassword] = useState<string | null>(null);
  const [passwordAgain, setPasswordAgain] = useState<string | null>(null);

  const handleSubmit = async () => {
    if (!password) {
      return;
    }

    const res = await confirmNewPassword(params.get("token") || "", password);

    if (res) {
      toastStore.setToast("ResetPasswordResetComplete", "success");
      history.push("/login");
    }
  };

  const passwordTooWeak = zxcvbn(password || "").score <= 2;
  const passwordsMatch = password === passwordAgain;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding={6}
      height="100%"
    >
      <Box display="flex" flex="0 1 500px" flexDirection="column">
        <WhiteTextField
          error={password !== null && passwordTooWeak}
          helperText={
            password !== null && passwordTooWeak
              ? intl.formatMessage({
                id: "error.validation.passwordStrength"
              })
              : null
          }
          id="reset-password"
          label={intl.formatMessage({ id: "Password" })}
          onChange={(event) => setPassword(event.target.value)}
          type="password"
          value={password || ""}
          variant="outlined"
        />
        <WhiteTextField
          error={passwordAgain !== null && !passwordsMatch}
          helperText={
            passwordAgain !== null && !passwordsMatch
              ? intl.formatMessage({
                id: "error.validation.password.mismatch"
              })
              : null
          }
          id="confirm-password"
          label={intl.formatMessage({ id: "ConfirmPassword" })}
          onChange={(event) => setPasswordAgain(event.target.value)}
          type="password"
          value={passwordAgain || ""}
          variant="outlined"
        />
        <Button
          color="secondary"
          disabled={!password || !passwordsMatch || passwordTooWeak}
          onClick={handleSubmit}
          variant="contained"
        >
          {intl.formatMessage({ id: "ResetPasswordResetAction" })}
        </Button>
      </Box>
    </Box>
  );
});
