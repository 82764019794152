import { makeAutoObservable, runInAction } from 'mobx';
import {
  getOrders,
  getProducts,
  markOrderDelivered,
  postOrder,
} from 'services/api';
import { ICompany } from 'models/company';
import {
  DeliveryType,
  IOrder,
  IOrderPackage,
  IPackage,
  IProduct,
} from 'models/orders';
import companyStore from './company-store';
import toastStore from './toast-store';
import userStore from './user-store';

// Adds two business days to date
const add2days = (date: Date | string) => {
  date = new Date(date);
  const offset = date.getDay() >= 4 ? 8 - date.getDay() : 2;
  return new Date(date.getTime() + offset * 86400000);
}

class OrderStore {
  constructor() {
    makeAutoObservable(this);
  }

  cart: IOrderPackage[] = [];
  editedCartItem: IOrderPackage | null = null;
  products: IProduct[] = [];
  orders: IOrder[] = [];

  // Contact Info
  address1: string = "";
  businessId: string = "";
  city: string = "";
  company: ICompany | null = null;
  companyName: string = "";
  country: string = "fi";
  email: string | null = "";
  firstName: string | null = "";
  lastName: string | null = "";
  phone: string | null = "";
  zipCode: string = "";

  // Validation
  emailOkay: boolean = true;
  firstNameOkay: boolean = true;
  lastNameOkay: boolean = true;
  phoneOkay: boolean = true;

  // Delivery Info
  deliveryType: DeliveryType = DeliveryType.NOTHING;
  deliveryDateFrom: string = "";
  deliveryDateTo: string = "";
  pickupDate: string = "";
  additionalInformation: string = "";

  private editedCartItemOriginal: IOrderPackage | null = null;
  private packageToProductMap: Record<string, IProduct> = {};

  get productsOrdered() {
    return this.products.slice().sort((a, b) => a.description.localeCompare(b.description))
  }

  get canPlaceOrder() {
    return !!this.country && !!this.email && this.emailOkay && !!this.firstName
      && this.firstNameOkay && !!this.lastName && this.lastNameOkay
      && !!this.phone && this.phoneOkay && this.businessId && this.cart.length
      && ((this.deliveryType === DeliveryType.PICKUP && this.pickupDate)
          || (this.deliveryType === DeliveryType.DELIVERY && this.deliveryDateTo
             && this.deliveryDateFrom && this.address1 && this.city
             && this.zipCode)) && !!this.company;
  }

  get minDeliveryDateFrom() {
    return add2days(new Date()).toISOString();
  }

  get minDeliveryDateTo() {
    return add2days(
      this.deliveryDateFrom || this.minDeliveryDateFrom
    ).toISOString();
  }

  loadOrders = async () => {
    try {
      const { data } = await getOrders();
      runInAction(() => {
        this.processUpdatedOrders(data);
      });
    } catch (error) {
      toastStore.setToast("OrdersLoadFailed");
    }
  };

  loadProducts = async () => {
    try {
      const { data } = await getProducts();
      runInAction(() => {
        this.products = data;

        const packageToProductMap: Record<string, IProduct> = {};
        this.products.forEach((p) => {
          p.packages.forEach((pkg) => {
            packageToProductMap[pkg.productCode] = p;
          });
        });
        this.packageToProductMap = packageToProductMap;

        this.loadOrders();
      });
    } catch (error) {
      toastStore.setToast("ProductsLoadFailed");
    }
  };

  placeOrder = async () => {
    try {
      if (this.deliveryType === DeliveryType.NOTHING) {
        throw new Error("No delivery type selected");
      }
      if (!this.company) {
        throw new Error("No company defined");
      }
      if (!userStore.currentUser) {
        throw new Error("No current user set");
      }
      const { data } = await postOrder(
        this.company?.id,
        {
          address1: this.deliveryType === DeliveryType.DELIVERY
            ? this.address1
            : "",
          city: this.deliveryType === DeliveryType.DELIVERY ? this.city : "",
          businessId: this.businessId,
          company: this.companyName,
          country: this.country,
          email: this.email || "",
          firstName: this.firstName || "",
          lastName: this.lastName || "",
          language: userStore.currentUser?.language || "en",
          phone: this.phone || "",
          zipCode: this.deliveryType === DeliveryType.DELIVERY
            ? this.zipCode
            : "",
        },
        {
          type: this.deliveryType,
          deliveryDateFrom: this.deliveryType === DeliveryType.DELIVERY
            ? this.deliveryDateFrom
            : "",
          deliveryDateTo: this.deliveryType === DeliveryType.DELIVERY
            ? this.deliveryDateTo
            : "",
          pickupDate: this.deliveryType === DeliveryType.PICKUP
            ? this.pickupDate
            : "",
          additionalInformation: this.additionalInformation,
        },
        this.cart.map((p) => ({ id: p.package!.id, amount: p.amount })),
        userStore.currentUser.id
      );
      runInAction(() => {
        this.cart = [];
        this.resetContactAndDeliveryInfo();
        data.customerOrderPackages.forEach((pkg) => {
          pkg.product = this.packageToProductMap[
            pkg.package?.productCode || ""
          ];
        });
        this.orders.unshift(data);
        toastStore.setToast("PostOrderSucceeded", "success");
      });
    } catch (error) {
      toastStore.setToast("PostOrderFailed");
    }
  };

  markOrderDelivered = async (orderId: number) => {
    try {
      const { data } = await markOrderDelivered(orderId);
      runInAction(() => {
        this.processUpdatedOrders(data);
      });
    } catch (error) {
      toastStore.setToast("MarkOrderDeliveredFailed");
    }
  };

  processUpdatedOrders = (data: IOrder[]) => {
    data.forEach((order) => {
      order.customerOrderPackages.forEach((pkg) => {
        pkg.product = this.packageToProductMap[
          pkg.package?.productCode || ""
        ];
      });
    });
    data.reverse(); // Reverses array in place
    this.orders = data;
  };

  addEditedCartItemToCart = () => {
    if (!this.editedCartItem) {
      return;
    }

    const index = this.editedCartItemOriginal
      ? this.cart.indexOf(this.editedCartItemOriginal)
      : -1;
    if (index >= 0) {
      this.cart = this.cart.slice(0, index).concat([this.editedCartItem])
                                           .concat(this.cart.slice(index + 1));
    } else {
      this.cart = [this.editedCartItem].concat(this.cart);
    }

    this.editedCartItem = null;
    this.editedCartItemOriginal = null;
  };

  addToCart = (p: IOrderPackage) => {
    this.cart = [{
      product: p.product,
      package: p.package,
      amount: p.amount,
    } as IOrderPackage].concat(this.cart);
  };

  removeFromCart = (p: IOrderPackage) => {
    const index = this.cart.indexOf(p);
    if (index >= 0) {
      this.cart = this.cart.slice(0, index).concat(this.cart.slice(index + 1));
    }
  };

  editCartItem = (item: IOrderPackage | null) => {
    this.editedCartItem = !item ? null : {
      product: item.product,
      package: item.package,
      amount: item.amount,
    } as IOrderPackage;
    this.editedCartItemOriginal = item;
  };

  updateEditedCartItem = (data: {
    product?: IProduct | null;
    package?: IPackage | null;
    amount?: number;
  }) => {
    this.editedCartItem = {
      product: data.product || this.editedCartItem?.product,
      package: data.package || this.editedCartItem?.package,
      amount: data.amount === undefined
        ? (this.editedCartItem?.amount || 0)
        : data.amount,
    };
  };

  resetContactAndDeliveryInfo = () => {
    this.deliveryType = DeliveryType.NOTHING;
    this.deliveryDateFrom = this.minDeliveryDateFrom;
    this.deliveryDateTo = this.minDeliveryDateTo;
    this.pickupDate = this.minDeliveryDateFrom;
    this.additionalInformation = "";
    this.company = null;
    this.resetContactInfo();
  };

  resetContactInfo = () => {
    this.address1 = "";
    this.businessId = "";
    this.city = "";
    this.companyName = "";
    this.country = "fi"; // Default to Finland
    this.setEmail("");
    this.setFirstName("");
    this.setLastName("");
    this.setPhone("");
    this.zipCode = "";
    this.setDefaultContactAndDeliveryInfo();
  };

  // Sets _empty_ fields to their default values
  setDefaultContactAndDeliveryInfo = () => {
    this.setEmail(this.email || userStore.currentUser?.email || "");
    this.setFirstName(this.firstName || userStore.currentUser?.first_name ||"");
    this.setLastName(this.lastName || userStore.currentUser?.last_name || "");
    this.setPhone(this.phone || userStore.currentUser?.phone || "");

    // Non-RaisioAdmins have only one company
    if (!this.company && companyStore.companies.length === 1) {
      this.company = companyStore.companies[0];
    }

    this.businessId = this.company?.customerid || "";
    this.companyName = this.company?.name || "";
    this.country = this.company?.country || "fi";

    if (this.company?.route && this.company?.street_number && !this.address1) {
      this.address1 = this.company?.route + " " + this.company?.street_number;
    } else if (!this.address1) {
      this.address1 = this.company?.address || "";
    }

    this.city = this.city || this.company?.locality || "";
    this.phone = this.phone || this.company?.phonenumber || "";
    this.zipCode = this.zipCode || this.company?.postal_code ||"";
  };

  setAdditionalInformation = (info: string) => {
    this.additionalInformation = info;
  };

  setAddress1 = (address1: string) => {
    this.address1 = address1;
  };

  setCity = (city: string) => {
    this.city = city;
  };

  setCompany = (company: ICompany | null) => {
    this.company = company;
    this.resetContactInfo();
  };

  setDeliveryDateFrom = (date: string) => {
    this.deliveryDateFrom = date;
    if (new Date(this.deliveryDateTo) < new Date(this.minDeliveryDateTo)) {
      this.deliveryDateTo = this.minDeliveryDateTo;
    }
  };

  setDeliveryDateTo = (date: string) => {
    this.deliveryDateTo = date;
  };

  setDeliveryType = (type: DeliveryType) => {
    this.deliveryType = type;
  };

  setPickupDate = (date: string) => {
    this.pickupDate = date;
  };

  setEmail = (email: string) => {
    this.email = email;
    this.emailOkay = !!email.match(/[^@]+@[^@]+\.[^@.]+/);
  };

  setFirstName = (firstName: string) => {
    this.firstName = firstName;
    this.firstNameOkay = firstName.length <= 150;
  };

  setLastName = (lastName: string) => {
    this.lastName = lastName;
    this.lastNameOkay = lastName.length <= 150;
  };

  setPhone = (phone: string) => {
    this.phone = phone;
    this.phoneOkay = !!phone.match(
      /^$|^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/
    );
  };

  setZipCode = (zipCode: string) => {
    this.zipCode = zipCode;
  };

  setBusinessId = (businessId: string) => {
    this.businessId = businessId;
  }
}

export default new OrderStore();
