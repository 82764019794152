/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useMemo, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { RAISIO_COMPANY } from 'models/company';
import { UserModel } from 'models/user';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { UserTable } from './user-table';
import { Column } from 'react-table';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { UserEditDialog } from './user-edit-dialog';
import { AlertDialog } from 'components/alert-dialog/alert';
import { PasswordChangeDialog } from './password-change-dialog';
import { useStore } from 'stores/store-hooks';
import { USER_GROUP } from 'stores/user-store';

export const UserListPage = observer(() => {
  const companyStore = useStore('companyStore');
  const userStore = useStore('userStore');

  useEffect(() => {
    companyStore.loadCompanies();
  }, [companyStore]);

  const companyId =
    !userStore.isRaisioAdminOrSuperuser && companyStore.companies.length === 1
      ? companyStore.companies[0].id
      : null;
  const theme = useTheme();
  return (
    <Box
      css={css`
        overflow-x: auto;
        overflow-y: auto;
        height: 100%;
        width: 100%;
        margin: ${theme.spacing(2)}px 0;
        padding-right: ${theme.spacing(1)}px;
      `}
    >
      <Paper elevation={0}>
        <UserList companyId={companyId} />
      </Paper>
    </Box>
  );
});

export const UserList: FC<{
  companyId: number | null;
}> = observer(({ companyId = null }) => {
  const intl = useIntl();
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [
    passwordChangeDialogUser,
    setPasswordChangeDialogUser,
  ] = useState<UserModel | null>(null);
  const userStore = useStore('userStore');
  const {
    fetchAllUsers,
    fetchUsersByCompanyId,
    deleteUser,
    editDialogOpen,
    editedUserCompanyId,
    updatePassword,
  } = userStore;

  const toastStore = useStore('toastStore');
  const [users, setUsers] = useState<UserModel[]>([]);

  useEffect(() => {
    let componentIsMounted = true;

    // fetch users based on company id
    const loadUsers = async () => {
      const data = await (companyId === null
        ? fetchAllUsers()
        : fetchUsersByCompanyId(companyId));
      if (componentIsMounted) {
        setUsers(data);
      }
    };
    loadUsers();
    return () => {
      componentIsMounted = false;
    };
  }, [companyId, fetchAllUsers, fetchUsersByCompanyId]);

  const columns = useMemo(
    () =>
      [
        {
          Header: () => (
            <Typography variant="h6">
              {intl.formatMessage({ id: 'Username' })}
            </Typography>
          ),
          accessor: 'username',
          isSorted: true,
        },
        {
          Header: (
            <Typography variant="h6">
              {intl.formatMessage({ id: 'FirstName' })}
            </Typography>
          ),
          accessor: 'first_name',
        },
        {
          Header: (
            <Typography variant="h6">
              {intl.formatMessage({ id: 'LastName' })}
            </Typography>
          ),
          accessor: 'last_name',
        },
        {
          Header: (
            <Typography variant="h6">
              {intl.formatMessage({ id: 'Email' })}
            </Typography>
          ),
          accessor: 'email',
        },
        {
          Header: (
            <Typography variant="h6">
              {intl.formatMessage({ id: 'Language' })}
            </Typography>
          ),
          id: 'language',
          Cell: ({ cell }) => {
            const language = (cell.row.original as UserModel)?.language;
            return language ? language.toUpperCase() : '-';
          },
        },
        {
          Header: (
            <Typography variant="h6">
              {intl.formatMessage({ id: 'Role' })}
            </Typography>
          ),
          Cell: ({ cell }) => {
            const group = (cell.row.original as UserModel)?.group;
            return group ? intl.formatMessage({ id: USER_GROUP[group] }) : '-';
          },
          id: 'group',
        },
        {
          Header: (
            <Typography variant="h6">
              {intl.formatMessage({ id: 'Edit' })}
            </Typography>
          ),
          id: 'edit',
          Cell: ({ cell }) => (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => handleEdit(cell.row.original as UserModel)}
            >
              <EditIcon />
            </IconButton>
          ),
          disableSortBy: true,
        },
        {
          Header: (
            <Typography variant="h6">
              {intl.formatMessage({ id: 'Password' })}
            </Typography>
          ),
          id: 'password',
          disableSortBy: true,
          Cell: ({ cell }) => (
            <Button
              aria-label="edit"
              color="primary"
              onClick={() =>
                handleChangePassword(cell.row.original as UserModel)
              }
            >
              {intl.formatMessage({ id: 'Change' }).toLowerCase()}
            </Button>
          ),
        },
        {
          Header: (
            <Typography variant="h6">
              {intl.formatMessage({ id: 'Remove' })}
            </Typography>
          ),
          id: 'delete',
          disableSortBy: true,
          Cell: ({ cell }) => (
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDelete(cell.row.original as UserModel)}
            >
              <DeleteIcon />
            </IconButton>
          ),
        },
      ] as Column[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl]
  );

  const handleEdit = (user: UserModel) => {
    userStore.editedUser = user;
    userStore.setEditDialogOpen(true);
    userStore.setEditedUserCompanyId(
      user.company === null ? RAISIO_COMPANY.id : user.company
    );
  };

  const handleDelete = (user: UserModel) => {
    userStore.editedUser = user;
    setOpenDeleteAlert(true);
  };

  const removeUser = async () => {
    setOpenDeleteAlert(false);
    const { editedUser } = userStore;
    if (editedUser) {
      const result = await deleteUser(editedUser.id);
      if (result.length) {
        const errorIds = result.join(', ');
        toastStore.setToast('UserDeleteError', 'error', {
          errorIds,
        });
      } else {
        setUsers(users.filter((u) => u.id !== editedUser.id));
      }
    }
  };

  const handleChangePassword = (user: UserModel) => {
    setPasswordChangeDialogUser(user);
  };

  const handleUpdateUserData = async (user: UserModel): Promise<any> => {
    if (user) {
      if (userStore.editedUser) {
        const index = users.findIndex((u) => u.id === userStore.editedUser?.id);
        users[index] = user;
        setUsers([...users]); // spread here generates a new object reference for mobx
      } else {
        setUsers([user, ...users]);
      }
    }
  };

  return (
    <Box>
      <UserTable companyId={companyId} columns={columns as any} users={users} />
      {editDialogOpen &&
        (editedUserCompanyId === companyId || companyId === null) && (
          <UserEditDialog updateUserData={handleUpdateUserData} />
        )}
      {openDeleteAlert && (
        <AlertDialog
          isOpen={openDeleteAlert}
          setOpen={setOpenDeleteAlert}
          titleTextId="DeleteUser"
          primaryButtonTextId="Delete"
          primaryButtonCallback={removeUser}
        >
          {`${intl.formatMessage({ id: 'ConfirmDelete' })} ${
            userStore.editedUser?.username
          }?`}
        </AlertDialog>
      )}
      {passwordChangeDialogUser && (
        <PasswordChangeDialog
          submitPassword={
            (password) => updatePassword(passwordChangeDialogUser, password)
          }
          handleClose={() => setPasswordChangeDialogUser(null)}
        />
      )}
    </Box>
  );
});
