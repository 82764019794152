/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Box } from '@material-ui/core';
import { FeederSettings } from './feeder-settings';
import { FeederGadgets } from './feeder-gadgets';
import { useStore } from 'stores/store-hooks';
import { MEDIA_BREAKPOINT_LG } from '../../../../constants';
import {DetailedVideoComponent} from "../../../video/detailed-video-component";
export const FeederAutomation = () => {
  const userStore = useStore('userStore');
  const basinStore = useStore('basinStore');

  const { canEdit } = userStore;
  return (
    <Box
      css={css`
        display: flex;
        flex: 1 1 100%;
        flex-direction: row;
      `}
    >
      <Box
        css={css`
          display: flex;
          flex-direction: column;
          flex: 0 1 450px;
          @media (max-width: ${MEDIA_BREAKPOINT_LG}px) {
            flex: 0 1 350px;
          }
        `}
      >
        <FeederGadgets />
        {canEdit && <FeederSettings />}
      </Box>
      <DetailedVideoComponent {...basinStore.selectedBasin} optimizeVideo={false}/>
    </Box>
  );
};
