import React from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { DatePicker } from '@material-ui/pickers';
import { Icon } from '@iconify/react';
import deliveryIcon from '@iconify-icons/carbon/delivery';
import shoppingBag from '@iconify-icons/carbon/shopping-bag';
import { useStore } from 'stores/store-hooks';
import { formatDate } from 'translations/locales';
import { DeliveryType } from 'models/orders';

const PickupFields = observer(() => {
  const intl = useIntl();
  const { minDeliveryDateFrom, pickupDate, setPickupDate } = useStore(
    'orderStore'
  );

  return (
    <Grid item>
      <DatePicker
        autoOk
        disableToolbar
        fullWidth
        label={intl.formatMessage({ id: 'PickupDate' })}
        labelFunc={(date) => formatDate(intl, date || new Date())}
        minDate={minDeliveryDateFrom}
        onChange={(date) => setPickupDate(date?.toISOString() || '')}
        shouldDisableDate={(d) => d?.getDay() === 0 || d?.getDay() === 6}
        value={pickupDate}
        variant="inline"
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => {}}>
              <CalendarTodayIcon />
            </IconButton>
          ),
        }}
      />
    </Grid>
  );
});

const DeliveryFields = observer(() => {
  const intl = useIntl();
  const {
    address1,
    city,
    deliveryDateFrom,
    deliveryDateTo,
    minDeliveryDateFrom,
    minDeliveryDateTo,
    setAddress1,
    setCity,
    setDeliveryDateFrom,
    setDeliveryDateTo,
    setZipCode,
    zipCode,
  } = useStore('orderStore');

  return (
    <>
      <Grid item>
        <TextField
          aria-label={intl.formatMessage({ id: 'Address' })}
          fullWidth
          label={intl.formatMessage({ id: 'Address' })}
          onChange={(e) => setAddress1(e.target.value)}
          value={address1}
        />
      </Grid>
      <Grid container spacing={2} item>
        <Grid item xs={6}>
          <TextField
            aria-label={intl.formatMessage({ id: 'PostalCity' })}
            fullWidth
            label={intl.formatMessage({ id: 'PostalCity' })}
            onChange={(e) => setCity(e.target.value)}
            value={city}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            aria-label={intl.formatMessage({ id: 'ZipCode' })}
            fullWidth
            label={intl.formatMessage({ id: 'ZipCode' })}
            onChange={(e) => setZipCode(e.target.value)}
            value={zipCode}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} item>
        <Grid item xs={6}>
          <DatePicker
            autoOk
            disableToolbar
            fullWidth
            label={intl.formatMessage({ id: 'DeliveryFrom' })}
            labelFunc={(date) => formatDate(intl, date || new Date())}
            minDate={minDeliveryDateFrom}
            onChange={(d) => setDeliveryDateFrom(d?.toISOString() || '')}
            shouldDisableDate={(d) => d?.getDay() === 0 || d?.getDay() === 6}
            value={deliveryDateFrom}
            variant="inline"
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => {}}>
                  <CalendarTodayIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            autoOk
            disableToolbar
            fullWidth
            label={intl.formatMessage({ id: 'DeliveryTo' })}
            labelFunc={(date) => formatDate(intl, date || new Date())}
            minDate={minDeliveryDateTo}
            onChange={(d) => setDeliveryDateTo(d?.toISOString() || '')}
            shouldDisableDate={(d) => d?.getDay() === 0 || d?.getDay() === 6}
            value={deliveryDateTo}
            variant="inline"
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => {}}>
                  <CalendarTodayIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
});

export const DeliveryInfoInput = observer(() => {
  const intl = useIntl();
  const {
    additionalInformation,
    deliveryType,
    setAdditionalInformation,
    setDeliveryType,
  } = useStore('orderStore');

  return (
    <Grid container direction="column" spacing={2} item>
      <Grid item>
        <Typography color="primary" variant="h2">
          {intl.formatMessage({ id: 'DeliveryInfo' })}
        </Typography>
      </Grid>
      <Grid container spacing={2} item>
        <Grid item xs={6}>
          <Button
            color="primary"
            fullWidth
            onClick={() => setDeliveryType(DeliveryType.DELIVERY)}
            size="large"
            startIcon={<Icon icon={deliveryIcon} />}
            variant="outlined"
          >
            {intl.formatMessage({ id: 'Delivery' })}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="primary"
            fullWidth
            onClick={() => setDeliveryType(DeliveryType.PICKUP)}
            size="large"
            startIcon={<Icon icon={shoppingBag} />}
            variant="outlined"
          >
            {intl.formatMessage({ id: 'Pickup' })}
          </Button>
        </Grid>
      </Grid>
      {deliveryType === DeliveryType.DELIVERY ? <DeliveryFields /> : null}
      {deliveryType === DeliveryType.PICKUP ? <PickupFields /> : null}
      <Grid item>
        <TextField
          aria-label={intl.formatMessage({ id: 'AdditionalInformation' })}
          fullWidth
          label={intl.formatMessage({ id: 'AdditionalInformation' })}
          onChange={(e) => setAdditionalInformation(e.target.value)}
          value={additionalInformation}
        />
      </Grid>
    </Grid>
  );
});
