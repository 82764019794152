/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FormattedMessage as M, useIntl } from 'react-intl';
import { InlineIcon } from '@iconify/react';
import { FC, Fragment } from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Fade,
  Grid,
  Paper,
  Typography,
  useTheme,
  Theme,
} from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import {
  usePopupState,
  bindToggle,
  bindPopper,
  PopupState,
} from 'material-ui-popup-state/hooks';
import iotDeviceStore from 'stores/iot-device-store';
import basinStore from 'stores/basin-store';
import { MEDIA_BREAKPOINT_SD } from '../../constants';
import closeOutline from "@iconify-icons/carbon/close-outline";

interface IUpdateData {
  forcefeedAmount: number;
}

export const StopFeedingPopover: FC<any> = ({ onChange }) => {
  const buttonTypographyStyle = (theme: Theme) => css`
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: ${theme.spacing(1)}px;
    > svg {
      margin-right: ${theme.spacing(1)}px;
    }
  `;

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'emergencyStop¢Popper',
  });

  const updateData = (data: IUpdateData) => {
    popupState.close();
  };

  return (
    <Box display="flex" alignItems="center">
      <Button
        variant="contained"
        type="submit"
        {...bindToggle(popupState)}
        css={(theme) => css`
          &.MuiButton-root {
            position: absolute;
            background-color: ${theme.palette.background2.main};
            box-shadow: 0px 3px 3px rgba(0, 88, 100, 0.15);
            :hover {
              background-color: ${theme.palette.hover.main};
            }
            color: ${theme.palette.primary.main};
            top: ${theme.spacing(4)}px;
            left: ${`${theme.spacing(6) + 150}`}px;
            width: 200px;
            @media (max-width: ${MEDIA_BREAKPOINT_SD}px) {
              width: 150px;
              left: ${`${theme.spacing(6) + 150}`}px;
            }
            z-index: 1;
          }
        `}
      >
        <Typography component="span" variant="h5" css={buttonTypographyStyle}>
          <InlineIcon icon={closeOutline} height={20} style={{marginTop: 2, marginBottom: 2}} />
          <M id="StopFeeding" />
        </Typography>
      </Button>
      <Popper {...bindPopper(popupState)} transition>
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={popupState.close}
            mouseEvent="onMouseDown"
          >
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <PopoverContent
                  popupState={popupState}
                  updateData={updateData}
                />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
};

interface IContent {
  popupState: PopupState;
  updateData: (data: IUpdateData) => void;
}

const PopoverContent = ({ popupState, updateData }: IContent) => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <Fragment>
      <Box display="flex" p={1} pl={2} bgcolor={theme.palette.grey[200]}>
        <Typography component="span" variant="h4">
          <M id="StopFeeding" />
        </Typography>
      </Box>
      <Box p={2} maxWidth={450}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" component="span">
              {intl.formatMessage({ id: 'AreYouSure' })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              type="submit"
              onClick={() => {
                if (basinStore.selectedBasin) {
                  iotDeviceStore.emergencyStop(basinStore.selectedBasin);
                  setTimeout(() => iotDeviceStore.startFeeding(basinStore.selectedBasin), 3000);
                }
                popupState.close();
              }}
            >
              <M id="Yes" />
            </Button>
            <Button onClick={popupState.close} color="primary">
              <M id="No" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
