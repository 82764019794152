/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Box, CircularProgress, Fade, Typography } from '@material-ui/core';
import Hls from 'hls.js';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import {createContext, FC, useCallback, useEffect, useMemo, useState} from 'react';
import { useIntl } from 'react-intl';
import {POWER_STATE} from "../../models/fishbasin";
import iotDeviceStore from "../../stores/iot-device-store";
import {getCameraDetails, startCamera} from "../../services/api";
import {ICameraDetails} from "./props";

interface IVideoContext {
  isCameraStreaming: boolean;
}

export const VideoContext = createContext<IVideoContext>({isCameraStreaming: false});

export const BaseVideoComponent: FC<ICameraDetails> = (
  {optimizeVideo = false, video_camera_name, supportunit_id, video_stream_url, children}) => {

  const [loading, setLoading] = useState(true);
  const [isCameraStreaming, setCameraStreamingStatus] = useState(false);
  const [videoStreamUrl, setVideoStreamUrl] = useState<string | undefined>(undefined);
  const [videoNode, setVideoNode] = useState<any>(null);
  const hls = useMemo(() => {
    const config = {
      enableWorker: true,
      debug: false,
      manifestLoadingTimeOut: 10000,
      manifestLoadingMaxRetry: 3,
      manifestLoadingRetryDelay: 1000,
      manifestLoadingMaxRetryTimeout: 64000,
    };
    const optimizedConfig = {
      backBufferLength: 0,
      initialLiveManifestSize: 3,
      capLevelToPlayerSize: true
    };
    return new Hls(optimizeVideo ? {...config, ...optimizedConfig} : config);
  }, [optimizeVideo]);

  const videoRef = useCallback((node) => {
    // note: using ref callback here to prevent extra render and video re-init.
    // https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
    if (node !== null) {
      setVideoNode(node);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Hls.isSupported() && videoNode && videoStreamUrl) {
      hls.attachMedia(videoNode);
      hls.once(Hls.Events.MEDIA_ATTACHED, function () {
        hls.loadSource(videoStreamUrl);
        hls.attachMedia(videoNode);
        hls.once(Hls.Events.FRAG_PARSED, function (event, data) {
          // fist data fragment received from the stream, remove the loading indicator
          setCameraStreamingStatus(true);
        });
        hls.on(Hls.Events.ERROR, function (event, data) {
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                // try to recover network error, this happens for example when component reloads on tab change
                hls.loadSource(videoStreamUrl);
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                hls.recoverMediaError();
                break;
              default:
                // cannot recover
                hls.destroy();
                break;
            }
          }
        });
        hls.once(Hls.Events.MANIFEST_PARSED, function (event, data) {
          const playPromise = videoNode.play();
          if (playPromise !== null) {
            playPromise.catch(() => {
              /* discard runtime error */
            });
          }
        });
      });
    }
    return () => {
      if (videoStreamUrl && videoNode) {
        hls.destroy();
      }
    };
  }, [hls, videoNode, videoStreamUrl]);

  useEffect(() => {

    const startCameraIfNeeded = async () => {
      const deviceInfo = iotDeviceStore.getDeviceInfo(supportunit_id);
      const power_source = deviceInfo?.deviceConfiguration?.power_source;

      // start camera if power state is undefined or BATTERY
      if (power_source !== POWER_STATE.POWER_SOURCE_MAINS && supportunit_id) {
        try {
          await startCamera(supportunit_id, 600); // start camera for 600s
        } catch (error) {
          console.log('Camera start command failed', error);
        }
      }
    }

    const setupVideoStreamUrl = async () => {
      try {
        if (!video_camera_name) throw new Error('No camera name available');
        const quality = optimizeVideo ? 'low' : 'high';
        const { data } = await getCameraDetails(video_camera_name, 'NA', quality);
        // delay to alloW BHI media server some time to start the video
        await new Promise((resolve) => setTimeout(resolve, 4000));
        const url = data.requested;
        if (!url) throw new Error('No camera URL available');
        setVideoStreamUrl(decodeURIComponent(url))
      } catch (error) {
        console.log('Camera URL fetching failed', error);
        return null;
      }
    };

    const closeCameraStream = async () => {
      try {
        if (!video_camera_name) throw new Error('No camera name available');
        setVideoStreamUrl(undefined);
      } catch (error) {
        console.log('Camera URL close failed', error);
        return null;
      }
    };

    const loadInitialData = async () => {
      if (video_camera_name) {
        // get video stream name from external API, if camera name has been specified
        await startCameraIfNeeded();
        await setupVideoStreamUrl();
      }
    };

    loadInitialData().then(() => setLoading(false));

    return () => {
      closeCameraStream();
    };
  }, [optimizeVideo, supportunit_id, video_camera_name]);

  if (loading) {
    return (
      <Box
        css={(theme) => css`
        flex: 1 1 auto;
        border-radius: 5px;
        margin: ${theme.spacing(2)}px;
        background-color: ${theme.palette.grey[100]};
        align-items: center;
        justify-content: center;
        display: flex;
        height: calc(100% - ${theme.spacing(4)}px);
      `}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Box
      css={(theme) => css`
        flex: 1 1 100%;
        position: relative;
        height: 100%;
      `}
    >
      <VideoContext.Provider value={{isCameraStreaming: isCameraStreaming}}>
        {!!children && children}
      </VideoContext.Provider>
      {videoStreamUrl && (
        <CameraStartProgress isCameraStreaming={isCameraStreaming} />
      )}
      {videoStreamUrl ? (
        <Box
          css={(theme) => css`
            flex: 1 1 auto;
            border-radius: 5px;
            margin: ${theme.spacing(2)}px;
            background-color: ${theme.palette.grey[100]};
            align-items: center;
            justify-content: center;
            display: flex;
            height: calc(100% - ${theme.spacing(4)}px);
            > video {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 5px;
              :focus {
                outline: none;
              }
            }
          `}
        >
          <video
            preload="none"
            ref={videoRef}
            controls
            muted
            autoPlay={false}
            controlsList=""
          />
        </Box>
      ) : <VideoComponentOff/>}
    </Box>
  );
};

const VideoComponentOff: FC = () => {
  return (
    <Box
      css={(theme) => css`
            flex: 1 1 auto;
            border-radius: 5px;
            margin: ${theme.spacing(2)}px;
            background-color: ${theme.palette.grey[100]};
            align-items: center;
            justify-content: center;
            display: flex;
            height: calc(100% - ${theme.spacing(4)}px);
          `}
    >
      <VideocamOffIcon
        css={(theme) => css`
                &.MuiSvgIcon-root {
                  color: ${theme.palette.grey[300]};
                  font-size: 240px;
                }
              `}
      />
    </Box>
  );
}

const CameraStartProgress: FC<{
  isCameraStreaming: boolean;
}> = ({ isCameraStreaming }) => {
  const intl = useIntl();
  return (
    <Fade
      in={!isCameraStreaming}
      style={{
        transitionDelay: '3000ms',
      }}
      unmountOnExit
    >
      <Box
        display="flex"
        alignItems="center"
        css={(theme) => css`
          position: absolute;
          background-color: ${theme.palette.common.white};
          box-shadow: 0px 3px 3px rgba(0, 88, 100, 0.15);
          top: ${theme.spacing(10)}px;
          left: ${`${theme.spacing(4)}`}px;
          z-index: 1;
          border-radius: 4px;
          padding: ${theme.spacing(2)}px;
        `}
      >
        <Typography
          component="span"
          variant="h5"
          css={(theme) => css`
            &.MuiTypography-root {
              margin-right: ${theme.spacing(1)}px;
            }
          `}
        >
          {intl.formatMessage({ id: 'CameraStartDelay' })}
        </Typography>
        <CircularProgress size={36} />
      </Box>
    </Fade>
  );
};
