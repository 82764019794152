/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FC, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import styled from '@emotion/styled';
import { FormattedMessage as M, useIntl } from 'react-intl';
import { IFishBasin, IBasinStartValues } from 'models/fishbasin';
import { BasinArchiveDialog } from 'components/archive-dialog/basin-archive-dialog';
import { useStore } from 'stores/store-hooks';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { AlertDialog } from 'components/alert-dialog/alert';

interface IBasinDetail {
  basin: IFishBasin;
}

export const BasinActionsFooter: FC<IBasinDetail> = ({ basin }) => {
  const basinStore = useStore('basinStore');
  const archiveStore = useStore('archiveStore');
  const toastStore = useStore('toastStore');
  const userStore = useStore('userStore');
  const { isRaisioAdminOrSuperuser, isCompanyAdmin } = userStore;
  const history = useHistory();
  const intl = useIntl();
  const [basinArchiveDialog, setBasinArchiveDialog] = useState<{
    visible: boolean;
    basinToArchive: IFishBasin;
  }>({
    visible: false,
    basinToArchive: basin,
  });
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);

  const updateArchivedBasin = async (
    copy: boolean,
    startValues: IBasinStartValues
  ) => {
    const { basinToArchive } = basinArchiveDialog;
    const response = copy
      ? await basinStore.archiveAndCopy(basinToArchive.id, startValues) // copy for new season
      : await basinStore.archiveBasin(basinToArchive.id); // archive without copying
    if (response.errors !== null && response.data) {
      basinStore.setSelectedBasin(response.data);
    }
    return response;
  };

  const removeBasin = async () => {
    setOpenDeleteAlert(false);
    const basinToDelete = basin;
    const result = await archiveStore.deleteBasin(basinToDelete.id);
    if (result.length) {
      const errorIds = result.join(', ');
      toastStore.setToast('ArchivedBasinDeleteError', 'error', {
        errorIds,
      });
    } else {
      // On success, redirect to archive and show alert that basin was deleted
      history.replace('/archive'); // Use replace to prevent navigating back to deleted basin
      toastStore.setToast('DeleteBasinSuccess', 'success', {
        name: basinToDelete.name,
      });
    }
  };

  const loadPreviousSeason = async () => {
    if (basin.ancestors) {
      const prevAncestor = Math.max(...basin.ancestors);
      basinStore.setSelectedBasin(null); // clear previous selected basin
      history.push(`/basin/${prevAncestor}`);
    }
  };

  return (
    <Box
      css={(theme) => css`
        display: flex;
        padding: ${theme.spacing(1)}px;
      `}
    >
      <Spacer />
      <Box>
        {(isCompanyAdmin || isRaisioAdminOrSuperuser) && !basin.deleted && (
          <Button
            onClick={() =>
              setBasinArchiveDialog({ visible: true, basinToArchive: basin })
            }
          >
            <M id="ArchiveBasin" />
          </Button>
        )}
        {(isCompanyAdmin || isRaisioAdminOrSuperuser) && basin.deleted && (
          <Button onClick={() => setOpenDeleteAlert(true)}>
            <M id="RemoveBasin" />
          </Button>
        )}
        {basin?.ancestors?.length !== 0 && (
          <Button
            onClick={() => {
              loadPreviousSeason();
            }}
          >
            <M id="ShowPreviousSeason" />
          </Button>
        )}
        {
          <Button
            onClick={async () => {
              const data = await basinStore.downloadSeasonReport(basin.id);
              const url = window.URL.createObjectURL(
                new Blob([data], {
                  type:
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
                })
              );
              const link = document.createElement('a');
              link.href = url;
              const fileName = `${basin.name}${format(
                basin.deleted && basin.deleted_timestamp
                  ? new Date(basin.deleted_timestamp)
                  : new Date(),
                '_dd_MM_yyyy'
              )}.xlsx`;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
              link.remove();
            }}
          >
            <M id="DownloadSeasonReport" />
          </Button>
        }
      </Box>
      {basinArchiveDialog.visible && (
        <BasinArchiveDialog
          basinToArchive={basinArchiveDialog.basinToArchive}
          handleClose={() =>
            setBasinArchiveDialog({ visible: false, basinToArchive: basin })
          }
          updateBasin={updateArchivedBasin}
        />
      )}
      {openDeleteAlert && (
        <AlertDialog
          isOpen={openDeleteAlert}
          setOpen={setOpenDeleteAlert}
          titleTextId="RemoveBasin"
          primaryButtonTextId="Delete"
          primaryButtonCallback={removeBasin}
        >
          {`${intl.formatMessage({ id: 'ConfirmDeleteBasin' })} ${basin.name}?`}
        </AlertDialog>
      )}
    </Box>
  );
};

// To push the footer so its aligned with the tab content container
const Spacer = styled.div`
  flex: 0 0 250px;
`;
