/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import { Accordion } from 'components/accordion';
import { FacilitySiteRowHeader } from './facility-site-row-header';
import styled from '@emotion/styled';
import { FormattedMessage as M } from 'react-intl';
import { Box, Button, LinearProgress } from '@material-ui/core';
import { FC, Fragment, useEffect, useState } from 'react';
import { useStore } from 'stores/store-hooks';
import { INewSite, ISimpleSite } from 'models/site';
import { IFacility } from 'models/facility';
import { SiteDialog } from './site-add-dialog';
import { IApiResponse } from 'services/api';
import { BasinList } from 'components/fishbasin-list/basin-list';
import { useCallback } from 'react';

const Root = styled(Box)`
  width: 100%;
  border-top: ${({ theme }) => `1px solid ${theme.palette.grey[500]}`};
`;

const Footer = styled.div`
  margin: ${({ theme }) => theme.spacing(1)}px;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => -theme.spacing(1)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface IFacilitySiteRow {
  facility: IFacility;
}

export const FacilitySiteRow: FC<IFacilitySiteRow> = ({ facility }) => {
  const userStore = useStore('userStore');
  const siteStore = useStore('siteStore');
  const toastStore = useStore('toastStore');
  const [loading, setLoading] = useState(true);
  const [sites, setSites] = useState<ISimpleSite[]>([]);

  const [siteDialog, setSiteDialog] = useState<{
    visible: boolean;
    editedSite: ISimpleSite | null;
  }>({
    visible: false,
    editedSite: null,
  });
  const theme = useTheme();

  const updateSites = (site: ISimpleSite) => {
    setSites([...sites.filter((s) => s.id !== site.id), site]);
  };

  const loadSites = useCallback(async () => {
    setLoading(true);
    const data: ISimpleSite[] = await siteStore.loadSiteDetailsByFacilityId(
      facility.id
    );
    setSites(data);
    setLoading(false);
  }, [facility.id, siteStore]);

  useEffect(() => {
    if (sites.length === 0) {
      loadSites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [deleteModeActive, setSiteDeleteMode] = useState(false);

  const startDeleteMode = () => {
    siteStore.clearDeleteList();
    setSiteDeleteMode(true);
  };

  const cancelChanges = async () => {
    setSiteDeleteMode(false);
    siteStore.cancelDelete();
    const data: ISimpleSite[] = await siteStore.loadSiteDetailsByFacilityId(
      facility.id
    );
    setSites(data);
  };

  const acceptChanges = async () => {
    setSiteDeleteMode(false);
    const result = await siteStore.deleteSites();
    if (result.length) {
      const errorIds = result.join(', ');
      toastStore.setToast('SiteDeleteError', 'error', {
        errorIds,
      });
    }
    setSiteDeleteMode(false);
    const data: ISimpleSite[] = await siteStore.loadSiteDetailsByFacilityId(
      facility.id
    );
    setSites(data);
  };

  const handleRemove = (id: number) => {
    setSites(sites.filter((site) => site.id !== id));
    siteStore.updateDeleteList(id, 'add');
  };

  const updateSite = async (siteId: number | null, modifiedData: INewSite) => {
    const { editedSite } = siteDialog;
    let response: IApiResponse<ISimpleSite>;
    if (editedSite) {
      response = await siteStore.updateSite(
        editedSite.id,
        modifiedData.name || ''
      );
    } else {
      response = await siteStore.createSite(
        facility.id,
        modifiedData.name || '',
        modifiedData.temperature
      );
    }
    if (response.data) {
      const index =
        sites && sites.findIndex((site) => site.id === response?.data?.id);
      if (sites && index >= 0) {
        sites[index] = response.data;
        setSites(sites);
      } else {
        setSites([...sites, response.data]);
      }
    }
    return response;
  };

  if (loading) {
    return (
      <Root>
        <LinearProgress />
      </Root>
    );
  }

  return (
    <Root>
      <Box
        css={(theme) => css`
          height: calc(100% - 20px);
          margin-top: ${theme.spacing(1)}px;
        `}
      >
        {sites &&
          sites.map((site: ISimpleSite) => {
            return (
              <Accordion
                id={site.id}
                key={site.id}
                defaultBorder={theme.palette.line1.main}
                defaultBgColor={theme.palette.background2.main}
                leftOffset={5}
                isExpandedInitially={!!siteStore.expandedSites[site.id]}
                onExpandCallback={async (expanded: boolean) => {
                  siteStore.setExpandedSite(site.id, expanded);
                  if (expanded) {
                    await loadSites();
                  }
                }}
                accordionHeader={
                  <FacilitySiteRowHeader
                    setSiteDialog={setSiteDialog}
                    site={site}
                  />
                }
                isDisabled={site?.basin_count !== 0}
                disabledTooltipTextId="SiteRemovalNotAllowed"
                accordionContent={
                  <BasinList site={site} updateSites={updateSites} />
                }
                controlButtonColor={
                  deleteModeActive ? theme.palette.error.main : undefined
                }
                onControlButtonClick={handleRemove}
              />
            );
          })}
        {(userStore.isCompanyAdmin || userStore.isRaisioAdminOrSuperuser) && (
          <Footer>
            {deleteModeActive ? (
              <Fragment>
                <Button onClick={acceptChanges}>
                  <M id="AcceptChanges" />
                </Button>
                |{' '}
                <Button onClick={cancelChanges}>
                  <M id="CancelChanges" />
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <Button
                  onClick={() =>
                    setSiteDialog({ visible: true, editedSite: null })
                  }
                >
                  <M id="AddNewSite" />
                </Button>
                |{' '}
                <Button onClick={startDeleteMode} disabled={!sites.length}>
                  <M id="RemoveSite" />
                </Button>
              </Fragment>
            )}
          </Footer>
        )}
      </Box>
      {siteDialog.visible && (
        <SiteDialog
          editedSite={siteDialog.editedSite}
          handleClose={() =>
            setSiteDialog({ visible: false, editedSite: null })
          }
          updateSite={updateSite}
        />
      )}
    </Root>
  );
};
