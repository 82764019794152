import { format } from 'date-fns';
import { nanoid } from 'nanoid';
import { IFishBasin } from './fishbasin';
import { IDeviceInfoResponse } from './iot-devices';

export interface IScheduleUpdateRequest {
  name: string;
  calibration: number;
  temperature: number;
  dailyFoodAmount: number;
  entries: {
    start: string;
    percentageOfDailyFeeding: number;
    timeFeeding: number;
    timeIdle: number;
  }[];
}

export interface IFeedingScheduleEntry {
  hourStart: number;
  minuteStart: number;
  secondStart: number;
  percentageOfDailyFeeding: number;
  amountOfDailyFeeding?: number;
  feedingBatchSize?: number;
  timeFeeding: number;
  totalTimeFeeding?: number;
  timeIdle: number;
  totalTimeIdle?: number;
  rowId?: string;
  startTimeStamp?: number;
  endTimeStamp?: number;
  endTimeDisplayValue?: string; // display value for end time, HH:mm, does not include 5min bugger
}

export interface IFeedingSchedule {
  id?: number;
  updated?: String;
  name: string;
  companyId: number | null;
  entries: IFeedingScheduleEntry[];
}

export class FeedingScheduleModel implements IFeedingSchedule {
  updated?: String;
  name: string;
  companyId: number | null;
  entries!: IFeedingScheduleEntry[];
  totalPercentage: number = 0;
  totalPeriods: number = 0;
  totalFeedAmount: number = 0;
  deviceInfo: IDeviceInfoResponse;
  private basin: IFishBasin;

  constructor(
    schedule: IFeedingSchedule,
    basin: IFishBasin,
    deviceInfo: IDeviceInfoResponse
  ) {
    this.updated = schedule.updated;
    this.name = schedule.name;
    this.companyId = null;
    this.basin = basin;
    this.deviceInfo = deviceInfo;
    this.updateEntries(schedule.entries);
  }

  private setTotalTimeFeeding = (entry: IFeedingScheduleEntry) => {
    const amountFed =
      (entry.percentageOfDailyFeeding / 100) *
      (this.basin.currentfeedinfo?.feed_amount || 0);
    let totalTimeFeeding =
      amountFed / (this.deviceInfo.deviceConfiguration?.fCal || 0);
    // If feeding takes less than one cycle
    if (totalTimeFeeding < entry.timeFeeding) {
      totalTimeFeeding = entry.timeFeeding;
    }
    entry.totalTimeFeeding = totalTimeFeeding;
    return entry;
  };

  private setTotalTimeIdle = (entry: IFeedingScheduleEntry) => {
    const totalTimeIdle =
      Math.floor((entry.totalTimeFeeding || 0) / entry.timeFeeding) *
        entry.timeIdle -
        entry.timeIdle || entry.timeIdle;
    entry.totalTimeIdle = totalTimeIdle;
    return entry;
  };

  /** Calculate start and end timestamps. Used to sort schedule entries and to show times on the UI */
  private setStartAndEndTimeStamps = (entry: IFeedingScheduleEntry) => {
    const start = new Date();
    start.setUTCHours(entry.hourStart, entry.minuteStart, entry.secondStart, 0);
    entry.startTimeStamp = start.getTime();
    const totalTimeSpent =
      (entry.totalTimeFeeding || 0) * 1000 + (entry.totalTimeIdle || 0) * 1000;
    const timeBufferBetweenEntries = 5 * 60 * 1000; // 5min
    entry.endTimeStamp =
      start.getTime() + totalTimeSpent + timeBufferBetweenEntries;
    entry.endTimeDisplayValue = format(
      new Date(start.getTime() + totalTimeSpent),
      'HH:mm'
    );
    return entry;
  };

  setAmountOfDailyFeeding = (entry: IFeedingScheduleEntry) => {
    // amount of daily feeding in kilograms
    entry.amountOfDailyFeeding =
      (entry.percentageOfDailyFeeding / 100) *
      ((this.basin.currentfeedinfo?.feed_amount || 0) / 1000);
    return entry;
  };

  setFeedingBatchSize = (entry: IFeedingScheduleEntry) => {
    // feeding batch size in kilograms
    entry.feedingBatchSize =
      entry.timeFeeding *
      ((this.deviceInfo.deviceConfiguration?.fCal || 0) / 1000);
    if (entry.feedingBatchSize > (entry.amountOfDailyFeeding || 0)) {
      entry.feedingBatchSize = (entry.amountOfDailyFeeding || 0);
    }
    return entry;
  };

  sortEntries() {
    this.entries = this.entries.sort((entry1, entry2) => {
      if (entry1.startTimeStamp && entry2.startTimeStamp) {
        return entry1.startTimeStamp - entry2.startTimeStamp;
      }
      return 0;
    });
  }

  calculateTotalFeedAmount = () => {
    return this.entries.reduce((currentValue, accumulator) => {
      return (accumulator.amountOfDailyFeeding || 0) + currentValue;
    }, 0);
  };

  calculateTotalPercentage = () => {
    return this.entries.reduce((currentValue, accumulator) => {
      return accumulator.percentageOfDailyFeeding + currentValue;
    }, 0);
  };

  calculatePeriodsForEntry = (entry: IFeedingScheduleEntry) => {
    return (entry.amountOfDailyFeeding || 0) * 1000 /
      (entry.timeFeeding * (this.deviceInfo.deviceConfiguration?.fCal || 0));
  };

  calculateTotalPeriods = () => {
    return this.entries.reduce((currentValue, accumulator) => {
      if (!accumulator.timeFeeding) {
        return currentValue;
      }

      const amountFed = accumulator.amountOfDailyFeeding || 0;
      const calibration = this.deviceInfo.deviceConfiguration?.fCal || 0;
      let periods =
        (amountFed * 1000) / (accumulator.timeFeeding * calibration);
      // If feeding takes less than one cycle
      if (periods < 1) {
        periods = 1;
      }
      return Math.ceil(periods + currentValue);
    }, 0);
  };

  updateEntries(entries: IFeedingScheduleEntry[]) {
    this.entries = entries.map((entry) => {
      entry.rowId = nanoid();
      this.setAmountOfDailyFeeding(entry);
      this.setTotalTimeFeeding(entry);

      // Set empty idle period if only one period is needed
      const periodsNeeded = this.calculatePeriodsForEntry(entry);
      if (periodsNeeded <= 1) {
        entry.timeIdle = 0;
      } else {
        if (entry.timeIdle <= 1) entry.timeIdle = 1;
      }

      this.setTotalTimeIdle(entry);
      this.setFeedingBatchSize(entry);
      this.setStartAndEndTimeStamps(entry);
      return entry;
    });
    this.sortAndRecalculateTineStamps();
    this.totalPercentage = this.calculateTotalPercentage();
    this.totalPeriods = this.calculateTotalPeriods();
    this.totalFeedAmount = this.calculateTotalFeedAmount();
  }

  /**
   * Sorts and re-calculates schedule entry time-stamps. At minium there must be 5min between schedule entries.
   */
  sortAndRecalculateTineStamps = () => {
    this.sortEntries();
    let prevEntry: IFeedingScheduleEntry | undefined;
    this.entries.forEach((currentEntry) => {
      if (prevEntry) {
        if (
          currentEntry.startTimeStamp &&
          prevEntry.endTimeStamp &&
          currentEntry.startTimeStamp - prevEntry.endTimeStamp < 5 * 60 * 1000
        ) {
          currentEntry.startTimeStamp = prevEntry.endTimeStamp;
          const date = new Date(currentEntry.startTimeStamp);
          currentEntry.hourStart = date.getUTCHours();
          currentEntry.minuteStart = date.getUTCMinutes();
          currentEntry.secondStart = date.getUTCSeconds();
          this.setStartAndEndTimeStamps(currentEntry);
        }
      }
      prevEntry = currentEntry;
    });
  };

  isValidSchedule = () =>
    this.entries.length > 0 && this.totalPercentage === 100;

  deleteEntry = (rowId: string) => {
    this.entries = this.entries.filter((e) => e.rowId !== rowId);
    this.updateEntries(this.entries);
  };

  addEntry = () => {
    const currentMax = Math.ceil((
      (100 - this.totalPercentage) / 100
    ) * (this.basin.currentfeedinfo?.feed_amount || 0));

    if (this.entries.length < 1) {
      const start = new Date();
      start.setMinutes(0, 0, 0);
      this.entries.push({
        hourStart: start.getUTCHours(),
        minuteStart: start.getUTCMinutes(),
        secondStart: start.getUTCSeconds(),
        percentageOfDailyFeeding: 100 - this.totalPercentage,
        timeFeeding: (currentMax > 30 ? 30 : currentMax),
        timeIdle: 30,
      });
    } else {
      const previousEntry = this.entries[this.entries.length - 1];
      if (previousEntry.endTimeStamp) {
        const start = new Date(previousEntry.endTimeStamp);
        this.entries.push({
          hourStart: start.getUTCHours(),
          minuteStart: start.getUTCMinutes(),
          secondStart: start.getUTCSeconds(),
          percentageOfDailyFeeding: 100 - this.totalPercentage,
          timeFeeding: (currentMax > 30 ? 30 : currentMax),
          timeIdle: 30,
        });
      }
    }
    this.updateEntries(this.entries);
  };

  toJS = (): IScheduleUpdateRequest => {
    interface IResponseEntry {
      start: string;
      percentageOfDailyFeeding: number;
      timeFeeding: number;
      timeIdle: number;
    }
    const entries: IResponseEntry[] = [];
    this.entries.forEach((entry) => {
      if (entry.startTimeStamp) {
        entries.push({
          start: new Date(entry.startTimeStamp).toISOString(),
          percentageOfDailyFeeding: entry.percentageOfDailyFeeding,
          timeFeeding: entry.timeFeeding,
          timeIdle: entry.timeIdle,
        });
      }
    });

    return {
      name: this.name,
      calibration: this.deviceInfo.deviceConfiguration?.fCal || 0,
      temperature: this.basin.site.last_temperature?.temperature || 0,
      dailyFoodAmount: this.basin.currentfeedinfo?.feed_amount || 0,
      entries,
    };
  };
}
