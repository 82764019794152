/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FC, Fragment, useEffect, useState } from 'react';
import { Accordion } from 'components/accordion';
import { UserList } from 'components/user/user-list';
import { useStore } from 'stores/store-hooks';
import { Box, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { CompanyListActions, CompanyListHeader } from './company-list-header';
import { CompanyListItem } from './company-list-item';
import { CompanyDialog } from './company-dialog';
import userStore from 'stores/user-store';
import { ICompany, RAISIO_COMPANY } from 'models/company';

export const CompanyList: FC = observer(() => {
  const companyStore = useStore('companyStore');
  const toastStore = useStore('toastStore');
  const [isEditing, setEdit] = useState(false);
  const [companyDialog, setCompanyDialog] = useState<{
    isVisible: boolean;
    selectedCompany: ICompany | null;
  }>({
    isVisible: false,
    selectedCompany: null,
  });
  const theme = useTheme();

  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    if (companyStore.companies) {
      companyStore.loadCompanies();
    }
  }, [companyStore]);

  const handleEditCompany = (company: ICompany) => {
    setCompanyDialog({ isVisible: true, selectedCompany: company });
  };

  const handleClick = async (type: CompanyListActions) => {
    switch (type) {
      case CompanyListActions.AddCompany:
        setCompanyDialog({ isVisible: true, selectedCompany: null });
        break;
      case CompanyListActions.RemoveCompanies:
        companyStore.clearDeleteList();
        setEdit(true);
        break;
      case CompanyListActions.CancelChanges:
        companyStore.cancelDelete();
        setEdit(false);
        break;
      case CompanyListActions.AcceptChanges:
        const result = await companyStore.deleteCompanies();
        if (result.length) {
          const errorIds = result.join(', ');
          toastStore.setToast('CompanyDeleteError', 'error', {
            errorIds,
          });
        } else {
          setEdit(false);
        }
        break;
    }
  };

  const closeCompanyDialog = () => {
    setCompanyDialog({ isVisible: false, selectedCompany: null });
  };

  return (
    <Box
      css={(theme) => css`
        overflow-y: auto;
        height: 100%;
        margin-top: ${theme.spacing(1)}px;
      `}
    >
      <Fragment>
        <CompanyListHeader
          onHeaderButtonClick={handleClick}
          isEditing={isEditing}
          setSearchTerm={setSearchTerm}
        />
        {
          // if current user is super user or raisio admin render separate "Raisio -company."
          // This is not an actual company, but a a place holder collection to manage users with RaisioAdmin privileges.
          userStore.isRaisioAdminOrSuperuser && (
            <Fragment>
              <Accordion
                key={RAISIO_COMPANY.id}
                id={RAISIO_COMPANY.id}
                accordionHeader={
                  <CompanyListItem
                    handleEditCompany={handleEditCompany}
                    company={RAISIO_COMPANY}
                  />
                }
                accordionContent={<UserList companyId={RAISIO_COMPANY.id} />}
              />
              <Box mb={10} />
            </Fragment>
          )
        }
        {
          // render the actual companies from the backend that currentUser has access to
          companyStore.filterCompanies(searchTerm).map((c) => (
            <Accordion
              key={c.id}
              id={c.id}
              accordionHeader={
                <CompanyListItem
                  handleEditCompany={handleEditCompany}
                  company={c}
                />
              }
              accordionContent={<UserList companyId={c.id} />}
              controlButtonColor={
                isEditing ? theme.palette.error.main : undefined
              }
              onControlButtonClick={(id) =>
                companyStore.updateDeleteList(id, 'add')
              }
            />
          ))
        }
      </Fragment>
      {companyDialog.isVisible && (
        <CompanyDialog
          updateCompany={companyStore.createCompany}
          handleClose={closeCompanyDialog}
          company={companyDialog.selectedCompany}
        />
      )}
    </Box>
  );
});
