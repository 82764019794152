import {useHistory} from "react-router-dom";
import React, {FC, Fragment, useEffect, useState} from "react";
import styled from "@emotion/styled";
import {useStore} from "../../stores/store-hooks";
import {observer} from "mobx-react-lite";
import {BaseVideoComponent} from "../video/base-video-component";
import {Button, Checkbox, Typography} from "@material-ui/core";
import {MultipleCameraViewHeader} from "./multiple-camera-view-header";
import {ForceFeedButton} from "./force-feed-button";
import {ForcefeedDialog} from "./forcefeed-dialog";
import {IFishBasin} from "../../models/fishbasin";
import {EmergencyStopButton} from "./emergency-stop-button";
import basinStore from "../../stores/basin-store";
import iotDeviceStore from "../../stores/iot-device-store";
import {FormattedMessage as M, useIntl} from "react-intl";
import {RefreshCamerasButton} from "./activate-video-button";
import {DRAWER_WIDTH_MIN} from "../../constants";
import {StopFeedingButton} from "./stop-feeding-button";
import {AreYouSureDialog} from "./areyousure-dialog";

export interface ISelectionItem {
  basin: IFishBasin;
  calibrationValue: number;
}

export interface IMultipleCameraViev {
  basins: IFishBasin[];
  header: string;
  showSite?: boolean;
}

/**
 * A control panel view for given basins' camera views. Allows the user to perform actions such as force feed on many devices.
 *
 * The basins in the basins list argument MUST have their device infos loaded via the iotStore before this component is rendered
 * header argument determines the header for this view.
 * showSite argument determines whether to show basin's site name.
 */
export const MultipleCameraView: FC<IMultipleCameraViev> = observer(({basins, header, showSite = false}) => {
  const toastStore = useStore('toastStore');
  const iotStore = useStore('iotStore');
  const { canEdit } = useStore('userStore');
  const history = useHistory();
  const intl = useIntl();
  const settingsStore = useStore('settingsStore');
  const [showForceFeedDialog, setShowForceFeedDialog] = useState(false);
  const [showStopFeedingDialog, setShowStopFeedingDialog] = useState(false);
  const [showEmergencyStopDialog, setShowEmergencyStopDialog] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Array<ISelectionItem>>([]);

  //hack to force remount on video components
  //should eventually be replaced by a proper hardware command provided by BHI
  const [keyMultiplier, setKeyMultiplier] = useState<number>(1);

  useEffect(
    () => settingsStore.setDrawerOpenStatus(false, DRAWER_WIDTH_MIN),
    [settingsStore]
  );

  const addSelectionItem = async (basin: IFishBasin) => {

    const basinAlreadySelected = !!selectedItems.find(item => item.basin === basin);
    if(basinAlreadySelected) return;

    const calibrationValue = await iotStore.getDeviceCalibration(basin);
    const item: ISelectionItem = {
      basin: basin,
      calibrationValue: calibrationValue
    };

    setSelectedItems(selectionItems => [...selectionItems, item]);
  }

  const removeSelectionItem = async (basin: IFishBasin) => {
    setSelectedItems(selectionItems => selectionItems.filter(i => i.basin !== basin))
  }

  const selectAll = async () => {
    basins.forEach((basin: IFishBasin) => {
      addSelectionItem(basin);
    })
  }

  const deselectAll = async () => {
    setSelectedItems([]);
  }

  const handleCheck = (event: any, basin: IFishBasin) => {
    if(event.target.checked) addSelectionItem(basin);
    else removeSelectionItem(basin);
  }

  const startForceFeed = async (forceFeedTime: number) => {
    const responsePromises: Array<Promise<any>> = [];
    selectedItems.forEach(item => {
      responsePromises.push(iotStore.startForceFeeding(item.basin, forceFeedTime));
    })

    const results = await Promise.all(responsePromises);
    for (const result of results) {
      if(result.status !== 200) {
        toastStore.setToast('IotCommandSendingFailed');
      } else {
        await basinStore.addForceFeedingEvent(result.data.basin, forceFeedTime);
      }
    }

    setShowForceFeedDialog(false);
  };

  const startEmergencyStop = async () => {
    selectedItems.forEach(item => {
      iotDeviceStore.emergencyStop(item.basin);
    });
    setShowEmergencyStopDialog(false);
  }

  const startFeedingStop = async () => {
    const stopFeeding = () => selectedItems.forEach(item => {
      iotDeviceStore.emergencyStop(item.basin);
    });
    const restartFeedingAutomationWithDelay = () => selectedItems.forEach(item => {
      setTimeout(() => iotDeviceStore.startFeeding(item.basin), 3000);
    });

    stopFeeding();
    restartFeedingAutomationWithDelay();
    setShowStopFeedingDialog(false);
  }

  const activateCameras = async () => {
    setKeyMultiplier(keyMultiplier*-1);
  }

  const openBasin = (basin: IFishBasin) => {
    basinStore.setSelectedBasin(basin);
    history.push(`/basin/${basin.id}`);
  }

  return (
    <Root>
        <div>
          <MultipleCameraViewHeader header={header}/>
          <ActionContainer>
            <ContentLeft>
              {
                canEdit && (
                  <Fragment>
                    <Button onClick={selectAll}>
                      <M id="SelectAll"/>
                    </Button>
                    <Button onClick={deselectAll}>
                      <M id="DeselectAll"/>
                    </Button>
                  </Fragment>
                )
              }
            </ContentLeft>
            <ContentRight>
              <RefreshCamerasButton handleClick={() => activateCameras()}/>
              {
                canEdit && (
                  <Fragment>
                    <ForceFeedButton handleClick={() => setShowForceFeedDialog(true)}/>
                    <StopFeedingButton handleClick={() => setShowStopFeedingDialog(true)}/>
                    <EmergencyStopButton handleClick={() => setShowEmergencyStopDialog(true)}/>
                  </Fragment>
                )
              }
            </ContentRight>
          </ActionContainer>
          <VideoContainer>
            {basins.map((basin: IFishBasin) => (
              <VideoItem key={basin.id}>
                <BasinHeader>
                  <Button onClick={() => openBasin(basin)}>
                    <div>
                      <Typography variant='h5'>{basin.name}</Typography>
                      { showSite && (<Typography style={{fontSize: 'small'}}>{basin.site.name}</Typography>)}
                    </div>
                  </Button>
                  {
                    canEdit && (
                      <Checkbox
                        checked={!!selectedItems.find(e => e.basin === basin)}
                        onChange={event => handleCheck(event, basin)}
                      />
                    )
                  }
                </BasinHeader>
                <BaseVideoComponent
                  key={keyMultiplier*basin.id}
                  optimizeVideo={true}
                  {...basin}/>
              </VideoItem>
            ))}
          </VideoContainer>
        </div>
      {showForceFeedDialog && (
        <ForcefeedDialog
          selectedItems={selectedItems}
          handleSubmit={(forceFeedTime: number) => startForceFeed(forceFeedTime)}
          handleClose={() => setShowForceFeedDialog(false)}/>
      )}
      {showStopFeedingDialog && (
        <AreYouSureDialog
          titleId="StopFeeding"
          selectedItems={selectedItems}
          handleSubmit={() => startFeedingStop()}
          handleClose={() => setShowStopFeedingDialog(false)}
        >
        </AreYouSureDialog>
      )}
      {showEmergencyStopDialog && (
        <AreYouSureDialog
          titleId="EmergencyStop"
          selectedItems={selectedItems}
          handleSubmit={() => startEmergencyStop()}
          handleClose={() => setShowEmergencyStopDialog(false)}
        >
          <Typography variant="h5" component="span">
            {intl.formatMessage({ id: 'EmergencyStopDisclaimer' })}
          </Typography>
        </AreYouSureDialog>
      )}
    </Root>
  );
});

const Root = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 5px;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  margin-left: ${({ theme }) => theme.spacing(0)}px;
  margin-right: ${({ theme }) => theme.spacing(0)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentLeft = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentRight = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;

const VideoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

const VideoItem = styled.div`
  margin-bottom: 50px;
`;


const BasinHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
