import { makeAutoObservable, runInAction } from 'mobx';
import { requestPasswordReset, confirmPasswordReset } from 'services/api';
import toastStore from './toast-store';

class PasswordResetStore {
  constructor() {
    makeAutoObservable(this);
  }

  email: string = "";

  private emailState: "bad" | "good" | "submitted" | "unset" = "unset";

  get canSubmit() {
    return this.emailState === "good";
  }

  get emailOkay() {
    return this.emailState !== "bad";
  }

  get hasSubmitted() {
    return this.emailState === "submitted";
  }

  resetStore = () => {
    runInAction(() => {
      this.email = "";
      this.emailState = "unset";
    });
  };

  setEmail = (email: string) => {
    runInAction(() => {
      this.email = email;
      this.emailState = email.match(/[^@]+@[^@]+\.[^@.]+/) ? "good" : "bad";
    });
  };

  submitEmail = async () => {
    try {
      if (!this.canSubmit) throw new Error("No valid email to submit");
      await requestPasswordReset(this.email);
      runInAction(() => {
        this.emailState = "submitted";
      });
    } catch (error) {
      toastStore.setToast("ResetPasswordRequestFailed");
    }
  };

  confirmNewPassword = async (token: string, password: string) => {
    try {
      await confirmPasswordReset(token, password);
      return true;
    } catch (error) {
      toastStore.setToast("ResetPasswordConfirmFailed");
      return false;
    }
  };
}

export default new PasswordResetStore();
