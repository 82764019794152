/** @jsxRuntime classic */
/** @jsx jsx */
import React, {useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {format} from 'date-fns';
import {getGeneratedCsvReport} from "../../services/api";
import {useIntl} from "react-intl";
import toastStore from "../../stores/toast-store";
import {css, jsx, keyframes} from "@emotion/react";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import {localeMap} from "../../translations/locales";
import {useStore} from "../../stores/store-hooks";
import fiLocale from "date-fns/locale/fi";

interface DateDialogProps {
  open: boolean;
  onClose: () => void;
}

export const DatePickerDialog: React.FC<DateDialogProps> = ({
                                                 open,
                                                 onClose
                                               }) => {



  const spin = keyframes`
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  `;
  const intl = useIntl();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const userStore = useStore('userStore');
  const [locale, setLocale] = useState(fiLocale)

  useEffect(() => {
    const code = userStore.selectedLanguage;
    setLocale(localeMap[code]);
  }, [userStore.selectedLanguage]);


  const handleOkClick = async () => {
    setLoading(true);
    const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : '';
    const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : '';

    try {
      const fileName = generateFileName(formattedStartDate, formattedEndDate)
      const response = await getGeneratedCsvReport(formattedStartDate, formattedEndDate);

      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        })
      );

      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      toastStore.setToast('DownloadReportSuccess', 'success');
    } catch (error) {
      toastStore.setToast('DownloadReportError', 'error');
      console.error('Error downloading the CSV:', error);
    }

    setLoading(false);
    onClose();
  };

  const generateFileName = (startDate: string, endDate: string) => {
    const [startYear, startMonth, startDay] = startDate.split('-');
    const [endYear, endMonth, endDay] = endDate.split('-');

    const formattedStartDate = `${startDay}-${startMonth}-${startYear}`;
    const formattedEndDate = `${endDay}-${endMonth}-${endYear}`;

    return  `${formattedStartDate}_${formattedEndDate}_report.csv`
  }
  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date){
      setEndDate(date);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        {intl.formatMessage({ id: "SelectDates" })}
      </DialogTitle>
      <DialogContent>
        <Typography>{intl.formatMessage({ id: "ReportDialogText" })}</Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            <KeyboardDatePicker
              style={{ marginRight: 10 }}
              // variant={"inline"}
              fullWidth={false}
              disableToolbar={true}
              margin="normal"
              label={intl.formatMessage({ id: "StartDate" })}
              format="dd/MM/yyyy"
              value={startDate}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              disabled={loading}
            />

            <KeyboardDatePicker
              style={{ marginLeft: 10 }}
              // variant={"inline"}
              fullWidth={false}
              disableToolbar={true}
              margin="normal"
              label={intl.formatMessage({ id: "EndDate" })}
              format="dd/MM/yyyy"
              value={endDate}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              disabled={loading}
            />
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        {loading ? (
          <AutorenewIcon
            css={(theme) => css`
              color: ${theme.palette.line2.main};
              animation: ${spin} 1s ease infinite;
            `}
          />
        ) : (
          <AutorenewIcon
            // This is just to align the divider nicely
            css={(theme) => css`
              opacity: 0;
            `}
          />
        )}
        <Button onClick={() => onClose()} color="primary"
                disabled={loading}>
          {intl.formatMessage({ id: "Cancel" })}
        </Button>
        <Button onClick={handleOkClick} color="primary"
                disabled={loading}>
          {intl.formatMessage({ id: "Download" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
