/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { FC, useEffect } from 'react';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { IWidgetComponent, IFeedStockItem } from 'models/widget';
import { useStore } from 'stores/store-hooks';
import { observer } from 'mobx-react-lite';
import { useTheme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { CustomProgressBar } from './custom-progress-bar';
import { useHistory } from 'react-router';
import { FeedStockWidgetPopover } from './feed-stock-widget-popover';
import {IFacility} from "../../models/facility";

export const FeedStockWidget: FC<IWidgetComponent> = observer(
  ({ widget, children }) => {
    const intl = useIntl();
    const theme = useTheme();
    const dashboardStore = useStore('dashboardStore');
    const {
      feedStockItems,
      basinListLoading,
      feedStockItemsLoading,
    } = dashboardStore;
    const filterStore = useStore('filterStore');
    const { dataFilter } = filterStore;
    const companyStore = useStore('companyStore');

    const findFacility = () => {
      if(companyStore.companies.length !== 1) return undefined;
      if(!companyStore.companies[0].facilities) return undefined;
      if(companyStore.companies[0].facilities.length === 0) return undefined;
      return companyStore.companies[0].facilities[0];
    }

    useEffect(() => {
      if (!basinListLoading) dashboardStore.loadFeedStockData();
    }, [dashboardStore, filterStore, dataFilter.id, basinListLoading]);

    if (basinListLoading || feedStockItemsLoading) {
      return <CircularProgress size={36} />;
    }

    return (
      <Box
        css={css`
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
          flex: 1 1 100%;
          align-items: center;
          justify-content: center;
          padding: 0 ${theme.spacing(2)}px;
        `}
      >
        <Box
          css={css`
            display: flex;
            flex-direction: row;
            // border-bottom: 1px solid ${theme.palette.line2.main};
            width: calc(100% - ${theme.spacing(4)}px);
            padding-top: ${theme.spacing(3)}px;
            padding-bottom: ${theme.spacing(1)}px;
          `}
        >
          <Typography variant="h4" color="textPrimary">
            {intl.formatMessage({ id: 'FeedStockStatus' })}
          </Typography>
        </Box>
        {
          <Box
            css={css`
              display: flex;
              flex: 1 1 100%;
              flex-direction: column;
              width: calc(100% - ${theme.spacing(2)}px);
              margin-bottom: ${theme.spacing(3)}px;
              overflow: auto;
            `}
          >
            {feedStockItems.length ? (
              feedStockItems.map((feedStockItem) => {
                return (
                  <FeedStockItem
                    key={feedStockItem.productCode}
                    feedStockItem={feedStockItem}
                    facility={findFacility()}
                  />
                );
              })
            ) : (
              <div>
                {intl.formatMessage({ id: 'FeedStockStatusNotAvailable' })}
              </div>
            )}
          </Box>
        }
      </Box>
    );
  }
);

const FeedStockItem: FC<{ feedStockItem: IFeedStockItem, facility: IFacility | undefined }> = ({
  feedStockItem, facility
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();
  const userStore = useStore('userStore');
  const { isCompanyAdmin } = userStore;
  const dashboardStore = useStore('dashboardStore');
  const { getBasinByProductCode } = dashboardStore;

  const feedDemandKg =
    getBasinByProductCode(feedStockItem.productCode).reduce((acc, basin) => {
      return acc + (basin?.currentfeedinfo?.feed_amount || 0);
    }, 0) / 1000;

  const amountInStock =
    (feedStockItem.cumulativeAmountDelivered || 0) +
    (feedStockItem.cumulativeStockAmount || 0);

  const remainingStockPercent =
    (amountInStock > 0 ? (amountInStock - feedDemandKg) / amountInStock : 0) *
    100;

  const stockTextColor =
    amountInStock < 0 ? theme.palette.error.main : theme.palette.text.primary;

  let progressBarColor = theme.palette.success.main;
  if (remainingStockPercent < 50) progressBarColor = theme.palette.warning.main;
  if (remainingStockPercent < 25) progressBarColor = theme.palette.error.main;

  return (
    <Box
      css={(theme) => css`
        display: flex;
        flex: 0 0 auto;
        flex-direction: row;
        width: calc(100% - ${theme.spacing(2)}px);
        padding-top: ${theme.spacing(1)}px;
        padding-bottom: ${theme.spacing(1)}px;
        border-bottom: 1px solid ${theme.palette.line2.main};
        :first-of-type {
          border-top: 1px solid ${theme.palette.line2.main};
        }
      `}
    >
      <Box
        css={(theme) => css`
          display: flex;
          flex-direction: column;
          flex: 0 0 190px;
          justify-content: center;
          margin-left: ${theme.spacing(2)}px;
        `}
      >
        <Typography
          variant="h5"
          component="div"
          color="primary"
          css={(theme) => css`
            &.MuiTypography-root {
              white-space: nowrap;
              margin-bottom: ${theme.spacing(1)}px;
            }
          `}
        >
          {feedStockItem.productName}
        </Typography>
        <CustomProgressBar
          value={remainingStockPercent}
          progressColor={progressBarColor}
        />
      </Box>
      <Box
        css={(theme) => css`
          display: flex;
          flex-direction: column;
          flex: 1 1 100%;
          justify-content: center;
          margin-left: ${theme.spacing(4)}px;
        `}
      >
        <Typography variant="h5" component="div">
          {intl.formatNumber(feedDemandKg, {
            style: 'decimal',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }) + ' ' + intl.formatMessage({ id: "UnitKg" })}
        </Typography>
        <Typography variant="h6" component="div">
          {intl.formatMessage({ id: 'Demand' })}
        </Typography>
      </Box>
      <Box
        css={(theme) => css`
          display: flex;
          flex-direction: column;
          flex: 1 1 100%;
          justify-content: center;
          margin-left: ${theme.spacing(4)}px;
        `}
      >
        <Typography
          variant="h5"
          component="div"
          css={css`
            color: ${stockTextColor};
          `}
        >
          {intl.formatNumber(amountInStock, {
            style: 'decimal',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }) + ' ' + intl.formatMessage({ id: "UnitKg" })}
        </Typography>
        <Typography variant="h6" component="div">
          {intl.formatMessage({ id: 'Stock' })}
        </Typography>
      </Box>
      <Box
        css={(theme) => css`
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-left: ${theme.spacing(2)}px;
        `}
      >
        {isCompanyAdmin && facility && (
          <FeedStockWidgetPopover
            facilityId={facility.id}
            productCode={feedStockItem.productCode}
          />
        )}
        <Button
          variant="outlined"
          onClick={() => {
            history.push(`/orders/`);
          }}
          css={css`
            width: 90px;
          `}
        >
          {intl.formatMessage({ id: 'Order' })}
        </Button>
      </Box>
    </Box>
  );
};
