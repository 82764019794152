import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage as M } from 'react-intl';
import { Grid, Link, Typography } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { UserModel } from 'models/user';
import { Modal, ModalActions, ModalContent } from 'components/modal-dialog';

interface IOwnUserEditDialog {
  handleClose: () => void;
  user: UserModel | null;
  updateUserData: (data: object) => void;
}

const phoneRegExp = /^$|^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;
const usernameRegExp = /^[a-zA-Z0-9@.+_-]+$/g;
const schema = yup.object().shape({
  username: yup // Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
    .string()
    .required('error.validation.username')
    .max(150, 'error.validation.username')
    .matches(usernameRegExp, 'error.validation.username'),
  email: yup.string().email('error.validation.email'),
  phone: yup
    .string()
    .max(32, 'error.validation.phone')
    .matches(phoneRegExp, 'error.validation.phone'),
  first_name: yup.string().max(150, 'error.validation.firstName'),
  last_name: yup.string().max(150, 'error.validation.lastName'),
});

export const OwnUserEditDialog = ({
  user,
  handleClose,
  updateUserData,
}: IOwnUserEditDialog) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: { ...user },
  });

  return (
    <Modal handleClose={handleClose}>
      <form
        noValidate
        onSubmit={handleSubmit((data) => {
          updateUserData(data);
          handleClose();
        })}
      >
        <ModalContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography component="span" variant="subtitle2">
                <M id="MySettings" />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                name="username"
                margin="dense"
                id="own-user-edit-dialog-username"
                label={<M id="Username" />}
                inputRef={register}
                error={!!errors.username}
                helperText={
                  errors.username && <M id={`${errors?.username?.message}`} />
                }
                fullWidth
              />
              <TextField
                margin="dense"
                name="first_name"
                id="own-user-edit-dialog-first-name"
                label={<M id="FirstName" />}
                error={!!errors.first_name}
                helperText={
                  errors.first_name && (
                    <M id={`${errors?.first_name?.message}`} />
                  )
                }
                inputRef={register}
                fullWidth
              />
              <TextField
                margin="dense"
                name="last_name"
                id="own-user-edit-dialog-last-name"
                label={<M id="LastName" />}
                inputRef={register}
                error={!!errors.last_name}
                helperText={
                  errors.last_name && <M id={`${errors?.last_name?.message}`} />
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="own-user-edit-dialog-email"
                name="email"
                label={<M id="Email" />}
                type="email"
                inputRef={register}
                error={!!errors.email}
                helperText={
                  errors.email && <M id={`${errors?.email?.message}`} />
                }
                fullWidth
              />
              <TextField
                margin="dense"
                id="own-user-edit-dialog-phone"
                label={<M id="PhoneNumber" />}
                name="phone"
                inputRef={register}
                error={!!errors.phone}
                helperText={
                  errors.phone && <M id={`${errors?.phone?.message}`} />
                }
                type="tel"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography component="span" variant="subtitle2">
                <M id="TermsAndConditions" />
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Link href="/terms" underline="always" target="_blank">
                <M id="TermsAndConditions" />
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Link href="/privacy" underline="always" target="_blank">
                <M id="PrivacyPolicy" />
              </Link>
            </Grid>
          </Grid>
        </ModalContent>
        <ModalActions>
          <Button
            color="primary"
            type="submit"
            disabled={!!Object.keys(errors).length}
          >
            <M id="Save" />
          </Button>
          <Button onClick={handleClose} color="primary">
            <M id="Cancel" />
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};
