/** @jsxRuntime classic */
/** @jsx jsx */
import {FC, FormEvent, useEffect} from 'react';
import {FormattedMessage as M, useIntl} from 'react-intl';
import {css, jsx} from '@emotion/react';
import {observer} from 'mobx-react-lite';
import {Box, Divider, Grid, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlertOutlined';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import {useStore} from 'stores/store-hooks';
import {AlarmTypes} from 'models/alarm';
import {ISimpleSite} from 'models/site';
import {Modal, ModalActions, ModalContent} from 'components/modal-dialog';
import {alarmTypes} from './alarm-types';
import {EmailChipInput} from './email-chip-input';
import {PhoneNumberChipInput} from './phonenumber-chip-input';
import {CheckboxRow, HeaderRow, ThresholdRow} from './settings-components';
import {IApiResponse} from '../../services/api';

export const NotificationsSettingsModal: FC<{
  handleClose: () => void;
  site: ISimpleSite;
}> = observer(({ handleClose, site }) => {
    const intl = useIntl();
    const alarmStore = useStore('alarmStore');
    const toastStore = useStore('toastStore');
    const iotStore = useStore('iotStore');
    const {
      addActiveDevice,
      canSaveThresholds,
      getLowerThreshold,
      getUpperThreshold,
      hasLowerThreshold,
      hasUpperThreshold,
      isDeviceActive,
      isSubscribedToEmail,
      isSubscribedToSms,
      removeActiveDevice,
      setAllDevicesActive,
      setAllDevicesInactive,
      setLowerThreshold,
      setUpperThreshold,
      subscribeToEmail,
      subscribeToSms,
      unsubscribeFromEmail,
      unsubscribeFromSms,
      setBatterySave, getBatterySave,
      canSaveBatterySave
    } = alarmStore;

    useEffect(() => {
      const loadInitialData = async () =>
        await Promise.all([
          alarmStore.loadNotificationSettings(site),
          ...iotStore.loadDeviceInfos(site)
        ]);
      loadInitialData().then(() => {
        if(site.fishbasins?.find(basin => iotStore.getBatterySaveEnabled(basin))) {
          setBatterySave(true);
        }
      });
    }, [alarmStore, iotStore, setBatterySave, site]);

    const allDevicesAreHw1 = (): boolean => {
      return !alarmStore.settings.devices.find(d => iotStore.isHw2(d.deviceId));
    }

    const submitData = async (event: FormEvent) => {
      const updateBatterySaveForDevices = () => {
        const promises: Promise<IApiResponse<any>>[] = [];
        for (const deviceSetting of alarmStore.settings.devices) {
          if(!iotStore.isHw2(deviceSetting.deviceId)) continue;
          const batterySaveThresholds = {
            allow: getUpperThreshold(AlarmTypes.BATTERY_VOLTAGE) || 0.0,
            deny: getLowerThreshold(AlarmTypes.BATTERY_VOLTAGE) || 0.0
          }
          promises.push(isDeviceActive(deviceSetting.deviceId)
            ? iotStore.updateBatterySave(deviceSetting.deviceId, batterySaveThresholds)
            : iotStore.disableBatterySave(deviceSetting.deviceId));
        }
        return promises;
      };

      event.preventDefault();
      const responses = await Promise.all([
        alarmStore.saveNotificationSettings(site),
        ...updateBatterySaveForDevices()
      ]);
      if(responses.find(r => r.status !== 200 && r.status !== 201)) {
        toastStore.setToast('NotificationSettingsSaveFailed');
      } else {
        handleClose();
      }
    };

    const basins = site.fishbasins?.filter(
      basin => basin.supportunit_id && !basin.deleted
    ) || [];

    return (
      <Modal handleClose={handleClose} size="lg">
        <form
          noValidate
          onSubmit={submitData}
          onClick={(event) => event.stopPropagation()}
        >
          <ModalContent>
            <Box
              css={css`
                min-height: 400px;
                min-width: 900px;
              `}
            >
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography component="span" variant="h1">
                    <M id={'NotificationSettings'} />
                  </Typography>
                  <Divider
                    variant="middle"
                    css={(theme) => css`
                      &.MuiDivider-root {
                        background-color: ${theme.palette.primary.main};
                        margin: ${theme.spacing(4)}px 0;
                      }
                    `}
                  />
                </Grid>

                <HeaderRow
                  header={<Typography />}
                  names={alarmTypes.map(
                    (alarm) => intl.formatMessage({ id: alarm.textId })
                  )}
                />

                <ThresholdRow
                  label={intl.formatMessage({ id: "ThresholdUpper" })}
                  types={alarmTypes.map((alarm) => {
                    if(!allDevicesAreHw1() && alarm.type === AlarmTypes.BATTERY_VOLTAGE && getBatterySave()) {
                      return alarm.type;
                    }
                    return hasUpperThreshold(alarm.type) ? alarm.type : null;
                  })}
                  getter={getUpperThreshold}
                  setter={setUpperThreshold}
                />
                <ThresholdRow
                  label={intl.formatMessage({ id: "ThresholdLower" })}
                  types={alarmTypes.map((alarm) =>
                    hasLowerThreshold(alarm.type) ? alarm.type : null
                  )}
                  getter={getLowerThreshold}
                  setter={setLowerThreshold}
                />

                <CheckboxRow
                  header={<PhoneEnabledIcon />}
                  isChecked={(type) => isSubscribedToSms(type as AlarmTypes)}
                  update={(type, value) =>
                    value
                      ? subscribeToSms(type as AlarmTypes)
                      : unsubscribeFromSms(type as AlarmTypes)
                  }
                  entries={alarmTypes.map((alarm) => alarm.type)}
                />
                <CheckboxRow
                  header={<EmailIcon />}
                  isChecked={(type) => isSubscribedToEmail(type as AlarmTypes)}
                  update={(type, value) =>
                    value
                      ? subscribeToEmail(type as AlarmTypes)
                      : unsubscribeFromEmail(type as AlarmTypes)
                  }
                  entries={alarmTypes.map((alarm) => alarm.type)}
                />
                {!allDevicesAreHw1() && (
                  <CheckboxRow
                    header={<BatteryAlertIcon />}
                    isChecked={(type) => getBatterySave()}
                    update={(type, value) => setBatterySave(value)}
                    entries={alarmTypes.map((alarm) => alarm.type === AlarmTypes.BATTERY_VOLTAGE ? alarm.type : null)}
                  />
                )}

                <Grid item xs={12}>
                  <Divider
                    variant="middle"
                    css={(theme) => css`
                      &.MuiDivider-root {
                        background-color: ${theme.palette.primary.main};
                        margin: ${theme.spacing(4)}px;
                      }
                    `}
                  />
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    ml={4}
                    mt={4}
                  >
                    <Box display="flex" alignItems="center" mr={4}>
                      <Typography variant="h4">
                        {intl.formatMessage({ id: "SettingsPerBasin" })}
                      </Typography>
                    </Box>
                    <Box mr={1}>
                      <Button
                        color="primary"
                        onClick={setAllDevicesActive}
                        variant="outlined"
                      >
                        {intl.formatMessage({ id: "SelectAll" })}
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        color="primary"
                        onClick={setAllDevicesInactive}
                        variant="outlined"
                      >
                        {intl.formatMessage({ id: "DeselectAll" })}
                      </Button>
                    </Box>
                  </Box>
                </Grid>

                <HeaderRow names={basins.map(basin => basin.name)} />
                <CheckboxRow
                  isChecked={isDeviceActive}
                  update={(deviceId, value) =>
                    value
                      ? addActiveDevice(deviceId)
                      : removeActiveDevice(deviceId)
                  }
                  entries={basins.map(basin => basin.supportunit_id || "")}
                />

                <EmailChipInput />
                <PhoneNumberChipInput />
              </Grid>
            </Box>
          </ModalContent>
          <ModalActions>
            <Button color="primary" type="submit" disabled={!allDevicesAreHw1() && (!canSaveThresholds || !canSaveBatterySave)}>
              <M id="Save" />
            </Button>
            <Button onClick={handleClose} color="primary">
              <M id="Cancel" />
            </Button>
          </ModalActions>
        </form>
      </Modal>
    );
  }
);
