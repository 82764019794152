/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Box, Typography } from '@material-ui/core';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useHeaderItems } from './feeding-schedule-hooks';

interface IFeedingScheduleHeader {
  isHw2: boolean;
}

export const FeedingScheduleHeader: FC<IFeedingScheduleHeader> = ({isHw2}) => {
  const items = useHeaderItems(isHw2);

  return (
    <Box display="flex" flexDirection="row" mb={2}>
      {items.map((item) => (
        <Header key={item.id} {...item} />
      ))}
    </Box>
  );
};

interface IHeader {
  flexBasis: string;
  headerId?: string;
}

const Header: FC<IHeader> = ({ headerId, flexBasis }) => {
  const intl = useIntl();
  return (
    <Box
      css={css`
        flex: 1 1 ${flexBasis};
        justify-content: center;
        display: flex;
      `}
    >
      <Typography component="div" variant="h5">
        {headerId ? intl.formatMessage({ id: headerId }) : ''}
      </Typography>
    </Box>
  );
};
