import { FC, Fragment, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { FormattedMessage as M, useIntl } from 'react-intl';
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Modal, ModalActions, ModalContent } from 'components/modal-dialog';
import { Controller, useForm, UseFormMethods } from 'react-hook-form';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { DatePicker } from '@material-ui/pickers';
import { endOfYear } from 'date-fns';
import { Autocomplete } from '@material-ui/lab';
import { useStore } from 'stores/store-hooks';
import { observer } from 'mobx-react-lite';
import { FeedTypeUsageStatus, IFeedStockData } from 'models/silo';
import { dateFormatString } from 'translations/locales';

interface IAddSiloEventDialog {
  handleClose: () => void;
}

const schema = yup.object().shape({
  feedtype_id: yup.number().nullable().required('error.validation.feedtype_id'),
  timestamp: yup.string().required('error.validation.timestamp'),
  feederamount: yup
    .number()
    .min(0, 'error.validation.feederamount')
    .max(2147483647, 'error.validation.feederamount')
    .typeError('error.validation.feederamount')
    .required('error.validation.feederamount'),
});

export const AddSiloEventDialog = observer(
  ({ handleClose }: IAddSiloEventDialog) => {
    const siloStore = useStore('siloStore');
    const { editedEntry } = siloStore;
    const defaultValues = editedEntry
      ? {
          timestamp: editedEntry.timestamp,
          feedtype_id: editedEntry.feedtype.id,
          feederamount: editedEntry.feederamount,
        }
      : {
          timestamp: new Date().toISOString(),
          feedtype_id: null,
        };
    const {
      register,
      handleSubmit,
      errors,
      clearErrors,
      control,
    } = useForm<IFeedStockData>({
      resolver: yupResolver(schema),
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      defaultValues,
    });
    const submitData = async (data: IFeedStockData) => {
      const success = editedEntry
        ? await siloStore.updateFeedStockEvent(data)
        : await siloStore.saveFeedStockEvent(data);
      if (success) handleClose();
    };
    return (
      <Modal handleClose={handleClose}>
        <form noValidate onSubmit={handleSubmit(submitData)}>
          <ModalContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography component="span" variant="subtitle2">
                  {<M id={editedEntry ? 'EditSiloEvent' : 'AddSiloEvent'} />}
                </Typography>
              </Grid>
              <EventDateSelection control={control} errors={errors} />
              <FeedTypeSelection control={control} errors={errors} />
              <FeedAmount errors={errors} register={register} />
            </Grid>
          </ModalContent>
          <ModalActions>
            <Button
              color="primary"
              type="submit"
              disabled={!!Object.keys(errors).length}
            >
              <M id="Save" />
            </Button>
            <Button
              onClick={() => {
                clearErrors();
                handleClose();
              }}
              color="primary"
            >
              <M id="Cancel" />
            </Button>
          </ModalActions>
        </form>
      </Modal>
    );
  }
);

// sub components for add silo event form

interface IInputProps
  extends Partial<Pick<UseFormMethods, 'register' | 'errors' | 'control'>> {}

const EventDateSelection: FC<IInputProps> = ({ control, errors }) => {
  const intl = useIntl();
  return (
    <Fragment>
      <Grid item xs={12}>
        <Controller
          name="timestamp"
          control={control}
          render={({ onChange, onBlur, value, ref }) => (
            <DatePicker
              onBlur={onBlur}
              onChange={(date) => {
                return date ? onChange(date.toISOString()) : date;
              }}
              value={value}
              inputRef={ref}
              disableToolbar
              autoOk
              variant="inline"
              error={!!errors?.timestamp}
              helperText={
                errors?.timestamp && <M id={`${errors.timestamp.message}`} />
              }
              format={dateFormatString(intl)}
              maxDate={endOfYear(new Date())}
              margin="dense"
              id="date-picker-event-time"
              fullWidth
              label={intl.formatMessage({ id: 'Date' })}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => {}}>
                    <CalendarTodayIcon />
                  </IconButton>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Fragment>
  );
};

const FeedTypeSelection: FC<IInputProps> = observer(({ control, errors }) => {
  const theme = useTheme();
  const intl = useIntl();
  const orderStore = useStore('orderStore');
  const siloStore = useStore('siloStore');
  const { filteredFeedTypes } = siloStore;
  useEffect(() => {
    siloStore.loadFeedTypes();
  }, [siloStore, orderStore]);

  // Proper grouping of feed types depends on orders being loaded
  useEffect(() => {
    orderStore.loadOrders();
  }, [orderStore]);

  return (
    <Fragment>
      <Grid item xs={12}>
        <Controller
          name="feedtype_id"
          control={control}
          render={({ onChange, onBlur, value }) => {
            const selectedOption =
              filteredFeedTypes.find((t) => t.id === value) || null;
            return (
              <Autocomplete
                id="feed-type-selection"
                onBlur={onBlur}
                getOptionSelected={
                  (option, value) => option.id === value.id
                }
                groupBy={(option) => {
                  if (option.usageStatus === FeedTypeUsageStatus.ORDERED) {
                    return intl.formatMessage({ id: "OrderedFeedTypes" });
                  } else if (option.usageStatus === FeedTypeUsageStatus.COMPANY_SPECIFIC) {
                    return intl.formatMessage({ id: "CompanySpecificFeedTypes" });
                  } else if (option.usageStatus === FeedTypeUsageStatus.USED) {
                    return intl.formatMessage({ id: "UsedFeedTypes" });
                  } else {
                    return intl.formatMessage({ id: "OtherFeedTypes" });
                  }
                }}

                value={selectedOption}
                onChange={(event, value) => {
                  if (value?.id) onChange(value.id);
                }}
                options={filteredFeedTypes}
                getOptionLabel={(option) => option.name}
                style={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: `FeedType`,
                    })}
                  />
                )}
              />
            );
          }}
        />
        {!!errors?.feedtype_id && (
          <Box color={theme.palette.error.main} mt={1}>
            <Typography variant="caption" component="p">
              {intl.formatMessage({ id: `${errors.feedtype_id.message}` })}
            </Typography>
          </Box>
        )}
      </Grid>
    </Fragment>
  );
});

const FeedAmount: FC<IInputProps> = ({ register, errors = {} }) => {
  const intl = useIntl();
  return (
    <Grid item xs={12}>
      <TextField
        name="feederamount"
        id="add-silo-event-feed-amount"
        label={intl.formatMessage({ id: 'AmountKg' })}
        inputRef={register}
        error={!!errors.feederamount}
        helperText={
          errors.feederamount && <M id={`${errors.feederamount.message}`} />
        }
        margin="dense"
        fullWidth
      />
    </Grid>
  );
};
