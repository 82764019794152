import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Button, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import { useStore } from 'stores/store-hooks';
import { IOrderPackage } from 'models/orders';
import { NumericInput } from 'components/input-fields';
import { Modal, ModalContent, ModalActions } from 'components/modal-dialog';
import { isBulk } from './orders-utils';

const OpenDialogButton: FC<{
  editCartItem: (item: IOrderPackage | null) => any;
}> = ({ editCartItem }) => {
  const intl = useIntl();
  return (
    <Button
      color="primary"
      onClick={() => editCartItem({ amount: 0 })}
      startIcon={<AddIcon />}
      variant="outlined"
    >
      {intl.formatMessage({ id: 'AddProductToCart' })}
    </Button>
  );
};

export const AddToCartDialog = observer(() => {
  const intl = useIntl();
  const {
    addEditedCartItemToCart,
    editCartItem,
    editedCartItem,
    productsOrdered,
    updateEditedCartItem,
  } = useStore('orderStore');

  const bulk = isBulk(editedCartItem);
  return !editedCartItem ? (
    <OpenDialogButton editCartItem={editCartItem} />
  ) : (
    <Modal handleClose={() => editCartItem(null)}>
      <ModalContent>
        <Grid container direction="column" spacing={4}>
          <Grid item xs={12}>
            <Autocomplete
              getOptionLabel={(option) => option.description}
              onChange={(event, v) => {
                updateEditedCartItem({ product: v, package: v?.packages[0] });
              }}
              options={productsOrdered}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({ id: 'ProductName' })}
                />
              )}
              value={editedCartItem?.product || null}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              getOptionLabel={(option) => option.description}
              onChange={(event, v) => {
                updateEditedCartItem({ package: v });
              }}
              options={editedCartItem?.product?.packages || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({ id: 'PackageName' })}
                />
              )}
              value={editedCartItem?.package || null}
            />
          </Grid>
          <Grid item xs={12}>
            <NumericInput
              disabled={!editedCartItem?.package}
              fractionDigits={bulk ? 2 : 0}
              label={intl.formatMessage({
                id: bulk ? 'AmountKg' : 'AmountPcs',
              })}
              onChange={(n) => {
                updateEditedCartItem({ amount: n });
              }}
              value={editedCartItem?.amount}
            />
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions>
        <Button
          color="primary"
          disabled={
            !editedCartItem?.product ||
            !editedCartItem?.package ||
            !editedCartItem?.amount
          }
          onClick={addEditedCartItemToCart}
        >
          {intl.formatMessage({ id: 'Save' })}
        </Button>
        <Button color="primary" onClick={() => editCartItem(null)}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
      </ModalActions>
    </Modal>
  );
});
