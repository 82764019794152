/** @jsxRuntime classic */
/** @jsx jsx */
import { FC, Fragment, useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';
import Button from '@material-ui/core/Button';
import { FormattedMessage as M, useIntl } from 'react-intl';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { IFacility, SEASONS, SEASON_MODES } from 'models/facility';
import { Modal, ModalActions, ModalContent } from 'components/modal-dialog';
import { Controller, useForm, UseFormMethods } from 'react-hook-form';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { useStore } from 'stores/store-hooks';
import { DatePicker } from '@material-ui/pickers';
import { format, setMonth, startOfYear, setDate, endOfYear } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { dateFormatString } from 'translations/locales';

interface IFacilityAddDialog {
  handleClose: () => void;
  editedFacility?: IFacility | null;
  updateFacility: (companyId: number | null, data: IFacility) => Promise<any>;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required('error.validation.name')
    .max(256, 'error.validation.name'),
  seasonmode: yup.string().required('error.validation.seasonmode'),
  address: yup.string().max(128, 'error.validation.address'),
});

export const FacilityDialog = ({
  editedFacility,
  handleClose,
  updateFacility,
}: IFacilityAddDialog) => {
  if (editedFacility && !editedFacility.staticseason) {
    // ensure staticseason has a default value
    editedFacility.staticseason = SEASONS.Spring;
  }
  const {
    register,
    handleSubmit,
    errors,
    clearErrors,
    control,
    watch,
  } = useForm<IFacility>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: editedFacility || {
      seasonmode: SEASON_MODES.Automatic,
      seasonstartsummer: format(
        setDate(setMonth(new Date(), 5), 1),
        'yyyy-MM-dd'
      ),
      seasonstartautumn: format(
        setDate(setMonth(new Date(), 8), 1),
        'yyyy-MM-dd'
      ),
      staticseason: SEASONS.Spring,
    },
  });
  const toastStore = useStore('toastStore');
  const userStore = useStore('userStore');
  const seasonmode = watch('seasonmode');
  const [companyId, setCompanyId] = useState<number | ''>('');

  const submitData = async (data: IFacility) => {
    const response = await updateFacility(companyId || null, data);
    const errors = response?.errors;
    if (errors) {
      // some other error -> show generic error note
      toastStore.setToast('validation.error.unexpected');
      return;
    }
    // success
    handleClose();
  };
  return (
    <Modal handleClose={handleClose}>
      <form noValidate onSubmit={handleSubmit(submitData)}>
        <ModalContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography component="span" variant="subtitle2">
                <M id={editedFacility ? 'EditFacility' : 'AddFacility'} />
              </Typography>
            </Grid>
            {userStore.isRaisioAdminOrSuperuser && editedFacility === null && (
              <CompanySelectorRow
                companyId={companyId}
                setCompanyId={setCompanyId}
              />
            )}
            <FacilityNameRow register={register} errors={errors} />
            <FacilityAddressRow register={register} errors={errors} />
            <FacilitySeasonSelection control={control} />
            {seasonmode === SEASON_MODES.Static && (
              <StaticSeasonSelection control={control} />
            )}
            {seasonmode === SEASON_MODES.Automatic && (
              <AutomaticSeasonSelection
                control={control}
                facility={editedFacility}
              />
            )}
          </Grid>
        </ModalContent>
        <ModalActions>
          <Button
            color="primary"
            type="submit"
            disabled={
              !!Object.keys(errors).length ||
              (!editedFacility &&
                userStore.isRaisioAdminOrSuperuser &&
                !companyId)
            }
          >
            <M id="Save" />
          </Button>
          <Button
            onClick={() => {
              clearErrors();
              handleClose();
            }}
            color="primary"
          >
            <M id="Cancel" />
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};

/**
 * Sub components for various form fields
 */

interface IInputProps
  extends Partial<Pick<UseFormMethods, 'register' | 'errors' | 'control'>> {
  facility?: IFacility | null;
}

/**
 *
 * Company selector component rendered for RaisioAdmin user
 */
const CompanySelectorRow: FC<{
  companyId: number | '';
  setCompanyId: any;
}> = observer(({ companyId, setCompanyId }) => {
  const intl = useIntl();
  const companyStore = useStore('companyStore');
  const userStore = useStore('userStore');
  const { isRaisioAdminOrSuperuser } = userStore;
  useEffect(() => {
    if (isRaisioAdminOrSuperuser && companyStore.companies.length === 0) {
      companyStore.loadCompanies();
    }
  }, [companyStore, isRaisioAdminOrSuperuser]);

  return (
    <Grid item xs={12} id="company-select-row" key="company-select-row">
      <FormControl fullWidth>
        <InputLabel id="company-select-label">
          {intl.formatMessage({
            id: 'SelectCompany',
          })}
        </InputLabel>
        <Select
          value={companyId}
          onChange={(event) => setCompanyId(event.target.value)}
          id="facility-static-season-select"
          fullWidth
        >
          {companyStore.companies.map((company) => (
            <MenuItem key={company.name} value={company.id}>
              {company.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
});

/**
 * Facility name row component
 */
const FacilityNameRow: FC<IInputProps> = ({ register, errors = {} }) => (
  <Grid
    item
    xs={12}
    id="facility-dialog-name-row"
    key="facility-dialog-name-row"
  >
    <TextField
      name="name"
      id="facility-add-dialog-name"
      label={<M id="FacilityName" />}
      inputRef={register}
      error={!!errors.name}
      helperText={errors.name && <M id={`${errors.name.message}`} />}
      autoFocus
      margin="dense"
      fullWidth
    />
  </Grid>
);

/**
 * Facility address row component
 */
const FacilityAddressRow: FC<IInputProps> = ({ register, errors = {} }) => (
  <Grid
    item
    xs={12}
    id="facility-dialog-address-row"
    key="facility-dialog-address-row"
  >
    <TextField
      name="address"
      id="facility-add-dialog-address"
      label={<M id="Address" />}
      inputRef={register}
      error={!!errors.address}
      helperText={errors.address && <M id={`${errors.address.message}`} />}
      margin="dense"
      fullWidth
    />
  </Grid>
);

/**
 * Season selection radio button component
 */
const FacilitySeasonSelection: FC<IInputProps> = ({ control }) => {
  const intl = useIntl();
  return (
    <Grid item xs={12}>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          <M id="SeasonSelectionMode" />
        </FormLabel>
        <Controller
          name="seasonmode"
          control={control}
          as={
            <RadioGroup
              aria-label="seasons selection"
              css={(theme) => css`
                margin-top: ${theme.spacing(1)}px;
                margin-left: ${theme.spacing(4)}px;
                &.MuiFormGroup-root {
                  flex-direction: row;
                }
              `}
            >
              <FormControlLabel
                value={SEASON_MODES.Automatic}
                control={<Radio />}
                label={intl.formatMessage({ id: 'AutomaticSeason' })}
              />
              <FormControlLabel
                value={SEASON_MODES.Static}
                control={<Radio />}
                label={intl.formatMessage({ id: 'FixedSeason' })}
              />
            </RadioGroup>
          }
        />
      </FormControl>
    </Grid>
  );
};

/** Manual season selection */

const StaticSeasonSelection: FC<IInputProps> = ({ control }) => {
  const intl = useIntl();
  return (
    <Grid
      item
      xs={6}
      id="facility-dialog-static-season-row"
      key="facility-dialog-static-seasons-row"
      css={(theme) => css`
        display: flex;
        &&.MuiGrid-item {
          padding-left: ${theme.spacing(6)}px;
        }
      `}
    >
      <Controller
        name="staticseason"
        control={control}
        render={({ onChange, onBlur, value, ref }) => (
          <Select
            onBlur={onBlur}
            onChange={onChange}
            value={value || SEASONS.Spring}
            inputRef={ref}
            id="facility-static-season-select"
            fullWidth
          >
            {Object.values(SEASONS).map((season) => {
              return (
                <MenuItem key={season} value={season || ''}>
                  {intl.formatMessage({
                    id: season.charAt(0).toUpperCase() + season.slice(1),
                  })}
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
    </Grid>
  );
};

/** Automatic season selection */

const AutomaticSeasonSelection: FC<IInputProps> = ({ control, facility }) => {
  const intl = useIntl();
  return (
    <Fragment>
      <Grid
        item
        xs={6}
        css={(theme) => css`
          display: flex;
          &&.MuiGrid-item {
            padding-left: ${theme.spacing(6)}px;
          }
        `}
      >
        <Controller
          name="seasonstartsummer"
          control={control}
          render={({ onChange, onBlur, value, ref }) => (
            <DatePicker
              onBlur={onBlur}
              onChange={(date) =>
                date ? onChange(format(date, 'yyyy-MM-dd')) : date
              }
              value={value}
              inputRef={ref}
              disableToolbar
              autoOk
              variant="inline"
              format={dateFormatString(intl)}
              minDate={
                facility
                  ? startOfYear(
                      new Date(facility.seasonstartsummer || '2000-01-01')
                    )
                  : startOfYear(new Date())
              }
              maxDate={
                facility
                  ? endOfYear(
                      new Date(facility.seasonstartsummer || '2100-01-01')
                    )
                  : endOfYear(new Date())
              }
              margin="dense"
              id="date-picker-seasonstartsummer"
              label={intl.formatMessage({ id: 'SeasonStartSummer' })}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="select season summer start date"
                    onClick={() => {}}
                  >
                    <CalendarTodayIcon />
                  </IconButton>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={6}
        css={css`
          display: flex;
          justify-content: center;
        `}
      >
        <Controller
          name="seasonstartautumn"
          control={control}
          render={({ onChange, onBlur, value, ref }) => (
            <DatePicker
              onBlur={onBlur}
              onChange={(date) =>
                date ? onChange(format(date, 'yyyy-MM-dd')) : date
              }
              value={value}
              inputRef={ref}
              disableToolbar
              autoOk
              variant="inline"
              format={dateFormatString(intl)}
              minDate={
                facility
                  ? startOfYear(
                      new Date(facility.seasonstartautumn || '2000-01-01')
                    )
                  : startOfYear(new Date())
              }
              maxDate={
                facility
                  ? endOfYear(
                      new Date(facility.seasonstartautumn || '2100-01-01')
                    )
                  : endOfYear(new Date())
              }
              margin="dense"
              id="date-picker-seasonstartautumn"
              label={intl.formatMessage({ id: 'SeasonStartAutumn' })}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="select season autumn start date"
                    onClick={() => {}}
                  >
                    <CalendarTodayIcon />
                  </IconButton>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Fragment>
  );
};
