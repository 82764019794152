import merge from 'lodash.merge';
import { IntlShape } from 'react-intl';

export const chartOptions = (defaults: any, intl: IntlShape) => {
  return merge(
    {},
    {
      title: {
        show: false,
      },
      xAxis: {
        type: 'time',
        splitNumber: 10,
        axisLabel: {
          formatter: (value: string) => {
            const asDate = new Date(value);
            const label = `${asDate.getDate()}.${
              asDate.getMonth() + 1
            }.${asDate.getFullYear()}`;
            return label;
          },
        },
      },
      yAxis: {
        type: 'value',
        nameLocation: 'middle',
        nameGap: 30,
        nameTextStyle: {
          fontSize: 12,
        },
      },
      dataZoom: {
        show: true,
        realtime: true,
        height: 40,
        labelFormatter: (value: string) => {
          const asDate = new Date(value);
          const label = `${asDate.getDate()}.${
            asDate.getMonth() + 1
          }.${asDate.getFullYear()}`;
          return label;
        },
      },
      calculable: false,
      animation: true,
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          var date = new Date(params[0].axisValue);
          const dateLabel = `${date.getDate()}.${
            date.getMonth() + 1
          }.${date.getFullYear()}  ${date.getHours()}:${
            date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
          }`;
          var retval: string[] = [];
          params.forEach((param: any) => {
            retval.push(
              '<span style="fontFamily: \'Roboto Slab\'">' +
                param.marker +
                ' ' +
                param.seriesName +
                ': ' +
                intl.formatNumber(param.value[1], {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }) +
                ' °C' +
                '</span>'
            );
          });
          return dateLabel + '<br>' + retval.join('<br>');
        },
        axisPointer: {
          animation: true,
        },
      },
      toolbox: {
        show: true,
        feature: {
          mark: {
            show: true,
          },
          magicType: {
            show: true,
            type: ['line', 'bar'],
            title: {
              line: 'Line',
              bar: 'Bar chart',
            },
          },
          restore: {
            title: 'Restore',
            show: true,
          },
          saveAsImage: {
            show: true,
            title: 'Save as image',
          },
        },
      },
    },
    defaults
  );
};
