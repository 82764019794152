import {IReportSite} from "../../../models/removed-fish-report";
import React, {FC, ReactChild, useState} from "react";
import {ExpandableTableRow} from "../expandable-table-row";
import {IconButton, TableCell as MuiTableCell, Typography, withStyles} from "@material-ui/core";
import {FormattedMessage as M, useIntl} from "react-intl";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {getAmountString} from "./removed-fish-utils";

interface ISiteRow {
  site: IReportSite;
  children: ReactChild | React.ReactChild[];
  color: string;
}

export const SiteRow: FC<ISiteRow> = ({site, color,  children}) => {

  const [expanded, setExpanded] = useState(false);
  const intl = useIntl();

  const totalFishRemovedKg = (site.deadFishKg || 0) + (site.removedFishKg || 0);
  const totalFishRemoved = (site.deadFish || 0) + (site.removedFish || 0);

  const TableCell = withStyles({
    root: {
      borderBottom: "none",
      backgroundColor: color,
    }
  })(MuiTableCell);


  return (
    <ExpandableTableRow expandComponent={children} expanded={expanded}>
      <TableCell>
        <IconButton onClick={() => setExpanded(!expanded)}>
          {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell>
        <Typography variant="h6" color="textPrimary">
          <M id="Site"/>
        </Typography>
        <Typography variant="subtitle2" color="primary">
          {site.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="h6" color="primary">
          <M id={'DeadFish'}/>
        </Typography>
        <Typography variant="subtitle2" color="primary">
          {getAmountString(intl, site.deadFishKg, site.deadFish)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="h6" color="primary">
          <M id={'OtherReason'}/>
        </Typography>
        <Typography variant="subtitle2" color="primary">
          {getAmountString(intl, site.removedFishKg, site.removedFish)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="h6" color="primary">
          <M id={'Total'}/>
        </Typography>
        <Typography variant="subtitle2" color="primary">
          {getAmountString(intl, totalFishRemovedKg, totalFishRemoved)}
        </Typography>
      </TableCell>
    </ExpandableTableRow>
  );
}
