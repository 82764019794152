/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { FC } from 'react';
import { AppBar, Box, Toolbar } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStore } from 'stores/store-hooks';
import { LanguageSelect } from './language-select';
import { APPBAR_HEIGHT } from '../../constants';
import { NavigationButtons } from './navigation-buttons';
import { useLocation } from 'react-router';

export const AppBarComponent: FC<{ leftOffset?: number }> = observer(
  ({ leftOffset = 0 }) => {
    const userStore = useStore('userStore');
    const { drawerWidth } = useStore('settingsStore');
    const basinStore = useStore('basinStore');
    const { isAuthenticated } = userStore;
    const { selectedBasin } = basinStore;
    const location = useLocation();
    const isBasinView = location.pathname.includes('basin/');

    if (!isAuthenticated) {
      return (
        <Box
          css={css`
            display: flex;
            justify-content: flex-end;
            align-content: center;
            position: absolute;
            right: 8px;
          `}
        >
          <LanguageSelect textColor="white" />
        </Box>
      );
    }

    return isAuthenticated && isBasinView && !selectedBasin?.deleted ? (
      <React.Fragment>
        <AppBar
          color="secondary"
          elevation={0}
          css={(theme) => css`
            && {
              height: ${APPBAR_HEIGHT}px;
              background: ${theme.palette.background.default};
              align-items: flex-start;
              width: auto;
              left: ${drawerWidth + 8}px;
              // left: ${leftOffset}px;
            }
          `}
        >
          <Toolbar
            css={(theme) => css`
              && {
                &.MuiToolbar-gutters {
                  padding-left: 0;
                }
                width: 100%;
                border-radius: 5px;
              }
            `}
          >
            <Box
              css={css`
                display: flex;
                flex-direction: row;
                width: 100%;
              `}
            >
              <NavigationButtons />
            </Box>
          </Toolbar>
        </AppBar>
        <Toolbar />
      </React.Fragment>
    ) : null;
  }
);
