import React, { FC } from 'react';
import { IntlShape } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { formatDate } from 'translations/locales';
import { DeliveryType, IContactInfo, IDeliveryInfo } from 'models/orders';

// XXX this really only works for Western-style names. Consider rethinking the
// entire name system if we expand to Hungary or the East.
// XXX This currently doesn't display the country, but that can be easily added
// below the zip code and city line as it becomes necessary.
export const ContactInfoView: FC<{ data: IContactInfo }> = ({ data }) => (
  <Grid container direction="column" item>
    <Grid item>
      <Typography>{data.firstName + ' ' + data.lastName}</Typography>
    </Grid>
    {!(data.company && data.businessId) ? null : (
      <Grid item>
        <Typography>
          {(data.company || '') +
            (data.businessId ? ' (' + data.businessId + ')' : '')}
        </Typography>
      </Grid>
    )}
    <Grid item>
      <Typography>{data.address1}</Typography>
    </Grid>
    {!data.address2 ? null : (
      <Grid item>
        <Typography>{data.address2}</Typography>
      </Grid>
    )}
    <Grid item>
      <Typography>{data.zipCode + ' ' + data.city}</Typography>
    </Grid>
    <Grid item>
      <Typography>{data.email}</Typography>
    </Grid>
    <Grid item>
      <Typography>{data.phone}</Typography>
    </Grid>
  </Grid>
);

const LabeledDateView: FC<{
  date?: string;
  intl: IntlShape;
  intlId: string;
}> = ({ date = '', intl, intlId }) => (
  <Grid container alignItems="center" spacing={2} item xs={6}>
    <Grid item>
      <Typography variant="h6">
        {intl.formatMessage({ id: intlId }) + ':'}
      </Typography>
    </Grid>
    <Grid item>
      <Typography>
        {formatDate(intl, new Date(date))}
      </Typography>
    </Grid>
  </Grid>
);

export const DeliveryInfoView: FC<{
  data: IDeliveryInfo;
  intl: IntlShape;
}> = ({ data, intl }) => (
  <Grid container direction="column" item>
    <Grid container item>
      {data.type !== DeliveryType.DELIVERY ? null : (
        <LabeledDateView
          date={data.deliveryDateFrom}
          intl={intl}
          intlId="DeliveryFrom"
        />
      )}
      {data.type !== DeliveryType.DELIVERY ? null : (
        <LabeledDateView
          date={data.deliveryDateTo}
          intl={intl}
          intlId="DeliveryTo"
        />
      )}
      {data.type !== DeliveryType.PICKUP ? null : (
        <LabeledDateView
          date={data.pickupDate}
          intl={intl}
          intlId="PickupDate"
        />
      )}
    </Grid>
    {!data.additionalInformation ? null : (
      <Grid container alignItems="center" spacing={2} item>
        <Grid item>
          <Typography variant="h6">
            {intl.formatMessage({ id: 'AdditionalInformation' }) + ':'}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>{data.additionalInformation}</Typography>
        </Grid>
      </Grid>
    )}
  </Grid>
);
