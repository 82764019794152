import {makeAutoObservable, runInAction} from 'mobx';
import {format} from 'date-fns';
import {
  clearFishBasinStatusHistory,
  getFastingEvents,
  getFishBasinStatuses,
  markBatchProcessed,
  moveFishToFasting,
  saveCleaningResult,
  updateCleaningResult,
} from 'services/api';
import {
  HarvestingAction,
  ICleaningResult,
  IFastingEvent,
  ILocalCleaningResult,
  IOngoingFast,
  IWeightFacts,
} from 'models/harvesting';
import basinStore from './basin-store';
import toastStore from './toast-store';
import {ILastStatus} from "../models/fishbasin";

const round2p = (n: number) => Math.round(n * 100) / 100;

class HarvestingStore {
  constructor() {
    makeAutoObservable(this);
  }

  action: HarvestingAction = HarvestingAction.NOTHING;
  fishbasinStatuses: ILastStatus[] = [];
  harvestingDate: Date = new Date();
  amountToFastOrHarvest: number = 0;
  fastOrHarvestAll: boolean = false;
  details: string | null = null;
  fastToHarvest: IOngoingFast | null = null;
  harvested: boolean = true;

  // Used when calculating cleaning details.
  // Fact is a not-estimated weight value the user has inputted
  facts: IWeightFacts = {live: false, cleaned: false, roe: false, gut: false};

  // Cleaning details
  cleanedweight: number = 0;
  liveweight: number = 0;
  cleaningdate: string = format(new Date(), 'yyyy-MM-dd');
  originalweight: number = 0;
  gut_percentage: number = 0;
  gut_weight: number = 0;
  roe_percentage: number = 0;
  roe_weight: number = 0;
  averageweight: number = 0;
  batchCompletelyGutted: boolean = false;
  eventToEdit: number = -1;

  private fastingEvents: IFastingEvent[] = [];

  get isRetroactiveFastAndHarvest(): boolean {
    return this.harvestingDate.toISOString().split('T')[0] !== new Date().toISOString().split('T')[0]
  }

  get ongoingFasts(): IOngoingFast[] {
    return this.fastingEvents.filter((fast) => !fast.processed).map((fast) => ({
      id: fast.id,
      timestamp: fast.timestamp,
      biomass: fast.fastingbiomass_table - fast.cleaningresults.reduce(
        (n, cr) => n + cr.originalweight,
        0
      ),
      amount: fast.fastingamount - fast.cleaningresults.reduce(
        (n, cr) => n + cr.amount,
        0
      ),
      details: fast.details,
    }));
  }

  get cleaningResults(): ICleaningResult[] {
    const results = this.fastingEvents.flatMap((fast) => fast.cleaningresults);
    results.sort((a, b) => (new Date(a.cleaningdate)).getTime()
                              - (new Date(b.cleaningdate)).getTime());
    return results;
  }

  // XXX Only computes values displayed in harvesting table; others are null/0.
  get cleaningResultsSummary(): ICleaningResult {
    const results = this.cleaningResults.filter(result => result.harvested === true)
    return {
      url: "",
      id: 0,
      fishbasinevent: "",
      cleaningdate: "",
      originalweight: results.reduce((n, cr) => n + cr.originalweight, 0),
      liveweight: results.reduce(
        (n, cr) => n + Number.parseFloat(cr.liveweight),
        0
      ) + "",
      cleanedweight: results.reduce(
        (n, cr) => n + Number.parseFloat(cr.cleanedweight),
        0
      ) + "",
      averageweight: "",
      amount: results.reduce((n, cr) => n + cr.amount, 0),
      roe_weight: results.reduce(
        (n, cr) => n + Number.parseFloat(cr.roe_weight),
        0
      ) + "",
      roe_percentage: "",
      gut_weight: results.reduce(
        (n, cr) => n + Number.parseFloat(cr.gut_weight),
        0
      ) + "",
      gut_percentage: "",
      details: "",
      harvested: this.harvested
    };
  }
  get removedResultsSummary(): ICleaningResult {
    const results = this.cleaningResults.filter(result => result.harvested === false)
    return {
      url: "",
      id: 0,
      fishbasinevent: "",
      cleaningdate: "",
      originalweight: results.reduce((n, cr) => n + cr.originalweight, 0),
      liveweight: results.reduce(
        (n, cr) => n + Number.parseFloat(cr.liveweight),
        0
      ) + "",
      cleanedweight: results.reduce(
        (n, cr) => n + Number.parseFloat(cr.cleanedweight),
        0
      ) + "",
      averageweight: "",
      amount: results.reduce((n, cr) => n + cr.amount, 0),
      roe_weight: results.reduce(
        (n, cr) => n + Number.parseFloat(cr.roe_weight),
        0
      ) + "",
      roe_percentage: "",
      gut_weight: results.reduce(
        (n, cr) => n + Number.parseFloat(cr.gut_weight),
        0
      ) + "",
      gut_percentage: "",
      details: "",
      harvested: this.harvested
    };
  }
  // Get cleaning result that is currently being edited/created
  get localCleaningResult(): ILocalCleaningResult {
    return {
      amount: this.amountToFastOrHarvest,
      cleanedweight: this.cleanedweight,
      liveweight: this.liveweight,
      cleaningdate: this.cleaningdate,
      gut_percentage: this.gut_percentage,
      gut_weight: this.gut_weight,
      originalweight: this.originalweight,
      roe_percentage: this.roe_percentage,
      roe_weight: this.roe_weight,
      averageweight: this.averageweight,
      details: this.details,
      harvested: this.harvested
    };
  }

  loadFastingEvents = async () => {

    const addLiveWeight = (result: ICleaningResult) => {
      result.liveweight = Math.round(
        (parseFloat(result.cleanedweight) + parseFloat(result.roe_weight) + parseFloat(result.gut_weight)) * 100
        ) / 100 + "";
    }

    try {
      if (!basinStore.selectedBasin?.id) throw new Error('No basin selected');
      const { data } = await getFastingEvents(basinStore.selectedBasin.id);
      runInAction(() => {
        data.forEach(event => event.cleaningresults.forEach(result => addLiveWeight(result)));
        this.fastingEvents = data;
      });
    } catch (error) {
      toastStore.setToast('LoadFastingEventsFailed');
    }
  };

  performAction = async () => {

    const doActionFast = async (timestamp: Date = new Date()) => {
      if (!basinStore.selectedBasin?.id) throw new Error('No basin selected');
      const { data } = await moveFishToFasting(
        basinStore.selectedBasin.id,
        this.amountToFastOrHarvest,
        this.details,
        timestamp
      );
      return data;
    };
    const doActionHarvestEdit = async () => {
      if (!this.fastToHarvest) throw new Error("No fast set for harvesting");

      await updateCleaningResult(
        this.fastToHarvest.id,
        this.localCleaningResult,
        this.eventToEdit
      );

      runInAction(() => {
        const cleaningResult = this.cleaningResults.find(
          (cr) => cr.id === this.eventToEdit
        );

        // This will never be undefined, but it make the compiler happy
        if (cleaningResult) {
          cleaningResult.cleanedweight = this.cleanedweight + "";
          cleaningResult.gut_percentage = this.gut_percentage + "";
          cleaningResult.gut_weight = this.gut_weight + "";
          cleaningResult.roe_percentage = this.roe_percentage + "";
          cleaningResult.roe_weight = this.roe_weight + "";
          cleaningResult.details = this.details || "";
        }

        this.clearAll();
      });
    }
    const doActionHarvest = async (fastAndCleanEvent: IFastingEvent | undefined = undefined) => {
      if (!this.fastToHarvest) throw new Error("No fast set for harvesting");

      const { data } = await saveCleaningResult(
        this.fastToHarvest.id,
        this.localCleaningResult
      );

      data.liveweight = (
        Number.parseFloat(data.cleanedweight) +
        Number.parseFloat(data.roe_weight) +
        Number.parseFloat(data.gut_weight) + ''
      );

      if (this.fastOrHarvestAll || this.batchCompletelyGutted || this.action === HarvestingAction.FASTANDHARVEST || this.action === HarvestingAction.CLEAR) {
        await markBatchProcessed(this.fastToHarvest.id);
      }

      runInAction(() => {
        const event = this.fastingEvents.find(
          (e) => e.id === this.fastToHarvest?.id
        );

        // Event should never be undefined, but this makes the compiler happy
        if (event) {
          event.cleaningresults.push(data);

          if (this.fastOrHarvestAll || this.batchCompletelyGutted || this.action === HarvestingAction.FASTANDHARVEST) {
            event.processed = true;
          }
        }

        this.harvested = true;
        this.clearAll();
      });
    }

    try {
      if (this.action === HarvestingAction.FAST) {
        const data = await doActionFast();
        runInAction(() => {
          this.fastingEvents.push(data);
          this.clearAll();
          if (basinStore?.selectedBasin?.id) {
            basinStore.loadBasin("" + basinStore.selectedBasin.id);
          }
        });
      } else if (this.action === HarvestingAction.HARVEST && this.eventToEdit >= 0) {
        await doActionHarvestEdit()
      } else if (this.fastToHarvest && this.action === HarvestingAction.HARVEST) {
        this.fastToHarvest.harvested = true;
        await doActionHarvest();
      } else if(this.fastToHarvest && this.action === HarvestingAction.CLEAR) {
        this.fastToHarvest.harvested = false;
        await doActionHarvest();
      } else if (this.action === HarvestingAction.FASTANDHARVEST && this.isRetroactiveFastAndHarvest) {
        if(!basinStore.selectedBasin) throw new Error('No basin selected');
        if(!this.fastToHarvest) throw new Error('No fast to harvest');
        this.originalweight = this.fastToHarvest.biomass;
        const data = await doActionFast(this.harvestingDate);
        const tempDate = this.harvestingDate;
        runInAction(() => {
          if(!this.fastToHarvest) throw new Error('No fast to harvest');
          this.fastToHarvest.id = data.id;
          this.fastingEvents.push(data);
          this.setCleaningDate(this.harvestingDate.toISOString().split('T')[0]);
        })
        await doActionHarvest();
        await clearFishBasinStatusHistory(basinStore.selectedBasin.id, tempDate.toISOString().split('T')[0]);
        await basinStore.loadBasin(basinStore.selectedBasin.id + '');
        basinStore.selectedBasin.feedingenabled && await basinStore.toggleCalculation(basinStore.selectedBasin.id)
      } else if (this.action === HarvestingAction.FASTANDHARVEST) {
        const data = await doActionFast();
        runInAction(() => {
          if(!this.fastToHarvest) throw new Error('No fast to harvest');
          this.fastToHarvest.id = data.id;
          this.fastingEvents.push(data);
        })
        await doActionHarvest();

        if(basinStore.selectedBasin) await basinStore.loadBasin(basinStore.selectedBasin.id + '');
      } else {
        throw new Error('No action selected')
      }
    } catch (error) {
      toastStore.setToast(
        this.action === HarvestingAction.FAST
          ? "HarvestingFastActionFailed"
          : "HarvestingHarvestActionFailed"
      );
      return false;
    }
  };

  setActionMoveToFast = () => {
    this.amountToFastOrHarvest = basinStore.selectedBasin?.currentamount || 0;
    this.fastOrHarvestAll = true;
    this.action = HarvestingAction.FAST;
  };

  setActionHarvest = (fast: IOngoingFast) => {
    this.amountToFastOrHarvest = fast.amount;
    this.fastToHarvest = fast;
    this.originalweight = fast.biomass;
    this.fastOrHarvestAll = true;
    this.action = HarvestingAction.HARVEST;
  };

  setActionRemoveRestFromHarvest = (fast: IOngoingFast) => {
    this.amountToFastOrHarvest = fast.amount;
    this.fastToHarvest = fast;
    this.originalweight = fast.biomass;
    this.fastOrHarvestAll = true;
    this.harvested = false;
    this.action = HarvestingAction.CLEAR;
  };

  setActionFastAndClean = () => {
    const avgFishWeight = (basinStore.selectedBasin?.currentaverageweights || [0])[0];

    this.clearAll();
    this.amountToFastOrHarvest = basinStore.selectedBasin?.currentamount || 0;
    this.originalweight = (avgFishWeight * this.amountToFastOrHarvest / 1000);
    this.fastOrHarvestAll = true;
    this.fastToHarvest = {
      id: -1,
      timestamp: new Date().toISOString(),
      biomass: (avgFishWeight * this.amountToFastOrHarvest / 1000),
      amount: this.amountToFastOrHarvest,
      details: null
    };
    this.action = HarvestingAction.FASTANDHARVEST;
  };

  setActionEditCleaningResult = (cr: ICleaningResult) => {
    this.amountToFastOrHarvest = cr.amount;
    this.details = cr.details;
    const fast = this.fastingEvents.find((fast) => {
      return fast.cleaningresults.find((result) => result.id === cr.id);
    });
    this.fastToHarvest = {
      id: fast?.id || 0,
      timestamp: fast?.timestamp || "",
      amount: fast?.fastingamount || 0,
      biomass: fast?.fastingbiomass_table || 0,
      details: fast?.details || "",
    };
    this.liveweight = parseFloat(cr.liveweight);
    this.cleanedweight = Number.parseFloat(cr.cleanedweight);
    this.originalweight = cr.originalweight;
    this.cleaningdate = cr.cleaningdate;
    this.gut_percentage = Number.parseFloat(cr.gut_percentage);
    this.gut_weight = Number.parseFloat(cr.gut_weight);
    this.roe_percentage = Number.parseFloat(cr.roe_percentage);
    this.roe_weight = Number.parseFloat(cr.roe_weight);
    this.averageweight = Number.parseFloat(cr.averageweight);
    this.eventToEdit = cr.id;
    this.action = HarvestingAction.HARVEST;
  };

  fetchFishbasinStatuses = async () => {
    if(!basinStore.selectedBasin) {
      toastStore.setToast(
        this.action === HarvestingAction.FAST || this.action === HarvestingAction.FASTANDHARVEST
          ? "HarvestingFastActionFailed"
          : "HarvestingHarvestActionFailed"
      );
      return false;
    }

    const statuses = (await getFishBasinStatuses(basinStore.selectedBasin.id)).data;
    runInAction(() => {
      this.fishbasinStatuses = statuses;
    });
  }

  selectHarvestingDateIfPossible = (date: Date | null) => {
    if(!date) throw Error('ErrorHarvestingNoDateSelected');
    runInAction(() => {
      this.harvestingDate = date;
    });

    const dateStr = date.toISOString().split('T')[0];
    const status = this.fishbasinStatuses.find(e => e.eventdate === dateStr);
    if (!status) throw Error('ErrorHarvestingNoStatusFound');
    if (!status.currentamount || status.currentamount !== basinStore.selectedBasin?.currentamount) throw Error('ErrorHarvestingInvalidStatus');

    runInAction(() => {
      this.amountToFastOrHarvest = status.currentamount || 0;
      this.fastOrHarvestAll = true;
      this.fastToHarvest = {
        id: -1,
        timestamp: new Date().toISOString(),
        biomass: status.biomass_user || 0,
        amount: this.amountToFastOrHarvest,
        details: null
      };
    });
  }

  setAmountToFastOrHarvest = (amount: number) => {
    this.amountToFastOrHarvest = amount;

    const fraction = amount / (this.fastToHarvest?.amount || 1);
    this.originalweight = (this.fastToHarvest?.biomass || 0) * fraction;
    this.fastOrHarvestAll = false;
  };

  setHarvestingDate = (date: Date) => {
    this.harvestingDate = date;
  }

  setDetails = (details: string) => {
    this.details = details;
  };

  setCleaningDate = (date: string) => {
    this.cleaningdate = date;
  };

  setCleanedWeightToFact = () => this.facts.cleaned = true;
  setLiveWeightToFact = () => this.facts.live = true;
  setRoeWeightToFact = () => this.facts.roe = true;
  setGutWeightToFact = () => this.facts.gut = true;

  setCleanedWeight = (weight: number) => {
    this.cleanedweight = weight;
    this.setGutWeight(this.gut_weight);
    this.setRoeWeight(this.roe_weight);
  };

  setLiveWeight = (weight: number) => {
    this.liveweight = weight;
    this.setGutWeight(this.gut_weight);
    this.setRoeWeight(this.roe_weight);
  }

  updateLiveWeight = () => {
    this.setLiveWeight(this.cleanedweight + this.roe_weight + this.gut_weight);
  }

  setGutWeight = (weight: number) => {
    this.gut_weight = round2p(weight);
    this.gut_percentage = this.liveweight ? Math.round(
      ((weight || 0) * 10000) / this.liveweight) / 100 : 0;
  };

  // Must not be called on a value outside of the inclusive range 0-100
  setGutPercentage = (percentage: number) => {
    this.gut_percentage = round2p(percentage);
    this.gut_weight = Math.round((percentage || 0) * this.liveweight) / 100;
  };

  setRoeWeight = (weight: number) => {
    this.roe_weight = round2p(weight);
    this.roe_percentage = this.liveweight ? Math.round(
      ((weight || 0) * 10000) / this.liveweight) / 100 : 0;
  };

  setRoePercentage = (percentage: number) => {
    this.roe_percentage = round2p(percentage);
    this.roe_weight = Math.round((percentage || 0) * this.liveweight) / 100;
  };

  toggleBatchCompletelyGutted = () => {
    this.batchCompletelyGutted = !this.batchCompletelyGutted;
  };

  clearAll = () => {
    this.harvestingDate = new Date();
    this.amountToFastOrHarvest = 0;
    this.fastOrHarvestAll = false;
    this.details = null;
    this.fastToHarvest = null;
    this.cleanedweight = 0;
    this.liveweight = 0;
    this.originalweight = 0;
    this.cleaningdate = format(new Date(), "yyyy-MM-dd");
    this.gut_percentage = 0;
    this.gut_weight = 0;
    this.roe_percentage = 0;
    this.roe_weight = 0;
    this.averageweight = 0;
    this.batchCompletelyGutted = false;
    this.eventToEdit = -1;
    this.action = HarvestingAction.NOTHING;
    this.facts = {live: false, cleaned: false, roe: false, gut: false};
  };

  get estimateLiveWeight(): number {
    return (this.fastToHarvest?.biomass || 0) * this.amountToFastOrHarvest / (this.fastToHarvest?.amount || 1);
  }

  calculateHarvestingValues = () => {

    //Equation solver function. Calculates remaining values based on facts and/or estimates

    const lw = this.facts.live;
    const cw = this.facts.cleaned;
    const rw = this.facts.roe;
    const gw = this.facts.gut;

    const estimateGutPercentage = 17;
    const estimateLiveWeight = this.estimateLiveWeight;

    if(!lw && !cw && !rw && !gw) {
      this.setLiveWeight(round2p(estimateLiveWeight));
      this.setGutPercentage(estimateGutPercentage);
      this.cleanedweight = round2p(this.liveweight - this.gut_weight);
    }
    else if(!lw && !cw && !rw && gw) {
      this.setLiveWeight(round2p(estimateLiveWeight));
      this.cleanedweight = round2p(this.liveweight - this.gut_weight);
    }
    else if(!lw && !cw && rw && !gw) {
      this.setLiveWeight(round2p(estimateLiveWeight));
      this.setGutPercentage(estimateGutPercentage);
      this.cleanedweight = round2p(this.liveweight - this.gut_weight - this.roe_weight);
    }
    else if(!lw && !cw && rw && gw) {
      this.setLiveWeight(round2p(estimateLiveWeight));
      this.cleanedweight = round2p(this.liveweight - this.gut_weight - this.roe_weight);
    }
    else if(!lw && cw && !rw && !gw) {
      this.setLiveWeight(round2p(this.cleanedweight / (1 - (estimateGutPercentage/100))))
      this.setGutWeight(this.liveweight - this.cleanedweight);
    }
    else if(!lw && cw && !rw && gw) {
      this.setLiveWeight(round2p(this.cleanedweight + this.gut_weight));
    }
    else if(!lw && cw && rw && !gw) {
      this.setLiveWeight(round2p(
        (this.cleanedweight + this.roe_weight) / (1 - (estimateGutPercentage / 100))
      ));
      this.setGutWeight(this.liveweight - this.cleanedweight - this.roe_weight);
    }
    else if(!lw && cw && rw && gw) {
      this.setLiveWeight(round2p(this.cleanedweight + this.roe_weight + this.gut_weight));
    }
    else if(lw && !cw && !rw && !gw) {
      this.setGutPercentage(estimateGutPercentage);
      this.cleanedweight = round2p(this.liveweight - this.gut_weight);
    }
    else if(lw && !cw && !rw && gw) {
      this.cleanedweight = round2p(this.liveweight - this.gut_weight);
    }
    else if(lw && !cw && rw && !gw) {
      this.setGutPercentage(estimateGutPercentage);
      this.cleanedweight = round2p(this.liveweight - this.roe_weight - this.gut_weight);
    }
    else if(lw && !cw && rw && gw) {
      this.cleanedweight = round2p(this.liveweight - this.roe_weight - this.gut_weight);
    }
    else if(lw && cw && !rw && !gw) {
      this.setGutWeight(this.liveweight - this.cleanedweight);
    }
    else if(lw && cw && !rw && gw) {
      this.setRoeWeight(this.liveweight - this.cleanedweight - this.gut_weight);
    }
    else if(lw && cw && rw && !gw) {
      this.setGutWeight(this.liveweight - this.cleanedweight - this.roe_weight);
    }
    else if(lw && cw && rw && gw) {

    }
  };
}

export default new HarvestingStore();
