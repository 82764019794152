/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/react';
import { FormattedMessage as M } from 'react-intl';
import { InlineIcon } from '@iconify/react';
import fastForwardLine from '@iconify-icons/clarity/fast-forward-line';
import { FC } from 'react';
import {
  Box,
  Button,
  Typography,
  Theme,
} from '@material-ui/core';
import { useStore } from 'stores/store-hooks';
import { observer } from 'mobx-react-lite';
import AutorenewIcon from '@material-ui/icons/Autorenew';

export const ForceFeedButton: FC<{
  handleClick: () => void;
}> = observer(({ handleClick }) => {
  const iotStore = useStore('iotStore');

  const spin = keyframes`
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  `;

  const buttonTypographyStyle = (theme: Theme) => css`
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: ${theme.spacing(1)}px;
    > svg {
      margin-right: ${theme.spacing(1)}px;
    }
  `;

  return (
    <Box display="flex" alignItems="center">
      <Button
        fullWidth
        color="primary"
        variant="contained"
        type="submit"
        onClick={() => {
          if(!iotStore.hasPendingForceFeedCommand) handleClick();
        }}
      >
        <Typography component="span" variant="h5" css={buttonTypographyStyle}>
          {iotStore.hasPendingForceFeedCommand ? (
            <AutorenewIcon
              css={(theme) => css`
                color: ${theme.palette.line2.main};
                animation: ${spin} 1s ease infinite;
              `}
            />
          ) : (
            <InlineIcon icon={fastForwardLine} height={24} />
          )}
          <M id="ForceFeed" />
        </Typography>
      </Button>
    </Box>
  );
});
