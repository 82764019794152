export const LAYOUT_DRAG_ENABLED = false;
export const LAYOUT_RESIZE_ENABLED_ = false;

export const breakpoints = { lg: 1100, md: 768 };
export const cols = { lg: 20, md: 4, sm: 4 };
export const layouts = {
  lg: [
    { i: 'filter-widget', x: 0, y: 1, w: 8, h: 1, static: true },
    { i: 'menu-widget', x: 0, y: 0, w: 8, h: 1, static: true },
    { i: 'fish-count', x: 8, y: 0, w: 4, h: 2 },
    { i: 'basin-count', x: 12, y: 0, w: 4, h: 2 },
    { i: 'biomass', x: 16, y: 0, w: 4, h: 2 },
    { i: 'site-list', x: 0, y: 1, w: 20, h: 4 },
    { i: 'company-list', x: 0, y: 1, w: 20, h: 4 },
    { i: 'comments', x: 0, y: 2, w: 10, h: 4 },
    { i: 'feed-stock', x: 10, y: 2, w: 10, h: 4 },
  ],
  md: [
    { i: 'filter-widget', x: 0, y: 1, w: 4, h: 1, static: true },
    { i: 'menu-widget', x: 0, y: 0, w: 8, h: 1, static: true },
    { i: 'basin-count', x: 0, y: 1, w: 2, h: 2 },
    { i: 'fish-count', x: 2, y: 1, w: 2, h: 2 },
    { i: 'site-list', x: 0, y: 2, w: 4, h: 6 },
    { i: 'company-list', x: 0, y: 2, w: 4, h: 3 },
    { i: 'biomass', x: 0, y: 0, w: 4, h: 2 },
    { i: 'comments', x: 0, y: 6, w: 4, h: 6 },
    { i: 'feed-stock', x: 0, y: 7, w: 4, h: 3 },
  ],
};
