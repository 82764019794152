/** @jsxRuntime classic */
/** @jsx jsx */
import React, { FC } from 'react';
import { css, jsx } from '@emotion/react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@material-ui/core';
import { Column, useTable } from 'react-table';

export const CompanyListTable: FC<{
  data: any[];
  columns: Column<any>[];
}> = ({ data, columns }) => {
  const theme = useTheme();
  const tableInstance = useTable({
    columns,
    data,
    initialState: {},
  });

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    getTableBodyProps,
  } = tableInstance;

  return (
    <Box display="flex" flex="1 1 100%" flexDirection="column" m={0}>
      <TableContainer
        css={(theme) => css`
          &.MuiTableContainer-root {
            padding-right: ${theme.spacing(1)}px;
          }
        `}
      >
        <Table {...getTableProps()}
          css={css`
            &.MuiTable-root {
              border-collapse: separate;
            }
          `}
        >
          <TableHead
            css={(theme) => css`
              &.MuiTableHead-root {
                position: sticky;
                top: 0;
                background-color: ${theme.palette.background.paper};
              }
            `}
          >
            {headerGroups.map((headerGroup, index) => (
              <TableRow
                {...headerGroup.getHeaderGroupProps()}
                css={(theme) => css`
                  .MuiTableCell-root {
                    padding: 2px;
                    border-bottom: 1px solid ${theme.palette.line2.main};
                    border-top: 1px solid ${theme.palette.line2.main};
                  }
                `}
              >
                {headerGroup.headers.map((column) => (
                  <TableCell key={column.id} component="th">
                    {column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  hover
                  css={css`
                    .MuiTableCell-root {
                      padding: 4px;
                      margin-right: 8px;
                      border-bottom: 1px solid ${theme.palette.line2.main};
                    }
                  `}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell component="td" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
