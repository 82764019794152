/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  BASIN_TYPE,
  FEEDING_METHOD,
  IBasinSplitData,
  IFishBasin,
} from 'models/fishbasin';
import { FormattedMessage as M } from 'react-intl';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Modal, ModalActions, ModalContent } from 'components/modal-dialog';
import { useForm } from 'react-hook-form';
import { useStore } from 'stores/store-hooks';
import {
  BasinNameRow,
  BasinTypeSelection,
  FishInitialWeight,
  BasinDimensionsRound,
  BasinDimensionsSquare,
  FeedingMethodSelection,
  FishStartAmount,
  Biomass,
} from './basin-form-components';
import { SplitDialogHeader } from './basin-split-header';

interface IBasinSplitDialog {
  siteId: number;
  handleClose: () => void;
  originalBasin?: IFishBasin | null;
  updateBasin: (newBasins: IFishBasin[]) => void;
}

const schema = yup.object().shape({
  basins: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .required('error.validation.name')
          .max(256, 'error.validation.name'),
        basintype: yup.string().required('error.validation.basintype'),
        dimensions: yup.object().when('basintype', {
          is: BASIN_TYPE.Round,
          then: yup.object({
            diameter: yup
              .number()
              .typeError('error.validation.diameter')
              .required('error.validation.diameter')
              .min(0, 'error.validation.diameter')
              .max(9999999999, 'error.validation.diameter'),
            depth: yup
              .number()
              .typeError('error.validation.depth')
              .required('error.validation.depth')
              .min(0, 'error.validation.depth')
              .max(9999999999, 'error.validation.depth'),
          }),
          otherwise: yup.object({
            width: yup
              .number()
              .typeError('error.validation.width')
              .required('error.validation.width')
              .min(0, 'error.validation.width')
              .max(9999999999, 'error.validation.width'),
            length: yup
              .number()
              .typeError('error.validation.length')
              .required('error.validation.length')
              .min(0, 'error.validation.length')
              .max(9999999999, 'error.validation.length'),
            depth: yup
              .number()
              .typeError('error.validation.depth')
              .required('error.validation.depth')
              .min(0, 'error.validation.depth')
              .max(9999999999, 'error.validation.depth'),
          }),
        }),
        startweight: yup
          .number()
          .typeError('error.validation.startweight')
          .required('error.validation.startweight')
          .max(9999999999, 'error.validation.startweight')
          .min(0, 'error.validation.startweight'),
        startamount: yup
          .number()
          .typeError('error.validation.startAmount')
          .required('error.validation.startAmount')
          .max(9999999999, 'error.validation.startAmount')
          .min(1, 'error.validation.startAmount'),
        biomass: yup
          .number()
          .typeError('error.validation.biomass')
          .required('error.validation.biomass')
          .max(9999999999, 'error.validation.biomass')
          .min(0, 'error.validation.biomass'),
      })
    )
    .required('Required'),
});

export const BasinSplitDialog = ({
  originalBasin,
  handleClose,
  updateBasin,
  siteId,
}: IBasinSplitDialog) => {
  const generateInitialSplitBasins = useCallback(
    (basinCount = 2) => {
      const initialSplit: IBasinSplitData[] = [];
      for (let i = 0; i < basinCount; i++) {
        initialSplit.push({
          name: `${originalBasin?.name} (${i + 1})`,
          startamount: Math.floor(
            (originalBasin?.currentamount || 0) / basinCount
          ),
          startweight: Math.floor(
            originalBasin?.currentaverageweights?.length
              ? originalBasin?.currentaverageweights[0] // currentaverageweights[0] is current weight reported by the user)
              : 0
          ),
          biomass: Math.floor((originalBasin?.biomass || 0) / basinCount),
          basintype: originalBasin?.basintype || BASIN_TYPE.Round,
          feedingmethod:
            originalBasin?.feedingmethod || FEEDING_METHOD.HandFeeding,
          dimensions: originalBasin?.dimensions || { diameter: 10, depth: 10 },
        });
      }
      return initialSplit;
    },
    [originalBasin]
  );

  const [splitBasins, setSplitBasins] = useState<IBasinSplitData[]>(
    generateInitialSplitBasins()
  );
  const {
    register,
    handleSubmit,
    errors,
    clearErrors,
    control,
    watch,
    reset,
  } = useForm<{ basins: IBasinSplitData[] }>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { basins: splitBasins },
  });
  const handleBasinChange = (count: number) => {
    const initialState = generateInitialSplitBasins(count);
    setSplitBasins(initialState);
    reset({ basins: initialState });
  };
  const modifiedBasins = watch(`basins`);
  const toastStore = useStore('toastStore');
  const basinStore = useStore('basinStore');
  const theme = useTheme();

  const submitData = async (data: any) => {
    if (originalBasin) {
      const newBasins = await basinStore.splitBasin(
        originalBasin.id,
        modifiedBasins
      );
      if (newBasins) {
        updateBasin(newBasins);
        handleClose();
        return; // success
      }
    }
    toastStore.setToast('BasinSplitError');
  };

  return (
    <Modal handleClose={handleClose} size="lg">
      <form noValidate onSubmit={handleSubmit(submitData)}>
        <ModalContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography component="span" variant="subtitle2">
                <M id={'SplitFishBasin'} />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SplitDialogHeader
                originalBasin={originalBasin}
                splitBasins={modifiedBasins}
                handleBasinChange={handleBasinChange}
              />
            </Grid>
            {splitBasins.map((e, i) => {
              const accessorPrefix = `basins[${i}]`;
              const basintype = watch(`basins[${i}].basintype`);
              return (
                <Grid item xs={12} md={4} key={i}>
                  <Grid
                    container
                    spacing={0}
                    css={css`
                      border: 2px dashed ${theme.palette.line2.main};
                      padding: ${theme.spacing(1)}px;
                    `}
                  >
                    <BasinNameRow
                      register={register}
                      errors={errors}
                      accessorPrefix={accessorPrefix}
                      size={6}
                    />
                    <FishStartAmount
                      register={register}
                      control={control as any}
                      errors={errors}
                      label="NumberOfFish"
                      accessorPrefix={accessorPrefix}
                      size={12}
                    />
                    <FishInitialWeight
                      register={register}
                      control={control as any}
                      errors={errors}
                      accessorPrefix={accessorPrefix}
                      size={12}
                    />
                    <Biomass
                      register={register}
                      control={control as any}
                      errors={errors}
                      accessorPrefix={accessorPrefix}
                      size={12}
                    />
                    <FeedingMethodSelection
                      control={control as any}
                      errors={errors}
                      accessorPrefix={accessorPrefix}
                    />
                    <BasinTypeSelection
                      control={control as any}
                      accessorPrefix={accessorPrefix}
                    />
                    {basintype === BASIN_TYPE.Round ? (
                      <BasinDimensionsRound
                        register={register}
                        errors={errors}
                        accessorPrefix={accessorPrefix}
                        size={2}
                      />
                    ) : (
                      <BasinDimensionsSquare
                        register={register}
                        errors={errors}
                        accessorPrefix={accessorPrefix}
                        size={2}
                      />
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </ModalContent>
        <ModalActions>
          <Button
            color="primary"
            type="submit"
            disabled={!!Object.keys(errors).length}
          >
            <M id="Save" />
          </Button>
          <Button
            onClick={() => {
              clearErrors();
              handleClose();
            }}
            color="primary"
          >
            <M id="Cancel" />
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};
