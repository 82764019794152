/** @jsxRuntime classic */
/** @jsx jsx */
import React, { FC, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { useIntl } from 'react-intl';
import {
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import { useStore } from 'stores/store-hooks';
import { IGrowthFactorEntry } from 'models/growth-factor-table';

const EditGrowthFactor: FC<{
  initValue: number;
  saveValue: (value: number) => any;
  discardValue: () => any;
}> = ({ initValue, saveValue, discardValue }) => {
  const intl = useIntl();
  const [growthString, setGrowthString] = useState(
    intl.formatNumber(initValue, {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      useGrouping: false,
    })
  );
  const isError = growthString.match(/^(\d*[.,])?\d*$/) === null;

  return (
    <div css={css`display: flex; align-items: center`}>
      <TextField
        aria-label={intl.formatMessage({ id: "GrowthFactor" })}
        error={isError}
        helperText={isError ? intl.formatMessage({
          id: "PleaseEnterANumber"
        }) : null}
        inputProps={{ step: 0.01 }}
        onChange={(event) => setGrowthString(event.target.value)}
        value={growthString}
      />
      <IconButton
        aria-label="save"
        color="primary"
        disabled={isError}
        onClick={() => saveValue(Number(growthString.replace(",", ".")))}
        size="small"
      >
        <CheckIcon />
      </IconButton>
      <IconButton
        aria-label="cancel"
        color="primary"
        onClick={discardValue}
        size="small"
      >
        <ClearIcon />
      </IconButton>
    </div>
  );
};

export const GrowthFactorTableRow: FC<{
  data: {
    entry: IGrowthFactorEntry;
    originalGrowth: number;
  };
  editedRow: IGrowthFactorEntry | null;
  setEditedRow: (editedRow: IGrowthFactorEntry | null) => any;
  save: () => any;
}> = ({ data, editedRow, setEditedRow, save }) => {
  const userStore = useStore("userStore");
  const { canEdit } = userStore;
  const intl = useIntl();
  const format = (n: number) => intl.formatNumber(n, {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const editingCurrentRow = editedRow !== null
    && editedRow?.min === data.entry.min
    && editedRow?.max === data.entry.max;

  const cellCss = css`
    &.MuiTableCell-root {
      padding-top: 0;
      padding-bottom: 0;
    }
  `;

  return ( 
    <TableRow hover>
      <TableCell component="td" css={cellCss}>
        <Typography>
          {data.entry.min + " - " + data.entry.max}
        </Typography>
      </TableCell>
      <TableCell component="td" css={cellCss}>
        {editingCurrentRow
          ? <EditGrowthFactor
              initValue={data.entry.growth}
              saveValue={(value: number) => {
                setEditedRow({
                  min: data.entry.min,
                  max: data.entry.max,
                  growth: value
                });
                save();
              }}
              discardValue={() => setEditedRow(null)}
            />
          : <div css={css`display: flex; align-items: center`}>
              <Typography css={css`margin-right: 5px !important`}>
                {format(data.entry.growth)}
              </Typography>
              {canEdit
                ? <IconButton
                    aria-label="edit"
                    color="primary"
                    onClick={() => setEditedRow(data.entry)}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                : null}
            </div>}
      </TableCell>
      <TableCell component="td" css={cellCss}>
        <Typography>
          {intl.formatNumber(data.originalGrowth, {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
