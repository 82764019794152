// Several numeric fields are returned as strings from the API.
export interface ICleaningResult {
  url: string;
  id: number;
  fishbasinevent: string;
  cleaningdate: string;
  originalweight: number;
  liveweight: string; //derived value, not in database
  cleanedweight: string;
  averageweight: string;
  amount: number;
  roe_weight: string;
  roe_percentage: string;
  gut_weight: string;
  gut_percentage: string;
  details: string;
  harvested?: boolean
}

export interface IFastingEvent {
  url: string;
  id: number;
  timestamp: string;
  fastingamount: number;
  details: string;
  processed: boolean;
  fastingweight_user: number;
  fastingweight_table: number;
  fastingbiomass_user: number;
  fastingbiomass_table: number;
  cleaningresults: ICleaningResult[];
}

export interface ILocalCleaningResult {
  amount: number;
  cleanedweight: number;
  liveweight: number;
  cleaningdate?: string;
  gut_percentage?: number;
  gut_weight?: number;
  originalweight: number;
  roe_percentage?: number;
  roe_weight?: number;
  averageweight?: number;
  details?: string | null;
  harvested: boolean;
}

export interface IOngoingFast {
  id: number;
  timestamp: string;
  biomass: number;
  amount: number;
  details: string | null;
  harvested?: boolean | null
}

export interface IWeightFacts {
  live: boolean;
  cleaned: boolean;
  roe: boolean;
  gut: boolean;
}

export enum HarvestingAction {
  FAST,
  HARVEST,
  FASTANDHARVEST,
  NOTHING,
  CLEAR
}
