import {Box, Typography} from "@material-ui/core";
import {css} from "@emotion/react";
import {KeyboardTimePicker} from "@material-ui/pickers";
import ScheduleIcon from "@material-ui/icons/Schedule";
import {FC, useState} from "react";
import {useIntl} from "react-intl";
import {useStore} from "../../../../../stores/store-hooks";

interface IDurationPicker {
  seconds: number;
  onAccept: (date: { hours: number; minutes: number; seconds: number }) => void;
  infoText: string;
  max?: number | null;
}

export const DurationPicker: FC<IDurationPicker> = ({seconds, onAccept, infoText, max = null}) => {

  const secondsToDate = (seconds: number) => new Date(1970, 0, 1, 0, 0, seconds);
  const dateToSeconds = (date: Date) => {
    return date.getHours()*3600 + date.getMinutes()*60 + date.getSeconds();
  }

  const toastStore = useStore('toastStore');
  const intl = useIntl();
  const [durationDate, setDurationDate] = useState(secondsToDate(seconds));
  const [dateValid, setDateValid] = useState(true);

  const onChange = (date: Date | null) => {
    if (date && !isNaN(date.valueOf())) {
      limitDuration(date);
      setDurationDate(date);
      setDateValid(true);
    } else {
      setDateValid(false);
    }
  };

  const limitDuration = (date: Date) => {

    const durationOver2h = (d: Date) => (d.getHours() === 2 && (d.getMinutes() > 0 || d.getSeconds() > 0)) ||
      (d.getHours() > 2);

    if (max && max < dateToSeconds(date)) {
      const maxDate = secondsToDate(max);
      date.setHours(maxDate.getHours());
      date.setMinutes(maxDate.getMinutes());
      date.setSeconds(maxDate.getSeconds());
    }

    if (durationOver2h(date)) {
      date.setHours(2);
      date.setMinutes(0);
      date.setSeconds(0);
      toastStore.setToast('FeedingScheduleDurationLimit')
    }
  }

  const dateToHHmmss = (date: Date) => {
    return {
      hours: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds()
    };
  };

  return (
    <Box display="flex" alignItems="flex-start">
      <Box width={"50%"}>
        <KeyboardTimePicker
          ampm={false}
          autoOk
          error={!dateValid}
          format="HH:mm:ss"
          helperText={
            !dateValid ? intl.formatMessage({ id: "feeder.error_5500" }) : null
          }
          keyboardIcon={<ScheduleIcon />}
          onAccept={(date) => {
            onChange(date);
            if (date) onAccept(dateToHHmmss(date));
          }}
          onChange={onChange}
          value={durationDate}
          views={['hours', 'minutes', 'seconds']}
        />
      </Box>
      &nbsp;
      <Box css={css`margin-top: 2px;`}>
        {infoText && (
          <Typography
            component="span"
            css={css`
              align-self: center;
            `}
          >
            {infoText}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
