/** @jsxRuntime classic */
/** @jsx jsx */
import React, { ReactNode } from 'react';
import { css, jsx } from '@emotion/react';
import Dialog from '@material-ui/core/Dialog';
import { Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';

interface IModalDialog {
  handleClose: () => void;
  children: ReactNode;
  size?: 'md' | 'xs' | 'sm' | 'lg' | 'xl' | undefined;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const Modal = ({ handleClose, children, size = 'md' }: IModalDialog) => {
  return (
    <Dialog
      maxWidth={size}
      fullWidth
      disableBackdropClick
      scroll="paper"
      open
      TransitionComponent={Transition}
      onClose={handleClose}
      css={css`
        & > .MuiDialog-container > .MuiDialog-paperScrollBody {
          vertical-align: baseline; // aligns dialog to top of the screen (instead to middle)
        }
      `}
    >
      {children}
    </Dialog>
  );
};
