/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Box, Grid } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useStore } from 'stores/store-hooks';
import * as yup from 'yup';

export const EmailChipInput = observer(() => {
  const intl = useIntl();
  const alarmStore = useStore('alarmStore');
  const [isEmailValid, setEmailValid] = useState(true);
  const validateEmail = (chip: any) => {
    const emailValidator = yup.string().email();
    const isValid = emailValidator.isValidSync(chip);
    setEmailValid(isValid);
    return isValid;
  };

  return (
    <Grid item xs={12}>
      <Box m={4}>
        <ChipInput
          fullWidth
          css={(theme) => css`
            .MuiChip-root {
              background-color: ${theme.palette.secondary.main} !important;
              :hover {
                background-color: ${theme.palette.secondary.dark};
              }
              :focus {
                background-color: ${theme.palette.secondary.light};
              }
            }
          `}
          defaultValue={alarmStore.settings.receivers.emailAddresses}
          onChange={alarmStore.updateEmails}
          onUpdateInput={(event) => {
            if (!isEmailValid) validateEmail(event.target.value);
          }}
          onBeforeAdd={validateEmail}
          label={intl.formatMessage({ id: 'Email' })}
          blurBehavior="clear"
          FormHelperTextProps={{
            style: {
              marginBottom: '-25px',
            },
          }}
          error={!isEmailValid}
          newChipKeyCodes={[13, 32]}
          helperText={
            isEmailValid
              ? intl.formatMessage({ id: 'SeparateAddressesWithSpace' })
              : intl.formatMessage({ id: 'EmailNotValid' })
          }
        />
      </Box>
    </Grid>
  );
});
