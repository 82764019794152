import React, { FC, ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { FormattedMessage as M } from 'react-intl';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export interface IAlertDialog {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  children: ReactNode;
  titleTextId: string;
  primaryButtonTextId?: string;
  primaryButtonCallback?: () => void;
  secondaryButtonTextId?: string;
  secondaryButtonCallback?: () => void;
}

export const AlertDialog: FC<IAlertDialog> = ({
  isOpen,
  setOpen,
  children,
  titleTextId,
  primaryButtonTextId = 'Yes',
  primaryButtonCallback,
  secondaryButtonTextId = 'Close',
  secondaryButtonCallback,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <M id={titleTextId} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={primaryButtonCallback || handleClose}
            color="primary"
          >
            <M id={primaryButtonTextId} />
          </Button>
          <Button
            onClick={secondaryButtonCallback || handleClose}
            color="primary"
            autoFocus
          >
            <M id={secondaryButtonTextId} />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
