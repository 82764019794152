/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { ReactElement, useState } from 'react';
import { ControlButton, IControlButton } from './control-button';
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from '@emotion/styled';
import { useTheme } from '@material-ui/core';

const Root = styled.div<{ accordionMargin?: number }>`
  display: flex;
  flex-direction: row;
  margin: ${({ theme, accordionMargin }) => {
    const topMargin =
      accordionMargin !== undefined ? accordionMargin : theme.spacing(2);
    const rightMargin =
      accordionMargin !== undefined ? accordionMargin : theme.spacing(1);
    return `${topMargin}px ${rightMargin}px 0 0`;
  }};
`;

interface IStyledAccordion {
  controlButtonColor?: string;
  defaultBorder?: string;
  defaultBgColor?: string;
  isDisabled: boolean | undefined;
  offSet?: string;
}
const StyledAccordion = styled((
  {
    //@ts-ignore - FIXME: styled component specific typing issues
    isDisabled,
    //@ts-ignore
    controlButtonColor,
    //@ts-ignore
    defaultBorder,
    //@ts-ignore
    defaultBgColor,
    //@ts-ignore
    children,
    //@ts-ignore
    offset,
    ...props
  } //@ts-ignore
) => <MuiAccordion children={children} {...props} />)<IStyledAccordion>`
  flex-grow: 1;
  margin-left: ${({ offset }) => offset};
  transition: margin-left 1s ease-in;
  border-radius: 5px;
  && {
    background-color: ${({ defaultBgColor }) => defaultBgColor};
    border: 1px solid
      ${() => ({ controlButtonColor, defaultBorder, isDisabled }) => {
        if (isDisabled && controlButtonColor) return 'theme.palette.grey[300]';
        return controlButtonColor || defaultBorder || 'transparent';
      }};
  }
  &.MuiAccordion-root {
    box-shadow: none; // 0px 4px 5px rgba(29, 54, 61, 0.05);
    :before {
      background-color: transparent;
    }
    .MuiAccordionSummary-content {
      margin: 8px;
    }
  }

  &.MuiAccordion-rounded {
    :last-child {
      border-radius: 5px;
    }
  }
  &.MuiAccordion-root.Mui-expanded {
    margin: 0;
    margin-left: ${({ offset }) => offset};
    border-radius: 5px;
  }
`;

interface IAccordion extends IControlButton {
  accordionHeader: ReactElement;
  accordionContent: ReactElement;
  expandIcon?: boolean;
  isDisabled?: boolean;
  disabledTooltipTextId?: string;
  defaultBorder?: string;
  defaultBgColor?: string;
  leftOffset?: number;
  onExpandCallback?: (expanded: boolean) => void;
  isExpandedInitially?: boolean;
  ref?: any;
}

export const Accordion = React.forwardRef<HTMLDivElement, IAccordion>(
  (props, ref) => {
    const {
      controlButtonColor,
      onControlButtonClick,
      accordionHeader,
      accordionContent,
      isDisabled,
      disabledTooltipTextId,
      expandIcon,
      id,
      defaultBorder,
      defaultBgColor,
      leftOffset,
      onExpandCallback,
      isExpandedInitially,
    } = props;
    const [expanded, setExpanded] = useState(isExpandedInitially || false);
    const theme = useTheme();

    const handleChange = () => {
      setExpanded(!expanded);
      if (onExpandCallback) {
        onExpandCallback(!expanded);
      }
    };

    const buttonProps = {
      id,
      controlButtonColor:
        isDisabled && controlButtonColor
          ? theme.palette.grey[300]
          : controlButtonColor,
      isDisabled,
      leftOffset,
      disabledTooltipTextId,
      onControlButtonClick,
    };

    return (
      <Root ref={ref}>
        <ControlButton {...buttonProps} />
        <StyledAccordion
          expanded={expanded}
          controlButtonColor={controlButtonColor}
          defaultBorder={defaultBorder}
          defaultBgColor={defaultBgColor}
          offset={
            controlButtonColor
              ? `${-30 - (leftOffset || 0)}px`
              : `${-60 - (leftOffset || 0)}px`
          }
          // offset={`${-30 - (leftOffset || 0)}px`}
          isDisabled={isDisabled}
          onChange={handleChange}
          TransitionProps={{
            mountOnEnter: true,
            unmountOnExit: true,
          }}
        >
          <AccordionSummary
            expandIcon={expandIcon && <ExpandMoreIcon />}
            aria-controls="panel-content"
            id="panel1-header"
          >
            {React.cloneElement(accordionHeader, { expanded })}
          </AccordionSummary>
          <AccordionDetails>{accordionContent}</AccordionDetails>
        </StyledAccordion>
      </Root>
    );
  }
);
