/** @jsxRuntime classic */
/** @jsx jsx */
import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { css, jsx } from '@emotion/react';
import { Box, Grid, Typography } from '@material-ui/core';
import { IFishBasin } from 'models/fishbasin';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { useStore } from 'stores/store-hooks';

interface IBasinDetail {
  basin: IFishBasin;
}

export const SiteDetails: FC<IBasinDetail> = observer(({ basin }) => {
  const intl = useIntl();
  const basinStore = useStore('basinStore');
  const siteStore = useStore('siteStore');

  const oxygenDepth = basin.site.preferred_depth_oxygen;
  const temperatureDepth = basin.site.preferred_depth;
  // const oxygenMeasureDepth = siteStore.selectedSite?.preferred_depth_oxygen; // basin.site.last_oxygen_value?.depth;
  // const temperatureMeasureDepth = siteStore.selectedSite?.preferred_depth; // basin.site.last_temperature?.depth;

  const [oxygenValue, setOxygenValue] = useState(
    basin.site.last_oxygen_value?.oxygenvalue
  );
  const [temperatureValue, setTemperatureValue] = useState(
    basin.site.last_temperature?.temperature
  );

  useEffect(() => {
    siteStore.loadSite(basin.site.id);
  }, [basin.site.id, siteStore]);

  useEffect(() => {
    const loadData = async () => {
      if (basin.site.mainunit_id && siteStore.selectedSite?.automatic_oxygen_update) {
        const oxygen = await siteStore.getOxygenValueForDepth(
          basin.site.mainunit_id,
          "1"
        );
        if (oxygen !== null) {
          setOxygenValue(oxygen);
        }
      } else if (siteStore.selectedSite?.id === basin.site.id) {
        setOxygenValue(siteStore.selectedSite?.last_oxygen_value?.oxygenvalue);
      }
    };
    loadData();
  }, [
    basin.site.id,
    basin.site.mainunit_id,
    oxygenDepth,
    siteStore,
    siteStore.selectedSite,
    siteStore.selectedSite?.last_oxygen_value,
    basinStore
  ]);

  useEffect(() => {
    const loadData = async () => {
      if (
        basin.site.mainunit_id && siteStore.selectedSite?.automatic_temperature_update
      ) {
        const temperature = await siteStore.getTemperatureForDepth(
          basin.site.mainunit_id,
          "1"
        );
        if (temperature !== null) {
          setTemperatureValue(temperature);
        }
      } else if (siteStore.selectedSite?.id === basin.site.id) {
        setTemperatureValue(
          siteStore.selectedSite?.last_temperature?.temperature
        );
      }
    };
    loadData();
  }, [
    basin.site.id,
    basin.site.mainunit_id,
    temperatureDepth,
    siteStore,
    siteStore.selectedSite,
    siteStore.selectedSite?.last_temperature,
    basinStore
  ]);

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      css={(theme) => css`
        overflow: hidden;
        padding: ${theme.spacing(2)}px;
      `}
    >
      <Grid item xs={12} css={(theme) => css``}>
        <Box display="flex" justifyContent="space-around">
          <Box>
            <Typography component="div" variant="caption" color="textPrimary">
              {intl.formatMessage({ id: 'Temperature' })}
            </Typography>
            <Typography component="span" variant="subtitle2" color="primary">
              {
                <span
                  css={css`
                    white-space: nowrap;
                  `}
                >
                  {temperatureValue === undefined
                    ? '-'
                    : intl.formatNumber(temperatureValue, {
                      style: "decimal",
                      maximumFractionDigits: 2,
                    })}
                </span>
              } &#176;C
            </Typography>
      {/*      <Box>
              {siteStore.selectedSite?.automatic_temperature_update && temperatureMeasureDepth !== '-1' ?
                <Typography component="span" variant="h6" color="primary">
                  {
                    <span
                      css={css`
                    white-space: nowrap;
                  `}
                    >{temperatureMeasureDepth === undefined
                      ? '-'
                      : temperatureMeasureDepth}
                      {' ' + intl.formatMessage({id: "TemperatureMeasureDepth"})}
                </span>
                  }
                </Typography>
                : <div/>
              }
            </Box>*/}
          </Box>
          <Divider />
          <Box>
            <Typography component="div" variant="caption" color="textPrimary">
              {intl.formatMessage({ id: 'Oxygen' })}
            </Typography>
            <Typography component="span" variant="subtitle2" color="primary">
              {
                <span
                  css={css`
                    white-space: nowrap;
                  `}
                >
                  {oxygenValue === undefined
                    ? '-'
                    : intl.formatNumber(oxygenValue, {
                        style: "decimal",
                        maximumFractionDigits: 2,
                      })}
                  {' ' + intl.formatMessage({ id: "UnitMgPerLiter" })}
                </span>
              }
            </Typography>
{/*            <Box>
              {siteStore.selectedSite?.automatic_oxygen_update && oxygenMeasureDepth !== '-1' ?
                <Typography component="span" variant="h6" color="primary">
                  {
                    <span
                      css={css`
                      white-space: nowrap;
                    `}
                    >{oxygenMeasureDepth === undefined
                      ? '-'
                      : oxygenMeasureDepth}
                      {' ' + intl.formatMessage({ id: "OxygenMeasureDepth" })}
                  </span>
                  }
                </Typography>
                : <div/>
              }
            </Box>*/}
          </Box>
          <Divider />
          <Box>
            <Typography component="div" variant="caption" color="textPrimary">
              {intl.formatMessage({ id: 'Biomass' })}
            </Typography>
            <Typography component="span" variant="subtitle2" color="primary">
              {
                <span
                  css={css`
                    white-space: nowrap;
                  `}
                >
                  {basin?.currentbiomasses && basin?.currentbiomasses?.length > 0
                    ? intl.formatNumber(basin.currentbiomasses[0] || 0, {
                      style: 'decimal',
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    })
                    : '-'}{' '}
                  {intl.formatMessage({ id: "UnitKg" })}
                </span>
              }
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
});

const Divider = styled.span`
  margin: 0 16px;
  border-right: 1px solid ${({ theme }) => theme.palette.primary.main};
  min-height: 56px;
`;
