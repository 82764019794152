/** @jsxRuntime classic */
/** @jsx jsx */
import React, { FC, useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { IntlShape, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { useStore } from 'stores/store-hooks';
import { dateAndTime } from 'translations/locales';
import { DeliveryStatus, IOrder, IOrderPackage } from 'models/orders';
import { UserNameModel } from 'models/user';
import { ContactInfoView, DeliveryInfoView } from './info-views';
import { OrderPackageTable } from './order-package-table';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { SearchField } from 'components/search/search';

const PastOrderView: FC<{
  intl: IntlShape;
  order: IOrder;
  addToCart: ((p: IOrderPackage) => any);
  markDelivered: (() => any) | null;
  setCompany: ((id: number) => any) | null;
  userList: UserNameModel[];
}> = ({ intl, markDelivered, order, addToCart, setCompany, userList }) => (
  <Accordion>
    <AccordionSummary
      aria-label={intl.formatMessage({ id: 'Expand' })}
      expandIcon={<ExpandMoreIcon />}
    >
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={3}>
          <Typography variant="h6">
            {dateAndTime(intl, new Date(order.created))}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6">
            {order.billingContact.company || '-'}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{order.deliveryInfo.invoiceRef}</Typography>
        </Grid>
        <Grid container justify="flex-end" item xs={4}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body2">
              {order.deliveryInfo.status === DeliveryStatus.DELIVERED
                ? intl.formatMessage({ id: 'Delivered' })
                : intl.formatMessage({ id: 'ProcessingOrder' })}
            </Typography>
            <Box ml={1}>
              {order.deliveryInfo.status === DeliveryStatus.DELIVERED ? (
                <CheckIcon color="secondary" />
              ) : (
                <ScheduleIcon />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container direction="column" spacing={2}>
        <Grid container item>
          <ContactInfoView data={order.deliveryContact} />
        </Grid>
        <Grid container item>
          <DeliveryInfoView data={order.deliveryInfo} intl={intl} />
        </Grid>
        <Grid container alignItems="center" spacing={2} item>
          <Grid item>
            <Typography variant="h6">
              {intl.formatMessage({ id: "OrderPlacedBy" }) + ":"}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {
                userList.find((user) => user.id === order.creatingUserId)
                  ?.full_name || intl.formatMessage({
                    id: "UnnamedUser"
                  }, {
                    user_id: order.creatingUserId
                  })
              }
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <OrderPackageTable data={order.customerOrderPackages} />
        </Grid>
        <Grid container item spacing={2}>
          <Grid item>
            <Button
              onClick={() => {
                order.customerOrderPackages.concat([]).reverse().map(addToCart);
                setCompany && setCompany(order.companyId);
              }}
              variant="outlined"
            >
              {intl.formatMessage({ id: 'OrderAllAgain' })}
            </Button>
          </Grid>
          <Grid item>
            {order.deliveryInfo.status === DeliveryStatus.DELIVERED ||
            !markDelivered ? null : (
              <Button onClick={markDelivered} variant="outlined">
                {intl.formatMessage({ id: 'MarkOrderDelivered' })}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>
);

type SortColumn = 'date' | 'ordernum' | 'company' | 'status';

interface IOrderTableSorting {
  direction: number; // 1 for ascending sort and -1 for descending
  column: SortColumn | undefined;
}

export const OrderHistoryView = observer(() => {
  const intl = useIntl();
  const { companiesById } = useStore('companyStore');
  const orderStore = useStore('orderStore');
  const { addToCart, loadOrders, markOrderDelivered, setCompany } = orderStore;
  const { fetchAllUsers, fetchRaisioUsersNames } = useStore('userStore');

  const { isRaisioAdminOrSuperuser } = useStore('userStore');
  const [tableSorting, setTableSorting] = useState<IOrderTableSorting>({
    direction: 1,
    column: 'date',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [userList, setUserList] = useState<UserNameModel[]>([]);

  const orders = orderStore.orders.concat([]);

  const dir = tableSorting.direction;
  if (tableSorting.column === 'date') {
    orders.sort((a, b) =>
      new Date(a.created) > new Date(b.created) ? -1 * dir : dir
    );
  }
  if (tableSorting.column === 'ordernum') {
    orders.sort((a, b) =>
      (a.deliveryInfo.invoiceRef || '') > (b.deliveryInfo.invoiceRef || '')
        ? -1 * dir
        : dir
    );
  }
  if (tableSorting.column === 'company') {
    orders.sort((a, b) =>
      (a.billingContact.company || '') > (b.billingContact.company || '')
        ? -1 * dir
        : dir
    );
  }

  const sortBy = (column: SortColumn, direction?: number) => {
    setTableSorting({
      column: column,
      direction: direction ? direction : -1 * tableSorting.direction,
    });
  };

  const filterOrders = (filter: string) => {
    return orders.filter((o) =>
      o.billingContact.company?.toLowerCase().includes(filter.toLowerCase())
    );
  };

  useEffect(() => {
    const loadData = async () => {
      await loadOrders();
    };
    loadData();
  }, [loadOrders]);

  useEffect(() => {
    let componentIsMounted = true;
    const loadData = async () => {
      const promises: Promise<UserNameModel[]>[] = [fetchAllUsers()];
      if (!isRaisioAdminOrSuperuser) {
        promises.push(fetchRaisioUsersNames());
      }
      const responses = await Promise.all(promises);
      if (componentIsMounted) {
        setUserList(responses[0].concat(responses[1] || []));
      }
    };
    loadData();
    return () => {
      componentIsMounted = false;
    };
  }, [fetchAllUsers, fetchRaisioUsersNames, isRaisioAdminOrSuperuser]);

  return (
    <Box>
      <Grid container direction="column">
        <Grid item>
          <Box
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              .search-container {
                padding-right: 0;
                margin-right: 0;
              }
            `}
          >
            <Typography variant="h2" color="primary">
              {intl.formatMessage({ id: 'PastOrders' })}
            </Typography>
            {isRaisioAdminOrSuperuser && (
              <SearchField setSearchTerm={setSearchTerm} />
            )}
          </Box>
        </Grid>
        <Grid container direction="column" item xs={12}>
          <Grid item xs={12}>
            <Box display="flex" ml={2} mr={6}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={3} onClick={() => sortBy('date')}>
                  <ColumnTitle
                    text={'Date'}
                    sortId={'date'}
                    tableSorting={tableSorting}
                  />
                </Grid>
                {isRaisioAdminOrSuperuser && (
                  <Grid item xs={3} onClick={() => sortBy('company')}>
                    <ColumnTitle
                      text={'Company'}
                      sortId={'company'}
                      tableSorting={tableSorting}
                    />
                  </Grid>
                )}
                <Grid item xs={2} onClick={() => sortBy('ordernum')}>
                  <ColumnTitle
                    text={'OrderNumber'}
                    sortId={'ordernum'}
                    tableSorting={tableSorting}
                  />
                </Grid>
                <Grid container justify="flex-end" item xs={4}>
                  <Box mr={4}>
                    <Typography variant="h6">
                      {intl.formatMessage({ id: 'Status' })}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {filterOrders(searchTerm).map((order) => (
              <Box css={css`width: 100%;`} key={order.id}>
                <PastOrderView
                  addToCart={addToCart}
                  intl={intl}
                  markDelivered={
                    isRaisioAdminOrSuperuser
                      ? () => markOrderDelivered(order.id)
                      : null
                  }
                  order={order}
                  setCompany={
                    isRaisioAdminOrSuperuser
                      ? (id: number) => {
                          companiesById[id] && setCompany(companiesById[id]);
                        }
                      : null
                  }
                  userList={userList}
                />
              </Box>
            ))}
            {orders.length > 0 ? null : (
              <Typography variant="body2">
                {intl.formatMessage({ id: 'OrdersNoPastOrders' })}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});

const TitleRoot = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

export const ColumnTitle: FC<{
  text: string;
  sortId: string;
  tableSorting: IOrderTableSorting;
}> = ({ text, sortId, tableSorting }) => {
  const intl = useIntl();

  return (
    <TitleRoot>
      <Typography variant="h6">{intl.formatMessage({ id: text })}</Typography>
      {tableSorting.column === sortId &&
        (tableSorting.direction === 1 ? (
          <ArrowDownwardIcon
            css={css`
              height: 19px;
            `}
          />
        ) : (
          <ArrowUpwardIcon
            css={css`
              height: 19px;
            `}
          />
        ))}
    </TitleRoot>
  );
};
