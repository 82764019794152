import {Modal, ModalActions, ModalContent} from "../modal-dialog";
import {Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme} from "@material-ui/core";
import {FormattedMessage as M, useIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import {FC, useState} from "react";
import {NumericInput} from "../input-fields";
import {ISelectionItem} from "./multiple-camera-view";


interface IForceFeedDialog {
  selectedItems: Array<ISelectionItem>;
  handleClose: () => void;
  handleSubmit: (time: number) => void;
}

export const ForcefeedDialog: FC<IForceFeedDialog> = ({handleClose, selectedItems, handleSubmit}) => {
  const theme = useTheme();
  const intl = useIntl();

  const [timeMinutes, setTimeMinutes] = useState(0);
  const [timeSeconds, setTimeSeconds] = useState<number | null>(null);
  const forceFeedTime = ((timeMinutes * 60) + (timeSeconds || 0)) || 0;

  return (
    <Modal handleClose={() => console.log("close")}>
      <form>
        <ModalContent>
          <Box display="flex" p={1} pl={2} bgcolor={theme.palette.grey[200]}>
            <Typography component="span" variant="h4">
              <M id="ForceFeed"/>
            </Typography>
          </Box>
          <Box p={2} maxWidth={450}>
            {selectedItems.length ? (
              <Grid container spacing={2}>
                <Grid container direction="column" item xs={12}>
                  <Grid container direction="row" spacing={1} item xs={12}>
                    <Grid item xs={6}>
                      <NumericInput
                        label={intl.formatMessage({id: "Minutes"})}
                        max={59}
                        onChange={setTimeMinutes}
                        value={timeMinutes}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <NumericInput
                        autoFocus
                        label={intl.formatMessage({id: "SecondsPl"})}
                        max={59}
                        onChange={setTimeSeconds}
                        value={timeSeconds}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6">
                          <M id="Basin"/>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" component="span">
                          {intl.formatMessage({id: 'GramsOfFeed'})}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedItems.map(item => (
                      <TableRow>
                        <TableCell>
                          <Typography variant="h6" component="span" color="primary">
                            {item.basin.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6" component="span" color="primary">
                            {
                              intl.formatNumber(
                                forceFeedTime * (item.calibrationValue || 0),
                                {
                                  style: 'decimal',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            ) : (
              <Typography variant="h5" component="span" color="primary">
                <M id="NoBasinsSelected"/>
              </Typography>
            )}
          </Box>
        </ModalContent>
        <ModalActions>
          <Button
            color="primary"
            type="submit"
            disabled={!selectedItems.length}
            onClick={(event: any) => {
              event.preventDefault();
              handleSubmit(forceFeedTime);
            }}
          >
            <M id="StartForceFeed" />
          </Button>
          <Button
            onClick={() => {
              handleClose();
            }}
            color="primary"
          >
            <M id="Cancel" />
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
}
