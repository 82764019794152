/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';
import { IWidgetComponent } from 'models/widget';
import { FC, useCallback, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { MenuItem, Select } from '@material-ui/core';
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'stores/store-hooks';
import { IFacility } from '../../models/facility';
import { ISimpleSite } from '../../models/site';

const SelectContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  padding: 0 ${({ theme }) => theme.spacing(3)}px;
`;

export const FilterWidget: FC<IWidgetComponent> = observer(({ widget }) => {
  const intl = useIntl();
  const userStore = useStore('userStore');
  const { isRaisioAdminOrSuperuser, isCompanyAdmin, isConcernCompanyAdmin } = userStore;
  const facilityStore = useStore('facilityStore');
  const companyStore = useStore('companyStore');
  const filterStore = useStore('filterStore');
  const { facilities } = facilityStore;
  const { companies } = companyStore;

  const getMenuItems = useCallback(
    (firstItemTextId: string, data: any) => {
      const menuItems = data.map((item: any) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ));
      menuItems.unshift(
        <MenuItem key={-1} value={-1}>
          {intl.formatMessage({ id: firstItemTextId })}
        </MenuItem>
      );
      return menuItems;
    },
    [intl]
  );

  const getSites = function () {
    const sites: ISimpleSite[] = [];
    facilities.forEach((facility: IFacility) => {
      facility.sites?.forEach((site: ISimpleSite) => {
        sites.push(site);
      });
    });
    return sites;
  }

  const sites = getSites();


  useEffect(() => {
    companyStore.loadCompanies();
    facilityStore.loadFacilities();
  }, [facilityStore, companyStore, isRaisioAdminOrSuperuser]);

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const id = event.target.value as number;
    if (id === -1) {
      filterStore.setDataFilter(id, 'all');
    } else if (isRaisioAdminOrSuperuser || isConcernCompanyAdmin) {
      filterStore.setDataFilter(id, 'company');
    } else {
      filterStore.setDataFilter(id, 'site');
    }
  };

  return (
    <Root>
      <Typography
        variant="h2"
        css={(theme) => css`
          && {
            font-weight: 600;
            color: ${theme.palette.primary.main};
          }
        `}
      >
        {intl.formatMessage({ id: 'WelcomeToKasvuluotain' })}
      </Typography>

      <SelectContent>
        <Typography
          variant="body2"
          css={(theme) => css`
            && {
              margin-right: ${theme.spacing(1)}px;
              color: ${theme.palette.text.primary};
            }
          `}
        >
          {intl.formatMessage({ id: 'Overview' })}:
        </Typography>
        <Select
          value={filterStore.dataFilter.id}
          disableUnderline
          onChange={handleSelectChange}
          autoWidth
          margin="dense"
          css={(theme) => css`
            font-size: 16px;
            &.MuiInput-root {
              font-size: 14px;
              font-weight: 900;
              .MuiInputBase-input {
                padding-bottom: 3px;
                color: ${theme.palette.primary.main};
              }
            }
          `}
        >
          {isRaisioAdminOrSuperuser || isConcernCompanyAdmin
            ? getMenuItems('AllCompanies', companies)
            : isCompanyAdmin ?
                getMenuItems('AllFacilities', sites) :
                getMenuItems('AllFacilities', sites)
          }
        </Select>
      </SelectContent>
    </Root>
  );
});
