/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/react';
import { FC, useCallback, useEffect } from 'react';
import { Typography, Button, Theme, MenuItem, Select } from '@material-ui/core';
import { useStore } from 'stores/store-hooks';
import { useIntl, FormattedMessage as M } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Icon } from '@iconify/react';
import printerLine from '@iconify-icons/clarity/printer-line';
import vmLine from '@iconify-icons/clarity/vm-line';
import { MEDIA_BREAKPOINT_SD } from '../../constants';
import { ISimpleSite } from "../../models/site";
import { IFacility } from "../../models/facility";
import {useHistory} from "react-router-dom";
// import twoWayArrowsLine from '@iconify-icons/clarity/two-way-arrows-line';

interface IBasinsHeader {
  printBasinList: () => void;
}

const Root = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  margin: 0 ${({ theme }) => theme.spacing(2)}px;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.primary.main}`};
`;

const ContentLeft = styled.div`
  display: flex;
  flex-grow: 1;
  cursor: default;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  min-width: 80px;
  flex-direction: column;
`;

const ContentMiddle = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
`;

const ContentRight = styled.div`
  display: flex;
  flex-grow: 1;
  cursor: default;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  .MuiTypography-h3 {
    margin-bottom: 3px;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const Divider = styled.span`
  margin: 0 ${({ theme }) => theme.spacing(3)}px;
  border-right: 1px solid ${({ theme }) => theme.palette.primary.main};
  min-height: 48px;
`;

const buttonCss = (theme: Theme) => css`
  &.MuiButton-root {
    box-shadow: 0px 3px 3px rgba(0, 88, 100, 0.15);
    border: 1px solid ${theme.palette.primary.main};
    background-color: ${theme.palette.common.white};
    color: ${theme.palette.primary.main};
    :hover {
      background-color: ${theme.palette.hover.main};
    }
    :first-of-type {
      margin-right: ${theme.spacing(3)}px;
    }
  }
`;

const SelectContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  @media (max-width: ${MEDIA_BREAKPOINT_SD}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const BasinsHeader: FC<IBasinsHeader> = observer(
  ({ printBasinList }) => {
    const intl = useIntl();
    const history = useHistory();
    const basinStore = useStore('basinStore');
    const userStore = useStore('userStore');
    const { isRaisioAdminOrSuperuser, isCompanyAdmin, canEdit } = userStore;
    const facilityStore = useStore('facilityStore');
    const companyStore = useStore('companyStore');
    const { facilities } = facilityStore;
    const { companies } = companyStore;
    const filterStore = useStore('filterStore');

    const getMenuItems = useCallback(
      (firstItemTextId: string, data: any) => {
        const menuItems = data.map((item: any) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ));
        menuItems.unshift(
          <MenuItem key={-1} value={-1}>
            {intl.formatMessage({ id: firstItemTextId })}
          </MenuItem>
        );
        return menuItems;
      },
      [intl]
    );

    const getSites = function () {
      const sites: ISimpleSite[] = [];
      facilities.forEach((facility: IFacility) => {
        facility.sites?.forEach((site: ISimpleSite) => {
          sites.push(site);
        });
      });
      return sites;
    }

    const sites = getSites();

    useEffect(() => {
      if (isRaisioAdminOrSuperuser) {
        companyStore.loadCompanies();
      } else {
        facilityStore.loadFacilities();
      }
    }, [facilityStore, companyStore, isRaisioAdminOrSuperuser]);

    const handleSelectChange = (
      event: React.ChangeEvent<{ value: unknown }>
    ) => {
      const id = event.target.value as number;
      if (id === -1) {
        filterStore.setDataFilter(id, 'all');
      } else if (isRaisioAdminOrSuperuser) {
        filterStore.setDataFilter(id, 'company');
      } else {
        filterStore.setDataFilter(id, 'site');
      }
    };

    // Load filtering options?

    return (
      <Root>
        <ContentLeft>
          <Typography
            variant="h1"
            css={(theme) => css`
              && {
                font-weight: 600;
                color: ${theme.palette.primary.main};
                @media (max-width: ${MEDIA_BREAKPOINT_SD}px) {
                  display: none;
                }
              }
            `}
          >
            <M id="AllBasins" />
          </Typography>
          <SelectContent>
            <Typography
              variant="body2"
              css={(theme) => css`
                && {
                  margin-right: ${theme.spacing(1)}px;
                  color: ${theme.palette.text.primary};
                }
              `}
            >
              {intl.formatMessage({ id: 'BasinView' })}:
            </Typography>
            <Select
              value={filterStore.dataFilter.id}
              disableUnderline
              onChange={handleSelectChange}
              autoWidth
              margin="dense"
              css={(theme) => css`
                font-size: 16px;
                &.MuiInput-root {
                  font-size: 14px;
                  font-weight: 900;
                  .MuiInputBase-input {
                    padding-bottom: 3px;
                    color: ${theme.palette.primary.main};
                  }
                }
              `}
            >
              {isRaisioAdminOrSuperuser
                ? getMenuItems('AllCompanies', companies)
                : isCompanyAdmin ?
                getMenuItems('AllFacilities', sites) :
                getMenuItems('AllFacilities', sites)
              }
            </Select>
          </SelectContent>
        </ContentLeft>
        <ContentMiddle>
          <Button
            variant="outlined"
            css={buttonCss}
            startIcon={<Icon icon={printerLine} />}
            onClick={printBasinList}
          >
            <M id="PrintBasinList" />
          </Button>
          {
            canEdit && (
              <Button
                variant="outlined"
                css={buttonCss}
                startIcon={<Icon icon={vmLine} />}
                onClick={() => history.push(`/basin-compare`)}
              >
                <M id="CompareBasins" />
              </Button>
            )
          }
        </ContentMiddle>
        <ContentRight>
          <NumberContainer>
            <Typography variant="h3">
              {basinStore.totalBiomass >= 10000
                ? `${intl.formatNumber(basinStore.totalBiomass / 1000, {
                    style: 'decimal',
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })} ${intl.formatMessage({ id: 'Tons' })}`
                : `${intl.formatNumber(basinStore.totalBiomass, {
                    style: 'decimal',
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })} kg`}
            </Typography>
            <Typography component="div" variant="h6" color="textPrimary">
              <M id="BiomassTot" />
            </Typography>
          </NumberContainer>
          <Divider />
          <NumberContainer>
            <Typography variant="h3">
              {`${intl.formatNumber(basinStore.totalFeedAmount / 1000, {
                style: 'decimal',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              })} kg`}
            </Typography>
            <Typography component="div" variant="h6" color="textPrimary">
              <M id="FeedAmountTot" />
            </Typography>
          </NumberContainer>
        </ContentRight>
      </Root>
    );
  }
);
