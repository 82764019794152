import {IRemovedFishReport, IReportEvent} from '../models/removed-fish-report';
import {getRemovedFishReportByCompanyId} from '../services/api';
import {action, makeObservable, observable, runInAction} from 'mobx';
import {AxiosResponse} from 'axios';

class ReportStore {
  //currently only removed fish reports, later when new reports come along
  //you can refactor function names etc to reflect better different reports.

  removedFishReports: Array<IRemovedFishReport> = [];

  constructor() {
    makeObservable(this, {
      removedFishReports: observable,
      loadReportForCompany: action,
    });
  }

  getReportByCompanyId = (companyId: number) => {
    return this.removedFishReports.find(
      (report) => report.company_id === companyId
    );
  };

  loadReportForCompany = async (companyId: number) => {
    const calculateCorrections = (
      response: AxiosResponse
    ): IRemovedFishReport => {

      const totalCorrectionByType = (arr: any, type: string): [number, number] => {
        if(!arr) return [0, 0];
        const eventsOfTypeArr = arr.filter((e: any) => e.eventtype === type);
        if (eventsOfTypeArr.length === 0) return [0, 0]
        return eventsOfTypeArr
          .map((e: any) => [Math.abs(e.fishamountcorrection), e.fishKgCorrection])
          .reduce((acc: [number, number], cur: [number, number]) => [acc[0] + cur[0], acc[1] + cur[1]]);
      }

      const getFishKgCorrection = (event: IReportEvent) => {
        return event.averageweight * 0.001 * Math.abs(event.fishamountcorrection);
      }

      const report = response.data as IRemovedFishReport;

      let companyRemovedFish = 0;
      let companyDeadFish = 0;
      let companyRemovedFishKg = 0;
      let companyDeadFishKg = 0;
      report.facilities.forEach((facility) => {
        let facilityRemovedFish = 0;
        let facilityDeadFish = 0;
        let facilityRemovedFishKg = 0;
        let facilityDeadFishKg = 0;
        facility.sites.forEach((site) => {
          let siteRemovedFish = 0;
          let siteDeadFish = 0;
          let siteRemovedFishKg = 0;
          let siteDeadFishKg = 0
          site.fishbasins.forEach((basin) => {
            basin.events.forEach((event) => {
              event.fishKgCorrection = getFishKgCorrection(event);
            });
            const removedCorrections = totalCorrectionByType(basin.events, 'R');
            const deadCorrections = totalCorrectionByType(basin.events, 'D');
            basin.removedFish = removedCorrections[0];
            basin.deadFish = deadCorrections[0];
            basin.removedFishKg = removedCorrections[1];
            basin.deadFishKg = deadCorrections[1];
            siteRemovedFish += basin.removedFish;
            siteDeadFish += basin.deadFish;
            siteRemovedFishKg += basin.removedFishKg;
            siteDeadFishKg += basin.deadFishKg;
          });
          site.removedFish = siteRemovedFish;
          site.deadFish = siteDeadFish;
          site.removedFishKg = siteRemovedFishKg;
          site.deadFishKg = siteDeadFishKg;
          facilityRemovedFish += siteRemovedFish;
          facilityDeadFish += siteDeadFish;
          facilityRemovedFishKg += siteRemovedFishKg;
          facilityDeadFishKg += siteDeadFishKg;
        });
        facility.removedFish = facilityRemovedFish;
        facility.deadFish = facilityDeadFish;
        facility.removedFishKg = facilityRemovedFishKg;
        facility.deadFishKg = facilityDeadFishKg;
        companyRemovedFish += facilityRemovedFish;
        companyDeadFish += facilityDeadFish;
        companyRemovedFishKg += facilityRemovedFishKg;
        companyDeadFishKg += facilityDeadFishKg;
      });
      report.removedFish = companyRemovedFish;
      report.deadFish = companyDeadFish;
      report.removedFishKg = companyRemovedFishKg;
      report.deadFishKg = companyDeadFishKg;

      return report;
    };
    const checkAndRemoveExistingReport = () => {
      const reportAlreadySavedId = this.removedFishReports.findIndex(
        (report) => report.company_id === companyId
      );
      this.removedFishReports.splice(reportAlreadySavedId, 1);
    };

    checkAndRemoveExistingReport();

    const response = await getRemovedFishReportByCompanyId(companyId);
    const report = calculateCorrections(response);

    runInAction(() => {
      this.removedFishReports.push(report);
    });
  };
}

export default new ReportStore();
