/** @jsxRuntime classic */
/** @jsx jsx */
import React, { FC } from 'react';
import { css, jsx } from '@emotion/react';
import { Box } from '@material-ui/core';

export const TabContent: FC = ({ children }) => {
  return (
    <Box
      css={(theme) => css`
        flex: 1 1 100%;
        flex-direction: column;
        border-radius: 5px;
        margin: ${theme.spacing(2)}px;
        align-items: center;
        justify-content: center;
        display: flex;
        height: 100%;
      `}
    >
      {children}
    </Box>
  );
};
