import {FC} from 'react';
import styled from '@emotion/styled';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../stores/store-hooks';
import {Box, Typography} from '@material-ui/core';
import {ExpandButton} from '../common/expand-button';
import {ICompany} from '../../models/company';
import {FormattedMessage as M} from "react-intl";

interface ICompanyHeader {
  expanded?: boolean;
  company: ICompany;
}

const ContentLeft = styled.div`
  flex-grow: 1;
  cursor: default;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;

export const ReportCompanyHeader: FC<ICompanyHeader> = observer(
  ({ company, expanded }) => {
    const userStore = useStore('userStore');

    return (
      <Root>
        <ExpandButton isExpanded={!!expanded} />
        <ContentLeft
          key={company.id}
          onClick={(event: any) => event.stopPropagation()}
          onFocus={(event: any) => event.stopPropagation()}
        >
          <Typography variant="h6" color="textPrimary">
            <M id="ReportsForCompany" />
          </Typography>
          {userStore.localLang === 'ru' ? (
            <Typography variant="subtitle1" color="primary">
              {company.name}
            </Typography>
          ) : (
            <Box>
              {company ? (
                <Box display="flex" flexDirection="row">
                  <Typography variant="subtitle1" color="primary">
                    {company.name}
                  </Typography>
                </Box>
              ) : null}
            </Box>
          )}
        </ContentLeft>
      </Root>
    );
  }
);

const Root = styled.div`
  display: flex;
  align-items: center;
`;
