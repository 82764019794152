/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FC } from 'react';

/* prettier-ignore */
export const TermsOfServiceRU: FC = () => {
  return (
    <div
      css={(theme) => css`
        white-space: pre-line;
        overflow-y: auto;
        padding: ${theme.spacing(2)}px;
        margin: ${theme.spacing(1)}px;
      `}
    >
      {``}
    </div>
  );
};
