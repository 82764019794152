/** @jsxRuntime classic */
/* @jsx jsx */
import React, {FC, Fragment, useState} from 'react';
import { useIntl } from 'react-intl';
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import { useStore } from 'stores/store-hooks';
import { NumericInput } from 'components/input-fields';
import { Modal, ModalContent, ModalActions } from 'components/modal-dialog';
import {IFeedType} from "../../../../models/silo";

const FeedTypeEntry: FC<{
  feedType: IFeedType;
  state: string;
}> = observer(({ feedType, state }) => {
  const intl = useIntl();
  const theme = useTheme();
  const styles = state === "deleted"
    ? css`color: ${theme.palette.error.main}; opacity: 0.5;`
    : (state === "added" ? css`color: ${theme.palette.secondary.main}` : null);

  const {
    removeFeedTypeFromDeleteList, addFeedTypeToDeleteList, removeFromFeedTypeList
  } = useStore('feedTypeListStore');

  return (
    <Grid container direction="row" item xs={12}>
      <Grid css={styles} item xs={7}>
        <Typography>
          {feedType.name}
        </Typography>
      </Grid>
      <Grid css={styles} item xs={2}>
        <Typography>
          {!feedType.phosphoruspercentage
            ? ""
            : intl.formatNumber(
            Number(feedType.phosphoruspercentage),
                {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                },
              ) + "%"}
        </Typography>
      </Grid>
      <Grid css={styles} item xs={2}>
        <Typography>
          {feedType.nitrogenpercentage === null
            ? ""
            : intl.formatNumber(
              Number(feedType.nitrogenpercentage),
                {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                },
              ) + "%"}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        {state === "deleted" ? (
          <IconButton
            aria-label="restore"
            color="primary"
            onClick={() => removeFeedTypeFromDeleteList(feedType)}
            size="small"
          >
            <RestoreFromTrashIcon />
          </IconButton>
        ) : (
          <IconButton
            aria-label="remove"
            color="primary"
            onClick={() => {
              addFeedTypeToDeleteList(feedType);
              removeFromFeedTypeList(feedType);
            }}
            size="small"
          >
            <ClearIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
});

const FeedTypeList: FC<{
  feedTypes: IFeedType[];
}> = observer(({ feedTypes }) => {

  const intl = useIntl();
  const [newFeedTypeName, setNewFeedTypeName] = useState("");
  const [newFeedTypePhosphorus, setNewFeedTypePhosphorus] = useState<number | null>(null);
  const [newFeedTypeNitrogen, setNewFeedTypeNitrogen] = useState<number | null>(null);

  const {
    addToFeedTypeList, feedTypesToAdd, feedTypesToDelete
  } = useStore('feedTypeListStore')

  return (
    <Grid container direction="column" item xs={12}>
      <Grid container direction="column" spacing={1} item xs={12}>
        <Grid container direction="row" spacing={1} item xs={12}>
          <Grid item xs={7}>
            <TextField
              aria-label={intl.formatMessage({ id: "PackageName" })}
              label={intl.formatMessage({ id: "PackageName" })}
              onChange={(event) => setNewFeedTypeName(event.target.value)}
              value={newFeedTypeName}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <NumericInput
              fractionDigits={2}
              label={intl.formatMessage({ id: "PhosphorPercentage" })}
              onChange={setNewFeedTypePhosphorus}
              value={newFeedTypePhosphorus}
            />
          </Grid>
          <Grid item xs={2}>
            <NumericInput
              fractionDigits={2}
              label={intl.formatMessage({ id: "NitrogenPercentage" })}
              onChange={setNewFeedTypeNitrogen}
              value={newFeedTypeNitrogen}
            />
          </Grid>
          <Grid container alignItems="flex-end" item xs={1}>
            <IconButton
              aria-label="add"
              color="primary"
              disabled={!newFeedTypeName}
              onClick={() => {
                const newFeedType: IFeedType = {
                  name: newFeedTypeName,
                  phosphoruspercentage: String(newFeedTypePhosphorus || 0),
                  nitrogenpercentage: String(newFeedTypeNitrogen || 0),
                  url: "",
                  deleted: false,
                };

                addToFeedTypeList(newFeedType);

                setNewFeedTypeName("");
              }}
              size="small"
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
        {feedTypesToAdd.map((feedType, i) => (
          <FeedTypeEntry
            key={0 - i}
            feedType={feedType}
            state="added"
          />
        ))}
        {feedTypes.map((feedType, i) => (
          <FeedTypeEntry
            key={i}
            feedType={feedType}
            state={feedTypesToDelete.includes(feedType) ? "deleted" : "in_db"}
          />
        ))}
      </Grid>
    </Grid>
  );
});

export const UpdateFeedTypesDialog = observer(() => {
  const intl = useIntl();
  const { companySpecificFeedTypes, loadFeedTypes } = useStore('siloStore');
  const {
    noUpdates,
    resetUpdates,
    saveUpdates,
  } = useStore('feedTypeListStore');
  const { canEdit } = useStore('userStore');
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    resetUpdates();
    setIsOpen(true);
  }

  const handleClose = () => {
    resetUpdates();
    setIsOpen(false);
  };

  if (!canEdit) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <Button
        color="primary"
        onClick={handleOpen}
        startIcon={<EditIcon />}
        variant="outlined"
      >
        {intl.formatMessage({ id: "CompanySpecificFeedTypes" })}
      </Button>
      { isOpen && (
        <Modal handleClose={handleClose}>
          <ModalContent>
            <Typography color="primary" variant="h2" style={{}}>
              {intl.formatMessage({ id: "CompanySpecificFeedTypes" })}
            </Typography>
            <Grid container><Box my={2} /></Grid>
            <Grid container direction="column" spacing={2}>
              <FeedTypeList
                feedTypes={companySpecificFeedTypes}
              />
            </Grid>
          </ModalContent>
          <ModalActions>
            <Button
              color="primary"
              disabled={noUpdates}
              onClick={async () => {
                await saveUpdates();
                loadFeedTypes();
                handleClose();
              }}
            >
              {intl.formatMessage({ id: "Save" })}
            </Button>
            <Button color="primary" onClick={handleClose}>
              {intl.formatMessage({ id: "Cancel" })}
            </Button>
          </ModalActions>
        </Modal>
      )}
    </Fragment>
  );
});
