/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { Column, useSortBy, useTable } from 'react-table';
import { IFishBasin } from 'models/fishbasin';
import { useStore } from 'stores/store-hooks';

interface IArchivedBasinTable {
  basins: IFishBasin[];
  columns: Column<IFishBasin>[];
}

export const ArchivedBasinTable: FC<IArchivedBasinTable> = observer(
  ({ basins, columns }) => {
    if (basins.length === 0) return null;

    const userStore = useStore('userStore');
    const { isRaisioAdminOrSuperuser, isCompanyAdmin } = userStore;
    const hiddenColumns =
      isRaisioAdminOrSuperuser || isCompanyAdmin ? [] : ['delete', 'unarchive'];

    const tableInstance = useTable(
      {
        columns,
        data: basins,
        initialState: {
          // @ts-ignore
          sortBy: [
            {
              id: 'name',
              desc: false,
            },
          ],
          hiddenColumns,
        },
      },
      useSortBy
    );

    const {
      getTableProps,
      headerGroups,
      prepareRow,
      rows,
      getTableBodyProps,
    } = tableInstance;

    const getColumnDirection = (column: any) => {
      if (column.isSorted) {
        return column.isSortedDesc ? 'desc' : 'asc';
      }
    };

    return (
      <TableContainer>
        <Table
          aria-label="user table"
          {...getTableProps()}
          css={(theme) => css`
            &.MuiTable-root {
              .MuiTableCell-body {
                padding: ${theme.spacing(1)}px;
              }
              .MuiTableCell-head {
                padding: ${theme.spacing(1)}px;
                color: ${theme.palette.primary.main};
                .MuiTableSortLabel-root.MuiTableSortLabel-active
                  .MuiTableSortLabel-icon {
                  color: ${theme.palette.primary.main};
                }
              }
            }
          `}
        >
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    component="th"
                    // @ts-ignore
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {
                      <div
                        css={css`
                          display: flex;
                        `}
                      >
                        {column.render('Header')}
                        {
                          // @ts-ignore
                          column.isSorted && (
                            <TableSortLabel
                              active
                              direction={getColumnDirection(column)}
                            />
                          )
                        }
                      </div>
                    }
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} hover>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell component="td" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
);
