export const waldenTheme = {
  // theme copied from KL1.0 and modified for echarts5, might need some updates still
  color: ['#3fb1e3', '#6be6c1', '#626c91', '#a0a7e6', '#c4ebad', '#96dee8'],
  backgroundColor: 'rgba(255,255,255,0)',
  textStyle: {
    fontFamily: 'Nunito',
    fontSize: 16,
  },
  title: {
    show: false,
  },
  grid: {
    bottom: 80,
    left: '10%',
    right: '5%',
  },
  line: {
    symbolSize: '4',
    symbol: 'circle',
    smooth: false,
  },
  radar: {
    symbolSize: '4',
    symbol: 'circle',
    smooth: false,
  },
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#cccccc',
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#333',
      },
    },
    axisLabel: {
      show: true,
      color: '#999999',
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#f7f7f7'],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
      },
    },
  },
  valueAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#cccccc',
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#333',
      },
    },
    axisLabel: {
      show: true,
      color: '#999999',
      fontSize: 12,
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#f7f7f7'],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
      },
    },
  },
  logAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#cccccc',
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#333',
      },
    },
    axisLabel: {
      show: true,
      color: '#999999',
      fontSize: 12,
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#f7f7f7'],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
      },
    },
  },
  timeAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#cccccc',
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#333',
      },
    },
    axisLabel: {
      show: true,
      color: '#999999',
      fontSize: 12,
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#f7f7f7'],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
      },
    },
  },
  toolbox: {
    iconStyle: {
      borderColor: '#014f59',
    },
  },
  legend: {
    color: '#999999',
    fontSize: 12,
  },
  tooltip: {
    axisPointer: {
      lineStyle: {
        color: '#cccccc',
        width: 1,
      },
      crossStyle: {
        color: '#cccccc',
        width: 1,
      },
    },
  },
  timeline: {
    lineStyle: {
      color: '#626c91',
      width: 1,
    },
    itemStyle: {
      color: '#626c91',
      borderWidth: 1,
    },
    controlStyle: {
      color: '#626c91',
      borderColor: '#626c91',
      borderWidth: 0.5,
    },
    checkpointStyle: {
      color: '#3fb1e3',
      borderColor: 'rgba(63,177,227,0.15)',
    },
    label: {
      color: '#626c91',
      fontSize: 12,
    },
  },
  visualMap: {
    color: ['#2a99c9', '#afe8ff'],
  },
  dataZoom: {
    backgroundColor: 'rgba(255,255,255,0)',
    dataBackgroundColor: 'rgba(255,255,255,1)',
    fillerColor: 'rgba(114,230,212,0.25)',
    handleColor: '#cccccc',
    handleSize: '100%',
    color: '#999999',
  },
  markPoint: {
    label: {
      color: '#ffffff',
    },
  },
};
