import { AlarmTypes } from 'models/alarm';

export const alarmTypes = [
  {
    id: 0,
    textId: 'UnavailabilityAlarm',
    type: AlarmTypes.UNAVAILABILITY,
  },
  {
    id: 1,
    textId: 'OxygenAlarm',
    type: AlarmTypes.OXYGEN,
  },
  {
    id: 2,
    textId: 'TemperatureAlarm',
    type: AlarmTypes.TEMPERATURE,
  },
  {
    id: 3,
    textId: 'BatteryAlarm',
    type: AlarmTypes.BATTERY_VOLTAGE,
  },
  {
    id: 4,
    textId: 'SiloAlarm',
    type: AlarmTypes.SILO,
  },
  {
    id: 5,
    textId: 'MotorFuseAlarm',
    type: AlarmTypes.MOTOR_FUSE,
  },
];
