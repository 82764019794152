/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Box, MenuItem, Select, Tooltip, Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import { Column } from 'react-table';
import { BASIN_TYPE, IFishBasin } from 'models/fishbasin';
import { IntlShape, useIntl } from 'react-intl';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { useStore } from 'stores/store-hooks';
import { Icon } from '@iconify/react';
import circleSolid from '@iconify/icons-clarity/circle-solid';
import circleLine from '@iconify/icons-clarity/circle-line';
import { MEDIA_BREAKPOINT_LG, MEDIA_BREAKPOINT_SD } from '../../constants';


const getBasinVolume = (basin: IFishBasin): number => {
  // XXX The "diameter" field of a basin is actually the circumference.
  return basin.basintype === BASIN_TYPE.Round
    ? Math.pow(basin.dimensions?.diameter || 0, 2)
    * (basin.dimensions?.depth || 0) / 4 / Math.PI
    : (basin.dimensions?.width || 0) * (basin.dimensions?.length || 0)
    * (basin.dimensions?.depth || 0);
}

export const getColumns = (
  intl: IntlShape,
  openBasin: (basin: IFishBasin) => void,
  setUpdatedCustomOrder: (value: number[]) => any,
  isRaisioAdminOrSuperuser: boolean,
) => {
  const columns = [
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Basin' })}
        </Typography>
      ),
      accessor: 'name',
      Cell: ({ value, cell }) => (
        <SiteNameCell
          intl={intl}
          value={value}
          basin={cell.row.original}
          openBasin={openBasin}
          isAvailable={cell.row.original.deviceInfo?.availability === 'AVAILABLE'}
          isConnected={cell.row.original.deviceInfo?.availability === 'AVAILABLE'}
        />
      ),
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Oxygen' })}
        </Typography>
      ),
      accessor: 'oxygenvalue',
      Cell: ({value, cell}) => {
        return (
          value ?
            <TypographyCell
              value={
                intl.formatNumber(Number(value) || 0, {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                }) + intl.formatMessage({ id: 'UnitMgPerLiter' })
              }
            />
            : '-'
        )
      },
    },
    {
      Header: () => (
        <Typography variant="h6" align="left">
          {intl.formatMessage({ id: 'WaterTemperature' })}
        </Typography>
      ),
      accessor: 'temperature',
      Cell: ({value, cell}) => {
        return (
          value ?
            <TypographyCell
              value={
                intl.formatNumber(Number(value) || 0, {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                }) + intl.formatMessage({ id: 'UnitCelsius' })
              }
            />
            : '-'
        )
      },
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'BatteryAlarm' })}
        </Typography>
      ),
      accessor: 'device_voltage',
      Cell: ({value, cell}) => {
        return (
          value ?
            <TypographyCell
              value={
                intl.formatNumber(Number(value) || 0, {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                }) + intl.formatMessage({ id: 'UnitVolts' })
              }
            />
            : '-'
        )
      },
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'FixedFeedingPercentShort' })}
        </Typography>
      ),
      accessor: 'usefixedfeedingpercent',
      Cell: ({ value }) => {
        return (
          <Tooltip
            title={intl.formatMessage({
              id: value ? 'On' : 'Off',
            })}
            // prettier-ignore
            aria-label={(value ? 'calculation on' : 'calculation off')}
            arrow
          >
            <Box
              css={css`
                display: flex;
                justify-content: center;
              `}
            >
              <Icon icon={value ? circleSolid: circleLine} color={value ? 'green' : 'white'} />
            </Box>
          </Tooltip>
        );
      },
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'FeedingPercentage' })}
        </Typography>
      ),
      accessor: 'currentfeedinfo',
      Cell: ({value, cell}) => {
        const basin = cell.row.original as IFishBasin;
        let val = Number(value?.feed_percentage);
        if (basin.usefixedfeedingpercent) {
          val = val * 100;
        }
        return (
          <TypographyCell
            value={intl.formatNumber(
              val || 0,
              {
                style: 'decimal',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }
            ) + ' %'}
          />
        )
      },
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'PercentageFromRecommendation' })}
        </Typography>
      ),
      accessor: 'lastpercentofrecommended',
      Cell: ({value, cell}) => {
        return (
          <TypographyCell
            value={
              (Number(value) || 0) >= 0 ?
              intl.formatNumber((Number(value) || 0) / 100, {
                style: 'percent',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }) : '-'
            }
          />
        )
      },
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Portion' })}
        </Typography>
      ),
      accessor: 'currentfeedinfo.feed_amount',
      Cell: ({ value }: { value: string }) => (
        <TypographyCell
          value={
            value
              ? intl.formatNumber((Number(value) || 0) / 1000, {
              style: 'decimal',
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + ' ' + intl.formatMessage({ id: "UnitKg" })
              : '-'
          }
        />
      ),
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'AverageWeight' })}
        </Typography>
      ),
      accessor: 'currentaverageweights.0',
      Cell: ({ value }: { value: string }) => (
        <TypographyCell
          value={
            value
              ? intl.formatNumber(Number(value) || 0, {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }) + ' ' + intl.formatMessage({ id: "UnitGrams" })
              : '-'
          }
        />
      ),
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'NumberOfFish' })}
        </Typography>
      ),
      accessor: 'currentamount',
      Cell: ({ value }: { value: string }) => (
        <TypographyCell
          value={
            value
              ? `${intl.formatNumber(Number(value) || 0, {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })} ${intl.formatMessage({ id: 'Pieces' })}`
              : '-'
          }
        />
      ),
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'BiomassForEachCubicMeter' })}
        </Typography>
      ),
      accessor: 'currentbiomasses',
      Cell: ({value, cell}) => {
        return (
          value ?
            <TypographyCell
              value={
                intl.formatNumber((value[0] || 0) / getBasinVolume(cell.row.original), {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                }) + intl.formatMessage({ id: 'UnitBiomassKgForEachCubicMeter' })
              }
            />
            : '-'
        )
      },
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Biomass' })}
        </Typography>
      ),
      accessor: 'currentbiomasses.0',
      Cell: ({ value }: { value: string }) => (
        <TypographyCell
          value={
            value
              ? intl.formatNumber(Number(value) || 0, {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }) + ' ' + intl.formatMessage({ id: "UnitKg" })
              : '-'
          }
        />
      ),
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'CumulativeFeedAmount' })}
        </Typography>
      ),
      accessor: 'laststatus.cumulativefeed_user',
      Cell: ({ value }: { value: string }) => (
        <TypographyCell
          value={
            value
              ? intl.formatNumber((Number(value) || 0) / 1000, {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }) + ' ' + intl.formatMessage({ id: "UnitKg" })
              : '-'
          }
        />
      ),
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Calculation' })}
        </Typography>
      ),
      accessor: 'feedingenabled',
      Cell: ({ value }) => {
        return (
          <Tooltip
            title={intl.formatMessage({
              id: value ? 'On' : 'Off',
            })}
            // prettier-ignore
            aria-label={(value ? 'calculation on' : 'calculation off')}
            arrow
          >
            <Box
              css={css`
                display: flex;
                justify-content: center;
              `}
            >
              <Icon icon={value ? circleSolid : circleLine} color={value ? 'green' : 'white'} />
            </Box>
          </Tooltip>
        );
      },
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Automatic' })}
        </Typography>
      ),
      /** Computed value. Automation is considered enabled if support unit id is attached */
      accessor: 'supportunit_id',
      Cell: ({ value, cell }) => {
        const iotStore = useStore('iotStore');
        const [automationStatusFetched, setAutomationStatusFetched] = useState(
          false
        );
        const [automaticFeeding, setAutomaticFeeding] = useState(false);
        const basin = cell.row.original as IFishBasin;

        useEffect(() => {
          let componentIsMounted = true;
          const loadData = async () => {
            if (!automationStatusFetched) {
              await iotStore.loadDeviceInfo(basin);
              if (componentIsMounted) {
                setAutomationStatusFetched(true);
              }
            }
            if (componentIsMounted) {
              setAutomaticFeeding(iotStore.getFeedAutomationStatus(basin));
            }
          };
          loadData();
          return () => {
            componentIsMounted = false;
          };
        }, [
          automationStatusFetched,
          basin,
          iotStore,
        ]);

        // Do not show toggle button if automation is not available (no IoT device)
        if (!value) {
          return (
            <Box
              css={css`
                display: flex;
                justify-content: center;
              `}
            >
              <Typography variant="h6">-</Typography>
            </Box>
          );
        }

        return (
          <Tooltip
            title={intl.formatMessage({
              id: automaticFeeding ? 'On' : 'Off',
            })}
            // prettier-ignore
            aria-label={(automaticFeeding ? 'automatic feeding on' : 'automatic feeding off')}
            arrow
          >
            <Box
              css={css`
                display: flex;
                justify-content: center;
              `}
            >
              <Icon
                icon={circleSolid}
                color={automaticFeeding ? 'green' : 'grey'}
              />
            </Box>
          </Tooltip>
        );
      },
    },
    {
      Header: () => (
        <Box
          css={css`
            display: flex;
            flex-direction: row;
            align-items: baseline;
            white-space: pre-wrap;
          `}
        >
          <Typography variant="h6">
            {intl.formatMessage({ id: 'Species' }) + ': '}
          </Typography>
          <FishTypeSelect />
        </Box>
      ),
      accessor: 'fishtype.name',
      disableSortBy: true,
      Cell: ({ value }: { value: string }) => (
        <TypographyCell
          // capitalize fish type e.g. whitefish to Whitefish (used as translation key)
          value={
            value
              ? intl.formatMessage({
                id: value.charAt(0).toUpperCase() + value.slice(1),
              })
              : '-'
          }
        />
      ),
    },
  ] as Column<IFishBasin>[];

  if (!isRaisioAdminOrSuperuser) {
    const customOrderingColumn = {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'CustomOrderingHeader' })}
        </Typography>
      ),
      accessor: 'custom_ordering_index',
      Cell: ({ row, rows, state }) => {
        const basinStore = useStore('basinStore');
        const { dataFilter } = useStore('filterStore');
        const basinIds = rows.map((row) => (row.original as IFishBasin).id);
        const currentRowBasinId = (row.original as IFishBasin).id;

        const sortBy = (state as Record<string, any>)["sortBy"][0]?.id;
        if (sortBy === "custom_ordering_index" && dataFilter.id === -1) {
          if ((state as Record<string, any>)["sortBy"][0]?.desc) {
            basinIds.reverse();
          }

          return (
            <Select
              css={(theme) => css`
                font-size: 16px;
                &.MuiInput-root {
                  font-size: 14px;
                  font-weight: 900;
                  .MuiInputBase-input {
                    color: ${theme.palette.primary.main};
                  }
                }
              `}
              disableUnderline
              onChange={async (event) => {
                const index = basinIds.indexOf(currentRowBasinId);
                const value = event.target.value as number;
                basinIds.splice(index, 1);
                basinIds.splice(value - 1, 0, currentRowBasinId);
                basinStore.saveOrder(basinIds);
                setUpdatedCustomOrder(basinIds);
              }}
              value={basinIds.indexOf(currentRowBasinId) + 1}
            >
              {basinIds.map((_, index) => (
                <MenuItem key={index} value={index + 1}>{index + 1}</MenuItem>
              ))}
            </Select>
          );
        }

        return "";
      },
    } as Column<IFishBasin>;

    columns.splice(1, 0, customOrderingColumn);
  }

  return columns;
};

// Custom components for different Cell-render functions in the columns

const TypographyCell: FC<{ value: string }> = ({ value }) => (
  <Typography
    component="div"
    variant="h5"
    color="textPrimary"
    css={(theme) => css`
      &.MuiTypography-root {
        margin: 0;
      }
    `}
  >
    {value}
  </Typography>
);

// To serve as parent for a row and its control button
const NameCellRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SiteNameCell: FC<{
  intl: IntlShape,
  value: string;
  basin: IFishBasin;
  controlButtonColor?: string;
  openBasin: (basin: IFishBasin) => void;
  isAvailable: boolean;
  isConnected: boolean;
}> = ({ intl, value, basin, controlButtonColor,
                            openBasin, isAvailable, isConnected}) => (
  <NameCellRoot>
    <Box
      onClick={() => openBasin(basin)}
      css={(theme) => css`
        border: 1px solid ${controlButtonColor || theme.palette.line2.main};
        background-color: ${theme.palette.common.white};
        :hover {
          background-color: ${theme.palette.hover.main};
        }
        margin: -1px 10px -1px -1px;
        border-radius: 15px;
        min-width: 200px;
        @media (max-width: ${MEDIA_BREAKPOINT_LG}px) {
          min-width: 180px;
        }
        @media (max-width: ${MEDIA_BREAKPOINT_SD}px) {
          min-width: 150px;
        }
        height: 48px;
        align-items: center;
        justify-content: space-between;
        display: flex;
        cursor: ${controlButtonColor ? 'not-allowed' : 'pointer'};
        > svg {
          margin-right: 8px;
        }
        position: relative;
        color: ${theme.palette.primary.main};
      `}
    >
      <Typography
        component="div"
        variant="h5"
        color="primary"
        css={(theme) => css`
          &.MuiTypography-root {
            margin: 0;
            margin-left: ${theme.spacing(3)}px;
          }
        `}
      >
        {
          <Box
            css={(theme) => css`
              height: 48px;
              align-items: center;
              justify-content: space-between;
              display: flex;
              position: relative;
            `}
          >
            <Tooltip
              title={
                intl.formatMessage({
                    id: isAvailable ? (isConnected ? 'DeviceAvailableAndConnected' : 'DeviceAvailableNotConnected') : 'DeviceNotAvailable',
                })}
              // prettier-ignore
              aria-label={(isConnected ? 'calculation on' : 'calculation off')}
              arrow
            >
              <Box
                css={css`
                display: flex;
                justify-content: center;
                margin-right: 10px;
              `}
              >
                {
                  isAvailable ?
                    <Icon icon={circleSolid} color={isConnected ? 'green' : 'red'} />
                    : <Icon icon={circleLine} color='white'/>
                }
              </Box>
            </Tooltip>
            {value}
          </Box>
        }
      </Typography>
      <ArrowForwardIosIcon fontSize={'small'} color={'inherit'} />
    </Box>
  </NameCellRoot>
);

// Filter components

const FishTypeSelect: FC = () => {
  const intl = useIntl();
  const basinStore = useStore('basinStore');
  useEffect(() => {
    basinStore.loadFishTypes();
  }, [basinStore]);
  const { fishTypes } = basinStore;

  return (
    <Select
      id="fishtype-filter-select"
      value={basinStore.fishtypeFilter.id}
      disableUnderline
      onChange={(event: ChangeEvent<{ value: unknown }>) => {
        const id = (event.target.value as number) || -1;
        basinStore.setFishTypeFilter(id);
      }}
      css={(theme) => css`
        &.MuiInputBase-root {
          .MuiSelect-root {
            color: ${theme.palette.primary.main};
          }
          .MuiSelect-icon {
            color: ${theme.palette.primary.main};
          }

          .MuiTypography-h5 {
            font-weight: 700;
          }
        }
      `}
    >
      <MenuItem key={'all'} value={-1}>
        <Typography variant="h5">
          {intl.formatMessage({ id: 'All' })}
        </Typography>
      </MenuItem>
      {fishTypes.map((type) => (
        <MenuItem key={type.id} value={type.id}>
          <Typography variant="h5">
            {intl.formatMessage({
              id: type.name.charAt(0).toUpperCase() + type.name.slice(1),
            })}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
};
