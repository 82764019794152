/** @jsxRuntime classic */
/** @jsx jsx */
import { useState } from 'react';
import { css, jsx } from '@emotion/react';
import { Box, Typography } from '@material-ui/core';
import { NumericInput } from 'components/input-fields';

export const PercentagePicker = ({
  value,
  infoText = '',
  onModified,
}: {
  value: number;
  infoText?: string;
  onModified: (percent: number) => void;
}) => {
  const [percent, setPercent] = useState(value || 0);
  const handleChange = (newValue: number) => {
    if (!Number.isNaN(newValue)) {
      setPercent(newValue);
    }
  };

  const onBlur = () => {
    onModified(percent);
  };

  return (
    <Box display="flex" alignItems="flex-start">
      <Box css={css`width: 60px;`} mr={1}>
        <NumericInput
          ariaLabel={"%"}
          max={100}
          onChange={handleChange}
          onBlur={onBlur}
          value={percent}
        />
      </Box>
      <Box css={css`margin-top: 2px;`}>
        <Typography
          component="span"
          css={css`
            align-self: center;
          `}
        >
          {infoText}
        </Typography>
      </Box>
    </Box>
  );
};
