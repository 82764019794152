import styled from '@emotion/styled';
import { IWidgetComponent } from 'models/widget';
import { FC } from 'react';
import { Icon } from '@iconify/react';
import fishLine from '@iconify/icons-clarity/fish-line';
import { CircularProgress, Typography, useTheme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useStore } from 'stores/store-hooks';
import { observer } from 'mobx-react-lite';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.spacing(3)}px;
`;
const IconArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

const BasinCount = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const WidgetTitle = styled.span`
  font-size: 28px;
  font-weight: 400;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const FishCountWidget: FC<IWidgetComponent> = observer(({ widget }) => {
  const intl = useIntl();
  const theme = useTheme();
  const dashboardStore = useStore('dashboardStore');
  return (
    <Root>
      <IconArea>
        <Icon height={56} icon={fishLine} color={theme.palette.line2.main} />
      </IconArea>
      <BasinCount>
        <WidgetTitle>
          {dashboardStore.isLoading ? (
            <CircularProgress size={20} />
          ) : (
            `${intl.formatNumber(dashboardStore.totalBiomass / 1000, {
              style: 'decimal',
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })} ${intl.formatMessage({ id: 'Tons' })}`
          )}
        </WidgetTitle>
        <Typography variant="h5" component="span" color="textPrimary">
          {intl.formatMessage({ id: 'BiomassTotal' })}
        </Typography>
      </BasinCount>
    </Root>
  );
});
