/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { ChangeEvent, FC } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Icon } from '@iconify/react';
import { languages } from '../../constants';
import { observer } from 'mobx-react-lite';
import styled from '@emotion/styled';
import { useStore } from 'stores/store-hooks';

interface ILanguageSelect {
  textColor?: 'white' | 'primary';
}

//@ts-ignore
const StyledSelect = styled(({ textColor, ...props }) => (
  <Select {...props} />
))<ILanguageSelect>`
  .MuiSelect-select {
    align-items: center;
    display: flex;
    font-size: 14px;
    > svg {
      margin-right: ${({ theme }) => theme.spacing(1)}px;
    }
  }
  &.MuiOutlinedInput-root {
    border-radius: 5px;
  }
  &.MuiInputBase-root,
  .MuiSelect-icon {
    color: ${({ textColor, theme }) =>
      textColor === 'white'
        ? theme.palette.common.white
        : theme.palette.text.primary};
  }
`;

export const LanguageSelect: FC<ILanguageSelect> = observer(
  ({ textColor = 'primary' }) => {
    const intl = useIntl();
    const userStore = useStore('userStore');
    return (
      <StyledSelect
        textColor={textColor}
        id="appbar-language-menu-select"
        value={userStore.selectedLanguage}
        disableUnderline
        onChange={(event: ChangeEvent<{ value: string }>) => {
          userStore.selectedLanguage = event.target.value;
        }}
      >
        {languages.map((language) => (
          <MenuItem
            key={language.id}
            value={language.id}
            css={(theme) => css`
              > svg {
                margin-right: ${theme.spacing(1)}px;
              }
            `}
          >
            <Icon height={24} icon={language.icon} />
            {intl.formatMessage({ id: language.textId })}
          </MenuItem>
        ))}
      </StyledSelect>
    );
  }
);
