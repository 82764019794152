import styled from "@emotion/styled";
import {Typography} from "@material-ui/core";
import {css} from "@emotion/react";
import {MEDIA_BREAKPOINT_SD} from "../../constants";
import {FC} from "react";

export interface IMultipleCameraViewHeader {
  header: string;
}

export const MultipleCameraViewHeader: FC<IMultipleCameraViewHeader> = ({header}) => {
  return (
    <Root>
      <ContentLeft>
        <Typography
          variant="h1"
          css={(theme) => css`
            && {
              font-weight: 600;
              color: ${theme.palette.primary.main};
              @media (max-width: ${MEDIA_BREAKPOINT_SD}px) {
                display: none;
              }
            }
          `}
        >
          {header}
        </Typography>
      </ContentLeft>
      <ContentMiddle>
      </ContentMiddle>
      <ContentRight>
      </ContentRight>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  margin: 0 ${({ theme }) => theme.spacing(2)}px;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.primary.main}`};
`;

const ContentLeft = styled.div`
  display: flex;
  flex-grow: 1;
  cursor: default;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  min-width: 80px;
  flex-direction: column;
`;

const ContentMiddle = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
`;

const ContentRight = styled.div`
  display: flex;
  flex-grow: 1;
  cursor: default;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;
