/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FC, ReactNode } from 'react';
import { IFeedingScheduleEntry } from 'models/feeding-schedule';
import { Box } from '@material-ui/core';
import { useEntryItems } from './feeding-schedule-hooks';

interface IFeedingScheduleEntryRow {
  entry: IFeedingScheduleEntry;
  calibration: number;
  isHw2: boolean;
}

export const FeedingScheduleEntryRow: FC<IFeedingScheduleEntryRow> = ({
  entry,
  calibration,
  isHw2
}) => {
  const items = useEntryItems(entry, calibration, isHw2);

  return (
    <Box display="flex" flexDirection="row">
      {items.map((item) => (
        <EntryRow key={item.id} {...item} />
      ))}
    </Box>
  );
};

interface IScheduleEntryRow {
  flexBasis: string;
  value: ReactNode;
}

const EntryRow: FC<IScheduleEntryRow> = ({ value, flexBasis }) => {
  return (
    <Box
      css={(theme) => css`
        flex: 1 1 ${flexBasis};
        justify-content: center;
        display: flex;
        margin-bottom: ${theme.spacing(2)}px;
      `}
    >
      {value}
    </Box>
  );
};
