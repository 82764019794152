/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import { MEDIA_BREAKPOINT_LG, MEDIA_BREAKPOINT_XL } from '../../../constants';
import { observer } from 'mobx-react-lite';
import { IFishBasin } from 'models/fishbasin';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useStore } from 'stores/store-hooks';

interface IBasinDetail {
  basin: IFishBasin;
}

export const BasinControlCalculation: FC<IBasinDetail> = observer(
  ({ basin }) => {
    const intl = useIntl();
    const userStore = useStore('userStore');
    const { canEdit } = userStore;
    const basinStore = useStore('basinStore');
    const [feedingEnabled, setFeedingEnabled] = useState(
      !!basin.feedingenabled
    );

    useEffect(() => {
      setFeedingEnabled(!!basin.feedingenabled);
    }, [basin]);

    const breakpointLg = useMediaQuery(`(min-width:${MEDIA_BREAKPOINT_LG}px)`, {
      noSsr: true,
    });

    const breakpointSd = useMediaQuery(`(max-width:${1280}px)`, {
      noSsr: true,
    });

    const handleToggleChange = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const response = await basinStore.toggleCalculation(basin.id);
      if (response.status === 200 && response.data?.feedingenabled) {
        setFeedingEnabled(response.data?.feedingenabled);
      }
    };

    return (
      <Grid
        container
        spacing={0}
        direction="row"
        css={(theme) => css`
          overflow-y: auto;
          padding: ${theme.spacing(2)}px;
          min-width: 150px;
          @media (max-width: ${MEDIA_BREAKPOINT_XL}px) {
            min-width: 100px;
            padding: ${theme.spacing(1.5)}px;
          }
        `}
      >
        <Grid item xs={12}>
          <Typography
            component="span"
            variant="h5"
            color="primary"
            css={css`
              &.MuiTypography-root {
                line-height: 1.5;
              }
            `}
          >
            {intl.formatMessage({ id: 'Calculation' })}
          </Typography>
          <Grid item xs={12} css={css``}>
            <Tooltip
              title={
                !canEdit
                  ? intl.formatMessage({
                      id: 'ModifyingSettingsNotAllowed',
                    })
                  : ''
              }
              aria-label="editing not allowed"
              arrow
            >
              <FormControlLabel
                labelPlacement={breakpointLg || breakpointSd ? 'end' : 'bottom'}
                control={
                  <Switch
                    size={breakpointLg || breakpointSd ? 'medium' : 'small'}
                    checked={feedingEnabled}
                    onChange={handleToggleChange}
                    disabled={!canEdit}
                  />
                }
                label={
                  <Typography
                    component="span"
                    variant="caption"
                    color="textPrimary"
                  >
                    {feedingEnabled
                      ? intl.formatMessage({ id: 'On' })
                      : intl.formatMessage({ id: 'Off' })}
                  </Typography>
                }
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);
