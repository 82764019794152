import { IFacility } from './facility';

export interface ICompany {
  url?: string;
  id: number;
  name?: string;
  businesscode?: string;
  customerid?: string;
  additional_customer_accounts?: IAdditionalCustomerAccount[];
  contactperson?: string;
  phonenumber?: string;
  website?: string;
  description?: string;
  deleted?: boolean;
  facilities?: IFacility[];
  address?: string;
  street_number?: string;
  route?: string;
  neighborhood?: string;
  locality?: string;
  postal_code?: string;
  country?: string;
  place_id?: string;
  is_concern?: boolean;
}

export interface IAdditionalCustomerAccount {
  customerid: string;
  description?: string;
  address?: string;
  locality?: string;
  postal_code?: string;
}

export const RAISIO_COMPANY = Object.freeze({
  url: '',
  id: -1,
  name: 'Raisio',
});
