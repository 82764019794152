/** @jsxRuntime classic */
/** @jsx jsx */
import React, { ChangeEvent, useEffect } from 'react';
import { css, jsx } from '@emotion/react';
import { Box, Tabs, Typography, useTheme, Tab } from '@material-ui/core';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router';
import { tabs } from './tabs';
import { TabContent } from './tab-content';
import { waldenTheme } from './echart-theme';
import * as echarts from 'echarts';
import { useStore } from 'stores/store-hooks';

echarts.registerTheme('walden', waldenTheme);

export const GraphTabs = () => {
  const history = useHistory();
  const { location } = history;
  const theme = useTheme();
  const initialTab = tabs.find((tab) => location.pathname.includes(tab.path));
  const initialTabValue = initialTab ? initialTab.value : 0;
  const [value, setValue] = useState(initialTabValue);
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const basinStore = useStore('basinStore');
  const graphStore = useStore('graphStore');
  const { hasIotAutomation } = basinStore;
  const filteredTabs = tabs.filter((t) => {
    // hide tabs requiring IoT automation if needed (oxygen, temperature etc.)
    return hasIotAutomation || !t.requiresAutomation;
  });

  useEffect(() => {
    // delete all chart data when component mounts for the first time, this is to ensure old data (from graphStore) is not visible when switching tabs
    graphStore.clearChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: ChangeEvent<{}>, index: number) => {
    const newValue = filteredTabs[index].value;
    setValue(newValue);
    const tab = filteredTabs.find((tab) => tab.value === newValue);
    if (tab) {
      history.push(`/basin/${id}${tab.path}`);
    } else {
      history.push(`/basin/${id}/graphs/biomass`);
    }
  };

  const customTabStyle = css`
    &.MuiTab-root {
      text-transform: none;
      border-right: 1px solid ${theme.palette.primary.main};
      padding: 8px;
      padding-bottom: 0px;
    }
    .MuiTab-wrapper {
      padding: 0 8px;
      height: 100%;
      width: 100%;
    }
    :hover,
    &&.Mui-selected {
      .MuiTab-wrapper {
        background-color: ${theme.palette.hover.main};
      }
    }
  `;

  return (
    <Box display="flex" flexDirection="column" width="100%" marginRight="-10em">
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          children: <span />,
        }}
        css={(theme) => css`
          .MuiTabs-indicator {
            display: flex;
            justify-content: center;
            background-color: transparent;
            height: 4px;
            & > span {
              width: calc(100% - 17px);
              background-color: ${theme.palette.secondary.main};
            }
          }
        `}
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {filteredTabs.map((tab) => {
          return (
            <Tab
              css={customTabStyle}
              key={tab.value}
              label={
                <Typography
                  component="span"
                  variant="h5"
                  // Match the font size and prevent overflow and scrolling
                  style={{ lineHeight: '16px' }}
                >
                  {intl.formatMessage({ id: tab.labelId })}
                </Typography>
              }
            />
          );
        })}
      </Tabs>
      <Switch>
        {filteredTabs.map((tab) => {
          const Component = tab.component;
          return (
            <Route key={tab.value} exact path={`/basin/:id${tab.path}`}>
              <TabContent>
                <Component />
              </TabContent>
            </Route>
          );
        })}
        <Redirect exact path="*" to={`/basin/${id}/graphs/biomass`} />
      </Switch>
    </Box>
  );
};
