/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Box, Checkbox, Grid, Typography, useTheme } from '@material-ui/core';
import { IFishBasin } from 'models/fishbasin';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import {FormattedMessage as M, useIntl} from 'react-intl';
import styled from '@emotion/styled';
import { EditableTextField } from './editable-text-field';
import { debounce } from 'debounce';
import { useStore } from 'stores/store-hooks';
import { MEDIA_BREAKPOINT_LG } from '../../../constants';
import {ISiloEntry} from "../../../models/silo";

interface IBasinDetail {
  basin: IFishBasin;
  siloEntries: ISiloEntry[];
}

export const FeedPercentage: FC<IBasinDetail> = ({ basin, siloEntries }) => {
  const intl = useIntl();
  const theme = useTheme();
  const basinStore = useStore('basinStore');
  const userStore = useStore('userStore');
  const {canEdit} = userStore;
  const { thousandSeparator } = userStore;
  const [useFixedPercent, setUseFixedPercent] = useState(
    basin?.usefixedfeedingpercent
  );

  const updateBasin = async (data: object) => {
    const response = await basinStore.updateBasin(basin.id, data as IFishBasin);
    if (response?.data) {
      basinStore.setSelectedBasin(response.data);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(debounce(updateBasin, 1000), []);

  const updateBasinData = (key: string, value: string) => {
    debouncedUpdate({ [key]: value });
  };

  const handleFixedPercentCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setUseFixedPercent(event.target.checked);
    updateBasin({
      usefixedfeedingpercent: event.target.checked,
    });
  };

  const updateForceFeedPercentage = (value: string) => {
    const fixedfeedingpercent =
      Number(
        value
          .replace(thousandSeparator, '') // remove thousand separator
          .replace(',', '.') // convert ',' decimal separator to dot
          .replace(/\s/g, '') // remove white space
      ) || 0 | 0;

    debouncedUpdate({
      fixedfeedingpercent,
    });
  };

  const showLastPercentOfRecommended = (): boolean => {
    const percent = Number(basin.lastpercentofrecommended);
    if((basin.currentfeedinfo?.raisio_recommended_percentage || 0) === 0) {
      return percent >= 0 && (Number(basin.fixedfeedingpercent) || 0) <= 0;
    }
    else {
      return percent >= 0;
    }
  }

  const latestEntry = () => {
    if (!siloEntries || siloEntries.length === 0) {
      return undefined;
    }
    return siloEntries.reduce((latest, entry) =>
      latest.timestamp < entry.timestamp ? entry : latest
    );
  }

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      css={(theme) => css`
        overflow: hidden;
        padding: ${theme.spacing(2)}px;
      `}
    >
      <Grid item xs={12} css={(theme) => css``}>
        <Grid container spacing={0} direction="row">
          <Grid item xs={5}>
            {!useFixedPercent ? (
              <Typography
                component="span"
                variant="h5"
                color="textPrimary"
                style={{ fontWeight: 700 }}
              >
                {intl.formatNumber(
                  (Number(basin.currentfeedinfo?.feed_percentage) || 0) / 100,
                  {
                    style: 'percent',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }
                )}
              </Typography>
            ) : (
              <EditableTextField
                disabled={!canEdit}
                component="span"
                variant="h5"
                color="primary"
                suffix="%"
                value={intl.formatNumber(
                  Number(basin.fixedfeedingpercent) || 0,
                  {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }
                )}
                inputProps={{
                  style: {
                    ...theme.typography.h5,
                    color: theme.palette.primary.main,
                  },
                }}
                onChange={(event) => {
                  updateForceFeedPercentage(event.target.value);
                }}
              />
            )}
            <Grid
              item xs={12}
              style={{
                marginTop: 2
              }}
            >
              <Typography
                component="span"
                variant="caption"
                color="textPrimary"
              >
                {`${intl.formatMessage({ id: 'FeedingPercentage' })} `}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Box
              css={css`
                white-space: nowrap;
              `}
            >
              <Checkbox
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: 0
                }}
                checked={useFixedPercent}
                onChange={handleFixedPercentCheckbox}
                name="termsAccepted"
                color="primary"
                disabled={!canEdit}
              />

              {intl.formatMessage({ id: 'FixedFeedingPercent' })}
            </Box>
            <Grid item xs={12}>
              <Typography
                component="span"
                variant="caption"
                color="textPrimary"
              >
                { basin.usefixedfeedingpercent ?
                  `${intl.formatMessage({ id: 'RaisioTableRecommends' })} `
                  + intl.formatNumber(basin.currentfeedinfo?.raisio_recommended_percentage || 0,
                {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
                  + ` %` : ''
                }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={0} direction="row">
          <Grid item xs={6} style={{marginTop: '2px'}}>
            {useFixedPercent ? (
              showLastPercentOfRecommended() ? (
                <Typography component="span" variant="h5" color="primary">
                  {intl.formatNumber(
                    Number(basin.lastpercentofrecommended) || 0,
                    {
                      style: 'decimal',
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }
                  )} %
                </Typography>
              ) : (
                <Typography component="span" variant="h5" color="primary">
                  <M id="NoValue"/>
                </Typography>
              )
            ) : (
              <EditableTextField
                disabled={!canEdit}
                component="span"
                variant="h5"
                color="primary"
                suffix="%"
                type="integer"
                value={intl.formatNumber(
                  Number(basin.lastpercentofrecommended) || 0,
                  {
                    style: 'decimal',
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }
                )}
                inputProps={{
                  style: {
                    ...theme.typography.h5,
                    color: theme.palette.primary.main,
                  },
                }}
                onChange={(event) => {
                  updateBasinData(
                    'lastpercentofrecommended',
                    String(parseInt(
                      event.target.value.replace(thousandSeparator, '')
                        .replace(/\s/g, '')
                    ))
                  );
                }}
              />
            )}
            <Grid item xs={12} spacing={0} direction="row">
              <Typography
                component="span"
                variant="caption"
                color="textPrimary"
              >
                {`${intl.formatMessage({
                  id: 'PercentageFromRecommendation',
                })} `}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <Typography component="span" variant="h5" color="primary">
                { latestEntry()?.feedtype.name || `${intl.formatMessage({ id: 'NoUsedFeed' })} `}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="span"
                variant="caption"
                color="textPrimary">
                {`${intl.formatMessage({ id: 'Feed' })} `}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  margin: ${({ theme }) => theme.spacing(2)}px 0;
  @media (max-width: ${MEDIA_BREAKPOINT_LG}px) {
    margin: ${({ theme }) => theme.spacing(1)}px 0;
  }
`;
