import { IntlShape } from 'react-intl';
import { Column } from 'react-table';
import { Typography } from '@material-ui/core';
import { ICleaningResult } from 'models/harvesting';
import { formatDate } from 'translations/locales';

export const getRemovingColumns = (
  intl: IntlShape,
  summaryRowCount: number,
  editCleaningResult: (cleaningResult: ICleaningResult) => any,
) => [
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "Date" })}
      </Typography>
    ),
    accessor: "cleaningdate",
    Cell: ({ value }: { value: string }) => (
      <Typography>
        {value ? formatDate(intl, new Date(value)) : ""}
      </Typography>
    ),
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "AmountPcs" })}
      </Typography>
    ),
    accessor: "amount",
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "HarvestingStartBiomass" })}
      </Typography>
    ),
    accessor: "originalweight",
    Cell: ({ value }: { value: number }) => (
      <Typography>
        {intl.formatNumber(value, {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "AdditionalInformation" })}
      </Typography>
    ),
    accessor: "details",
  },
] as Column<ICleaningResult>[];
