/** @jsxRuntime classic */
/** @jsx jsx */
import {FC, Fragment, ReactNode} from "react";
import {Box, Button, ClickAwayListener, Fade, Paper, Theme, Typography, useTheme} from "@material-ui/core";
import {css, jsx, keyframes} from "@emotion/react";
import Popper from "@material-ui/core/Popper";
import {bindPopper, bindToggle, PopupState, usePopupState} from "material-ui-popup-state/hooks";
import {ArrowBack, ArrowDownward, ArrowForward, ArrowUpward} from "@material-ui/icons";
import {useStore} from "../../stores/store-hooks";
import {IFishBasin} from "../../models/fishbasin";
import {observer} from "mobx-react-lite";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { FormattedMessage as M } from 'react-intl';

export const CameraControlsPopover: FC<any> = () => {
  const theme = useTheme();

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'cameraControlsPopper',
  });

  const updateData = () => {
    popupState.close();
  };

  return (
    <Box>
      <Button
        variant="contained"
        {...bindToggle(popupState)}
        style={{
          backgroundColor: theme.palette.background2.main,
          boxShadow: '0px 3px 3px rgba(0, 88, 100, 0.15)',
          position: "absolute",
          zIndex: 1,
          top: `${theme.spacing(4)}px`,
          right: `${theme.spacing(4)}px`
        }}
      >
        <Typography component="span" variant="h5" css={buttonTypographyStyle}>
          <M id="CameraControls"/>
        </Typography>
      </Button>
      <Popper {...bindPopper(popupState)} transition>
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={popupState.close}
            mouseEvent="onMouseDown"
          >
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <PopoverContent
                  popupState={popupState}
                  updateData={updateData}
                />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
};

interface IContent {
  popupState: PopupState;
  updateData: () => void;
}

const PopoverContent: FC<IContent> = observer(
  ({ popupState, updateData }) => {

  const iotStore = useStore('iotStore');
  const isControlDisabled = () => iotStore.hasPendingCameraMovementCommand;

  const spin = keyframes`
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  `;

  return (
    <Fragment>
      <Box style={{margin: '5px 5px'}}>
        <div style={rotationButtonsGrid}>
          <GridButton col={2} row={1} onClick={iotStore.moveCameraUp} disabled={isControlDisabled()}>
            <ArrowUpward/>
          </GridButton>
          <GridButton col={1} row={2} onClick={iotStore.moveCameraLeft} disabled={isControlDisabled()}>
            <ArrowBack/>
          </GridButton>
          <div
            style={{gridColumnStart: 2, gridRowStart: 2, display: "flex", justifyContent: "center", alignItems: "center"}}
          >
            {isControlDisabled() && (
              <AutorenewIcon
                css={(theme) => css`
                  color: ${theme.palette.line2.main};
                  animation: ${spin} 1s ease infinite;
                `}
              />
            )}
          </div>
          <GridButton col={2} row={3} onClick={iotStore.moveCameraDown} disabled={isControlDisabled()}>
            <ArrowDownward/>
          </GridButton>
          <GridButton col={3} row={2} onClick={iotStore.moveCameraRight} disabled={isControlDisabled()}>
            <ArrowForward/>
          </GridButton>
        </div>
        <Typography variant="h6" align="center">
          Zoom
        </Typography>
        <div style={zoomButtonsFlex}>
          <ZoomButton zoomLevel={1} disabled={isControlDisabled()}/>
          <ZoomButton zoomLevel={2} disabled={isControlDisabled()}/>
          <ZoomButton zoomLevel={3} disabled={isControlDisabled()}/>
          <ZoomButton zoomLevel={4} disabled={isControlDisabled()}/>
        </div>
      </Box>
    </Fragment>
  );
});

interface IGridButton {
  col: number;
  row: number;
  onClick: (basin: IFishBasin) => void;
  disabled: boolean;
  children?: ReactNode;
}

const GridButton: FC<IGridButton> = ({col, row, disabled, onClick, children}) => {
  const basinStore = useStore('basinStore');

  return (
    <div style={{gridColumnStart: col, gridRowStart: row}}>
      <Button
        disabled={disabled}
        style={{maxWidth: '100%', maxHeight: '100%', minWidth: '100%', minHeight: '100%'}}
        onClick={() => {
          if(basinStore.selectedBasin) {
            onClick(basinStore.selectedBasin);
          }
        }}
      >
        {children}
      </Button>
    </div>
  );
}

interface IZoomButton {
  zoomLevel: number;
  disabled: boolean;
}

const ZoomButton: FC<IZoomButton> = ({zoomLevel, disabled}) => {

  const basinStore = useStore('basinStore');
  const iotStore = useStore('iotStore');

  return (
    <Button
      style={{minWidth: 0}}
      disabled={disabled}
      onClick={() => {
        if(basinStore.selectedBasin) {
          console.log(iotStore.zoomCamera(basinStore.selectedBasin, zoomLevel));
        }
      }}
    >
      <Typography variant={"h6"}>
        {zoomLevel}x
      </Typography>
    </Button>
  );
}

const rotationButtonsGrid = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 30px)',
  gridTemplateRows: 'repeat(3, 30px)',
  gap: '10px',
};

const zoomButtonsFlex = {
  display: 'flex'
};

const buttonTypographyStyle = (theme: Theme) => css`
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: ${theme.spacing(1)}px;
  > svg {
    margin-right: ${theme.spacing(1)}px;
  }
`;
