import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import ReactECharts from 'echarts-for-react';
import { useStore } from 'stores/store-hooks';
import {
  supportUnitBatteryConfig,
  supportUnitBatteryOption,
} from './chart-config';

export const BatteryLevelGraph: FC = observer(() => {
  const graphStore = useStore('graphStore');
  const { batteryChartData, batteryChartLoading } = graphStore;
  const intl = useIntl();
  useEffect(() => {
    graphStore.loadBatteryValues(intl);
  }, [graphStore, intl]);

  return (
    <ReactECharts
      style={{ height: '420px', width: '100%' }}
      showLoading={batteryChartLoading}
      option={{ ...supportUnitBatteryOption(intl), ...batteryChartData }}
      {...supportUnitBatteryConfig}
    />
  );
});
