import { IFishBasin } from '../../models/fishbasin';

export const additionalGrowth = (basin: IFishBasin) => {
  if (
    basin.currentbiomasses &&
    basin.startamount &&
    basin.currentbiomasses[0]
  ) {
    return basin.currentbiomasses[0]
      + (basin.fastingweights?.length ? basin.fastingweights[0] : 0)
      - (basin.startamount * Number(basin.startweight)) / 1000 || 0;
  }
  return 0;
};
