import { BiomassGraph } from './biomass-graph';
import { AverageWeightGraph } from './average-weight-graph';
import { BatteryLevelGraph } from './battery-level-graph';
import { FeedAmountGraph } from './feed-amount-graph';
import { CumulativeFeedAmountGraph } from './cumulative-feed-amount-graph';
import { DeviationGraph } from './deviation-graph';
import { SiloLevelGraph } from './silo-level-graph';
import { SiteGraphs } from './site-graphs';
import {FeedPercentageGraph} from "./feed-percentage-graph";

export const tabs = [
  {
    value: 0,
    path: `/graphs/biomass`,
    labelId: 'Biomass',
    requiresAutomation: false,
    component: BiomassGraph,
  },
  {
    value: 1,
    path: `/graphs/average-weight`,
    labelId: 'AverageWeight',
    requiresAutomation: false,
    component: AverageWeightGraph,
  },
  {
    value: 2,
    path: `/graphs/feed-amount`,
    labelId: 'FeedAmount',
    requiresAutomation: false,
    component: FeedAmountGraph,
  },
  {
    value: 3,
    path: `/graphs/feed-percentage`,
    labelId: 'FeedingPercentage',
    requiresAutomation: false,
    component: FeedPercentageGraph,
  },
  {
    value: 4,
    path: `/graphs/cun-feed-amount`,
    labelId: 'CumulativeFeedAmount',
    requiresAutomation: false,
    component: CumulativeFeedAmountGraph,
  },
  {
    value: 5,
    path: `/graphs/deviation`,
    labelId: 'DeviationFromRecommendation',
    requiresAutomation: false,
    component: DeviationGraph,
  },
  {
    value: 6,
    path: `/graphs/site-graphs`,
    labelId: 'SiteGraphs',
    requiresAutomation: false,
    component: SiteGraphs,
  },
  {
    value: 7,
    path: `/graphs/silo-level`,
    labelId: 'SiloLevel',
    requiresAutomation: true,
    component: SiloLevelGraph,
  },
  {
    value: 8,
    path: `/graphs/battery-level`,
    labelId: 'BatteryLevel',
    requiresAutomation: true,
    component: BatteryLevelGraph,
  },
];
