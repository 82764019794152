/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Switch,
  Theme,
  CircularProgress,
} from '@material-ui/core';
import styled from '@emotion/styled';
import { Cell, Column } from 'react-table';
import { IFishBasin } from 'models/fishbasin';
import { IntlShape, useIntl } from 'react-intl';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { FC, Fragment, useState, useEffect } from 'react';
import { useStore } from 'stores/store-hooks';
import { format } from 'date-fns';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import { MEDIA_BREAKPOINT_LG, MEDIA_BREAKPOINT_SD } from '../../constants';
import WarningIcon from '@material-ui/icons/Warning';
import {theme} from '../../theme/theme';
import {runInAction} from 'mobx';

export const getColumns = (
  intl: IntlShape,
  handleEdit: (basin: IFishBasin) => void,
  openBasin: (basin: IFishBasin) => void,
  handleSplit: (basin: IFishBasin) => void,
  downloadSeasonReport: (basinId: number) => Promise<any>,
  handleArchive: (basin: IFishBasin) => void,
  toggleCalculation: (basin: IFishBasin) => Promise<any>,
  toggleAutomation: (
    basin: IFishBasin,
    nextIsAutomatic: boolean
  ) => Promise<any>,
  controlButtonColor?: string
) =>
  [
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Basin' })}
        </Typography>
      ),
      accessor: 'name',
      Cell: ({ value, cell }) => (
        <SiteNameCell
          theme={theme}
          value={value}
          basin={cell.row.original}
          openBasin={openBasin}
          controlButtonColor={controlButtonColor}
          onControlButtonClick={handleArchive}
          leftOffset={15}
        />
      ),
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Species' })}
        </Typography>
      ),
      accessor: 'fishtype.name',
      Cell: ({ value }: { value: string }) => (
        <TypographyCell
          // capitalize fish type e.g. whitefish to Whitefish (used as translation key)
          value={
            value
              ? intl.formatMessage({
                  id: value.charAt(0).toUpperCase() + value.slice(1),
                })
              : ''
          }
        />
      ),
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Biomass' })}
        </Typography>
      ),
      accessor: 'currentbiomasses.0',
      Cell: ({ value }: { value: string }) => (
        <TypographyCell
          value={
            value
              ? intl.formatNumber(Number(value) || 0, {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }) + ' ' + intl.formatMessage({ id: "UnitKg" })
              : ''
          }
        />
      ),
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Portion' })}
        </Typography>
      ),
      accessor: 'currentfeedinfo.feed_amount',
      Cell: ({ value }: { value: string }) => (
        <TypographyCell
          value={
            value
              ? intl.formatNumber((Number(value) || 0) / 1000, {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }) + ' ' + intl.formatMessage({ id: "UnitKg" })
              : ''
          }
        />
      ),
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'PercentageFromRecommendation' })}
        </Typography>
      ),
      accessor: 'lastpercentofrecommended',
      Cell: ({ value }: { value: string }) => (
        <TypographyCell
          value={
            (Number(value) || 0) >= 0 ?
              intl.formatNumber((Number(value) || 0) / 100, {
                  style: 'percent',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })
              : '-'
          }
        />
      ),
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'CumulativeFeedAmount' })}
        </Typography>
      ),
      accessor: 'laststatus.cumulativefeed_user',
      Cell: ({ value }: { value: string }) => (
        <TypographyCell
          value={
            value
              ? intl.formatNumber((Number(value) || 0) / 1000, {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }) + ' ' + intl.formatMessage({ id: "UnitKg" })
              : ''
          }
        />
      ),
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'AverageWeight' })}
        </Typography>
      ),
      accessor: 'currentaverageweights.0',
      Cell: ({ value }: { value: string }) => (
        <TypographyCell
          value={
            value
              ? intl.formatNumber(Number(value) || 0, {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }) + ' ' + intl.formatMessage({ id: "UnitGrams" })
              : ''
          }
        />
      ),
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'NumberOfFish' })}
        </Typography>
      ),
      accessor: 'currentamount',
      Cell: ({ value }: { value: string }) => (
        <TypographyCell
          value={
            value
              ? `${intl.formatNumber(Number(value) || 0, {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })} ${intl.formatMessage({ id: 'Pieces' })}`
              : ''
          }
        />
      ),
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Calculation' })}
        </Typography>
      ),
      accessor: 'feedingenabled',
      Cell: ({ value, cell }) => {
        const userStore = useStore('userStore');
        const { canEdit } = userStore;

        const handleToggleChange = async (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          return toggleCalculation(cell.row.original as IFishBasin);
        };

        return (
          <Tooltip
            title={
              !canEdit
                ? intl.formatMessage({
                    id: 'ModifyingSettingsNotAllowed',
                  })
                : intl.formatMessage({ id: value ? 'On' : 'Off' })
            }
            // prettier-ignore
            aria-label={!canEdit ? 'editing not allowed' : (value ? 'calculation on' : 'calculation off')}
            arrow
          >
            <Box
              css={css`
                display: flex;
                justify-content: center;
              `}
            >
              <Switch
                checked={value} //feedingEnabled
                onChange={handleToggleChange}
                disabled={!canEdit}
              />
            </Box>
          </Tooltip>
        );
      },
    },
    {
      Header: () => (
        <Typography variant="h6">
          {intl.formatMessage({ id: 'Automatic' })}
        </Typography>
      ),
      accessor: 'supportunit_id',
      Cell: ({ value, cell }) => {
        const userStore = useStore('userStore');
        const { canEdit } = userStore;
        const [automationStatusFetched, setAutomationStatusFetched] =
          useState(false);
        const [automaticFeeding, setAutomaticFeeding] = useState(false);
        const [loading, setLoading] = useState(false); // loading state
        const iotStore = useStore('iotStore');

        useEffect(() => {
          const loadData = async () => {
            setLoading(true);
            await runInAction(async () => {
              const basin = cell.row.original as IFishBasin;
              if (!automationStatusFetched) {
                const { data: deviceInfo } = await iotStore.loadDeviceInfo(basin);
                setAutomaticFeeding(!!deviceInfo?.deviceConfiguration?.autofeed);
                setAutomationStatusFetched(true);
              }
              // setAutomaticFeeding(iotStore.getFeedAutomationStatus(basin));
              setLoading(false);
            });
          };
          loadData();
        }, [automationStatusFetched, cell.row.original, iotStore]);

        const handleToggleChange = async (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          setLoading(true);
          await runInAction(async () => {
            const nextIsAutomatic = event.target.checked;
            await toggleAutomation(
              cell.row.original as IFishBasin,
              nextIsAutomatic
            );
            setAutomaticFeeding(nextIsAutomatic);
            setLoading(false);
          });
        };

        if (value) {
          return (
            <Tooltip
              title={
                !canEdit
                  ? intl.formatMessage({
                    id: 'ModifyingSettingsNotAllowed',
                  })
                  : intl.formatMessage({
                    id: automaticFeeding ? 'On' : 'Off',
                  })
              }
              aria-label={
                !canEdit
                  ? 'editing not allowed'
                  : automaticFeeding
                    ? 'automation on'
                    : 'automation off'
              }
              arrow
            >
              <Box
                css={css`
                  display: flex;
                  justify-content: center;
                `}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <Switch
                    checked={automaticFeeding}
                    onChange={handleToggleChange}
                    disabled={!canEdit}
                  />
                )}
              </Box>
            </Tooltip>
          );
        }
        return (
          <Typography variant="h6">-</Typography>
        );
      },
    },
    {
      id: 'edit',
      disableSortBy: true,
      Cell: ({ cell }: { cell: Cell<{}, any> }) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const userStore = useStore('userStore');
        const { canEdit } = userStore;

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
          setAnchorEl(null);
        };

        return (
          <Fragment>
            <IconButton aria-haspopup="true" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                disabled={!canEdit}
                onClick={() => {
                  handleEdit(cell.row.original as IFishBasin);
                  setAnchorEl(null);
                }}
              >
                {intl.formatMessage({ id: 'Edit' })}
              </MenuItem>
              <MenuItem
                disabled={!canEdit}
                onClick={() => {
                  handleSplit(cell.row.original as IFishBasin);
                  setAnchorEl(null);
                }}
              >
                {intl.formatMessage({ id: 'SplitFishBasin' })}
              </MenuItem>
              <MenuItem
                disabled={!canEdit}
                onClick={async () => {
                  const basin = cell.row.original as IFishBasin;
                  const data = await downloadSeasonReport(basin.id);
                  const url = window.URL.createObjectURL(
                    new Blob([data], {
                      type:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
                    })
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  const fileName = `${basin.name}${format(
                    new Date(),
                    '_dd_MM_yyyy'
                  )}.xlsx`;
                  link.setAttribute('download', fileName);
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  handleClose();
                }}
              >
                {intl.formatMessage({ id: 'DownloadSeasonReport' })}
              </MenuItem>
            </Menu>
          </Fragment>
        );
      },
    },
  ] as Column<IFishBasin>[];

// Custom components for different Cell-render functions in the columns

const TypographyCell: FC<{ value: string }> = ({ value }) => (
  <Typography
    component="div"
    variant="h5"
    color="primary"
    css={(theme) => css`
      &.MuiTypography-root {
        margin: 0;
      }
    `}
  >
    {value}
  </Typography>
);

// To serve as parent for a row and its control button
const NameCellRoot = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SiteNameCell: FC<{
  theme: Theme,
  value: string;
  basin: IFishBasin;
  controlButtonColor?: string;
  leftOffset?: number;
  openBasin: (basin: IFishBasin) => void;
  onControlButtonClick: (basin: IFishBasin) => void;
}> = ({
  theme,
  value,
  basin,
  controlButtonColor,
  openBasin,
  onControlButtonClick,
  leftOffset,
}) => {
  const activeAlarms = basin.activeAlarms ? basin.activeAlarms : false
  const activeAlarmsCounts = basin.activeAlarmsCount ? basin.activeAlarmsCount : 0;
  return (
    <NameCellRoot>
      <TableControlButton
        basin={basin}
        controlButtonColor={controlButtonColor}
        leftOffset={leftOffset || 30}
        onControlButtonClick={onControlButtonClick}
        // isDisabled={...} // Is there a situation where archive would be disabled?
      />
      <Box
        onClick={() => openBasin(basin)}
        css={(theme) => css`
          border: 1px solid ${controlButtonColor || theme.palette.line2.main};
          background-color: ${theme.palette.common.white};

          :hover {
            background-color: ${theme.palette.hover.main};
          }

          margin: -1px 10px -1px -1px;
          border-radius: 15px;
          height: 56px;
          min-width: 360px;
          @media (max-width: ${MEDIA_BREAKPOINT_LG}px) {
            min-width: 260px;
          }
          @media (max-width: ${MEDIA_BREAKPOINT_SD}px) {
            min-width: 150px;
          }
          align-items: center;
          justify-content: space-between;
          display: flex;
          cursor: ${controlButtonColor ? 'not-allowed' : 'pointer'};

          > svg {
            margin-right: 8px;
          }

          position: relative;
          color: ${theme.palette.primary.main};
          z-index: 1000;
        `}
      >
          <Box
            css={(theme) => css`
              align-items: center;
              display: flex;
              padding-left: ${theme.spacing(1)}px;
            `}
          >
            { activeAlarms &&
            <WarningIcon
              style={{
                color: theme.palette.error.main,
              }}
            />
            }
            { activeAlarms && activeAlarmsCounts > 1 &&
              <Typography
                variant="h4"
                component="div"
                css={css`
                    color: ${theme.palette.error.main};
                  `}
              >
                {activeAlarmsCounts}
              </Typography>
            }
          </Box>
        <Typography
          component="div"
          variant="h5"
          color="primary"
          css={(theme) => css`
            &.MuiTypography-root {
              margin: 0;
            }
          `}
        >
          {value}
        </Typography>
        <ArrowForwardIosIcon fontSize={'small'} color={'inherit'}/>
      </Box>
    </NameCellRoot>
  )
}

/* Control button adapted for table use from accordion's control button */

interface IControlButton {
  basin: IFishBasin;
  isDisabled?: boolean;
  controlButtonColor?: string;
  disabledTooltipTextId?: string;
  onControlButtonClick?: (id: IFishBasin) => void;
  leftOffset?: number;
}

export const TableControlButton: FC<IControlButton> = ({
  controlButtonColor,
  onControlButtonClick,
  disabledTooltipTextId,
  isDisabled,
  leftOffset,
  basin,
  // id,
}) => {
  const intl = useIntl();
  const handleClick = () => {
    onControlButtonClick && onControlButtonClick(basin);
  };

  return (
    <Box
      key={basin.id}
      component="span"
      css={(theme) => css`
        padding: ${theme.spacing(1)}px;
        padding-left: ${theme.spacing(0.5)}px;
        height: ${theme.spacing(6)}px;
        position: absolute;
        left: -${leftOffset || 0}px;
        min-width: 48px;
        background-color: ${controlButtonColor || 'unset'};
        visibility: ${controlButtonColor ? 'visible' : 'hidden'};
        border-radius: 15px 0 0 15px;
        display: flex;
        justify-items: center;
        align-items: center;
        z-index: 2000;
      `}
    >
      <Tooltip
        title={
          isDisabled ? intl.formatMessage({ id: disabledTooltipTextId }) : ''
        }
        aria-label="archive not allowed"
        arrow
        css={css`
          &&& {
            font-size: 28px;
          }
        `}
      >
        <RemoveRoundedIcon
          onClick={handleClick}
          css={(theme) => css`
            color: ${theme.palette.common.white};
            cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
          `}
        />
      </Tooltip>
    </Box>
  );
};
