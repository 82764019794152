/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useCallback, useEffect } from 'react';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { ISiteListData, IWidgetComponent } from 'models/widget';
import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import {Box, CircularProgress, Theme, Typography, useTheme} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { SiteListTable } from './site-list-table';
import { getColumns } from './site-list-columns';
import { Column } from 'react-table';
import { useStore } from 'stores/store-hooks';
import { useHistory } from 'react-router';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(3)}px;
`;

export const SiteListWidget: FC<IWidgetComponent> = observer(({ widget }) => {
  const intl = useIntl();
  const history = useHistory();
  const dashboardStore = useStore('dashboardStore');
  const facilityStore = useStore('facilityStore');
  const siteStore = useStore('siteStore');
  const theme: Theme = useTheme();

  useEffect(() => {
    dashboardStore.loadSitesListsWidget(intl);
  }, [dashboardStore, intl]);

  const navigate = useCallback(
    (facilityId: number | undefined, siteId: number) => {
      facilityStore.setFacilityScrollToTarget(facilityId);
      siteStore.setExpandedSites({ [siteId]: true });
      history.push(`/facilities/`);
    },
    [facilityStore, history, siteStore]
  );
  const { filteredSiteListData, siteListWidgetLoading } = dashboardStore;
  const columns = getColumns(intl, navigate, theme) as Column<ISiteListData>[];

  return (
    <Root>
      <Box
        css={(theme) => css`
          display: flex;
          flex: 1 1 auto;
          width: 100%;
          margin-bottom: ${theme.spacing(1)}px;
        `}
      >
        <Typography
          variant="h4"
          color="primary"
          css={css`
            &.MuiTypography-h4 {
              font-weight: 700;
            }
          `}
        >
          {intl.formatMessage({ id: 'SiteView' })}
        </Typography>
      </Box>
      <Box display="flex" flex="1 1 100%" width="100%" overflow="auto">
        {siteListWidgetLoading ? (
          <Box
            display="flex"
            flex="1 1 100%"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <SiteListTable columns={columns} data={filteredSiteListData} />
        )}
      </Box>
    </Root>
  );
});
