/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { ExpandButton } from '../common/expand-button';
import { ICompany } from '../../models/company';
import { Typography, Box } from '@material-ui/core';
import { FC } from 'react';
import { FormattedMessage as M } from 'react-intl';

interface IArchiveCompanyHeader {
  company: ICompany;
  expanded?: boolean;
}

export const ArchiveCompanyHeader: FC<IArchiveCompanyHeader> = ({
  company,
  expanded,
}) => {
  return (
    <Box
      css={css`
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        align-items: center;
      `}
    >
      <ExpandButton isExpanded={!!expanded} />
      <ContentLeft
        key={company.id}
        onClick={(event: any) => event.stopPropagation()}
        onFocus={(event: any) => event.stopPropagation()}
      >
        <Typography variant="h6" color="textPrimary">
          <M id="Company" />
        </Typography>
        <Typography variant="subtitle1" color="primary">
          {company.name}
        </Typography>
      </ContentLeft>
    </Box>
  );
};

const ContentLeft = styled.div`
  flex-grow: 1;
  cursor: default;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;
