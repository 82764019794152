import {
  Grid,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { FormattedMessage as M } from 'react-intl';
import { useState, Fragment } from 'react';
import { IFishBasin, IBasinStartValues } from 'models/fishbasin';
import { Modal, ModalActions, ModalContent } from 'components/modal-dialog';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useStore } from 'stores/store-hooks';
import { useForm } from 'react-hook-form';
import {
  FishInitialWeight,
  FishStartAmount,
} from 'components/fishbasin-list/basin-form-components';

interface IBasinArchiveDialog {
  handleClose: () => void;
  basinToArchive?: IFishBasin | null;
  updateBasin: (copy: boolean, startValues: IBasinStartValues) => any;
}

const schemaCreateNew = yup.object().shape({
  startweight: yup
    .number()
    .typeError('error.validation.startweight')
    .required('error.validation.startweight')
    .max(9999999999, 'error.validation.startweight')
    .min(0, 'error.validation.startweight'),
  startamount: yup
    .number()
    .typeError('error.validation.startAmount')
    .required('error.validation.startAmount')
    .max(9999999999, 'error.validation.startAmount')
    .min(0, 'error.validation.startAmount'),
});

export const BasinArchiveDialog = ({
  basinToArchive,
  handleClose,
  updateBasin,
}: IBasinArchiveDialog) => {
  // Which modal is shown: creating new from archived or just archive
  const [createNewFromArchived, setNewFromArchived] = useState(true);

  const {
    register,
    handleSubmit,
    errors,
    clearErrors,
    control,
  } = useForm<IFishBasin>({
    resolver: createNewFromArchived ? yupResolver(schemaCreateNew) : undefined,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: basinToArchive || {},
  });
  const toastStore = useStore('toastStore');

  const submitData = async (data: any) => {
    // TODO: Return error ids as response
    const response = updateBasin(createNewFromArchived, data);
    if (response?.errors) {
      toastStore.setToast('BasinArchiveError');
      return;
    }
    // success
    handleClose();
  };

  return (
    <Modal handleClose={handleClose}>
      <form noValidate onSubmit={handleSubmit(submitData)}>
        <ModalContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component="span" variant="subtitle2">
                <M id={'ArchiveBasin'} />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="span" variant="body1">
                <M id={'ConfirmArchive'} />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    aria-label="create new from archived selection"
                    onClick={() => setNewFromArchived(!createNewFromArchived)}
                    defaultChecked
                  />
                }
                label={<M id={'SelectCreateFromArchived'} />}
              />
            </Grid>
            {createNewFromArchived && (
              <Fragment>
                <FishInitialWeight
                  register={register}
                  control={control}
                  errors={errors}
                  size={6}
                />
                <FishStartAmount
                  register={register}
                  control={control}
                  errors={errors}
                  size={6}
                />
              </Fragment>
            )}
          </Grid>
        </ModalContent>
        <ModalActions>
          <Button
            color="primary"
            type="submit"
            disabled={!!Object.keys(errors).length}
          >
            <M id="Yes" />
          </Button>
          <Button
            onClick={() => {
              clearErrors();
              handleClose();
            }}
            color="primary"
          >
            <M id="Cancel" />
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};
