import flagFinland from '@iconify/icons-openmoji/flag-finland';
import flagSweden from '@iconify/icons-openmoji/flag-sweden';
import flagUnitedKingdom from '@iconify/icons-openmoji/flag-united-kingdom';
import flagRussia from '@iconify/icons-openmoji/flag-russia';

export const MEDIA_BREAKPOINT_XL = 1800;
export const MEDIA_BREAKPOINT_LG = 1550;
export const MEDIA_BREAKPOINT_MD = 1440;
export const MEDIA_BREAKPOINT_SD = 1024;

export const DRAWER_WIDTH_MAX = 250;
export const DRAWER_WIDTH_MIN = 75;
export const APPBAR_HEIGHT = 60;

export enum FISH_TYPES {
  Rainbowtrout = 'rainbowtrout',
  Whitefish = 'whitefish',
}

export const languages = [
  {
    id: 'fi',
    textId: 'Finnish',
    icon: flagFinland,
  },
  {
    id: 'sv',
    textId: 'Swedish',
    icon: flagSweden,
  },
  {
    id: 'en',
    textId: 'English',
    icon: flagUnitedKingdom,
  },
  {
    id: 'ru',
    textId: 'Russian',
    icon: flagRussia,
  },
];
