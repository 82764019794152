import {useIntl} from "react-intl";
import React, {ChangeEvent, FC, useState} from "react";
import {IFishBasin} from "../../models/fishbasin";
import {Box, Tab, Tabs, Typography} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import {tabs} from "./tabs";
import {Redirect, Route, Switch, useHistory} from "react-router";

interface IBasinCompareGraphs {
  fetchSelectedBasins: () => IFishBasin[];
}

export const BasinCompareGraphs: FC<IBasinCompareGraphs> = observer(({fetchSelectedBasins}) => {

  const intl = useIntl();
  const history = useHistory();
  const initialTab = tabs.find((tab) => history.location.pathname.includes(tab.path));
  const initialTabValue = initialTab ? initialTab.value : 0;
  const [value, setValue] = useState(initialTabValue);

  const handleChange = (event: ChangeEvent<{}>, index: number) => {
    const newValue = tabs[index].value;
    setValue(newValue);
    const tab = tabs.find((tab) => tab.value === newValue);
    if (tab) {
      history.push(`/basin-compare${tab.path}`);
    } else {
      history.push(`/basin-compare/biomass`);
    }
  };

  return (
    <Box display="flex" flexDirection="column" width="100%" height="70vh">
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        variant="fullWidth"
      >
        {tabs.map((tab) => {
          return (
            <Tab
              key={tab.value}
              style={{ minWidth: 50 }}
              label={
                <Typography
                  component="span"
                  variant="h5"
                  // Match the font size and prevent overflow and scrolling
                  style={{ lineHeight: '16px' }}
                >
                  {intl.formatMessage({ id: tab.labelId })}
                </Typography>
              }
            />
          );
        })}
      </Tabs>
      <Switch>
        {tabs.map((tab) => {
          const Component = tab.component;
          return (
            <Route key={tab.value} exact path={`/basin-compare${tab.path}`}>
              <Component fetchSelectedBasins={fetchSelectedBasins} />
            </Route>
          );
        })}
        <Redirect exact path="*" to={`/basin-compare/biomass`} />
      </Switch>
    </Box>
  );
});

