/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FormattedMessage as M } from 'react-intl';
import { InlineIcon } from '@iconify/react';
import { FC } from 'react';
import {
  Box,
  Button,
  Typography,
  Theme,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import exclamationCircleSolid from "@iconify-icons/clarity/exclamation-circle-solid";

export const EmergencyStopButton: FC<{
  handleClick: () => void;
}> = observer(({ handleClick }) => {

  const buttonTypographyStyle = (theme: Theme) => css`
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: ${theme.spacing(1)}px;
    > svg {
      margin-right: ${theme.spacing(1)}px;
    }
  `;

  return (
    <Box display="flex" alignItems="center">
      <Button
        fullWidth
        color="primary"
        variant="contained"
        type="submit"
        onClick={handleClick}
        css={(theme) => css`
          &.MuiButton-root {
            background-color: ${theme.palette.error.main};
            box-shadow: 0px 3px 3px rgba(0, 88, 100, 0.15);
            :hover {
              background-color: ${theme.palette.error.dark};
            }
            color: ${theme.palette.common.white};
          }
        `}
      >
        <Typography component="span" variant="h5" css={buttonTypographyStyle}>
          <InlineIcon icon={exclamationCircleSolid} height={24} />
          <M id="EmergencyStop" />
        </Typography>
      </Button>
    </Box>
  );
});
