/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Typography } from '@material-ui/core';
import { useStore } from 'stores/store-hooks';
import { WhiteTextField } from './misc-components';

export const ForgotPasswordComponent = observer(() => {
  const intl = useIntl();
  const {
    canSubmit,
    email,
    emailOkay,
    hasSubmitted,
    resetStore,
    setEmail,
    submitEmail,
  } = useStore('passwordResetStore');

  useEffect(() => {
    resetStore();
  }, [resetStore]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding={6}
      height="100%"
    >
      {hasSubmitted ? (
        <Typography
          css={(theme) => css`
            color: ${theme.palette.common.white};
          `}
          variant="body2"
        >
          {intl.formatMessage({ id: "ResetPasswordRequestComplete" }, { email: (
            <Box component="span" display="inline" fontWeight="fontWeightBold">
              {email}
            </Box>
          )})}
        </Typography>
      ) : (
        <Box display="flex" flex="0 1 500px" flexDirection="column">
          <Box mb={1}>
            <Typography
              css={(theme) => css`
                color: ${theme.palette.common.white};
              `} 
              variant="subtitle2"
            >
              {intl.formatMessage({ id: "ResetPasswordRequestMessage" })}
            </Typography>
          </Box>
          <WhiteTextField
            error={!emailOkay}
            helperText={emailOkay ? null : intl.formatMessage({
              id: "error.validation.email"
            })}
            id="login-email"
            label={intl.formatMessage({ id: "Email" })}
            onChange={(event) => setEmail(event.target.value)}
            value={email}
            variant="outlined"
          />
          <Button
            color="secondary"
            disabled={!canSubmit}
            onClick={submitEmail}
            variant="contained"
          >
            {intl.formatMessage({ id: "ResetPasswordRequestAction" })}
          </Button>
        </Box>
      )}
    </Box>
  );
});
