/** @jsxRuntime classic */
/** @jsx jsx */
import { Fragment } from 'react';
import { css, jsx } from '@emotion/react';
import { FC } from 'react';
import { IconButton, Toolbar, Typography } from '@material-ui/core';
import { FormattedMessage as M } from 'react-intl';
import { GlobalFilter, IGlobalFilter } from './global-filter';
import styled from '@emotion/styled';
import AddIcon from '@material-ui/icons/Add';
import { useStore } from 'stores/store-hooks';

interface IUserListToolBar extends IGlobalFilter {
  companyId: number | null;
}

const AddUserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > button {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const UserListToolBar: FC<IUserListToolBar> = ({
  globalFilter,
  setGlobalFilter,
  companyId,
}) => {
  const userStore = useStore('userStore');

  const openEditDialog = () => {
    userStore.setEditDialogOpen(true);
    userStore.editedUser = null;
    userStore.setEditedUserCompanyId(companyId);
  };

  return (
    <Fragment>
      <Toolbar
        css={(theme) => css`
          justify-content: space-between;
          align-items: center;
          &.MuiToolbar-gutters {
            padding-left: ${theme.spacing(2)}px;
            padding-right: ${theme.spacing(2)}px;
          }
        `}
      >
        <AddUserWrapper>
          {companyId === null ? null : (
            <IconButton aria-label="add" onClick={openEditDialog}>
              <AddIcon />
            </IconButton>
          )}
          <Typography variant="h3" id="user-list-toolbar-title">
            <M id="Users" />
          </Typography>
        </AddUserWrapper>
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Toolbar>
    </Fragment>
  );
};
