import styled from '@emotion/styled';
import { TextField } from '@material-ui/core';

export const WhiteTextField = styled(TextField)`
  && {
    margin-bottom: 1rem;
  }
  label.MuiFormLabel-root {
    color: ${({ theme }) => theme.palette.common.white};
  }
  .MuiInputBase-input {
    color: ${({ theme }) => theme.palette.common.white};
  }
  .MuiOutlinedInput-root {
    fieldset,
    &:hover fieldset,
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
    }
  }
` as typeof TextField;
