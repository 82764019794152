import { Theme } from '@material-ui/core';
import { IDeviceInfoResponse, IMeasurementResult } from 'models/iot-devices';
import { IntlShape } from 'react-intl';
import {voltageToPercents} from "./voltage-to-percents";

export const getBatteryLevelProps = (
  deviceInfo: IDeviceInfoResponse | null,
  measurements: IMeasurementResult[],
  intl: IntlShape,
  theme: Theme
) => {
  const voltages = measurements.filter((x) => x.value[2] === "c8y_Battery");
  const percents = measurements.filter((x) => x.value[2] === "%");
  const batteryStatus = deviceInfo?.custom_props?.parsedStatus || null;
  const props = {
    headerLabel: intl.formatMessage({ id: 'BatteryLevel' }),
    headerValue: '',
    value: 0,
    progressColor: theme.palette.line2.main,
    startValue: '0%',
    midValue: '50%',
    endValue: '100%',
    hideFooter: false,
  };
  const powerSource = deviceInfo?.deviceConfiguration?.power_source || 0;
  if (powerSource === 0) {
    const powerOn = deviceInfo?.availability === "AVAILABLE" &&
      (deviceInfo?.version !== "2.0" || deviceInfo?.connection === "CONNECTED");
    props.value = powerOn ? 100 : 0;
    props.progressColor = powerOn ? theme.palette.success.main
                                  : theme.palette.error.main;
    props.headerLabel = intl.formatMessage({ id: 'MainsPower' });
    props.hideFooter = true;
  } else if (batteryStatus || measurements.length > 0) {
    const voltage = batteryStatus?.battery_voltage
                      || (voltages[0]?.value || [])[1] || 0;
    const charge = batteryStatus?.batt_charge
                      || (percents[0]?.value || [])[1]
                      || voltageToPercents(voltage, (powerSource === 1 ? '24v' : '12v'));

    props.value = charge;
    props.headerValue = intl.formatNumber(voltage, {
      style: 'decimal',
      maximumFractionDigits: 2,
    }) + intl.formatMessage({ id: 'UnitVolts' });
    if (charge < 15) {
      props.progressColor = theme.palette.error.main;
    } else if (charge < 30) {
      props.progressColor = theme.palette.warning.main;
    } else {
      props.progressColor = theme.palette.success.main;
    }
  }
  return props;
};

export const getSiloLevelProps = (
  measurements: IMeasurementResult[],
  intl: IntlShape,
  theme: Theme
) => {
  const props = {
    headerLabel: intl.formatMessage({ id: 'SiloStatus' }),
    headerValue: '-',
    value: 0,
    progressColor: theme.palette.line2.main,
    startValue: '0%',
    midValue: '50%',
    endValue: '100%',
  };

  if (measurements.length > 0) {
    props.value = measurements[0].value[1] || 0;
    props.headerValue = `${measurements[0].value[1]}%`;
    if (measurements[0]?.value[1] < 15) {
      props.progressColor = theme.palette.error.main;
    } else if (measurements[0]?.value[1] < 30) {
      props.progressColor = theme.palette.warning.main;
    } else {
      props.progressColor = theme.palette.success.main;
    }
  }
  return props;
};

export const getFeedUsageProps = (
  deviceInfo: IDeviceInfoResponse | null,
  measurements: IMeasurementResult[],
  intl: IntlShape,
  theme: Theme
) => {
  const feedingTarget = deviceInfo?.deviceConfiguration?.fSch_max_amount || 0;
  const amountFed = deviceInfo?.custom_props?.parsedStatus?.amount_fed
                      || (measurements[0]?.value || [])[1] || 0;
  const fedPercentage = Math.min((amountFed / feedingTarget) * 100, 100) || 0;
  const kg = intl.formatMessage({ id: "UnitKg" });
  const headerValue = `${intl.formatNumber(amountFed / 1000 || 0, {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })} ${kg}`;
  const midValue = `${intl.formatNumber(feedingTarget / (2 * 1000) || 0, {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })} ${kg}`;
  const endValue = `${intl.formatNumber(feedingTarget / 1000 || 0, {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })} ${kg}`;
  const props = {
    headerLabel: intl.formatMessage({ id: 'FeedingStatus' }),
    headerValue,
    value: fedPercentage,
    progressColor: theme.palette.line2.main,
    startValue: `0 ${kg}`,
    midValue,
    endValue,
  };
  if (fedPercentage) {
    if (fedPercentage < 15) {
      props.progressColor = theme.palette.error.main;
    } else if (fedPercentage < 30) {
      props.progressColor = theme.palette.warning.main;
    } else {
      props.progressColor = theme.palette.success.main;
    }
  }
  return props;
};
