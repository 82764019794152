/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import React, { Fragment, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Typography, IconButton, Box, Paper } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { useIntl } from 'react-intl';
import { ArchivedBasinTable } from './archived-basin-table';
import { Column } from 'react-table';
import { IFishBasin } from 'models/fishbasin';
import { useStore } from 'stores/store-hooks';
import { AlertDialog } from 'components/alert-dialog/alert';
import { SearchField } from 'components/search/search';
import { formatDate } from 'translations/locales';
import { useHistory } from 'react-router-dom';

interface IArchivedBasinList {
  companyId: number;
}

export const ArchivedBasinList: FC<IArchivedBasinList> = observer(
  ({ companyId }) => {
    const intl = useIntl();
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [openUnarchiveAlert, setOpenUnarchiveAlert] = useState(false);
    const archiveStore = useStore('archiveStore');
    const { deleteBasin } = archiveStore;
    const toastStore = useStore('toastStore');
    const basinStore = useStore('basinStore');
    const { isCompanyAdmin, isConcernCompanyAdmin } = useStore('userStore');
    const history = useHistory();

    const [basins, setBasins] = useState(
      archiveStore.getBasinsByCompanyId(companyId)
    );

    const columns = useMemo(
      () =>
        [
          {
            Header: () => (
              <Typography variant="h6">
                {intl.formatMessage({ id: 'BasinName' })}
              </Typography>
            ),
            accessor: 'name',
            Cell: ({ value, cell }) => (
              <BasinNameCell
                value={value}
                basin={cell.row.original}
                openBasin={openBasin}
              />
            ),
          },
          {
            Header: () => (
              <Typography variant="h6">
                {intl.formatMessage({ id: 'Facility' })}
              </Typography>
            ),
            accessor: 'site.facility.name',
          },
          {
            Header: () => (
              <Typography variant="h6">
                {intl.formatMessage({ id: 'ArchivedOn' })}
              </Typography>
            ),
            accessor: 'deleted_timestamp',
            Cell: ({ value }: { value: string }) => (
              <TypographyCell
                value={value ? formatDate(intl, new Date(value)) : '-'}
              />
            ),
          },
          {
            Header: () => (
              <Typography variant="h6">
                {intl.formatMessage({ id: 'Biomass' })}
              </Typography>
            ),
            accessor: 'currentbiomasses[0]',
            Cell: ({ value }: { value: string }) => (
              <TypographyCell
                value={
                  value
                    ? intl.formatNumber(Number(value) || 0, {
                        style: 'decimal',
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      }) + ' ' + intl.formatMessage({ id: "UnitKg" })
                    : '-'
                }
              />
            ),
          },
          {
            Header: () => (
              <Typography variant="h6">
                {intl.formatMessage({ id: 'AverageWeight' })}
              </Typography>
            ),
            accessor: 'currentaverageweights[0]',
            Cell: ({ value }: { value: string }) => (
              <TypographyCell
                value={
                  value
                    ? intl.formatNumber(Number(value) || 0, {
                        style: 'decimal',
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      }) + ' ' + intl.formatMessage({ id: "UnitGrams" })
                    : '-'
                }
              />
            ),
          },
          {
            Header: () => (
              <Typography variant="h6">
                {intl.formatMessage({ id: 'NumberOfFish' })}
              </Typography>
            ),
            accessor: 'currentamount',
            Cell: ({ value }: { value: string }) => (
              <TypographyCell
                value={
                  value
                    ? `${intl.formatNumber(Number(value) || 0, {
                        style: 'decimal',
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })} ${intl.formatMessage({ id: 'Pieces' })}`
                    : '-'
                }
              />
            ),
          },
          {
            Header: () => (
              <Typography variant="h6">
                {intl.formatMessage({ id: 'Species' })}
              </Typography>
            ),
            accessor: 'fishtype.name',
            Cell: ({ value }: { value: string }) => (
              <TypographyCell
                value={
                  value
                    ? intl.formatMessage({
                        id: value.charAt(0).toUpperCase() + value.slice(1),
                      })
                    : ''
                }
              />
            ),
          },
          {
            Header: (
              <Typography variant="h6">
                {intl.formatMessage({ id: 'Remove' })}
              </Typography>
            ),
            id: 'delete',
            disableSortBy: true,
            // @ts-ignore
            Cell: ({ cell }) => (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => handleDelete(cell.row.original as IFishBasin)}
              >
                <DeleteIcon />
              </IconButton>
            ),
          },
          {
            Header: (
              <Typography variant="h6">
                {intl.formatMessage({ id: 'Unarchive' })}
              </Typography>
            ),
            id: 'unarchive',
            disableSortBy: true,
            // @ts-ignore
            Cell: ({ cell }) => (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => handleUnarchive(cell.row.original as IFishBasin)}
              >
                <UnarchiveIcon />
              </IconButton>
            ),
          },
        ] as Column<IFishBasin>[],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [intl]
    );

    const openBasin = (basin: IFishBasin) => {
      basinStore.setSelectedBasin(null); // basin view will reload basin with correct ancestor data
      history.push(`/basin/${basin.id}`);
    };

    const handleDelete = (basin: IFishBasin) => {
      archiveStore.basinToDelete = basin;
      setOpenDeleteAlert(true);
    };

    const removeBasin = async () => {
      setOpenDeleteAlert(false);
      const { basinToDelete } = archiveStore;
      if (basinToDelete) {
        const result = await deleteBasin(basinToDelete.id);
        if (result.length) {
          const errorIds = result.join(', ');
          toastStore.setToast('ArchivedBasinDeleteError', 'error', {
            errorIds,
          });
        } else {
          setBasins(basins.filter((b) => b.id !== basinToDelete.id));
        }
      }
    };

    const handleUnarchive = (basin: IFishBasin) => {
      archiveStore.basinToUnarchive = basin;
      setOpenUnarchiveAlert(true);
    };

    const unarchiveBasin = async () => {
      setOpenUnarchiveAlert(false);
      const { basinToUnarchive } = archiveStore;
      if (basinToUnarchive) {
        const response = await archiveStore.unarchiveBasin(
          basinToUnarchive.id,
          basinToUnarchive
        );
        if (response.errors) {
          toastStore.setToast('ErrorRestoringArchivedBasin');
        } else if (response.data) {
          setBasins(basins.filter((b) => b.id !== basinToUnarchive.id));
        }
      }
    };

    const [searchTerm, setSearchTerm] = useState("");
    const filteredBasins = basins.filter(
      (b) =>
        b.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        b.site.facility.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
      <Fragment>
        {(isCompanyAdmin && !isConcernCompanyAdmin) ? (
          <Box
            css={(theme) => css`
              display: flex;
              flex-direction: row;
              margin-bottom: ${theme.spacing(1)}px;
              margin-left: ${theme.spacing(0)}px;
              align-content: space-space-between;
              justify-content: space-between;
            `}
          >
            <Box />
            <SearchField setSearchTerm={setSearchTerm} />
          </Box>
        ) : null}
        {basins.length === 0 ? (
          <EmptyArchiveNote>
            <Typography variant="h1">
              {intl.formatMessage({ id: 'ArchiveEmpty' })}
            </Typography>
          </EmptyArchiveNote>
        ) : (
          filteredBasins.length === 0 ? (
            <EmptyArchiveNote>
              <Typography variant="h1">
                {intl.formatMessage({ id: 'NoSearchResults' })}
              </Typography>
            </EmptyArchiveNote>
          ) : (
            <ArchivedBasinTable
              basins={filteredBasins}
              columns={columns as any}
            />
          )
        )}
        {openDeleteAlert && (
          <AlertDialog
            isOpen={openDeleteAlert}
            setOpen={setOpenDeleteAlert}
            titleTextId="RemoveBasin"
            primaryButtonTextId="Delete"
            primaryButtonCallback={removeBasin}
          >
            {`${intl.formatMessage({ id: 'ConfirmDeleteBasin' })} ${
              archiveStore.basinToDelete?.name
            }?`}
          </AlertDialog>
        )}
        {openUnarchiveAlert && (
          <AlertDialog
            isOpen={openUnarchiveAlert}
            setOpen={setOpenUnarchiveAlert}
            titleTextId="UnarchiveBasin"
            primaryButtonTextId="Unarchive"
            primaryButtonCallback={unarchiveBasin}
          >
            {`${intl.formatMessage({ id: 'ConfirmUnarchive' })} ${
              archiveStore.basinToUnarchive?.name
            }?`}
          </AlertDialog>
        )}
      </Fragment>
    );
  }
);

const TypographyCell: FC<{ value: string }> = ({ value }) => (
  <Typography
    component="div"
    variant="h6"
    color="textPrimary"
    css={(theme) => css`
      &.MuiTypography-root {
        margin: 0;
      }
    `}
  >
    {value}
  </Typography>
);

const BasinNameCell: FC<{
  value: string;
  basin: IFishBasin;
  openBasin: (basin: IFishBasin) => void;
}> = ({ value, openBasin, basin }) => (
  <Box
    onClick={() => openBasin(basin)}
    css={(theme) => css`
      cursor: pointer;
      text-align: left;
    `}
  >
    <Typography component="div" variant="h5" color="primary">
      {value}
    </Typography>
  </Box>
);

const EmptyArchiveNote = styled(Paper)`
  &.MuiPaper-root {
    color: ${({ theme }) => theme.palette.text.secondary};
    background: ${({ theme }) => theme.palette.background.default};
    height: 100%;
    min-height: 200px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    box-shadow: none;
  }
`;
