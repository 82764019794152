/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FC } from 'react';
import { IBasinSplitData, IFishBasin } from 'models/fishbasin';
import { useIntl } from 'react-intl';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@material-ui/core';

export const SplitDialogHeader: FC<{
  originalBasin: IFishBasin | null | undefined;
  splitBasins: IBasinSplitData[];
  handleBasinChange: (count: number) => void;
}> = ({ originalBasin, splitBasins, handleBasinChange }) => {
  const intl = useIntl();
  const theme = useTheme();
  const biomassRowData = () => {
    const label = intl.formatMessage({ id: 'Biomass' });
    const total = originalBasin?.biomass || 0;
    const remaining =
      total -
      splitBasins.reduce((acc, data) => {
        return (acc += Number(data.biomass));
      }, 0);
    return {
      label,
      remaining: intl.formatNumber(Math.floor(remaining), {
        style: 'decimal',
      }),
      total: intl.formatNumber(Math.floor(total), {
        style: 'decimal',
      }),
    };
  };
  const fishRowData = () => {
    const label = intl.formatMessage({ id: 'NumberOfFish' });
    const total = originalBasin?.currentamount || 0;
    const remaining =
      total -
      splitBasins.reduce((acc, data) => {
        return (acc += Number(data.startamount));
      }, 0);
    return {
      label,
      remaining: intl.formatNumber(Math.floor(remaining), {
        style: 'decimal',
      }),
      total: intl.formatNumber(Math.floor(total), {
        style: 'decimal',
      }),
    };
  };

  const rows = [biomassRowData(), fishRowData()];

  return (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <TableContainer>
          <Table
            css={css`
              &&.MuiTable-root {
                .MuiTableRow-root {
                  border-bottom: 2px solid ${theme.palette.line2.main};
                }
                .MuiTableCell-root {
                  padding: 0;
                }
              }
            `}
          >
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="right">
                  {intl.formatMessage({ id: 'Remains' })}
                </TableCell>
                <TableCell align="right">
                  {intl.formatMessage({ id: 'Total' })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.label}>
                  <TableCell>{row.label}</TableCell>
                  <TableCell align="right">{row.remaining}</TableCell>
                  <TableCell align="right">{row.total}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel id="company-select-label">
            {intl.formatMessage({
              id: 'SplitTo',
            })}
          </InputLabel>
          <Select
            onChange={(event) => handleBasinChange(Number(event.target.value))}
            defaultValue={2}
            id="split-count-select"
            margin="dense"
          >
            {[2, 3, 4, 5].map((value) => {
              return (
                <MenuItem key={value} value={value}>
                  {`${value}`}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
